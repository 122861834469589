export enum TRAIT_TYPES {
  AGE = 'Age',
  DESIRE_LEVEL = 'Desire Level',
  DESIRE_TYPE = 'Desire Type',
  GENDER = 'Gender',
  INTERESTED_IN = 'Interested in',
  RELATIONSHIP_STATUS = 'Relationship Status',
  TRAUMA = 'Shyness',
}

export enum TraitSteps {
  Age = 'Age',
  Gender = 'Gender',
  InterestedIn = 'InterestedIn',
  RelashionshipStatus = 'RelashionshipStatus',
  PartnerCode = 'PartnerCode',
}

export enum DesireTypeSteps {
  DesireFeel = 'DesireFeel',
  DesireMood = 'DesireMood',
  DesireFantasy = 'DesireFantasy',
  DesirePartner = 'DesirePartner',
  Connection = 'Connection',
  Novelty = 'Novelty',
  DesireTurns = 'DesireTurns',
  Initiative = 'Initiative',
  DesirePartnerMood = 'DesirePartnerMood',
  DesireGetInMood = 'DesireGetInMood',
  DesirePartnerGetInMood = 'DesirePartnerGetInMood',
}

export type TRAIT_TYPE = {
  id: number;
  name: string;
  groupName: string;
};