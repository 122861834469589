import {useEffect} from 'react';
import {Switch, useLocation} from 'react-router-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import flagsmith from 'flagsmith';

import {useDimensionsContext} from 'context/dimensions';
import {useFirebaseAuthContext} from 'context/auth';
import {useFlagsmithContext} from 'context/flagsmith';
import {FLAGSMITH_KEY} from 'helpers/constants-helper';
import * as MyRoutes from 'constants/Routes';

import AuthenticatedRoute from './AuthenticatedRoute';
import PublicRoute from './PublicRoute';
import ScrollToTop from './ScrollToTop';

import useFlagsmith from 'common/hooks/useFlagsmith';
import {
  FEATURE_FLAG_NAME,
  FEATURE_FLAG_VARIANT_TYPE,
} from 'constants/feature-flags';

import NoMatchScreen from 'screens/NoMatch';
import OnBoardingTraitsScreen from 'screens/Onboarding';
import OnBoardingGenderTraitScreen from 'screens/Onboarding/TraitGender';
import OnBoardingAgeTraitScreen from 'screens/Onboarding/TraitAge';
import OnBoardingInterestedInTraitScreen from 'screens/Onboarding/TraitInterestedIn';
import OnBoardingRelationshipStatusTraitScreen from 'screens/Onboarding/TraitRelationshipStatus';
import OnBoardingPartnerCodeScreen from 'screens/Onboarding/PartnerCode';
import OnBoardingPartnerSendCodeScreen from 'screens/Onboarding/PartnerSendCode';
import OnBoardingTraitsConfirmationScreen from 'screens/Onboarding/TraitsConfirmation';
import OnBoardingAskOrganicSource from 'screens/Onboarding/AskOrganicSource';
import OnBoardingDesireTypeIntroScreen from 'screens/Onboarding/DesireTypeIntro';
import OnBoardingDesireTypeFeelsScreen from 'screens/Onboarding/DesireTypeFeels';
import OnBoardingDesireTypeFeelsResultScreen from 'screens/Onboarding/DesireTypeFeelsResult';
import OnBoardingDesireTypeMoodScreen from 'screens/Onboarding/DesireTypeMood';
import OnBoardingDesireTypeFantasyScreen from 'screens/Onboarding/DesireTypeFantasy';
import OnBoardingDesireTypeConnectionScreen from 'screens/Onboarding/DesireTypeConnection';
import OnBoardingDesireTypeNoveltyScreen from 'screens/Onboarding/DesireTypeNovelty';
import OnBoardingDesireTypeDesirePartnerScreen from 'screens/Onboarding/DesireTypeDesirePartner';
import OnBoardingDesireTypeTurnsOnScreen from 'screens/Onboarding/DesireTypeTurnsOn';
import OnBoardingDesireTypeConfirmationScreen from 'screens/Onboarding/DesireTypeConfirmation';
import OnBoardingDesireTypeResultScreen from 'screens/Onboarding/DesireTypeResult';
import OnBoardingDesireTypeNextScreen from 'screens/Onboarding/DesireTypeNext';
import OnBoardingDesireTypeIARIntroScreen from 'screens/Onboarding/DesireTypeIARIntro';
import OnBoardingDesireTypeIARInitiativeScreen from 'screens/Onboarding/DesireTypeIARInitiative';
import OnBoardingDesireTypeIARMoodScreen from 'screens/Onboarding/DesireTypeIARMood';
import OnBoardingDesireTypeIARPartnerMoodScreen from 'screens/Onboarding/DesireTypeIARPartnerMood';
import OnBoardingDesireTypeIARGetMoodScreen from 'screens/Onboarding/DesireTypeIARGetMood';
import OnBoardingDesireTypeIARPartnerGetMoodScreen from 'screens/Onboarding/DesireTypeIARPartnerGetMood';
import OnBoardingDesireTypeIARConfirmationScreen from 'screens/Onboarding/DesireTypeIARConfirmation';
import OnBoardingDesireTypeIARResultScreen from 'screens/Onboarding/DesireTypeIARResult';
import OnBoardingDesireTypeTraumaScreen from 'screens/Onboarding/DesireTypeTrauma';
import OnBoardingSuggestedJourneysScreen from 'screens/Onboarding/SuggestedJourneys';
import OnBoardingFreeTrialScreen from 'screens/Onboarding/FreeTrial';
import HomeScreen from 'screens/Home';
import AuthScreen from 'screens/auth/Login';
import LogoutScreen from 'screens/Logout';
import Confirmation from 'screens/Confirmation';
import CodeScreen from 'screens/Code';
import CoachingPayment from 'screens/CoachingPayment';
import OnboardingPitchOne from 'screens/auth/OnboardingPitch/one';
import Video from 'screens/Video';
import ReferralId from 'screens/ReferralId';
import OnboardingPitchTwo from 'screens/auth/OnboardingPitch/two';
import OnboardingPitchThree from 'screens/auth/OnboardingPitch/three';
import OnboardingQuiz from 'screens/auth/OnboardingQuiz';
import CoachingConfirmation from 'screens/CoachingConfirmation';
import Discussion from 'screens/Discussion';
import {getStorageItem, setStorageItem} from 'helpers';
import DiscussionDetails from 'screens/DiscussionDetails';
import ExerciseDetails from 'screens/ExerciseDetails';
import NewHome from 'screens/auth/NewHome';
import DiscussionList from 'screens/auth/DiscussionList';
import AccountDeletion from 'screens/DeleteAccount';
import OnboardingChat from 'screens/Onboarding/OnboardingChat';
import OnboardingPlay from 'screens/Onboarding/OnboardingPlay';
import OnboardingLearn from 'screens/Onboarding/OnboardingLearn';
import OnboardingGoal from 'screens/Onboarding/OnboardingGoal';
import TrialPaymentSelection from 'screens/Onboarding/TrialPaymentSelection';
import CombinedFreeTrial from 'screens/Onboarding/CombinedFreeTrial';

const Routes = () => {
  const {authUser} = useFirebaseAuthContext();
  const {setLoadingFeatureFlags, getRandomID} = useFlagsmithContext();
  const location = useLocation();

  useEffect(() => {
    const initFlagsmith = async () => {
      // Identify user if can. It's needed to use flagsmith for not logged in users
      if (authUser) {
        const userId = authUser.uid;
        const email = authUser.email;
        let traits = {
          email,
        };

        const isDTQFirstForGUID = getStorageItem('isDTQFirst');
        if (isDTQFirstForGUID) {
          traits['isDTQFirst'] = true;
        }

        await flagsmith.identify(userId, traits);
      } else {
        if (location.pathname === '/desire-type') {
          setStorageItem('isDTQFirst', true);
          await flagsmith.identify(getRandomID(), {isDTQFirst: true});
        } else {
          await flagsmith.identify(getRandomID());
        }
      }
      try {
        setTimeout(() => {
          setLoadingFeatureFlags(false);
        }, 5000);
        await flagsmith
          .init({
            environmentID: FLAGSMITH_KEY,
          })
          .then(() => {
            setLoadingFeatureFlags(false);
          });
      } catch (error) {
        setLoadingFeatureFlags(false);
      }
    };

    (async () => {
      await initFlagsmith();
    })();

    return () => {
      (async () => {
        await flagsmith.logout();
      })();
    };
  }, [authUser]);

  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER,
  });

  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;

  const variantRoute = (path: string, component: React.ComponentType<any>) => {
    return isNewOBOrder ? (
      <PublicRoute path={path} component={component} />
    ) : (
      <AuthenticatedRoute path={path} component={component} />
    );
  };

  return (
    <Switch>
      <AuthenticatedRoute exact path={MyRoutes.HOME} component={HomeScreen} />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_PARTNER_CODE}
        component={OnBoardingPartnerCodeScreen}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_PARTNER_SEND_CODE}
        component={OnBoardingPartnerSendCodeScreen}
      />

      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_TRAUMA}
        component={OnBoardingDesireTypeTraumaScreen}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_CHAT}
        component={OnboardingChat}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_PLAY}
        component={OnboardingPlay}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_LEARN}
        component={OnboardingLearn}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_TRIAL_PAYMENT_SELECTION}
        component={TrialPaymentSelection}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_GOAL}
        component={OnboardingGoal}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_SUGGESTED_JOURNEYS}
        component={OnBoardingSuggestedJourneysScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_FREE_TRIAL}
        component={OnBoardingFreeTrialScreen}
      />

      <AuthenticatedRoute path={MyRoutes.VIDEO} component={Video} />

      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_RESULT}
        component={OnBoardingDesireTypeResultScreen}
      />
      <AuthenticatedRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_RESULT}
        component={OnBoardingDesireTypeIARResultScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_NEXT}
        component={OnBoardingDesireTypeNextScreen}
      />
      <PublicRoute
        path={MyRoutes.DESIRE_TYPE}
        component={OnBoardingDesireTypeIARIntroScreen}
      />

      <PublicRoute path={MyRoutes.CONFIRMATION} component={Confirmation} />
      <PublicRoute
        path={MyRoutes.ACCOUNT_DELETTION}
        component={AccountDeletion}
      />

      {/* variant routes, depending on FF value */}
      {/* {variantRoute(MyRoutes.ONBOARDING_TRAITS, OnBoardingTraitsScreen)} */}
      <PublicRoute
        path={MyRoutes.ONBOARDING_TRAITS}
        component={OnBoardingTraitsScreen}
      />
      {variantRoute(
        MyRoutes.ONBOARDING_GENDER_TRAIT,
        OnBoardingGenderTraitScreen,
      )}
      {variantRoute(MyRoutes.ONBOARDING_AGE_TRAIT, OnBoardingAgeTraitScreen)}
      {variantRoute(
        MyRoutes.ONBOARDING_INTERESTED_IN_TRAIT,
        OnBoardingInterestedInTraitScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_RELATIONSHIP_STATUS_TRAIT,
        OnBoardingRelationshipStatusTraitScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_TRAITS_CONFIRMATION,
        OnBoardingTraitsConfirmationScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_ASK_ORGANIC_SOURCE,
        OnBoardingAskOrganicSource,
      )}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_INTRO,
        OnBoardingDesireTypeIntroScreen,
      )} */}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS,
        OnBoardingDesireTypeFeelsScreen,
      )} */}
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS}
        component={OnBoardingDesireTypeFeelsScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS_RESULT}
        component={OnBoardingDesireTypeFeelsResultScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_MOOD}
        component={OnBoardingDesireTypeMoodScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_CONNECTION}
        component={OnBoardingDesireTypeConnectionScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_TURNS_ON}
        component={OnBoardingDesireTypeTurnsOnScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_NOVELTY}
        component={OnBoardingDesireTypeNoveltyScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_CONFIRMATION}
        component={OnBoardingDesireTypeConfirmationScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_FANTASY}
        component={OnBoardingDesireTypeFantasyScreen}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER}
        component={OnBoardingDesireTypeDesirePartnerScreen}
      />
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS_RESULT,
        OnBoardingDesireTypeFeelsResultScreen,
      )} */}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_MOOD,
        OnBoardingDesireTypeMoodScreen,
      )} */}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_FANTASY,
        OnBoardingDesireTypeFantasyScreen,
      )} */}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_CONNECTION,
        OnBoardingDesireTypeConnectionScreen,
      )} */}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_NOVELTY,
        OnBoardingDesireTypeNoveltyScreen,
      )} */}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_TURNS_ON,
        OnBoardingDesireTypeTurnsOnScreen,
      )} */}
      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_CONFIRMATION,
        OnBoardingDesireTypeConfirmationScreen,
      )} */}

      {/* {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER,
        OnBoardingDesireTypeDesirePartnerScreen,
      )} */}

      {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INTRO,
        OnBoardingDesireTypeIARIntroScreen,
      )}
      {/* {variantRoute(MyRoutes.DESIRE_TYPE, OnBoardingDesireTypeIARIntroScreen)} */}
      {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INITIATIVE,
        OnBoardingDesireTypeIARInitiativeScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_MOOD,
        OnBoardingDesireTypeIARMoodScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD,
        OnBoardingDesireTypeIARPartnerMoodScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_GET_MOOD,
        OnBoardingDesireTypeIARGetMoodScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_GET_MOOD,
        OnBoardingDesireTypeIARPartnerGetMoodScreen,
      )}
      {variantRoute(
        MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_CONFIRMATION,
        OnBoardingDesireTypeIARConfirmationScreen,
      )}

      <PublicRoute
        path={MyRoutes.ONBOARDING_LANDING}
        component={OnboardingGoal}
      />
      <PublicRoute
        path={MyRoutes.NEW_HOME}
        component={NewHome}
        isHomePage={true}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_PITCH_ONE}
        component={OnboardingPitchOne}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_PITCH_TWO}
        component={OnboardingPitchTwo}
      />
      <PublicRoute
        path={MyRoutes.ONBOARDING_PITCH_THREE}
        component={OnboardingPitchThree}
      />
      <PublicRoute
        path={`${MyRoutes.ONBOARDING_QUIZ}/:screen?`}
        trackPage={false}
        component={OnboardingQuiz}
      />
      <PublicRoute path={`${MyRoutes.INVITE}/:id?`} component={ReferralId} />
      <PublicRoute path={MyRoutes.LOGIN} component={AuthScreen} />
      <PublicRoute
        path={MyRoutes.COACHING_PAYMENT}
        component={CoachingPayment}
      />
      <PublicRoute
        path={MyRoutes.COACHING_CONFIRMATION}
        component={CoachingConfirmation}
      />
      <PublicRoute
        path={`${MyRoutes.DISCUSSIONS}/:id`}
        component={DiscussionDetails}
        isHomePage={true}
      />
      <PublicRoute
        path={`${MyRoutes.ALL_DISCUSSIONS}/:id`}
        component={DiscussionList}
        isHomePage={true}
      />
      <PublicRoute
        path={MyRoutes.DISCUSSIONS}
        component={Discussion}
        isHomePage={true}
      />
      <PublicRoute path={MyRoutes.EXERCISES} component={ExerciseDetails} />
      <AuthenticatedRoute path={MyRoutes.LOGOUT} component={LogoutScreen} />
      <PublicRoute path={MyRoutes.CODE} component={CodeScreen} />
      <PublicRoute component={NoMatchScreen} />
    </Switch>
  );
};

const BrowserRouter = () => {
  const {height} = useDimensionsContext();

  useEffect(() => {
    document.documentElement.style.height = `${height}px !important`;
  }, [height]);

  return (
    <Router>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </Router>
  );
};

export default BrowserRouter;
