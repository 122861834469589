import gql from 'graphql-tag';

const GET_STATIC_TRAITS = gql`
  query Traits {
    staticTraits {
      id
      name
      displayName
      groupName
      display
    }
  }
`;

const USER_TRAITS = gql`
  query GetUserTraits {
    getUserTraitsForSettings {
      id
      name
      groupName
    }
  }
`;

export default {USER_TRAITS, GET_STATIC_TRAITS};
