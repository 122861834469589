import {useEffect} from 'react';
import { useHistory} from 'react-router';

import { DESIRE_TYPES, TOTAL_STEPS, } from 'constants/onboarding';

import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';
import { useFirebaseAuthContext } from 'context/auth';

import {
  useOnboardingDesireTypeContext,
  DesireType,
  DesireTypeSteps,
} from 'context/onboarding-desire-type';

import { useCacheContext } from 'context/cache';

import desireTypeData, {
  DESIRE_TURNS_ANSWER,
  descriptions as descriptionsPartner,
} from 'constants/desire-types-partnered.mock';
import { descriptions as descriptionsSingle } from 'constants/desire-types-single.mock';
import * as MyRoutes from 'constants/Routes';

import { isInARelationship } from 'helpers/traits-helper';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const OPTION_VALUE_ID = {
  [DESIRE_TURNS_ANSWER.SUDDENLY]: 19,
  [DESIRE_TURNS_ANSWER.SLOWLY]: 18,
  [DESIRE_TURNS_ANSWER.BOTH_WAYS]: 27,
};

const OPTION_CATEGORY = 'Desire Type';


const OnboardingDesireTypeTurnsOn = () => {
  const history = useHistory();
  const { setUserDesireType, steps } = useOnboardingDesireTypeContext();
  const { userTraits } = useCacheContext();
  const { height } = useDimensionsContext();
  const { storeUserTraitsLocally, selectedTrait } = useOnboardingTraitsContext();
  const { isAuthenticated } = useFirebaseAuthContext();
  const isUserInARelationship = isInARelationship(userTraits);
  const {utmMedium, getAllParamsString} = useUTMParams();
  const description = isUserInARelationship
    ? descriptionsPartner[DESIRE_TYPES.DESIRE_TURNS]
    : descriptionsSingle[DESIRE_TYPES.DESIRE_TURNS];
  const title = isUserInARelationship
    ? 'My desire for my partner turns on'
    : 'My desire\nturns on';

  const desireTurnsOn = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES.DESIRE_TURNS,
  );
  const handleSelectValue = async (value: DesireType) => {
    setUserDesireType(DesireTypeSteps.DesireTurns, value);

    if (!isAuthenticated) {
      storeUserTraitsLocally({ id: OPTION_VALUE_ID[value.name], groupName: OPTION_CATEGORY, name: value.name });
    }

    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_CONFIRMATION}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_CONFIRMATION);
  };

useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_TURNS_ON);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={10} total={TOTAL_STEPS} />
        <S.Answer
          text="My desire feels"
          selectedOption={steps?.DesireFeel?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS)
          }
        />
          <>
            <S.Answer
              text="Desire takes"
              selectedOption={steps?.DesireMood?.name}
              color={Colors.PaleCoral}
              onClick={() =>
                history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_MOOD)
              }
            />
            <S.Answer
              text="I need"
              selectedOption={steps?.DesirePartner?.name}
              color={Colors.Skin02}
              onClick={() =>
                history.push(
                  MyRoutes.ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER,
                )
              }
            />
          </>
      
        <S.Answer
          text="Connection is"
          selectedOption={steps?.Connection?.name}
          color={Colors.SeaGreen}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_CONNECTION)
          }
        />
        <S.Answer
          text="Novelty"
          selectedOption={steps?.Novelty?.name}
          color={Colors.Skin01}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_NOVELTY)
          }
        />
        <S.DescriptionLeft>{description}</S.DescriptionLeft>
        <S.LeftHeading>{title}</S.LeftHeading>
        <OnBoardingOptions>
          {desireTurnsOn?.map((value) => (
            <OnBoardingOption
              key={value.id}
              text={value.name}
              color={Colors.Roe}
              onClick={() => handleSelectValue(value)}
              loading={selectedTrait === value.id}
            />
          ))}
        </OnBoardingOptions>
      </S.TopContent>
      <S.BottomImage24 />
    </S.Container>
  );
};

export default OnboardingDesireTypeTurnsOn;
