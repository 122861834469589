import * as S from './styles';

export type Testimonial = {
  comment: string;
  author?: string;
  avaliation: number;
};

const TestimonialComment = ({ data }: { data: Testimonial }) => {
  const { comment, author, avaliation } = data;
  const avaliationStars = [...Array(avaliation).keys()];

  return (
    <S.CommentContainer>
      <S.CommentAvaliation>
        {avaliationStars.map((index) => (
          <S.StarIcon key={index} />
        ))}
      </S.CommentAvaliation>
      <S.CommentContent>“{comment}”</S.CommentContent>
      {author && <S.CommentAuthor>-{author}</S.CommentAuthor>}
    </S.CommentContainer>
  );
};

export default TestimonialComment;
