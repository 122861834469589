import gql from 'graphql-tag';

const SET_USER_TRAITS_ON_BOARDING = gql`
  mutation SetUserTraitsOnBoarding($traitId: Int, $traitCategory: String) {
    setUserTraitsOnBoarding(traitId: $traitId, traitCategory: $traitCategory) {
      traits
    }
  }
`;

const SET_USER_TRAITS_BULK = gql`
  mutation setUserTraitsBulk($traitIds: [Int]) {
    setUserTraitsBulk(traitIds: $traitIds) {
      traits
    }
  }
`;

const ACCEPT_PARTNER_ON_BOARDING = gql`
  mutation AcceptPartnerOnBoarding($acceptPartner: AcceptPartner) {
    acceptPartnerOnBoarding(acceptPartner: $acceptPartner) {
      isSubscriptionFreeFromPartner
      accepted
      inviterEmail
      inviterPhone
    }
  }
`;

const SET_TRAITS_ONBOARDING_COMPLETE = gql`
  mutation SetUserTraitsOnBoardingComplete {
    setUserTraitsOnBoardingComplete {
      id
    }
  }
`;

const SET_DESIRE_TYPE_ONBOARDING_COMPLETE = gql`
  mutation setDesireTypeComplete {
    setDesireTypeComplete {
      id
    }
  }
`;

export default {
  SET_USER_TRAITS_ON_BOARDING,
  ACCEPT_PARTNER_ON_BOARDING,
  SET_TRAITS_ONBOARDING_COMPLETE,
  SET_DESIRE_TYPE_ONBOARDING_COMPLETE,
  SET_USER_TRAITS_BULK
};
