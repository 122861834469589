import React from 'react';

import TestimonialComment, { Testimonial } from '../TestimonialComment';

import * as S from './styles';

type Props = {
  testimonials: Array<Testimonial>;
};

const TestimonialCarousel = ({ testimonials }: Props) => (
  <>
    <S.CarouselContainer>
      <TestimonialComment data={testimonials[0]} />
    </S.CarouselContainer>
  </>
);

export default TestimonialCarousel;
