import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Trait } from 'context/types';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import { TRAIT_TYPES, TraitSteps } from 'constants/traits';
import OnBoardingOption from 'components/OnboardingOption';
import OnboardingAnswersList from 'components/OnboardingAswersList';
import * as MyRoutes from 'constants/Routes';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const TraitGender = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const {
    getTraitsForStep,
    loadingSetTrait,
    setUserTrait,
    selectedTrait,
  } = useOnboardingTraitsContext();
  const genders = getTraitsForStep(TRAIT_TYPES.GENDER);

  const handleSelectGender = async (gender: Trait) => {
    setUserTrait(TraitSteps.Gender, gender);
    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_AGE_TRAIT}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_AGE_TRAIT);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_GENDER_TRAITS);
  }, []);

  return (
    <S.Container height={height}>
      <S.CollageMan2 />
      <S.BottomImage1 />
      <S.BottomImage2 />
      <OnboardingAnswersList progress={14} />
      <S.LeftHeading>I’m</S.LeftHeading>
      <S.OptionList>
        {genders?.map((gender: Trait) => (
          <OnBoardingOption
            key={gender.id}
            width={178}
            height={160}
            text={gender.name}
            color={Colors.PaleCoral}
            onClick={() => handleSelectGender(gender)}
            loading={loadingSetTrait && selectedTrait === gender.id}
          />
        ))}
      </S.OptionList>
    </S.Container>
  );
}

export default TraitGender;
