import * as React from "react"

function Divider() {
  return (
    <svg
      width={138}
      height={10}
      viewBox="0 0 138 10"
      fill="none"
    >
      <path
        d="M138 1c-7.666 0-7.666 8-15.334 8-7.667 0-7.667-8-15.333-8S99.667 9 92 9c-7.668 0-7.668-8-15.334-8C69 1 69 9 61.334 9c-7.666 0-7.666-8-15.334-8-7.666 0-7.666 8-15.333 8s-7.667-8-15.333-8C7.666 1 7.666 9 0 9"
        stroke="#ECA78C"
        strokeWidth={2}
      />
    </svg>
  )
}

export default Divider
