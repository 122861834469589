import React, {
  useContext,
  useState,
  useEffect,
  createContext,
} from 'react';
import {useQuery} from '@apollo/client';

import UnitsQueries from 'graphql/units/units.gql.queries';
import { getUnit, getUnit_unit, getUnit_unit_slides } from 'graphql/units/types/getUnit';
import { showErrorToast } from 'helpers/utility-helper';

interface UnitContextProps {
  unit: getUnit_unit;
  consistentUnitContentData: boolean;
  currentSlide: getUnit_unit_slides;
  currentSlideIndex: number;
  slidesSize: number;
  isFirstSlide: () => boolean;
  isLastSlide: () => boolean;
  closeUnit: () => void;
  setSlideIndex: (slideIndex: number) => void;
  incrementSlideIndex: () => void;
  navigateToNextSlide: () => void;
  decrementSlideIndex: () => void;
  navigateToPreviousSlide: () => boolean;
  navigateToPreviousSlideAdvance: () => void;
}

const UnitReadingContext = createContext({} as UnitContextProps);

export const UnitReadingProvider = ({
  children,
  unitId,
  initialSlideIndex = 0,
}: {
  children: React.ReactNode;
  unitId: number;
  initialSlideIndex?: number;
}) => {
  console.log(unitId);
  const [slidesSize, setSlidesSize] = useState<number>(
    initialSlideIndex,
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(
    initialSlideIndex,
  );
  const [currentSlide, setCurrentSlide] = useState<getUnit_unit_slides>();
  const [slideStack, setSlideStack] = useState<number[]>([initialSlideIndex]);
  const { data: unitContentData, error: unitContentError } = useQuery<getUnit>(UnitsQueries.GET_UNIT, {
    variables: { id: unitId },
    fetchPolicy: 'no-cache',
  });
  const [consistentUnitContentData, setConsistentUnitContentData] = useState<boolean>(false);

  useEffect(() => {
    if (unitContentError) {
      // Sentry.captureException(unitContentError);
      showErrorToast(
        'Oops!',
        `Can't get Unit Data.`,
      );
    } else if (unitContentData?.unit?.slides) {
      setSlidesSize(unitContentData?.unit?.slides.length);
      setCurrentSlide(unitContentData?.unit?.slides[currentSlideIndex]);
      setConsistentUnitContentData(true);
    } else if (unitContentData) {
      // Sentry.captureException(new Error(`Inconsistent unitContentData: ${JSON.stringify(unitContentData)}`));
      showErrorToast(
        'Oops!',
        'Inconsistent Unit Data.',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitContentData, unitContentError]);

  useEffect(() => {
    const newSlideStack = [...slideStack, currentSlideIndex];
    setSlideStack(newSlideStack);
    setCurrentSlide(unitContentData?.unit?.slides[currentSlideIndex]);
  }, [currentSlideIndex]);

  const decrementSlideIndex = () => {
    const newSlideStack = slideStack.slice(0, -1);
    setSlideStack(newSlideStack);
    setCurrentSlideIndex(newSlideStack.pop());
  };

  const decrementSlideIndexAdvance = () => {
    const newSlideStack = [...slideStack, currentSlideIndex - 1];
    setSlideStack(newSlideStack);
    setCurrentSlideIndex(currentSlideIndex - 1);
  };

  const incrementSlideIndex = () => {
    setCurrentSlideIndex(currentSlideIndex + 1);
  };

  const setSlideIndex = (slideIndex: number) => {
    setSlideStack([...slideStack, slideIndex]);
    setCurrentSlideIndex(slideIndex);
  };

  const closeUnit = () => {
    // TrackPlayer.reset();

    // if (navigatedFrom && navigatedFrom !== UnitReadingSlideRoutes.Slide) {
      // navigation.navigate({
      //   name: navigatedFrom,
      //   params: {},
      //   merge: true,
      // });
      // return;
    // }
    // if (navigation.canGoBack()) {
    //   navigation.goBack();
    //   return;
    // }

    // navigation.navigate(MainRoutes.Home, {
    //   screen: HomeTabsRoutes.Home,
    //   params: {},
    //   merge: true,
    // });
  };

  const isLastSlide = () => currentSlideIndex === (slidesSize - 1);
  const isFirstSlide = () => currentSlideIndex === 0;

  const navigateToNextSlide = async () => {
    // resetAudioPlayer();
    if (!isLastSlide()) {
      incrementSlideIndex();
    }
  };

  const navigateToPreviousSlideAdvance = async () => {
    // resetAudioPlayer();
    if (!isFirstSlide()) {
      decrementSlideIndexAdvance();
    }
  };

  const navigateToPreviousSlide = React.useCallback(() => {
    // resetAudioPlayer();
    if (!isFirstSlide()) {
      decrementSlideIndex();
      return true;
    }
    return false;
  }, []);

  // const resetAudioPlayer = TrackPlayer.reset;

  console.log(unitContentData);
  return (
    <UnitReadingContext.Provider
      value={{
        unit: unitContentData?.unit,
        consistentUnitContentData,
        closeUnit,
        slidesSize,
        setSlideIndex,
        currentSlideIndex,
        currentSlide,
        isFirstSlide,
        isLastSlide,
        incrementSlideIndex,
        navigateToNextSlide,
        decrementSlideIndex,
        navigateToPreviousSlide,
        navigateToPreviousSlideAdvance,
      }}
    >
      {children}
    </UnitReadingContext.Provider>
  );
};

export const useUnitReadingContext = () => useContext(UnitReadingContext);
