import {createContext, ReactNode, useContext, useState} from 'react';
import {useQuery} from '@apollo/client';
import TraitsQueries from 'graphql/traits/traits.gql.queries';
import CoursesQueries from 'graphql/courses/courses.qgl.queries';
import {
  GetCourses,
  GetCourses_staticCourses,
} from 'graphql/courses/types/GetCourses';
import { Trait, Traits } from 'context/types';

export const CacheContext = createContext<{
  courses: GetCourses_staticCourses[];
  traits: Trait[];
  userTraits: Array<{
    id: string;
    name: string;
    groupName: string;
  }>;
  userTraitsLoaded: boolean;
  fetchUserTraits: () => void;
}>({
  courses: [],
  traits: [],
  userTraits: [],
  userTraitsLoaded: false,
  fetchUserTraits: () => {},
});

/**
 * It is Context for storing data from
 * - {@link Units | https://data.getcoral.app/CONTENT/units.json}
 * - {@link Courses | https://data.getcoral.app/CONTENT/courses.json}
 * - {@link Traits | https://data.getcoral.app/CONTENT/traits.json}
 * - {FAQ}
 * - {FAQ categories}
 */

export function CacheProvider({children}: {children: ReactNode}) {
  const [traitsLoaded, setTraitsLoaded] = useState(false);

  const {data: traitsResponse} = useQuery<Traits>(
    TraitsQueries.GET_STATIC_TRAITS,
  );

  const {data: coursesResponse} = useQuery<GetCourses>(
    CoursesQueries.GET_STATIC_COURSES,
  );

  const {
    data: {getUserTraitsForSettings = []} = {},
    refetch: fetchUserTraits,
  } = useQuery(TraitsQueries.USER_TRAITS, {
    onCompleted: () => {
      setTraitsLoaded(true);
    },
  });

  return (
    <CacheContext.Provider
      value={{
        traits: traitsResponse?.staticTraits || [],
        userTraits: getUserTraitsForSettings,
        userTraitsLoaded: traitsLoaded,
        courses: coursesResponse?.staticCourses || [],
        fetchUserTraits,
      }}>
      {children}
    </CacheContext.Provider>
  );
}

export const useCacheContext = () => useContext(CacheContext);
