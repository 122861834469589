import { useState } from 'react';
import { useQuery } from '@apollo/client';
import DiscussionsQuery from 'graphql/discussions/discussions.queries';
import { APP_STORES_URL_FOR_DISCUSSION } from 'helpers/constants-helper';
import { Helmet } from 'react-helmet';

import * as S from './styles';
import { useParams } from 'react-router-dom';
import {
  CommentUnitMeta,
  GetPaginatedCommentsByUnitId_getPaginatedCommentsByUnitId_comments,
} from '../../graphql/discussions/types/GetPaginatedCommentsByUnitId';
import Comment from '../../components/Comment';
import DiscussionsSection from '../../components/DiscussionsSection';
import { isMobile } from 'react-device-detect';
import { ONBOARDING_LANDING } from '../../constants/Routes';
import { useHistory } from 'react-router';
import Header from 'components/NewHome/Header';
import Footer from 'components/NewHome/Footer';
import RatingReview from 'components/NewHome/Sponsors';
import analytics, { discussionDetailPage } from 'helpers/analytics-helper';
import { useDiscussionContext } from 'context/discussion';
import Loader from 'components/base/Loader';
import Colors from 'assets/styles/Colors';
import { decodeHtmlCharCodes } from 'helpers';

import { STORES_URL } from 'constants/Routes'
type getComments = {
  getPaginatedCommentsByUnitIdPublic: {
    comments: GetPaginatedCommentsByUnitId_getPaginatedCommentsByUnitId_comments[];
    unitMeta: CommentUnitMeta;
  };
};

type ParamProps = { id: string };

const DiscussionDetails = () => {
  const history = useHistory();
  const params = useParams<ParamProps>();
  const unitId = parseInt(params.id);
  const [unitMeta, setUnitMeta] = useState<CommentUnitMeta>();
  const [comments, setComments] = useState<
    GetPaginatedCommentsByUnitId_getPaginatedCommentsByUnitId_comments[]
  >([]);
  const { getUnit, getRelatedUnits } = useDiscussionContext();

  const discussion = getUnit(unitId);
  const units = getRelatedUnits(discussion?.courseId);

  useQuery<getComments>(
    DiscussionsQuery.GET_PAGINATED_DISCUSSION_COMMENTS_BY_UNIT_ID_PUBLIC,
    {
      variables: {
        unitId,
        offset: 0,
        limit: 5,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setUnitMeta(data.getPaginatedCommentsByUnitIdPublic.unitMeta);
        setComments([
          ...comments,
          ...data.getPaginatedCommentsByUnitIdPublic?.comments,
        ]);
      },
    },
  );

  const openLink = () => {
    window.fbq('track', 'Lead');
  };

  let commentsText = '';

  if (discussion?.comments) {
    commentsText =
      discussion?.comments > 1
        ? discussion?.comments + ' Comments'
        : '1 Comment';
  }

  const openTrial = () => {
    if (isMobile) {
      window.open(APP_STORES_URL_FOR_DISCUSSION);
    } else {
      history.push(ONBOARDING_LANDING);
    }
  };

  const buttonClick = (id: number) => {
    const newEvent = { ...discussionDetailPage, 'Unit ID': id };
    analytics.googleAnalyticsEventTracker(newEvent);
  };

  const handleStoresCTAClick = (action: string) => {
    analytics.gaEvent(`Clicked on ${action}`);
  }

  if (!unitMeta) {
    return (
      <S.LoaderContainer>
        <Loader color={Colors.Red} />
      </S.LoaderContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>{unitMeta.name}</title>
        <link
          rel="canonical"
          href={`${window.location.host}/discussions/${unitId}`}
        />
      </Helmet>
      <Header textColorBlack={true} />
      <S.WaveBg1 />
      <S.Container>
        <S.DiscussitonDetailContainer>
          <S.Header>
            <S.AppOfTheDayLogo />
            <S.CoralLogo />
            <S.TitleCenter>Start your journey today</S.TitleCenter>
            <S.TextCenter>
              We help over 500k people deepen their connection with science
              backed methodology
            </S.TextCenter>
            <S.ExpertButton
              to={{ pathname: STORES_URL}}
              target='_blank'
              onClick={() => handleStoresCTAClick('Learn more')}
              >
              LEARN MORE
            </S.ExpertButton>

            <S.Separator />
          </S.Header>
          <S.UnitLayer>
            <S.UnitTitle>{unitMeta.name ? unitMeta.name : ''}</S.UnitTitle>
            <S.UnitText>{decodeHtmlCharCodes(unitMeta.description)}</S.UnitText>
            <S.Separator />
            <S.CommentsNumberText>{commentsText}</S.CommentsNumberText>
          </S.UnitLayer>
          <S.CommentsLayer>
            <S.CommentsLayerWidth>
              {comments.slice(0, 5).map((comment, index) => (
                <Comment
                  {...comment}
                  isReply={false}
                  isNew={false}
                  author={comment.user?.username}
                  forumTitle={comment.user?.forumTitle}
                  key={index.toString()}
                />
              ))}
            </S.CommentsLayerWidth>
            {comments.length ? (
              <S.OverFlowComments>
                <S.SeeMoreLayer>
                  <S.ExpertButton
                    to={{ pathname: STORES_URL}}
                    target='_blank'
                    onClick={() => handleStoresCTAClick('See more answers')}
                  >
                    SEE MORE ANSWERS
                  </S.ExpertButton>
                </S.SeeMoreLayer>
              </S.OverFlowComments>
            ) : (
              <S.SeeMoreLayer>
                <S.ExpertButton
                  to={{ pathname: STORES_URL}}
                  target='_blank'
                  onClick={() => handleStoresCTAClick('See more answers')}
                >
                  SEE MORE ANSWERS
                </S.ExpertButton>
              </S.SeeMoreLayer>
            )}
          </S.CommentsLayer>
          {units.length ? (
            <>
              <S.RelatedBox>
                <S.RelatedTitle>RELATED DISCUSSIONS</S.RelatedTitle>
                <S.NumberOfUnitsContainer>
                  <S.NumberOfUnitsText>{units.length}</S.NumberOfUnitsText>
                </S.NumberOfUnitsContainer>
              </S.RelatedBox>
              <DiscussionsSection
                title={'Related Discussions'}
                shownUnits={units}
              />
            </>
          ) : (
            <></>
          )}
          <S.DownloadLayer>
            <S.AppOfTheDayLogoFooter />
            <S.TitleCenterFooter>Start your journey today</S.TitleCenterFooter>
            <S.StoreLinksContainer>
              <S.ClickEvent onClick={() => buttonClick(discussion?.id)}>
                <S.StoreLink
                  target="_blank"
                  href={APP_STORES_URL_FOR_DISCUSSION}
                  onClick={openLink}>
                  <S.AppStoreLogo />
                </S.StoreLink>
              </S.ClickEvent>
              <S.ClickEvent onClick={() => buttonClick(discussion?.id)}>
                <S.StoreLink
                  target="_blank"
                  href={APP_STORES_URL_FOR_DISCUSSION}
                  onClick={openLink}>
                  <S.PlayStoreLogo />
                </S.StoreLink>
              </S.ClickEvent>
            </S.StoreLinksContainer>
            <S.ExpertButtonFooter
              to={{ pathname: STORES_URL}}
              target='_blank'
              onClick={() => handleStoresCTAClick('Let\'s go!')}
            >
              LET'S GO!
            </S.ExpertButtonFooter>
          </S.DownloadLayer>
          <S.RatingAndMediaContainer>
            <RatingReview />
          </S.RatingAndMediaContainer>
        </S.DiscussitonDetailContainer>
        <Footer />
      </S.Container>
    </>
  );
};

export default DiscussionDetails;
