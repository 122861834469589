import { useEffect} from 'react';
import { useHistory } from 'react-router';
import * as MyRoutes from 'constants/Routes';
import TestimonialCarousel from 'components/TestimonialCarousel';
import { testimonialsItems } from 'constants/testimonialsItems';

import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import { useIntercom } from "react-use-intercom";
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';

const OnBoardingTraits = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();
  const { update } = useIntercom();

  const handleContinueButtonPressed = () => {
    history.push(MyRoutes.LOGIN);
  };  

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_TRAITS);
    update({ hideDefaultLauncher: false })
  }, []);

  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  });

  const isVersionedOB = featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_B;

  return (
    <S.Container height={height}>
      <S.Content>
        <S.Heading type={2}>Coral curates a personal journey just for you.</S.Heading>
        {!isVersionedOB && <TestimonialCarousel testimonials={testimonialsItems} />}
        <S.CompactContent>
          <S.Text type={7}>
            We will never sell your information.
          </S.Text>
          <S.Text type={7}>
            It will always remain private.
          </S.Text>
        </S.CompactContent>
      </S.Content>
      <S.Button
        onClick={handleContinueButtonPressed}
      >GREAT! LET'S GO</S.Button>
      <S.CollageMan />
      <S.HeaderMoon />
      <S.BottomImage />
    </S.Container>
  );
}

export default OnBoardingTraits;
