import { DiscussionProps } from 'types/api';
import * as S from './styles';

type OnboardingProps = {
  data: DiscussionProps;
};

const CommunityCard = ({ data }: OnboardingProps) => {
  const commentsLabel =
    data.comments === 0
      ? 'No comments yet'
      : data.comments === 1
      ? '1 comment'
      : `${data.comments} comments`;
  return (
    <S.ListContainer>
      <S.CardTitleContainer>
        <S.CardTitle>{data.name}</S.CardTitle>
        <S.CardImage />
      </S.CardTitleContainer>
      <S.FooterDescriptionContainer>
        <S.FooterDescription>{data.description}</S.FooterDescription>
      </S.FooterDescriptionContainer>
      <S.CardFooter>
        {data.isNew && <S.NewTooltips>NEW</S.NewTooltips>}
        <S.CommentCount>{commentsLabel}</S.CommentCount>
      </S.CardFooter>
    </S.ListContainer>
  );
};

export default CommunityCard;
