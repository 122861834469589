import * as moment from 'moment';
import 'moment-duration-format';
import * as S from './styles';

const Bar = (props) => {
    const { duration, curTime, onTimeUpdate } = props;

    const curPercentage = (curTime / duration) * 100;

    const formatDuration = (duration) => {
        return moment
            .duration(duration, "seconds")
            .format("mm:ss", { trim: false });
    }

    const calcClickedTime =(e) => {
        const clickPositionInPage = e.pageX;
        const bar = document.getElementById("progress");
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    const handleTimeDrag = (e) => {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = eMove => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener("mousemove", updateTimeOnMove);

        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    return (
        <>
            <S.Bar>
                <S.BarProgress onMouseDown={e => handleTimeDrag(e)} style={{
                    background: `linear-gradient(to right, white ${curPercentage}%, black 0)`
                }} id='progress'>
                    <S.BarProgressKnob curPercentage={curPercentage}>
                    </S.BarProgressKnob>
                </S.BarProgress>
            </S.Bar>

            <S.BarTimeContainer>
                <S.BarTime>
                    {formatDuration(curTime)}
                </S.BarTime>
                <S.BarTimeTotal>
                    {formatDuration(duration)}
                </S.BarTimeTotal>
            </S.BarTimeContainer>
        </>
    );
}

export default Bar;