import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';

import { GET_STRIPE_PROMOTION_CODE } from 'graphql/subscription/subscription.queries';
import { setStorageItem, removeStorageItem, showErrorToast, showSuccessToast } from 'helpers';

import { KEY_PROMOTION_CODE } from 'helpers/constants-helper';
import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const Code = ({ match }) => {
  const { code } = match.params;
  const history = useHistory();

  const {data: promotionCodeData} = useQuery(
    GET_STRIPE_PROMOTION_CODE,
    {
      variables: {
        code,
      },
      onError: () => {
        removeStorageItem(KEY_PROMOTION_CODE);
        showErrorToast('Error', 'Promotion Code not found or is not valid.');
        history.push(MyRoutes.HOME, {
          performSignUp: true
        });
      }
    }
  )

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.CODE);
  }, []);

  useEffect(() => {
    if(!!promotionCodeData) {
      if(promotionCodeData.getStripePromotionCode?.active) {
        setStorageItem(KEY_PROMOTION_CODE, promotionCodeData.getStripePromotionCode);
        showSuccessToast('Promotion Code Applied', 'Your discount will be available on payment.');
      } else {
        showErrorToast('Invalid Promotion Code', 'This Promotion Code is no longer available');
      }
      history.push(MyRoutes.LOGIN, {
        performSignUp: true
      });
    }
  }, [promotionCodeData]);

  return (
    <S.Container>
      <S.LoadingContainer>
        <S.Spinner />
        <S.LoadingContainerText>
          Loading Discount...
        </S.LoadingContainerText>
      </S.LoadingContainer>
    </S.Container>
  );
}

export default Code;