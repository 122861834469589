export const testimonialsItems = [
  {
    comment:
      'I came to Coral feeling really down and missing my desire. Now I have hope.',
    author: 'Yasmine',
    avaliation: 5,
  },
  {
    comment:
      'I’m becoming more comfortable with my body and sexual wants and desires.',
    author: 'Emily',
    avaliation: 5,
  },
  {
    comment: 'Coral has really put the fun back into sex.',
    author: 'Nick',
    avaliation: 5,
  },
];

export const testimonialConnectItems = [
  {
    comment:
      'Using the guided chat sparked a very intense, deep dialogue between the two of us about sex that we have NEVER had before.',
    author: 'Amy',
    avaliation: 5,
  },
  {
    comment:
      'I’ve found new ways of thinking about sex, love, and my relationship that I had never considered.',
    author: 'Carrie',
    avaliation: 5,
  },
  {
    comment:
      'I am married with kids and this app has completely changed my relationship for the better.',
    author: 'Helmut',
    avaliation: 5,
  },
];
