import styled from 'styled-components';
import tw from 'twin.macro';
import CustomButton from 'components/base/Button';
import Title from 'components/base/Heading';
import Paragraph from 'components/base/Paragraph';

import Colors from 'assets/styles/Colors';
import { ASSETS_PATH } from 'constants/assets';
import QRCodeImg from 'assets/images/qrcode.jpg'

export const Container = styled.div`
  background-color: ${Colors.Coconut};
  ${tw`flex flex-1 flex-col p-5`}
`;

export const CoralLogo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/coral-logo.svg`,
}))`
  ${tw`my-4 text-left mt-24 mb-1 z-10`}
  width: 200px;
`;

export const Heading = styled(Title).attrs(() => ({
  type: 4,
}))`
  ${tw`my-4 text-left mb-1 z-10`}
  max-width: 75%;
  font-family: 'GTSuper';
`;

export const SubHeading = styled(Paragraph).attrs(() => ({
  type: 3,
}))`
  ${tw`my-4 text-left mb-5 z-10`}
  max-width: 80%;
  font-family: 'Andes';
  font-weight: 500;
`;

export const StoreLinks = styled.div`
  ${tw`z-10 flex mb-40`}
  margin: auto auto;
`;

export const StoreLink = styled.a`
  ${tw`z-10 flex`}
`;

export const AppStoreLogo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/app-store.png`,
}))`
  width: 151px;
  margin-right: 5px;
`;

export const PlayStoreLogo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/play-store.png`,
}))`
  width: 151px;
`;

export const HeaderBackgroundImage = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/confirmation-top.png`,
}))`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;

  @media screen and (min-width: 560px) {
    height: 540px;
  }
`;

export const FooterBackgroundImage = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/confirmation-bottom.png`,
}))`
  position: absolute;
  width: 100%;
  bottom: -25px;
  left: 0;
  object-fit: cover;
  width: 100vw;
`;

export const BigOrangeMoon = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/confirmation-moon.png`,
}))`
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: cover;
`

export const QRCode = styled.img.attrs(() => ({
  src: QRCodeImg,
}))`
  display: block;
	width: 280px;
	height: 280px;
  margin: 1rem auto 9rem;
`;
;

export const Button = styled(CustomButton)`
  ${tw`z-10 mb-4`};
  width: 80%;
  align-self: center
`;
