import * as S from './styles';

const CoachingCheckoutAboutV1 = () => {
  return (
    <S.About>
    <S.PicureContainer>
      <S.PictureContent />
    </S.PicureContainer>
    <S.AboutCenter>
      <S.AboutTitle>IMPROVE YOUR RELATIONSHIP WITH</S.AboutTitle>
      <S.AboutName>Zoë Kors</S.AboutName>
    </S.AboutCenter>
    <S.AboutRight>
      <S.AboutTitle>ABOUT</S.AboutTitle>
      <S.AboutContentText>
      Zoe is a recognized sex and intimacy expert, educator, coach and author of Radical Intimacy. Zoë is trained in Sri Vidya tantra and is a certified co-active coach.
      </S.AboutContentText>
    </S.AboutRight>
  </S.About>
  );
}

export default CoachingCheckoutAboutV1;