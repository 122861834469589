import {useState} from 'react';
import * as S from './styles';

interface Props {
  children: string;
}

const EllipsedText = ({
  children,
}: Props) => {
  const [showAllText, setShowAllText] = useState(false);

  const toggleEllipsedText = () => {
    setShowAllText(!showAllText);
  };

  const sanitizeText = (text: string) => {
    // currently only <br> tags show up in comment text.
    // if we encounter more HTML tags in the text, we
    // should simply use Webview to render the text.
    return text?.replace(/<br\s*\/?>/gi, '\n');
  };

  return (
    <S.Container onClick={toggleEllipsedText}>
      <S.Text showAllText={showAllText}>
        {sanitizeText(children)}
      </S.Text>
    </S.Container>
  );
};

export default EllipsedText;
