import * as S from './styles';

const Footer = () => {
  const FooterMenu1 = [
    { label: 'Home', url: 'https://getcoral.app' },
    { label: 'Get Coral', url: 'https://getcoral.app/download' },
    { label: 'Jobs', url: 'https://getcoral.app/jobs' },
    { label: 'Press', url: 'https://getcoral.app/press' },
  ];
  const FooterMenu2 = [
    { label: 'CONTACT US', url: 'https://getcoral.app/contact' },
    { label: 'COPYRIGHT', url: 'https://getcoral.app/copyright' },
    { label: 'PRIVACY', url: 'https://getcoral.app/privacy' },
    { label: 'TERMS', url: 'https://getcoral.app/terms' },
  ];

  return (
    <>
      <S.HomeFooterContainer>
        <S.FooterBGImage />
        <S.FooterTextContainer>
          <S.FooterImageContainer>
            <S.Twitter />
            <S.Facebook />
            <S.Instagram />
          </S.FooterImageContainer>
          <S.CoralLogoFooter />

          <S.MenuContainer>
            {FooterMenu1.map((menu, index) => {
              return (
                <S.MenuName key={index} href={menu.url}>
                  {menu.label}
                </S.MenuName>
              );
            })}
          </S.MenuContainer>

          <S.LastMenuContainer>
            {FooterMenu2.map((menu, index) => {
              return (
                <S.LastMenuName key={index} href={menu.url}>
                  {menu.label}
                </S.LastMenuName>
              );
            })}
          </S.LastMenuContainer>
        </S.FooterTextContainer>
      </S.HomeFooterContainer>
    </>
  );
};

export default Footer;
