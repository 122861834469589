import {useState, useEffect} from 'react';
import { useHistory } from 'react-router';

import { DESIRE_TYPES, TOTAL_STEPS } from 'constants/onboarding';

import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';
import { useFirebaseAuthContext } from 'context/auth';

import {
  useOnboardingDesireTypeContext,
  DesireType,
  DesireTypeSteps,
} from 'context/onboarding-desire-type';

import { useCacheContext } from 'context/cache';

import desireTypeData, {
  DESIRE_FEEL_ANSWER,
  descriptions as descriptionsPartner,
} from 'constants/desire-types-partnered.mock';
import { descriptions as descriptionsSingle } from 'constants/desire-types-single.mock';

import { isInARelationship } from 'helpers/traits-helper';

import * as MyRoutes from 'constants/Routes';
import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { parseStringToMultipleLines } from 'helpers';
import { useDimensionsContext } from 'context/dimensions';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const OPTION_VALUE_ID = {
  [DESIRE_FEEL_ANSWER.LOWER_THAN_ID_LIKE]: 15,
  [DESIRE_FEEL_ANSWER.JUST_RIGHT]: 16,
  [DESIRE_FEEL_ANSWER.HIGHER_THAN_ID_LIKE]: 17,
};
const OPTION_CATEGORY = 'Desire Level';

const OnboardingDesireTypeFeels = () => {
  const history = useHistory();
  const { setUserDesireType } = useOnboardingDesireTypeContext();
  const { userTraits } = useCacheContext();
  const { height } = useDimensionsContext();
  const { storeUserTraitsLocally } = useOnboardingTraitsContext()
  const [loadingTrait, setTrait] = useState<null | number>(null);
  const { isAuthenticated } = useFirebaseAuthContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const isUserInARelationship = isInARelationship(userTraits);
  const description = isUserInARelationship
    ? descriptionsPartner[DESIRE_TYPES.DESIRE_FEEL]
    : descriptionsSingle[DESIRE_TYPES.DESIRE_FEEL];

  const desireFeel = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES.DESIRE_FEEL,
  );

  const handleSelectValue = async (value: DesireType) => {
    try {

      setTrait(value.id);
      setUserDesireType(DesireTypeSteps.DesireFeel, value);

    if (!isAuthenticated) {
      //variant A
      storeUserTraitsLocally({ id: OPTION_VALUE_ID[value.name], groupName: OPTION_CATEGORY, name: value.name })
    } else {
      setTrait(null);
    }

    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS_RESULT}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS_RESULT);
    } catch (error) {
    }
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_FEELS);
  }, []);

  return (
    <S.Container height={height}>
      <S.ProgressBar progress={4} total={TOTAL_STEPS} />
      {parseStringToMultipleLines(description).map((descriptionLine, idx) => (
        <S.DescriptionLeft key={idx}>{descriptionLine}</S.DescriptionLeft>
      ))}
      <S.DescriptionLeft>
        How do you feel about your current level of desire?
      </S.DescriptionLeft>
      <S.LeftHeading>My sexual desire feels</S.LeftHeading>
      <OnBoardingOptions>
        {desireFeel?.map((value) => (
          <OnBoardingOption
            key={value.id}
            text={value.name}
            color={Colors.PaleKelp}
            onClick={() => handleSelectValue(value)}
            loading={loadingTrait === value.id}
          />
        ))}
      </OnBoardingOptions>
      <S.BottomImage19 />
    </S.Container>
  );
};

export default OnboardingDesireTypeFeels;
