import Colors from 'assets/styles/Colors';
import styled from 'styled-components';
import tw from 'twin.macro';
import { FaStar } from 'react-icons/fa'

export const CommentContainer = styled.div`
  ${tw` flex flex-col justify-center rounded-xl`}
`;

export const CommentAvaliation = styled.div`
  ${tw`flex flex-row`}
`;

export const StarIcon = styled(FaStar)`
  ${tw`mr-2 text-lg text-yellow-400`}
`;

export const CommentContent = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  ${tw`leading-5 tracking-tight mt-4`}
`;

export const CommentAuthor = styled.div`
  font-family: 'Andes';
  color: #042552;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  ${tw`font-normal leading-4 uppercase`}
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
