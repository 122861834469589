import React from 'react';
import * as S from './styles';
import Loader from 'components/base/Loader';
import Colors from '../../assets/styles/Colors';

type Props = {
  text: string;
  width?: number;
  height?: number;
  color: Colors;
  onClick: () => void;
  loading?: boolean;
};

const OnBoardingOption = ({
  color,
  text,
  width = 168,
  height = 96,
  onClick,
  loading = false,
}: Props) => {

  return (
    <S.Container
      onClick={onClick}
      width={width}
      height={height}
      backgroundColor={color}>
      {loading ? (
        <Loader />
      ) : (
        <S.Text width={width}>{text}</S.Text>
      )}
    </S.Container>
  )
};

export default OnBoardingOption;
