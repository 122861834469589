import * as Routes from 'constants/Routes';

const GRAPHQL_BASE_URL = process.env.REACT_APP_BASE_URL;
const PUBLIC_IP_FETCH_URL = 'https://api.ipify.org?format=json';
const DEEPLINK_URL = 'coral://';
const STRIPE_API_URL = process.env.REACT_APP_STRIPE_API_URL;
const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const STRIPE_SUBSCRIPTION_PRICE_ID =
  process.env.REACT_APP_STRIPE_SUBSCRIPTION_PRICE_ID;
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const FLAGSMITH_KEY = process.env.REACT_APP_FLAGSMITH_KEY;
const PURSHASES_API_KEY = process.env.REACT_APP_PURSHASES_API_KEY;
const CONVERSIONS_API_URL = process.env.REACT_APP_CONVERSIONS_API_URL;
const CONVERSIONS_API_KEY = process.env.REACT_APP_CONVERSIONS_ACCESS_TOKEN;

const KEY_ACCESS_TOKEN = 'accessToken';
const KEY_RANDOM_GUID = 'randomGUID';
const KEY_PROMOTION_CODE = 'promotionCode';
const KEY_LOCAL_TRAITS = 'LOCAL_TRAITS';
const KEY_LOCAL_DESIRE_TYPES = 'LOCAL_DESIRE_TYPES';

//Referral Id
const KEY_REFERRAL_ID = 'REFERRAL_ID';

const APP_STORES_URL_FOR_HOME = 'https://link.getcoral.app/yn0uZSzqxtb';
const APP_STORES_URL_FOR_DISCUSSION = 'https://link.getcoral.app/rwUbluEqxtb';
const APP_STORES_URL_FOR_NON_DISCUSSION =
  'https://link.getcoral.app/OxUL5SQqxtb';

const KEY_ONBOARDING_TRAITS_COMPLETED = 'onboardingTraitsCompleted';
const KEY_ONBOARDING_DESIRE_TYPE_COMPLETED = 'onboardingDesireTypeCompleted';

const EVENT_MAP_FOR_ROUTES = {
  [Routes.HOME]: 'Home',
  [Routes.LOGIN]: 'Login/Sign Up',
  [Routes.VIDEO]: 'Video',
  [Routes.ONBOARDING_TRAITS]: 'Traits Start',
  [Routes.ONBOARDING_GENDER_TRAIT]: 'Traits Gender',
  [Routes.ONBOARDING_AGE_TRAIT]: 'Traits Age',
  [Routes.ONBOARDING_INTERESTED_IN_TRAIT]: 'Traits Interested In',
  [Routes.ONBOARDING_RELATIONSHIP_STATUS_TRAIT]: 'Traits Relationship Status',
  [Routes.ONBOARDING_PARTNER_CODE]: 'Partner Code Provided',
  [Routes.ONBOARDING_PARTNER_SEND_CODE]: 'Partner Code Share',
  [Routes.ONBOARDING_TRAITS_CONFIRMATION]: 'Traits Confirmation',
  [Routes.ONBOARDING_ASK_ORGANIC_SOURCE]: 'How Did You Hear',
  [Routes.ONBOARDING_DESIRE_TYPE]: 'DTQ Start',
  [Routes.ONBOARDING_DESIRE_TYPE_FEELS]: 'DTQ Desire Feels',
  [Routes.ONBOARDING_DESIRE_TYPE_FEELS_RESULT]: 'DTQ Desire Feels Result',
  [Routes.ONBOARDING_DESIRE_TYPE_FANTASY]: 'DTQ Fantasy',
  [Routes.ONBOARDING_DESIRE_TYPE_MOOD]: 'DTQ Mood',
  [Routes.ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER]: 'DTQ Time',
  [Routes.ONBOARDING_DESIRE_TYPE_CONNECTION]: 'DTQ Connection',
  [Routes.ONBOARDING_DESIRE_TYPE_TURNS_ON]: 'DTQ Desire Speed',
  [Routes.ONBOARDING_DESIRE_TYPE_NOVELTY]: 'DTQ Novelty',
  [Routes.ONBOARDING_DESIRE_TYPE_RESULT]: 'DTQ Assessment',
  [Routes.ONBOARDING_DESIRE_TYPE_TRAUMA]: 'Trauma Question',
  [Routes.ONBOARDING_SUGGESTED_JOURNEYS]: 'Journey Recommendations',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_INTRO]: 'IAR-DTQ Start Screen',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_INITIATIVE]: 'IAR-DTQ Q1',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_MOOD]: 'IAR-DTQ Q2',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD]: 'IAR-DTQ Q3',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_GET_MOOD]: 'IAR-DTQ Q4',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_GET_MOOD]: 'IAR-DTQ Q5',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_CONFIRMATION]: 'IAR-DTQ Confirmation',
  [Routes.ONBOARDING_DESIRE_TYPE_IAR_RESULT]: 'IAR-DTQ Assessment',
  [Routes.ONBOARDING_FREE_TRIAL]: 'Trial Start',
  [Routes.SUBSCRIBE]: 'Subscription Payment',
  [Routes.CODE]: 'Apply Promotion Code',
  [Routes.CONFIRMATION]: 'Subscription Confirmation',
  [Routes.ONBOARDING_LANDING]: 'Onboarding Landing',
  [Routes.ONBOARDING_PITCH_ONE]: 'Onboarding Pitch One',
  [Routes.ONBOARDING_PITCH_TWO]: 'Onboarding Pitch Two',
  [Routes.ONBOARDING_PITCH_THREE]: 'Onboarding Pitch Three',
  [Routes.ACCOUNT_DELETTION]: 'Account Deletion',
  [Routes.ONBOARDING_CHAT]: 'Onboarding Chat',
  [Routes.ONBOARDING_PLAY]: 'Onboarding Play',
  [Routes.ONBOARDING_LEARN]: 'Onboarding Learn',
  [Routes.ONBOARDING_GOAL]: 'Onboarding Goal',
  [Routes.DESIRE_TYPE]: 'Onboarding Desire Type',
  [Routes.ONBOARDING_DESIRE_TYPE_CONFIRMATION]: 'Boarding Desire Type Confirmation',
  [Routes.ONBOARDING_DESIRE_TYPE_NEXT]: 'Boarding Desire Type Next',
};

export {
  PUBLIC_IP_FETCH_URL,
  GRAPHQL_BASE_URL,
  KEY_ACCESS_TOKEN,
  KEY_RANDOM_GUID,
  KEY_PROMOTION_CODE,
  KEY_LOCAL_TRAITS,
  KEY_LOCAL_DESIRE_TYPES,
  KEY_REFERRAL_ID,
  DEEPLINK_URL,
  APP_STORES_URL_FOR_HOME,
  APP_STORES_URL_FOR_DISCUSSION,
  APP_STORES_URL_FOR_NON_DISCUSSION,
  KEY_ONBOARDING_TRAITS_COMPLETED,
  KEY_ONBOARDING_DESIRE_TYPE_COMPLETED,
  EVENT_MAP_FOR_ROUTES,
  STRIPE_API_URL,
  STRIPE_API_KEY,
  STRIPE_KEY,
  STRIPE_SUBSCRIPTION_PRICE_ID,
  INTERCOM_APP_ID,
  FLAGSMITH_KEY,
  PURSHASES_API_KEY,
  CONVERSIONS_API_URL,
  CONVERSIONS_API_KEY
};
