import styled from 'styled-components';
import tw from 'twin.macro';

import CustomHeading from 'components/base/Heading';
import Link from 'components/base/Link';
import Button from 'components/base/Button';
import Input from 'components/base/Input';

import Colors from 'assets/styles/Colors';
import LoginBottomBackground from 'assets/images/login-bottom-background';
import Snake from 'assets/images/snake';
import LoginTextBackgroundSrc from 'assets/images/background-login-text.png';
import CoralBlack from 'assets/images/coral-logo-black.png';
import AppleLogoSvg from 'assets/images/appleLogo.svg';
import Paragraph from 'components/base/Paragraph';

import { FaStar, FaCheck, FaApple } from 'react-icons/fa';
import { ASSETS_PATH } from 'constants/assets';

export const Container = styled.div<{ height: number }>`
  background-color: ${Colors.Coconut};
  ${tw`flex flex-1 flex-col overflow-y-auto`};
  padding-bottom: 0px;
`;

export const InputsContainer = styled.div`
  ${tw`flex-1 flex-col z-10`}
  flex: 0.7;
`;

export const Heading = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  ${tw`text-center`};
  padding: 1rem;
`;

export const SloganContainer = styled.div`
  ${tw`my-7 mx-10`}
`;

export const SloganText = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 4,
}))`
  ${tw`text-center`}
`;

export const StarsContainer = styled.div`
  ${tw`flex flex-row mt-2 justify-center`}
`;

export const StarIcon = styled(FaStar)`
  ${tw`mr-1 text-xs text-yellow-400`}
`;

export const CheckIcon = styled(FaCheck)`
  color: ${Colors.ColorIcon};
  font-size: 32px;
  margin-right: 20px;
`;

export const AuthInput = styled(Input)`
  ${tw`p-4`}
`;

export const AuthButton = styled(Button).attrs({
  type: 'submit',
})`
  ${tw`z-10 mb-4`}
`;

export const AppleIcon = styled(FaApple)`
  ${tw`mr-1.5 mb-1 text-xl`}
`;

export const LinksContainer = styled.div`
  font-family: 'Andes';
  letter-spacing: 0.2em;
  margin-top: 6%;
  margin-bottom: 4%;
  ${tw`text-center text-xs uppercase font-medium z-10`}
`;

export const MiddleContainer = styled.div`
  z-index: 999;
  flex: 0.5;
`;
export const ChecklistContainer = styled.div`
  padding: 44px;
`;

export const CheckText = styled.div`
  font-family: 'Andes';
  font-size: 22px;
  line-height: 26px;
  display: flex;
  min-width: 80%;
  flex-direction: row;
  align-items: center;
  margin: 24px;
  @media screen and (max-width: 640px) {
    margin: 24px 0px;
  }
`;

export const AuthMethodLink = styled(Link).attrs({
  type: 'submit',
  to: '#',
})``;

export const Footer = styled.div`
  align-items: center;
  z-index: 2;
  background-color: ${Colors.BackgroundFooterWave};
  height: auto;
`;

export const Logo = styled.img.attrs(() => ({
  // src: `${ASSETS_PATH}/coral-logo.png`,
  src: CoralBlack,
}))`
  height: 40px;
  width: 135px;
  margin: 24px auto 30px;
`;

export const AppleLogo = styled.img.attrs(() => ({
  src: AppleLogoSvg,
}))`
margin: auto;
padding: 2rem 2rem 0.5rem 2rem;
`;

export const PrivacyText = styled(Paragraph).attrs(() => ({
  type: 3,
}))`
  text-align: center;
  margin-bottom: 0px;
  justify-content: center;
  line-height: 24px;
  padding: 0 40px;
`;

export const LinkText = styled(Link)`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: underline;
  color: ${Colors.Ash};
  text-decoration-color: ${Colors.Ash};
`;

export const HeaderBackgroundImage = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/moon-big-yellow.png`,
}))`
  position: absolute;
  width: 571px;
  height: 593px;
  top: -450px;
  left: 0;
  object-fit: cover;
`;

export const BottomBackgroundImage = styled(LoginBottomBackground)``;

export const LoginTextBackground = styled.img.attrs({
  src: LoginTextBackgroundSrc,
})`
  width: 100%;
  height: auto;
  left: 0px;  
  z-index: 0;
`;

export const Spaccing = styled.div`
  padding: 1.25rem
`;

export const BigGreyMoon = styled.div`
  position: absolute;
  width: 227.05px;
  height: 227.05px;
  left: 0;
  bottom: 50px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-big-grey.png`});
  background-size: cover;
  background-position-x: -140px;
  background-repeat: no-repeat;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  width: 124px;
  height: 124px;
  right: 0px;
  bottom: 200px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: cover;
  background-position-x: 90px;
  background-repeat: no-repeat;
`;

export const HeadingFooter = styled(Heading)`
  font-size: 26px;
  line-height: 32px;
  padding: 0 40px;
`;

export const CurvedLineContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px auto -20px;
`;

export const CurvedLine = styled(Snake)``;