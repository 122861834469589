import React, { useEffect } from 'react';
import { useHistory} from 'react-router';

import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import PlayIcon from 'assets/images/exercise_pillar.png';
import PlayContentIcon from 'assets/images/pillar_two.png';

const OnboardingPlay = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const onPressContinue = () => {
    history.push(MyRoutes.ONBOARDING_LEARN);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.ONBOARDING_PLAY);
  }, []);

  return (
    <S.Container height={height}>
        <S.PlayTopBackground />
      {/* <S.TopContent style={{paddingTop: 30}}>
        <S.Heading type={2}>Welcome to Coral</S.Heading>
      </S.TopContent> */}
      <S.Content>
      <S.PillarView>
            <S.PillarIcon src={PlayIcon} />
            <S.PillarTitle>
              {'PLAY'}
            </S.PillarTitle>
          </S.PillarView>
      <S.ChatContentImage src={PlayContentIcon} />
      <S.ChatContent  type={4}>
          Try new things and explore more pleasure with our library of hundreds of sexy guided exercises
    </S.ChatContent>
        </S.Content>
      <S.Footer>
        <S.Button onClick={onPressContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.PlayBottomImage />
    </S.Container>
  );
};

export default OnboardingPlay;
