import {DESIRE_TYPES} from 'constants/onboarding';

export const DESIRE_FEEL_ANSWER = {
  LOWER_THAN_ID_LIKE: 'lower than I’d like',
  JUST_RIGHT: 'just right',
  HIGHER_THAN_ID_LIKE: 'higher than I’d like',
};

export const DESIRE_TURNS_ANSWER = {
  SUDDENLY: 'suddenly',
  SLOWLY: 'slowly',
  BOTH_WAYS: 'both ways',
};

export default [
  {
    id: 1,
    name: DESIRE_FEEL_ANSWER.LOWER_THAN_ID_LIKE,
    type: 'desire-feel',
  },
  {
    id: 2,
    name: DESIRE_FEEL_ANSWER.JUST_RIGHT,
    type: 'desire-feel',
  },
  {
    id: 3,
    name: DESIRE_FEEL_ANSWER.HIGHER_THAN_ID_LIKE,
    type: 'desire-feel',
  },
  {
    id: 4,
    name: 'often',
    type: DESIRE_TYPES.FANTASY,
  },
  {
    id: 5,
    name: 'never',
    type: DESIRE_TYPES.FANTASY,
  },
  {
    id: 6,
    name: 'sometimes',
    type: DESIRE_TYPES.FANTASY,
  },
  {
    id: 7,
    name: 'more time',
    type: 'desire-partner',
  },
  {
    id: 8,
    name: 'less time',
    type: 'desire-partner',
  },
  {
    id: 9,
    name: 'about the same',
    type: 'desire-partner',
  },
  {
    id: 10,
    name: 'necessary',
    type: 'connection',
  },
  {
    id: 11,
    name: 'not necessary',
    type: 'connection',
  },
  {
    id: 12,
    name: 'a bonus',
    type: 'connection',
  },
  {
    id: 13,
    name: 'increases my desire',
    type: 'novelty',
  },
  {
    id: 14,
    name: 'decreases my desire',
    type: 'novelty',
  },
  {
    id: 15,
    name: 'isn’t a factor',
    type: 'novelty',
  },
  {
    id: 16,
    name: DESIRE_TURNS_ANSWER.SUDDENLY,
    type: 'desire-turns',
  },
  {
    id: 17,
    name: DESIRE_TURNS_ANSWER.SLOWLY,
    type: 'desire-turns',
  },
  {
    id: 18,
    name: DESIRE_TURNS_ANSWER.BOTH_WAYS,
    type: 'desire-turns',
  },
];

export const STEPS = {
  FEEL: 'feel',
  MOOD: 'mood',
  PARTNER: 'partner',
  CONNECTION: 'connection',
  NOVELTY: 'novelty',
  TURNS_ON: 'turns-on',
};

export const descriptions = {
  [DESIRE_TYPES.DESIRE_FEEL]:
    'Understanding that desire changes over time and discovering how you primarily experience desire at the moment are the first steps to creating your best intimate life. ',
  [DESIRE_TYPES.FANTASY]:
    'Does your mind often wander into a sexual fantasy without an obvious cause, like when you’re picking up groceries, sitting in traffic or just staring into space?',
  [DESIRE_TYPES.CONNECTION]:
    'Do you need to feel a connection in order to feel desire?',
  [DESIRE_TYPES.NOVELTY]:
    'Does novelty, or trying new things in bed, increase your desire?',
  [DESIRE_TYPES.DESIRE_TURNS]:
    'Does your sexual desire turn on suddenly or grow slowly within a sexual context?',
};

export const desireFeelResult = {
  [DESIRE_FEEL_ANSWER.LOWER_THAN_ID_LIKE]: `There are many causes of low desire.

It could be that you’re primarily experiencing  responsive desire at the moment, and you’re not creating the circumstances you need for your desire to grow.
  
Let's figure out if you have responsive desire.
  `,
  [DESIRE_FEEL_ANSWER.JUST_RIGHT]: `Great! How we feel about our desire is the most important test of whether or not it's at a healthy level. 

Now, let's understand what types of desire you’re experiencing.
  `,
  [DESIRE_FEEL_ANSWER.HIGHER_THAN_ID_LIKE]: `There are many causes of high desire. 

It could be a physiological reason, or you could think you have problematically high desire because your desire levels are high relative to your partner or peers.

Let's start by understanding what types of desire you’re experiencing.
  `,
};

export const desireTypeResultSlideOne = {
  [DESIRE_TURNS_ANSWER.SUDDENLY]: `This means you most likely tend towards spontaneous desire. You’re in good company! This is the dominant experience for 34% of male, 12% of female and 15% of trans and gender diverse Coralers. 

Spontaneous desire is like a light switch and can seem to appear out of nowhere.
  `,
  [DESIRE_TURNS_ANSWER.SLOWLY]: `This means you most likely tend towards responsive desire. You’re in good company! This is the dominant experience for 35% of female, 14% of male and 27% of trans and gender diverse Coralers.   

Responsive desire appears gradually through arousal, or when you deliberately engage in sexy behavior. Sometimes it takes more effort to get in the mood.
  `,
  [DESIRE_TURNS_ANSWER.BOTH_WAYS]: `This means you experience a mix of spontaneous and responsive desire. You’re in the majority! 

This is the dominant experience for 53% of female, 52% of male and 58% of trans and gender diverse Coralers.
  `,
};

export const desireTypeResultSlideTwo = {
  [DESIRE_TURNS_ANSWER.SUDDENLY]: `The other type of desire is responsive. 

You likely experience responsive desire on occasion, like when you’re flirting with a crush or your desire grows in response to self-pleasure.
  `,
  [DESIRE_TURNS_ANSWER.SLOWLY]: `The other type of desire is spontaneous. 

Spontaneous desire is like a light switch and can seem to appear out of nowhere. You may experience spontaneous desire on occasion.
  `,
  [DESIRE_TURNS_ANSWER.BOTH_WAYS]: `Spontaneous desire is like a light switch and can seem to appear out of nowhere. 

Responsive desire appears gradually through arousal, or when you deliberately engage in sexy behavior. Sometimes it takes more effort to get in the mood.`,
};
