import DiscussionCard from 'components/DiscussionCard';
import { DiscussionProps } from 'types/api';

import * as S from './styles';
import * as MyRoutes from 'constants/Routes';
import analytics from 'helpers/analytics-helper';

type DiscussionsSectionProps = {
  courseId?: number;
  title?: string;
  shownUnits: Array<DiscussionProps>;
};

const DiscussionsSection = ({
  courseId,
  title,
  shownUnits,
}: DiscussionsSectionProps) => {
  const data = shownUnits
    ? shownUnits.length > 3
      ? shownUnits.slice(0, 3)
      : shownUnits
    : [];

  const handleClick = (discussionsPage: any) => {
    const newEventList = { ...discussionsPage, 'Category name': title };
    analytics.googleAnalyticsEventTracker(newEventList);
  };

  return (
    <S.Container>
      <S.DiscussionListContainer>
        {data.map((discussion, index) => (
          <DiscussionCard key={index} discussion={discussion} big />
        ))}
        {shownUnits.length > 3 && (
          <S.FooterCard
            to={`${MyRoutes.ALL_DISCUSSIONS}/${courseId}`}
            onClick={handleClick}>
            <S.ViewAllText>View All</S.ViewAllText>
          </S.FooterCard>
        )}
      </S.DiscussionListContainer>
    </S.Container>
  );
};

export default DiscussionsSection;
