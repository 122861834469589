import Button from 'components/base/Button';
import TestimonialCarousel from 'components/TestimonialNewCarousel';
import * as S from './styles';
import { testimonialConnectItems } from 'constants/testimonialsItems';

type Props = {
  title?: string;
  description: string;
  subDescription?: string;
  subDescriptionRed?: string;
  logoUrl: string;
  onPressContinue: () => void;
  showTestimonial?: boolean;
};

const OnboardingPitch = ({
  title,
  description,
  logoUrl,
  onPressContinue,
  subDescription,
  subDescriptionRed,
  showTestimonial,
}: Props) => {
  return (
    <>
      {showTestimonial ?
        <>
          <S.TestimonialContent>
            <S.TestimonialTitle>{description}</S.TestimonialTitle>
            <S.TestimonialCart>
              <S.TestimonialContainer>
                <TestimonialCarousel testimonials={testimonialConnectItems} />
              </S.TestimonialContainer>
              <S.Title>{title}</S.Title>
              <S.SubDescription>
                <S.SubDescriptionRed>
                  {subDescriptionRed ? subDescriptionRed + ' ' : ''}
                </S.SubDescriptionRed>
                <S.SubDescriptionText>
                  {subDescription}
                </S.SubDescriptionText>
              </S.SubDescription>
            </S.TestimonialCart>
            <S.BackGroungImage />
          </S.TestimonialContent>
          <S.TestimonialFooter>
            <Button onClick={onPressContinue}>CONTINUE</Button>
          </S.TestimonialFooter>
        </>
        :
        <>
          <S.Content>
            <S.Cart>
              <S.Logo src={logoUrl} style={{alignSelf: 'center'}} />
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
              <S.SubDescription>
                <S.SubDescriptionRed>
                  {subDescriptionRed ? subDescriptionRed + ' ' + subDescription : ''}
                </S.SubDescriptionRed>
              </S.SubDescription>
            </S.Cart>
          </S.Content>
          <S.Footer>
            <Button onClick={onPressContinue}>CONTINUE</Button>
          </S.Footer>
        </>
      }

    </>
  );
};

export default OnboardingPitch;
