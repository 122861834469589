import {
  USER_TRAITS_GROUP_NAME,
  USER_TRAIT_RELATIONSHIP_STATUS,
  MAPPED_USER_TRAIT_GROUP_NAME,
  USER_TRAIT_SHYNESS_DISPLAY_NAME,
  MAPPED_USER_TRAIT_SHYNESS_DISPLAY_NAME,
} from 'constants/user-traits';

type UserTraits = {
  id: string;
  name: string;
  groupName: string;
};

export const isInARelationship = (userTraits: UserTraits[]) => {
  const relationshipTraitValue = userTraits.find(
    ({groupName}) => groupName === USER_TRAITS_GROUP_NAME.RELATIONSHIP_STATUS,
  );

  const inARelationship =
    relationshipTraitValue &&
    relationshipTraitValue.name ===
      USER_TRAIT_RELATIONSHIP_STATUS.IN_A_RELATIONSHIP;

  return inARelationship;
};

/**
 * This mapping exists in order to keep the description "Shyness" for internal references
 * and show "Survivor of sexual trauma" to the users.
 *
 * Ref.: https://getcoral.atlassian.net/browse/PHOEN-250
 */
export const mapUserTraitShynessGroupName = (traitGroupName: string) =>
  traitGroupName === USER_TRAITS_GROUP_NAME.SHYNESS
    ? MAPPED_USER_TRAIT_GROUP_NAME.SHYNESS
    : traitGroupName;

/**
 * This mapping exists in order to replace the Shyness trait values:
 * Not at All -> No
 * Very shy -> Yes
 *
 * Ref.: https://getcoral.atlassian.net/browse/PHOEN-250
 */
export const mapUserTraitShynessDisplayName = (traitDisplayName: string) => {
  switch (traitDisplayName) {
    case USER_TRAIT_SHYNESS_DISPLAY_NAME.NOT_AT_ALL:
      return MAPPED_USER_TRAIT_SHYNESS_DISPLAY_NAME.NOT_AT_ALL;
    case USER_TRAIT_SHYNESS_DISPLAY_NAME.VERY_SHY:
      return MAPPED_USER_TRAIT_SHYNESS_DISPLAY_NAME.VERY_SHY;
    default:
      return traitDisplayName;
  }
};
