import styled from 'styled-components';
import Title from '../base/Heading';
import Colors from '../../assets/styles/Colors';
import tw from 'twin.macro';

interface OptionProps {
  width: number;
  height: number;
  backgroundColor: Colors;
};

type TextProps = typeof Title & {
  width: number;
};

export const Container = styled.a<OptionProps>`
  height: ${({height}) => `${height}px`};
  background-color: ${({backgroundColor}) => backgroundColor};
  ${tw`flex items-center justify-center px-4 rounded-2xl`}
`;

export const Text = styled(Title).attrs({
  type: 6,
})<TextProps>`
  text-align: center;
`;
