import {useEffect} from 'react';
import {useHistory} from 'react-router';

import {useOnboardingDesireTypeContext} from 'context/onboarding-desire-type';
import {useCacheContext} from 'context/cache';

import ProgressBar from 'components/ProgressBar';

import {TOTAL_STEPS} from 'constants/onboarding';
import {desireTypeResultSlideTwo as desireTypeResultsPartnered} from 'constants/desire-types-partnered.mock';
import {desireTypeResultSlideTwo as desireTypeResultsSingle} from 'constants/desire-types-single.mock';
import {desireTypeNextNoUser as desireTypeNextWithoutUser} from 'constants/desire-types-partnered.mock';

import {isInARelationship} from 'helpers/traits-helper';

import * as MyRoutes from 'constants/Routes';
import * as S from './styles';
import {parseStringToMultipleLines} from 'helpers/utility-helper';
import {useDimensionsContext} from 'context/dimensions';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';

const OnboardingDesireTypeNext = () => {
  const history = useHistory();
  const {steps} = useOnboardingDesireTypeContext();
  const {userTraits} = useCacheContext();
  const {height} = useDimensionsContext();

  const isUserInARelationship = isInARelationship(userTraits);
  const description = !steps.DesireTurns ?
    desireTypeNextWithoutUser :
    isUserInARelationship
      ? desireTypeResultsPartnered[steps.DesireTurns.name]
      : desireTypeResultsSingle[steps.DesireTurns.name];


  const onPressContinue = () => {
    history.push(MyRoutes.ONBOARDING_TRAITS);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_NEXT);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <ProgressBar progress={13} total={TOTAL_STEPS} />
        {parseStringToMultipleLines(description).map((descriptionLine, idx) => (
          <S.DescriptionLeft key={idx}>{descriptionLine}</S.DescriptionLeft>
        ))}
      </S.TopContent>
      <S.Footer>
        <S.Button onClick={onPressContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.BottomFeet3 />
      <S.BottomCircle />
    </S.Container>
  );
};

export default OnboardingDesireTypeNext;
