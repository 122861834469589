import { createContext, useContext, useState } from "react";
import qs from 'query-string';

type Params = object | string;

type ParamsContextProps = {
  params: object,
  addParams: (params: Params) => void,
  resetParams: () => void,
};

const ParamsContext = createContext({} as ParamsContextProps);

export const ParamsProvider = ({children}: {children: React.ReactNode}) => {
  const [params, setParams] = useState({});

  const addParams = (params: Params) => {
    const parsedParams = (typeof(params) === 'string') 
      ? qs.parse(params, {parseBooleans: true, parseNumbers: true})
      : params;


    setParams(currentParams => (
      {...currentParams, ...parsedParams}
    ))
  };

  const resetParams = () => setParams({});

  return (
    <ParamsContext.Provider value={{
      params,
      addParams,
      resetParams,
    }}>
      {children}
    </ParamsContext.Provider>
  );
}

export const useParamsContext = () => useContext(ParamsContext);
