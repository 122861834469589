import * as React from 'react';

function ConnectOBLayer2() {
  return (
    <div style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 610,
      overflow: 'hidden'
    }}>
      <svg
        fill="none"
        viewBox="0 0 375 811"
        width="100vw"
      >
        <path
          d="M661.138 383.561C790.434 385.08 835.996 445.069 844 580.235V764h-2730c-.9-38.835 168.07-259.935 299.85-309.819 86.24-32.646 148.26 47.535 253.66 19.659 108.9-28.797 82.77-99.115 179.79-114.663 122.92-19.699 134.22 74.417 224.111 95.004 210.825 48.284 118.829-34.171 195.791-78.973 72.483-42.195 118.289 32.533 179.168 51.636 48.72 15.288 114.619-9.511 155.771-125.294C-319.05 79.817-216.229 201.314-160.2 104.116-104.172 6.918-6.892-58.386 93.466 76.78c100.359 135.166 141.611 5.315 210.569 53.155 68.957 47.84 18.113 182.596 168.7 113.144 146.536-67.583 59.107 138.963 188.403 140.482z"
          fill="#f7f4f3"
        />
      </svg>
    </div>
  );
}

export default ConnectOBLayer2;
