import { useEffect } from 'react';
import * as S from './styles';
import ReactPlayer from 'react-player';
import videoCust from '../../assets/videos/video.mp4';
import { useHistory } from 'react-router';
import * as MyRoutes from 'constants/Routes';
import { useIntercom } from "react-use-intercom";
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const Video = () => {
  const history = useHistory();
  const { update } = useIntercom();
  const {utmMedium, getAllParamsString} = useUTMParams();

  useEffect(() => {
    analytics.track(ANALYTICS_EVENT_NAMES.VIEWED_OB_VIDEO);

  }, []);

  const handleGoToConfirmationStep = (eventName?: string) => {
    eventName && analytics.track(eventName);
        utmMedium ? 
    history.push(`${MyRoutes.ONBOARDING_GENDER_TRAIT}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_GENDER_TRAIT);
  };

  useEffect(() => {
    update({ hideDefaultLauncher: true })
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.VIDEO);
  }, []);

  return (
    <S.Video>
      <ReactPlayer
        playing
        volume={1}
        url={videoCust}
        width="100%"
        height="100%"
        controls={true}
        playsinline
        onEnded={() =>
          handleGoToConfirmationStep(ANALYTICS_EVENT_NAMES.FINISHED_OB_VIDEO)
        }
        config={{
          file: {
            attributes: {
              autoPlay: true,
              muted: true
            }
          }
        }}
      />
      <S.SkipButton
        onClick={() =>
          handleGoToConfirmationStep(ANALYTICS_EVENT_NAMES.SKIPPED_OB_VIDEO)
        }
      >
        <S.Text>SKIP</S.Text>
      </S.SkipButton>
    </S.Video>
  );
};

export default Video;
