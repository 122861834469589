import {convertObjectKeysToSnakeCase} from './utility-helper';
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import * as amplitude from '@amplitude/analytics-browser';
import {sendEvents} from './conversation-helper';
import CryptoJS from "crypto-js";
import {getAuth} from 'firebase/auth';

const hashEmail = () => {
  const auth = getAuth();
  const email = auth.currentUser ? auth.currentUser?.email : 'support@getcoral.co';
  return CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
};

const identifyUser = (userId: string, additionalData?: any) => {
  amplitude.setUserId(userId);
  const identifyObj = new amplitude.Identify();
  Object.keys(additionalData).forEach(item => {
    identifyObj.set(item, JSON.stringify(additionalData[item]));
  })
  amplitude.identify(identifyObj);
};

const addPlatform = (events: any) => {
  return events ? {...events, platform: 'web'} : {platform: 'web'};
};

const track = (
  eventName: string,
  eventProps?: any,
  convertPropsToSnakeCase = true,
) => {
  const props = addPlatform(eventProps);
  const data = convertPropsToSnakeCase ? convertObjectKeysToSnakeCase(props) : props;
  fbq('track', eventName, data);
  amplitude.track(
    eventName,
    data
  );
  sendEvents({
    data: [
      {
        event_name: eventName,
        event_time: Math.floor(Date.now() / 1000),
        user_data: {
          em: hashEmail()
        },
      },
    ],
  });
};

const initializeGoogleAnalytics = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_WRITE_KEY);
  ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_WRITE_KEY);
};

const googleAnalyticsEventTracker = (category: any) => {
  ReactGA.pageview(category);
  ReactGA4.send({hitType: "pageview", page: category});
};

const gaEvent = (action: string) => {
  ReactGA.event({
    category: 'CTA',
    action
  });
  ReactGA4.event({
    category: 'CTA',
    action
  });
};

const page = (pageName: string, pageProps?: any) => {
  if (!pageName)
    return;
  const props = addPlatform(pageProps);
  fbq('track', `Viewed ${pageName} Screen`, props);
  amplitude.track(`Viewed ${pageName} Screen`, props);
  sendEvents({
    data: [
      {
        event_name: `Viewed ${pageName} Screen`,
        event_time: Math.floor(Date.now() / 1000),
        user_data: {
          em: hashEmail()
        },
      },
    ],
  });
};

const reset = () => {
  amplitude.reset();
};

export default {
  identifyUser,
  track,
  page,
  reset,
  googleAnalyticsEventTracker,
  gaEvent,
  initializeGoogleAnalytics,
};

export enum ANALYTICS_EVENT_NAMES {
  USER_REGISTERED = 'User Registered',
  USER_LOGIN = 'User Login',
  UNIT_STARTED = 'Unit Started',
  LOG_IN_EMAIL = 'Log In Email',
  LOG_IN_PHONE = 'Log In Phone',
  CREATE_ACCOUNT_EMAIL = 'Create Account Email',
  CREATE_ACCOUNT_PHONE = 'Create Account Phone',
  TRIAL_STARTED = 'Trial Started',
  TRIAL_SKIPPED = 'Trial Skipped',
  TRIAL_START_CLICKED = 'Trial Start Clicked',
  TRIAL_START = 'Trial Start',
  PARTNERS_CONNECTED = 'Partners Connected',
  PARTNER_INVITED = 'Partner Invited',
  LANDING_PITCH = 'Viewed Landing Pitch',
  USER_ORGANIC_SOURCE_SUBMITTED = 'User Organic Source Submitted',
  VIEWED_OB_VIDEO = 'Viewed OB Video',
  SKIPPED_OB_VIDEO = 'Skipped OB Video',
  FINISHED_OB_VIDEO = 'Finished OB Video',
  BOARDING_LANDING = 'Boarding Landing',
  BOARDING_PITCH_ONE = 'Boarding Pitch One',
  BOARDING_PITCH_TWO = 'Boarding Pitch Two',
  BOARDING_PITCH_THREE = 'Boarding Pitch Three',
  BOARDING_TRAITS = 'Boarding Traits',
  BOARDING_GENDER_TRAITS = 'Boarding Gender Traits',
  BOARDING_AGE_TRAITS = 'Boarding Age Traits',
  BOARDING_INTRESTED_TRAITS = 'Boarding Intrested In Traits',
  BOARDING_RELATIONSHIP_STATUS_TRAITS = 'Boarding Relationship Status Traits',
  BOARDING_PARTNER_CODE = 'Boarding Partner Code',
  PARTNER_SEND_CODE = 'Partner Send Code',
  BOARDING_TRAITS_CONFIRMATION = 'Boarding Traits Confirmation',
  BOARDING_ASK_ORGANIC_SOURCE = 'Boarding ASK Organic Source',
  BOARDING_DESIRE_TYPE_INTRO = 'Boarding Desire Type Intro',
  BOARDING_DESIRE_TYPE_FEELS = 'Boarding Desire Type Feels',
  BOAEDING_DESIRE_TYPE_FEELS_RESULTS = 'Boarding Desire Type Feels Results',
  BOARDING_DESIRE_TYPE_MOOD = 'Boarding Desire Type Mood',
  BOARDING_DESIRE_TYPE_FANTASY = 'Boarding Desire Type Fantasy',
  BOARDING_DESIRE_TYPE_CONNECTION = 'Boarding Desire Type Connection',
  BOARDING_DESIRE_TYPE_NOVELTY = 'Boarding Desire Type Novelty',
  BOARDING_DESIRE_TYPE_DESIRE_PARTNER = 'Boarding Desire Type Desire Partner',
  BOARDING_DESIRE_TYPE_TURNS_ON = 'Boarding Desire Type Turns On',
  BOARDING_DESIRE_TYPE_CONFIRMATION = 'Boarding Desire Type Confirmation',
  BOARDING_DESIRE_TYPE_NEXT = 'Boarding Desire Type Next',
  BOARDING_DESIRE_TYPE_IAR_INTRO = 'Boarding Desire Type IAR Intro',
  BOARDING_DESIRE_TYPE_IAR_INITIATIVE = 'Boarding Desire Type IAR Initiative',
  BOARDING_DESIRE_TYPE_IAR_MOOD = 'Boarding Desire Type IAR Mood',
  BOARDING_DESIRE_TYPE_RESULT = 'Boarding Desire Type Result',
  BOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD = 'Boarding Desire TypE IAR Partner Mood',
  BOARDING_DESIRE_TYPE_IAR_GET_MOOD = 'Boarding Desire TypE IAR Get Mood',
  BOARDING_DESIRE_TYPE_IAR_PARTNER_GET_MOOD = 'Boarding Desire Type IAR Partner Get Mood',
  BOARDING_DESIRE_TYPE_IAR_GET_CONFIRMATION = 'Boarding Desire Type IAR Get Confiramation',
  BOARDING_DESIRE_TYPE_IAR_GET_RESULT = 'Boarding Desire Type IAR Get Result',
  BOARDING_DESIRE_TYPE_TRAUMA = 'Boarding Desire Type Trauma',
  BOARDING_SUGGESTED_JOURNEYS = 'Boarding Suggested Journeys',
  BOARDING_FREE_TRIAL = 'Boarding Free Trial',
  CONFIRMATION = 'Confirmation',
  SUBSCRIBE = 'Subscribe',
  VIDEO = 'Video',
  COACHING_PAYMENT = 'Coaching Payment',
  COACHING_CONFIRMATION = 'Coaching Confirmation',
  LOGOUT = 'Logout',
  CODE = 'Code',
  REFERRAL_ACCEPTED = 'Referral Accepted',
  ACCOUNT_DELETTION = 'Account deletion',
  ONBOARDING_CHAT = 'Onboarding Chat',
  ONBOARDING_PLAY = 'Onboarding Play',
  ONBOARDING_LEARN = 'Onboarding Learn',
  ONBOARDING_GOAL = 'Onboarding Goal',
  ONBOARDING_GOAL_SELECT_OPTION = 'Onboarding Landing Select Option',
  DESIRE_TYPE = 'Onboarding Desire Type'
}

export const homePage = {
  Platform: 'Web',
  From: 'Website Home Page',
};

export const discussionsPage = {
  Platform: 'Web',
  From: 'Discussions Page',
};

export const discussionListPage = {
  Platform: 'Web',
  From: 'Category Page',
};

export const discussionDetailPage = {
  Platform: 'Web',
  From: 'Discussion unit',
};
