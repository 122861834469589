import { FC, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';

import StripeForm from 'components/StripeForm';
import { StripeProvider } from 'context/Stripe';
import * as MyRoutes from 'constants/Routes';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';

import { showErrorToast, showSuccessToast } from 'helpers/utility-helper';
import { PaymentError } from 'types/subscription-types';
import {
  createStripeCustomer,
  createStripeSetupIntent,
  createStripeSubscription,
  getStripePromotionCode,
  getStripePrice,
  updateStripeCustomerPaymentMethod
} from 'helpers/stripe-helper';
import useFlagsmith from 'common/hooks/useFlagsmith';

import * as S from './styles';
import CoachingCheckoutAboutV1 from 'components/CoachingCheckoutAboutV1';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const STRIPE_PRODUCT_PRICE_ID = 'price_1KoKwtJJfNcfvpVEYTzy1oXd';

const CoachingPayment: FC = () => {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);
  const [weekPrices, setWeekPrices] = useState([]);
  const [userEmail, setUserEmail] = useState<string>(null);
  const [promotionCode, setPromotionCode] = useState(null);
  const [price, setPrice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [setupIntent, setSeuptIntent] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const updatePaymentMethod = async (paymentMethod: string) => {
    await updateStripeCustomerPaymentMethod(paymentMethod, customer.id);
  }

  const createSubscription = async () => {
    return await createStripeSubscription({
      customerId: customer?.id,
      priceId: price?.id,
      promotionCode: promotionCode?.id,
    });
  };

  const onSubscriptionSuccess = async (paymentMethod: string) => {
    try {
      setIsSubscribing(true);
      await updatePaymentMethod(paymentMethod);
      const {data: subscriptionData} = await createSubscription();
  
      if(!subscriptionData) throw Error();

      history.push(MyRoutes.COACHING_CONFIRMATION);
    } catch(error) {
      showErrorToast(
        'Subscription failure',
        'Your subscription failed. Please try again.',
      );
    } finally {
      setIsSubmitButtonPressed(false);
    }
  };

  const onSubscriptionFailure = () => {
    showErrorToast('Payment failure', 'Your payment failed. Please try again.');
    setIsSubmitButtonPressed(false);
  };

  const handleSubmit = () => {
    setIsSubmitButtonPressed(true);
  }

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.COACHING_PAYMENT);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { email, code, priceid } = qs.parse(location.search);
        
        if(!email) throw new PaymentError('No user email provided');

        setUserEmail(email.toString());

        const price = await getStripePrice(priceid ? priceid.toString() : STRIPE_PRODUCT_PRICE_ID);

        if(!price) throw new PaymentError('No payment information provided');

        setWeekPrices(JSON.parse(price?.metadata?.week_prices ?? []));
        setPrice(price);

        if(code) {
          const promoCodeData = await getStripePromotionCode(code.toString());

          if(!promoCodeData) throw new PaymentError(`${code} code is not valid`)

          if(promoCodeData?.coupon?.metadata?.product_id === price?.product) {
            setPromotionCode(promoCodeData);
            showSuccessToast('Promotion Code Applied', 'Your discount will be available on payment.');
          } else {
            showErrorToast('Promotion Code Error', <span><strong>{code}</strong> code is not valid for this product.</span>)
          }
        }

        const newCustomerData = {
          name: `Customer for ${email}`,
          email,
          description: 'Customer user from Coaching webflow',
        }

        const {data: customerData} = await createStripeCustomer(newCustomerData);
        if(!customerData) throw new PaymentError(`Unable to register user ${email}`);

        setCustomer(customerData);

        const {data: setupIntentData} = await createStripeSetupIntent({ 'payment_method_types[]': 'card', customer: customerData.id });
        if(!setupIntentData) throw Error();

        setSeuptIntent(setupIntentData);

      } catch(error) {
        if(error instanceof PaymentError) {
          showErrorToast('Error', error.message);
        } else {
          showErrorToast('Internal Error', 'There was a problem loading the payment screen');
        }
      }
    })();
  }, [location.search]);

  return (
    <S.Container>
      <S.Head>
        <S.HeadContent>
          <S.HeadLeftContent>
            <a href="https://trycoral.webflow.io/coral-coaching"><S.Logo /></a>
          </S.HeadLeftContent>
          <S.HeadRightContent>
            <a href="https://trycoral.webflow.io/faq" target="_blank" rel="noreferrer">FAQ</a>
          </S.HeadRightContent>
        </S.HeadContent>
      </S.Head>
      <S.Content>
        <CoachingCheckoutAboutV1 />
        {setupIntent?.client_secret ? (
          <S.Form>
            <S.LeftContent>
                <S.Card>
                  <S.CardHead>
                    <S.CardTitle>Your Info</S.CardTitle>
                  </S.CardHead>
                  <S.CardBody>
                    <S.CardRowInput>
                      <S.CardInputLabel>Email *</S.CardInputLabel>
                      <S.CardInputField value={userEmail} />
                    </S.CardRowInput>
                  </S.CardBody>
                </S.Card>
                <S.Card>
                  <S.CardHead>
                    <S.CardTitle>Payment Info</S.CardTitle>
                  </S.CardHead>
                  <S.CardBody>
                    <StripeProvider
                      clientSecret={setupIntent?.client_secret}>
                      <StripeForm
                        onSuccess={onSubscriptionSuccess}
                        onFailure={onSubscriptionFailure}
                        isSubscribing={isSubscribing}
                        showPaymentButton={false}
                        externalSubmit={isSubmitButtonPressed}
                      />
                    </StripeProvider>
                  </S.CardBody>
                </S.Card>
                <S.SmallScreen>
                  <S.SubmitButton
                    disabled={isSubmitButtonPressed}
                    onClick={handleSubmit}>
                    PLACE ORDER
                  </S.SubmitButton>
                </S.SmallScreen>
            </S.LeftContent>
            <S.RightContent>
                <S.Card>
                  <S.CardBody>
                    <S.CardRowTop>
                      <S.PlanTitle>
                        Your Coaching Payment
                        <S.PlanDescription>
                          Expert 1:1 coaching, including weekly calls, daily texts and a custom treatment plan.
                        </S.PlanDescription>
                      </S.PlanTitle>
                      <S.PlanPrice>${weekPrices[0] || '19'}/week 1</S.PlanPrice>
                    </S.CardRowTop>
                    <S.CardRowGold>
                      Register today for 80% off your first session!*
                    </S.CardRowGold>
                    <S.Separator />
                    <S.CardRowGold>
                      <S.CardRowLabel>TODAY'S TOTAL</S.CardRowLabel>
                      <S.PlanPrice>${weekPrices[0] || '19'} for week 1</S.PlanPrice>
                    </S.CardRowGold>
                      <S.CardRowSmall>
                        <S.CardSmallText>
                          Your coach will connect with you via text within 24 hours.
                        </S.CardSmallText>
                      </S.CardRowSmall>
                      <S.CardRowSmall>
                        <S.CardSmallText>
                          Future service billed ${weekPrices[1] || '59'} per week. We will send you a email reminder 2 days before.
                        </S.CardSmallText>
                      </S.CardRowSmall>
                      <S.CardRowSmall>
                        <S.CardSmallText>
                          Cancel anytime.
                        </S.CardSmallText>
                      </S.CardRowSmall>
                  </S.CardBody>
                </S.Card>
                <S.BigScreen>
                  <S.SubmitButton
                    disabled={isSubmitButtonPressed}
                    onClick={handleSubmit}>
                    PLACE ORDER
                  </S.SubmitButton>
                </S.BigScreen>
            </S.RightContent>
          </S.Form>
        ) : (
          <></>
        )}
      </S.Content>
      <S.Footer>
        <S.FooterContent>
          <S.FooterLeftContent>
            <a href="https://trycoral.webflow.io/coral-coaching"><S.LogoWhite /></a>
          </S.FooterLeftContent>
          <S.FooterRightContent>
          </S.FooterRightContent>
        </S.FooterContent>
      </S.Footer>
    </S.Container>
  );
};

export default CoachingPayment;
