import * as React from 'react';
import Colors from "../styles/Colors";

function StarCircle(props) {
    const { size = 22, backgroundcolor = Colors.White, fillColor = Colors.Black } = props;
    const starSize = size / 2;
    const starCenter = starSize / 2;

    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" {...props}>
            <circle r={starSize} cx={starSize} cy={starSize} fill={backgroundcolor} />
            <path transform={`translate(${starCenter}, ${starCenter})`} d="M4.99986 8.21982L7.42069 9.68399C7.86403 9.95232 8.40653 9.55566 8.28986 9.05399L7.64819 6.30066L9.78903 4.44566C10.1799 4.10732 9.96986 3.46566 9.45653 3.42482L6.63903 3.18566L5.53653 0.583989C5.33819 0.111489 4.66153 0.111489 4.46319 0.583989L3.36069 3.17982L0.543193 3.41899C0.0298593 3.45982 -0.180141 4.10149 0.210693 4.43982L2.35153 6.29482L1.70986 9.04816C1.59319 9.54982 2.13569 9.94649 2.57903 9.67816L4.99986 8.21982Z" fill={fillColor} />
        </svg>
    );
}

export default StarCircle;
