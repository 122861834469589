import { APP_STORES_URL_FOR_NON_DISCUSSION } from 'helpers/constants-helper';

import * as S from './styles';
import RatingAndMedia from 'components/RatingAndMedia';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as MyRoutes from 'constants/Routes';
import { useHistory } from 'react-router';
import { UnitReadingProvider } from 'context/unit-reading';
import Slide from './Slide';
import { useEffect } from 'react';
import analytics from 'helpers/analytics-helper';

type ParamsProps = {
  unitId: string;
};
const ExerciseDetails = () => {
  const history = useHistory();
  const { unitId } = useParams<ParamsProps>();

  const openLink = () => {
    window.fbq('track', 'Lead');
  };

  const openTrial = () => {
    if (isMobile) {
      window.open(APP_STORES_URL_FOR_NON_DISCUSSION);
    } else {
      history.push(MyRoutes.ONBOARDING_LANDING);
    }
  };

  const handleStoresCTAClick = (action: string) => {
    analytics.gaEvent(`${action} Clicked`);
  }

  useEffect(() => {
    analytics.initializeGoogleAnalytics();
  }, [])

  return (
    <S.Container>
      <S.Header>
        <S.AppOfTheDayLogo />
        <S.CoralLogo />
        <S.TitleCenter>Start your journey today</S.TitleCenter>
        <S.TextCenter>
          We help over 500k people deepen their connection with science backed
          methodology
        </S.TextCenter>
        <S.ClickEvent onClick={() => handleStoresCTAClick('Try it free')}>
          <S.ExpertButton onClick={openTrial}>TRY IT FREE</S.ExpertButton>
        </S.ClickEvent>
        <S.Separator />
      </S.Header>
      <UnitReadingProvider unitId={parseInt(unitId)}>
        <Slide />
      </UnitReadingProvider>
      <S.RelatedBox>
        <S.RelatedTitle>NEXT UP</S.RelatedTitle>
        <S.NumberOfUnitsContainer>
          <S.NumberOfUnitsText>1</S.NumberOfUnitsText>
        </S.NumberOfUnitsContainer>
      </S.RelatedBox>
      <S.DownloadLayer>
        <S.AppOfTheDayLogo />
        <S.TitleCenter>Start your journey today</S.TitleCenter>
        <S.StoreLinksContainer>
          <S.StoreLink
            target="_blank"
            href={APP_STORES_URL_FOR_NON_DISCUSSION}
            onClick={openLink}>
            <S.AppStoreLogo />
          </S.StoreLink>
          <S.StoreLink
            target="_blank"
            href={APP_STORES_URL_FOR_NON_DISCUSSION}
            onClick={openLink}>
            <S.PlayStoreLogo />
          </S.StoreLink>
        </S.StoreLinksContainer>
        <S.ClickEvent onClick={() => handleStoresCTAClick('Let\'s go')}>
          <S.ExpertButton onClick={openTrial}>LET'S GO!</S.ExpertButton>
        </S.ClickEvent>
      </S.DownloadLayer>
      <S.RatingAndMediaContainer>
        <RatingAndMedia />
      </S.RatingAndMediaContainer>
      <S.BottomLayer>
        <S.BgBottom />
        <S.CoralLogoWhite />
        <S.MenuContainer>
          <S.MenuBig>Home</S.MenuBig>
          <S.MenuBig>Get Coral</S.MenuBig>
          <S.MenuBig>Jobs</S.MenuBig>
          <S.MenuBig>Press</S.MenuBig>
        </S.MenuContainer>
        <S.MenuContainer>
          <S.MenuSmall>Contact us</S.MenuSmall>
          <S.MenuSmall>Copyright</S.MenuSmall>
          <S.MenuSmall>Privacy</S.MenuSmall>
          <S.MenuSmall>Terms</S.MenuSmall>
        </S.MenuContainer>
      </S.BottomLayer>
    </S.Container>
  );
};

export default ExerciseDetails;
