import React from 'react';
import * as S from './styles';

type Props = {
  progress: number;
  total: number;
};

const ProgressBar = ({progress, total}: Props) => (
  <S.Container>
    <S.Bar progress={progress} total={total} />
  </S.Container>
);

export default ProgressBar;
