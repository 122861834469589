import styled from 'styled-components';
import Colors from 'assets/styles/Colors';

export const Video = styled.div`
  background-color: ${Colors.BlackReal};
`;

export const SkipButton = styled.div`
  top: 50%;
  position: absolute;
  z-index: 9;
  right: 20px;
`;

export const Text = styled.div`
  text-align: center;
  color: ${Colors.White};
  letter-spacing: 3px;
  font-family: 'Andes';
  font-weight: 600;
  font-size: 20px;
`;
