import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';

import onBoardingQueries from 'graphql/onBoarding/onBoarding.queries';
import TopicsMutations from 'graphql/topics/topics.qgl.mutations';

import { useOnboardingContext } from 'context/onboarding';

import * as MyRoutes from 'constants/Routes';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnboardingSuggestedJourneys = () => {
  const history = useHistory();
  const { setOnboardingDesireTypeCompleted } = useOnboardingContext();
  const { height } = useDimensionsContext();

  const { data, loading } = useQuery(
    onBoardingQueries.GET_ONBOARDING_JOURNEY_RECOMMENDATIONS,
  );

  const [addUserTopics] = useMutation(TopicsMutations.ADD_TOPICS);

  const handleContinue = async () => {
    setOnboardingDesireTypeCompleted();
    history.push(MyRoutes.ONBOARDING_FREE_TRIAL);
  };

  if (data) {
    const userGoalsToAdd = data.onBoardingJourneyRecommendations.map(
      (goal: { id: number }) => goal.id,
    );
    addUserTopics({ variables: { topics: userGoalsToAdd } });
  }

  const renderSuggestedJourneys = () => (
    <>
      <S.CenterContent>
        <S.Heading type={5}>
          Here are your recommended journeys for a happier, healthier intimate
          life
        </S.Heading>
        <S.SuggestionsList>
          {data?.onBoardingJourneyRecommendations.map((suggestion: any) => (
            <S.SuggestionListItem key={suggestion.id}>
              <S.SuggestionListItemDot />
              <S.SuggestionTitle>{suggestion.name}</S.SuggestionTitle>
            </S.SuggestionListItem>
          ))}
        </S.SuggestionsList>
        <S.Heading type={5}>
          Don’t worry, you can change or add to these later.
        </S.Heading>
      </S.CenterContent>
      <S.Button onClick={handleContinue}>CONTINUE</S.Button>
    </>
  );

  const renderLoadingJourney = () => (
    <S.Content>
      <S.Text>Hold on... we're customizing your recommended journeys</S.Text>
      {/* <S.SuggestionsLoader /> */}
    </S.Content>
  );

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(
      ANALYTICS_EVENT_NAMES.BOARDING_SUGGESTED_JOURNEYS,
    );
  }, []);

  return (
    <S.Container height={height}>
      {loading ? renderLoadingJourney() : renderSuggestedJourneys()}
      <S.HeaderImageLayer1 />
      <S.HeaderImageLayer2 />
      <S.BottomBackground />
      <S.BottomFeet />
    </S.Container>
  );
};

export default OnboardingSuggestedJourneys;
