import styled from 'styled-components';
import tw from 'twin.macro';

import Title from 'components/base/Heading';

import Colors from 'assets/styles/Colors';
import Paragraph from 'components/base/Paragraph';

import {ASSETS_PATH} from 'constants/assets';
import TextImage from 'assets/images/text-logo.png';
import RedLine from 'assets/images/red-line.png';
import LoginTextBackgroundSrc from 'assets/images/background-login-text.png';

export const Container = styled.div`
  ${tw`flex flex-col`}
  width: 100%;
`;

export const Content = styled.div`
  background-color: ${Colors.PaleCoral};
  ${tw`p-5 flex flex-col`}
`;

export const Heading = styled.text`
font-size: 22px;
color: ${Colors.Black};
font-family: 'Andes';
font-weight: 500;
margin-left: 10px;
text-align: center;
margin-bottom: 15px;
`;

export const SubHeading = styled(Paragraph).attrs(() => ({
  type: 3,
}))`
  ${tw`my-4 text-left mb-4 z-10`}
  max-width: 70%;
  font-family: 'Andes';
  font-weight: 500;
`;

export const DiscountInfoRow = styled.div`
 ${tw`flex mb-3`}
`;

export const PriceInfo = styled.div`
  ${tw`pt-3 mb-5 z-10`}
  font-family: 'Andes';
`;

export const PriceInfoRow = styled.div`
  ${tw`flex mb-3`}
`;

export const PriceInfoText = styled.div`
  ${tw`flex mb-3`}
`;

export const PriceInfoLabelBold = styled.div`
  ${tw`flex flex-1 font-medium`}
`;

export const PriceInfoValueBold = styled.div`
  ${tw`flex font-bold`}
`;

export const PriceInfoLabel = styled.div`
  ${tw`flex flex-1 mt-auto`}
`;

export const PriceInfoValue = styled.div`
  ${tw`flex`}
`;

export const PriceInfoValueNewText = styled.div`
  ${tw`flex`}
  width: 35%;
  text-align: end;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.96px;
  font-size: 13px;
`;

export const DiscountInfoLabel = styled.div`
  ${tw`flex flex-1`}
  color: #179E35;
  font-weight: 500;
`;

export const DiscountInfoValue = styled.div`
  ${tw`flex`}
  text-decoration: line-through;
`;

export const PriceInfoLabelSmall = styled.div`
  ${tw`flex flex-1 text-sm font-medium`}
`;

export const SubscriptionMethods = styled.div`
  ${tw`z-10`}
  padding: 2rem 1rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin: 10px;
`;

export const HeaderBackgroundImage = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/moon-big-yellow.png`,
}))`
  position: absolute;
  width: 571px;
  height: 593px;
  top: -450px;
  right: -224px;
  transform: rotate(45deg);
  object-fit: cover;
`;

export const BigGreyMoon = styled.div`
  position: absolute;
  width: 227.05px;
  height: 227.05px;
  left: 0;
  bottom: 50px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-big-grey.png`});
  background-size: cover;
  background-position-x: -140px;
  background-repeat: no-repeat;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  width: 124px;
  height: 124px;
  right: -60px;
  top: 55px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const PaymentBox = styled.div`
  background-color: #F8F4F3;
  padding: 0px 16px ;
  display: flex;
  flex-direction: column;
  z-index: 100;
`
export const HeaderContainer = styled.div`
  flex-direction: column;
  background-color: ${Colors.PaleCoral};
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  margin-bottom: -5px;
`

export const TextLogo = styled.img.attrs((props: any) => ({
  src: props.src ?? TextImage,
}))`
  width: 80%;
  object-fit: contain;
`;

export const RedLineImage = styled.img.attrs((props: any) => ({
  src: props.src ?? RedLine,
}))`
  width: 30%;
  object-fit: contain;
  margin-bottom: 10px;
`;

export const CurvelHeader = styled.img.attrs({
  src: LoginTextBackgroundSrc,
})`
  width: 100%;
  height: auto;
  left: 0px;  
  z-index: 0;
`;
