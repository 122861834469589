import { useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';

import { useMutation, useLazyQuery } from '@apollo/client';
import onBoardingQueries from 'graphql/onBoarding/onBoarding.queries';
import onBoardingMutations from 'graphql/onBoarding/onBoarding.mutations';

import Input from 'components/base/Input';
import OnBoardingOption from 'components/OnboardingOption';
import * as MyRoutes from 'constants/Routes';
import * as S from './styles';
import Colors from 'assets/styles/Colors';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnBoardingPartnerCodeForm = () => {
  const history = useHistory();
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [connectingToPartner, setConnectingToPartner] = useState(false);
  const [code, setPartnerCode] = useState('');

  
  const [checkInvitationCode, { loading: checkingCode, data, error }] =
    useLazyQuery(onBoardingQueries.CHECK_INVITATION_CODE, {
      variables: {
        invitationCode: code,
      },
      fetchPolicy: 'no-cache',
    });
  const inviterEmail = data?.checkInvitationCodeOnBoarding?.inviterEmail || '';

  const [acceptConnectionToPartner] = useMutation(
    onBoardingMutations.ACCEPT_PARTNER_ON_BOARDING,
    {
      variables: {
        acceptPartner: {
          invitationCode: code,
        },
      },
      onCompleted: async (connectionData) => {
        const partnerConnected =
          connectionData?.acceptPartnerOnBoarding?.accepted;

        setConnectingToPartner(false);
        analytics.track(ANALYTICS_EVENT_NAMES.PARTNERS_CONNECTED, {
          from: 'web-reg',
        });

        history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_TRAUMA)
      },
    },
  );

  const handleGoToNext = () => {
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_TRAUMA);
  };

  const goToSendCode = () => {
    history.push(MyRoutes.ONBOARDING_PARTNER_SEND_CODE);
  };

  const handleConfirmConnection = async () => {
    setConnectingToPartner(true);
    await acceptConnectionToPartner();
  };

  const handleCodeInputToggle = () => {
    setShowCodeInput(true);
  };

  const handleCodeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setPartnerCode(ev.target.value);
  };

  const renderCodeInput = () => {
    if (inviterEmail) {
      return (
        <>
          <S.Paragraph>Thanks! We’ll connect you to {inviterEmail}</S.Paragraph>
          <S.Paragraph>
            Enjoy free, premium access to Coral and a private partner chat.
            Disconnect in settings anytime.
          </S.Paragraph>
          <S.ConnectButton
            disabled={connectingToPartner}
            onClick={handleConfirmConnection}>
            {connectingToPartner ? 'CONFIRMING' : 'CONFIRM CONNECTION'}
          </S.ConnectButton>
          <S.SkipButton onClick={handleGoToNext}>BACK</S.SkipButton>
        </>
      );
    }

    return (
      <>
        <Input
          autoCapitalize="none"
          placeholder="Enter your partner’s code"
          value={code}
          onChange={handleCodeChange}
          error={!!error && !!code}
          errorMessage="Invalid partner code"
        />
        <S.ConnectButton disabled={!code} onClick={() => checkInvitationCode()}>
          {checkingCode && !error ? 'CONNECTING...' : 'CONNECT TO PARTNER'}
        </S.ConnectButton>
        <S.SkipButton onClick={handleGoToNext}>SKIP</S.SkipButton>
      </>
    );
  };

  const onFirstToJoinPress = () => goToSendCode();

  return (
    <S.Container>
      <S.Title>Partner Code?</S.Title>
      <S.Paragraph>
        Did your partner provide you with their partner code?
      </S.Paragraph>
      {showCodeInput ? (
        renderCodeInput()
      ) : (
        <S.List>
          <OnBoardingOption
            width={168}
            height={160}
            text="No, I’m first to join"
            color={Colors.Skin01}
            onClick={onFirstToJoinPress}
          />
          <OnBoardingOption
            width={168}
            height={160}
            text="Yes, I have one"
            color={Colors.Skin01}
            onClick={handleCodeInputToggle}
          />
        </S.List>
      )}
    </S.Container>
  );
};

export default OnBoardingPartnerCodeForm;
