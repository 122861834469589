import styled from 'styled-components';
import tw from 'twin.macro';

import Colors from 'assets/styles/Colors';
import Shadows from 'assets/styles/Shadows';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const Separator = styled.div`
  width: 8px;
`;
export const DiscussionListContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 20px 0;
  width: 100%;
  scrollbar-width: none;
  /* white-space: nowrap; */
  gap: 10px;

  @media (min-width: 1600px) {
    overflow-x: hidden;
  }; 
`
export const FooterCard = styled(Link)`
  min-width: 250px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  padding: 24px;
  background-color: ${Colors.White};
  box-shadow: ${Shadows.Box_6_2};

  &:hover {
    cursor: pointer;
  }
`;

export const ViewAllText = styled.div`
  font-family: 'GTSuper';
  font-size: 24px;
  letter-spacing: -0.2px;
  text-align: center;
`;
