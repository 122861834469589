export const FEATURE_FLAG_NAME = {
  FE_SHOW_LANDING_SCREENS_OB: 'fe_show_landing_screens_ob',
  WR_OB_ORDER: 'wr_ob_order',
  WR_DISCOUNT_BANNER: 'wr_discount_banner',
};

export const FEATURE_FLAG_VARIANT_TYPE = {
  DEFAULT: 'default',
  VARIANT_A: 'variant_a',
  VARIANT_B: 'variant_b',
  VARIANT_C: 'variant_c',
  VARIANT_D: 'variant_d',
  VARIANT_E: 'variant_e',
  VARIANT_F: 'variant_f',
};
