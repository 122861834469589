import React from 'react';
import * as S from './styles';

type Props = {
  children: React.ReactNode;
  style?: object;
};

const OnBoardingOptions = ({children, style}: Props) => (
  <S.Container style={style}>{children}</S.Container>
);

export default OnBoardingOptions;
