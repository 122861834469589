import styled from 'styled-components';
import { FiEdit2 } from 'react-icons/fi'
import Heading from 'components/base/Heading';
import Colors from 'assets/styles/Colors';
import tw from 'twin.macro';

type SelectedOptionProp = {
  color: string;
};

export const Container = styled.a`
  ${tw`flex items-center mb-3 z-10`}
`;

export const Text = styled(Heading).attrs({
  type: 6,
})`
  color: ${Colors.Ash};
  margin-right: 4px;
`;

export const SelectedOption = styled.div<SelectedOptionProp>`
  padding: 2px 8px;

  border-radius: 40px;
  background-color: ${({color}) => color};
  ${tw`flex flex-row items-center`}
`;

export const SelectedOptionText = styled(Heading).attrs({
  type: 6,
})`
  color: ${Colors.Black};
  text-align: center;
`;

export const Edit = styled(FiEdit2).attrs({
  size: 18,
  color: Colors.TransparentBlack,
})`
  margin-left: 5px;
`;