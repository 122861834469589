import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Discussion from 'assets/icons/Discussion';

import Colors from 'assets/styles/Colors';
import Shadows from 'assets/styles/Shadows';

type CardContainerProps = {
  big: boolean;
};

export const getCardWidth = (big: boolean) => (big ? 100 : 330);

export const Container = styled(Link)<CardContainerProps>`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 32px;
  padding: 24px;
  background-color: ${Colors.White};
  box-shadow: ${Shadows.Box_6_2};
  width: ${({big}: CardContainerProps) => big ? '700px' : 'auto'}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
`;

export const Title = styled.div`
  flex: 1;
  font-family: 'GTSUper';
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${Colors.Black};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
 line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Icon = styled(Discussion).attrs(() => ({
  width: 48,
  height: 48,
}))`
  margin-left: 16px;
`;

export const DescriptionOverlay = styled.div`
  margin-top: 16px;
  background-color: ${Colors.Coconut};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 16px 20px;
`;

export const Description = styled.div`
  color: ${Colors.Black};
  font-family: 'Inter';
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3;
  line-clamp: 3;
   -webkit-box-orient: vertical;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const Badge = styled.div`
  padding-horizontal: 8px;
  padding-vertical: 3px;
  background-color: ${Colors.Ash};
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const BadgeLabel = styled.div`
  letter-spacing: 0.2em;
  margin-left: 2.4px;
  font-size: 12px;
  font-weight: 600;
  align-self: center;
  color: ${Colors.White};
  font-family: 'Andes';
  padding: 3px 8px;
`;

export const Comments = styled.div`
  font-family: 'Inter';
  font-size: 15px;
  color: ${Colors.Ash};
`;
