import React from "react";
import styled from "styled-components";
import tw from 'twin.macro';

type InputProps = React.HTMLAttributes<HTMLDivElement> & {
  error?: boolean
}

export const Container = styled.div<InputProps>`
  ${tw`mb-2 border rounded-lg outline-none`}
  ${({ error }) => error 
    ? tw`border-red-500 text-red-500`
    : tw`border-transparent`
  }
`;

export const InputContainer = styled.div`
  ${tw`block`}
  margin: 0 10px;

  @media (max-width: 350px) {
    margin: 0 2px;
  }
`;

export const Label = styled.label`
  ${tw`flex items-center pl-4 font-medium whitespace-nowrap text-gray-400`}
`;

export const Input = styled.input`
  ${tw`w-full p-4 outline-none block`}

  ::-webkit-input-placeholder {
    color: #FF6F61;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: #FF6F61;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: #FF6F61;
  }

  :-ms-input-placeholder {  
    color: #FF6F61;
  }
`;

export const Underline = styled.span`
  background: black;
  display: block;
  height: 1px;
  width: 300px;
  opacity: 0.5;

  @media screen and (max-width: 768px) {
    width: 200px;
  }
}
`;

export const ErrorMessage = styled.div`
  ${tw`font-bold text-xs text-gray-400 px-4 pb-2`}
  margin-top: -8px;
`;
