import React, { useEffect } from 'react';
import { useHistory} from 'react-router';

import {useOnboardingDesireTypeContext} from 'context/onboarding-desire-type';

import * as MyRoutes from 'constants/Routes';
import {TOTAL_STEPS} from 'constants/onboarding';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import useUTMParams from 'common/hooks/useUTMParams';

const OnboardingDesireTypeIARConfirmation = () => {
  const history = useHistory();
  const {utmMedium, getAllParamsString} = useUTMParams();
  const {steps} = useOnboardingDesireTypeContext();
  const { height } = useDimensionsContext();

  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  });

  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;

  const onClickContinue = async () => {
    isNewOBOrder
      ? history.push(MyRoutes.LOGIN)
      : utmMedium 
      ? history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_RESULT}?${getAllParamsString()}`)
      : history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_RESULT);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_IAR_GET_CONFIRMATION);
  }, []);

  return (
    <S.Container height={height}>
      <S.CompactContent>
        <S.ProgressBar progress={16} total={TOTAL_STEPS} />
        <S.Answer
          showIcon
          text="I initiate"
          selectedOption={steps?.Initiative?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INITIATIVE)
          }
        />
        <S.Answer
          showIcon
          text="Desire takes"
          selectedOption={steps?.DesireMood?.name}
          color={Colors.PaleCoral}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_MOOD)
          }
        />
        <S.Answer
          showIcon
          text="Their desire"
          selectedOption={steps?.DesirePartnerMood?.name}
          color={Colors.Skin02}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD,
            )
          }
        />
        <S.Answer
          showIcon
          text="My desire is"
          selectedOption={steps?.DesireGetInMood?.name}
          color={Colors.Skin01}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_GET_MOOD,
            )
          }
        />
        <S.Answer
          showIcon
          text="My partner’s desire is"
          selectedOption={steps?.DesirePartnerGetInMood?.name}
          color={Colors.Roe}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_GET_MOOD,
            )
          }
        />
      </S.CompactContent>
      <S.Content>
        <S.LeftHeading>How does that look?</S.LeftHeading>
      </S.Content>
      <S.Button
        icon={<S.CheckIcon />}
        onClick={onClickContinue}
      >LOOKS GOOD</S.Button>
      <S.BottomImage14 />
    </S.Container>
  );
};

export default OnboardingDesireTypeIARConfirmation;
