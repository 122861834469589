import axios from 'axios';
import {STRIPE_API_KEY, STRIPE_API_URL} from './constants-helper';
import qs from 'query-string';
import {stringify} from 'querystring';

type SubscriptionProps = {
  customerId: string,
  priceId: string,
  promotionCode?: string,
}
const stripeAPI = axios.create({
  baseURL: STRIPE_API_URL,
  headers: {
    'Authorization': `Bearer ${STRIPE_API_KEY}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  },
});

export const createStripeCustomer = async (customerData: {}) => {
  return await stripeAPI.post('/customers', qs.stringify(customerData));
}

export const updateStripeCustomerPaymentMethod = async (paymentMethod: string, customerId: string) => {
  const paymentMethodData = {
    'invoice_settings[default_payment_method]': paymentMethod
  };

  return await stripeAPI.post(`/customers/${customerId}`, qs.stringify(paymentMethodData));
}

export const createStripeSetupIntent = async (setupIntentData: {}) => {
  return await stripeAPI.post('/setup_intents', qs.stringify(setupIntentData));
}

export const createStripeSubscription = async (subscriptionData: SubscriptionProps) => {
  const formattedSubscriptionData = {
    customer: subscriptionData.customerId,
    'items[0][price]': subscriptionData.priceId,
    promotion_code: subscriptionData?.promotionCode ?? undefined
  }

  return await stripeAPI.post('/subscriptions', qs.stringify(formattedSubscriptionData))
}

export const getStripePromotionCode = async (code: string) => {
  const {data: {data: promoCodes}} = await stripeAPI.get('/promotion_codes');

  return promoCodes.find((promoCode: {code: string}) => promoCode?.code === code);
}

export const getStripePrice = async (priceId: string) => {
  const {data: price} = await stripeAPI.get(`/prices/${priceId}`)

  return price;
}

export const getStripePlans = async () => {
  const response = await stripeAPI.get(`/plans`)
  return response.data;
}

export const createPaymentIntent = async (body: any) => {
  const response = await stripeAPI.post(`/payment_intents`, stringify(body))

  return response.data;
}
