import styled from 'styled-components';
import { FiCheck } from 'react-icons/fi';
import Colors from 'assets/styles/Colors';
import Bar from 'components/ProgressBar';
import CustomButton from 'components/base/Button';
import CustomHeading from 'components/base/Heading';
import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingAnswer from 'components/OnboardingAnswer';
import Paragraph from 'components/base/Paragraph';
import tw from 'twin.macro';
import CustomLoader from 'components/base/Loader';
import { ASSETS_PATH } from 'constants/assets';
import AppleLogoSvg from 'assets/images/appleLogo.svg';

import OnBoardingTraitBottom from 'assets/images/onboarding-trait-bg-bottom';
import OnBoardingDesireType from 'assets/images/onboarding-desire-type';
import ConnectOBLayer1 from 'assets/images/ConnectOBLayer1';
import ConnectOBLayer2 from 'assets/images/ConnectOBLayer2';
import Title from 'components/base/Title';
import ChatIcon from 'assets/images/chat_pillar.png';
import ChatContentIcon from 'assets/images/onboarding_question_image.png';
import PlayBackground from 'assets/images/play_background.png';
import TrialPaymentBackground from 'assets/images/trial_payment_background.png';
import ArrowCurved from 'assets/images/arrow_curved.png';
import BottomTrialPaymentSelection from 'assets/images/bottom_trial_payment_selection.png';
import TopFreeTrialBackground from 'assets/images/top_free_trial_background.png';
import CoralLogoBlack from 'assets/images/coral-logo-black.png';
import PersonalizedCheckIcon from 'assets/images/check_icon.png';
import StarSvg from 'assets/images/star-rating.svg';
import FreeTrialChartImage from 'assets/images/free_trial_chart.png';
import { FiX } from 'react-icons/fi';

export const Container = styled.div<{ height: number }>`
  background-color: ${Colors.Coconut};
  min-height: ${({ height }) => height}px !important;
  ${tw`flex flex-1 flex-col p-5 h-full z-10 overflow-y-auto`}
`;

export const Container2 = styled.div<{ height: number }>`
  background-color: ${Colors.Skin01};
  min-height: ${({ height }) => height}px !important;
  ${tw`flex flex-1 flex-col p-5 h-full z-10 overflow-y-auto`}
`;

export const Content = styled.div`
  ${tw`flex flex-col flex-1 justify-evenly z-10`}
`;

export const CenterContent = styled.div`
  ${tw`flex flex-col flex-1 justify-center z-10`}
`;

export const TopContent = styled.div`
  ${tw`flex flex-col flex-1`}
`;

export const CompactContent = styled.div`
  ${tw`flex flex-col`}
`;

export const FormContent = styled.div`
  ${tw`flex flex-col flex-1`}
`;

export const Loader = styled(CustomLoader)`
  margin-top: 20px;
`;

export const Heading = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 1,
}))`
  ${tw`text-center z-10 leading-10 font-medium`}
`;

export const HeadingSpaced = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 1,
}))`
  ${tw`text-center z-10 mt-3 mb-6`}
`;

export const LeftHeading = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 1,
}))`
  ${tw`z-10 font-medium`}
`;

export const Text = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  ${tw`text-center z-10`}
`;

export const TextSpaced = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  font-family: 'Andes';
  ${tw`text-center mt-4 z-10 pl-6 pr-6 font-medium`}
`;

export const Description = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  ${tw`mb-4 z-10 text-justify`}
`;

export const DescriptionLeft = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  ${tw`mb-4 z-10`}
`;

export const DescriptionTopSpaced = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  ${tw`mt-4 z-10 text-justify`}
`;

export const DescriptionTopSpacedLeft = styled(CustomHeading).attrs(
  ({ type }) => ({
    type: type ?? 6,
  }),
)`
  ${tw`mt-4 z-10`}
`;

export const DescriptionSpaced = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  ${tw`z-10 text-justify mb-7`}
`;

export const Button = styled(CustomButton)`
  ${tw`z-10`}
`;

export const Greeting = styled(CustomHeading).attrs({
  size: 2,
})`
  ${tw`z-10`}
`;

export const Message = styled(CustomHeading).attrs({
  type: 4,
})`
  ${tw`text-center z-10`}
`;

export const Answer = styled(OnBoardingAnswer)``;

export const ProgressBar = styled(Bar)`
  ${tw`mb-3`}
`;

export const FinishButton = styled(CustomButton)`
  margin-top: auto;
`;

export const OptionList = styled(OnBoardingOptions)``;

export const CheckIcon = styled(FiCheck)``;

export const SkipContainer = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 32px;
`;

export const SkipButton = styled.a`
  font-family: 'Andes';
  letter-spacing: 0.2em;
  ${tw`text-right text-xs font-medium`}
`;

export const SourceTitle = styled(CustomHeading).attrs({
  type: 3,
})`
  text-align: center;
  margin-bottom: 24px;
`;

export const SourceButton = styled.a`
  background-color: ${Colors.White};
  margin-bottom: 8px;
  ${tw`px-4 py-5 text-sm rounded-lg mb-2`}
`;

export const SourceText = styled(Paragraph).attrs({
  type: 4,
})``;

export const Footer = styled.div`
  z-index: 2;
`;

export const SuggestionsList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  padding: 30px 25px;
  min-height: 200px;
  justify-content: space-between;
  background-color: ${Colors.White};
  border-radius: 16px;
`;

export const SuggestionListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SuggestionListItemDot = styled.span.attrs({
  children: `\u25CF`,
})`
  color: ${Colors.Red};
`;

export const SuggestionTitle = styled(Title).attrs({
  type: 0,
})`
  margin-left: 11px;
  text-transform: uppercase;
  text-align: left;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2em;
`;

export const HeaderMoon = styled.div.attrs(() => ({
  resizeMode: 'stretch',
}))`
  position: absolute;
  width: 571px;
  height: 593px;
  left: -450px;
  top: -500px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-big-yellow.png`});
  background-size: cover;
  /* background-position-x: 90px; */
  background-repeat: no-repeat;
`;

export const CollageMan = styled.div`
  position: absolute;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  background-image: url(${`${ASSETS_PATH}/collage-man.png`});
  background-size: 440px;
  background-position: bottom;
  background-position-x: 10px;
  background-repeat: no-repeat;
`;

export const CollageMan2 = styled.div`
  position: absolute;
  height: 60px;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  background-image: url(${`${ASSETS_PATH}/collage-man.png`});
  background-size: 440px;
  background-position: bottom;
  background-position-x: -280px;
  background-repeat: no-repeat;
`;

export const BottomImage = styled.div`
  position: absolute;
  height: 405px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-trait-0-bg-bottom-1.png`});
  background-size: 1397px 505px;
  background-position-x: -370px;
  background-repeat: no-repeat;
`;

export const BottomImage1 = styled.div`
  position: absolute;
  height: 405px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-trait-0-bg-bottom-1.png`});
  background-size: 2794px 405px;
  background-position-x: -330px;
  background-repeat: no-repeat;
`;

export const BottomImage2 = styled.div`
  position: absolute;
  height: 211px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-trait-0-bg-bottom-2.png`});
  background-size: 1135px 211px;
  background-position-x: -700px;
  background-repeat: no-repeat;
`;

export const BottomImage3 = styled(OnBoardingTraitBottom).attrs(() => ({
  fill: Colors.PaleKelp,
  width: 1500,
  positionX: 390,
}))``;

export const BottomImage4 = styled(OnBoardingTraitBottom).attrs(() => ({
  fill: Colors.Roe,
  width: 1300,
  positionX: 760,
}))``;

export const BottomImage5 = styled(OnBoardingTraitBottom).attrs(() => ({
  fill: Colors.Skin02,
  positionX: 880,
  width: 1500,
}))``;

export const BottomImage6 = styled.div`
  position: absolute;
  height: 405px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-trait-0-bg-bottom-1.png`});
  background-size: 1397px 405px;
  background-position-x: -380px;
  background-repeat: no-repeat;
`;

export const BottomImage7 = styled.div`
  position: absolute;
  height: 211px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-trait-0-bg-bottom-2.png`});
  background-size: 970px 211px;
  background-position-x: -70px;
  background-repeat: no-repeat;
`;

export const BottomImage8 = styled.div`
  position: absolute;
  width: 2217px;
  height: 503.06px;
  left: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-trait-6-bg-bottom.png`});
  background-size: 2217px 503px;
  background-position-x: -2030px;
  background-repeat: no-repeat;
`;

export const BottomImage9 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.PaleKelp,
  positionX: 132,
}))``;

export const BottomImage10 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.PaleCoral,
  positionX: 877,
}))``;

export const BottomImage11 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin02,
  positionX: 1272,
}))``;

export const BottomImage12 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 2042,
}))``;

export const BottomImage13 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Roe,
  positionX: 2425,
}))``;

export const BottomImage14 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 2425,
}))``;

export const BottomImage15 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 3122,
}))``;

export const BottomImage16 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 2560,
}))``;

export const BottomImage17 = styled.div`
  position: absolute;
  width: 2217px;
  height: 503.06px;
  left: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-trait-6-bg-bottom.png`});
  background-size: 2217px 503px;
  background-position-x: -2030px;
  background-repeat: no-repeat;
`;

export const BottomImage18 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.PaleKelp,
  positionX: -260,
  positionY: 0,
}))``;

export const BottomImage19 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.PaleKelp,
  positionX: 132,
}))``;

export const BottomImage20 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.PaleKelp,
  positionX: 502,
}))``;

export const BottomImage21 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.PaleCoral,
  positionX: 877,
}))``;

export const BottomImage22 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.SeaGreen,
  positionX: 1642,
}))``;

export const BottomImage23 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 2042,
}))``;

export const BottomImage24 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Roe,
  positionX: 2425,
}))``;

export const BottomImage25 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 2425,
}))``;

export const BottomImage26 = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 2425,
}))``;

export const BottomImageDesireType = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin04,
  positionX: 1550,
}))``;

export const BottomLegsImage = styled.div`
  position: absolute;
  width: 217px;
  height: 668px;
  right: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/legs-and-ball.png`});
  background-size: 217px 668px;
  background-position-x: 108px;
  background-position-y: 195px;
  background-repeat: no-repeat;
`;

export const TraitBottomImage = styled(OnBoardingTraitBottom).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 1180,
}))``;

export const ConnectBgImageLayer1 = styled(ConnectOBLayer1)`
  position: absolute;
  left: 0;
  top: 0;
`;
export const ConnectBgImageLayer2 = styled(ConnectOBLayer2)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

//
export const ConnectImage = styled.div`
  flex: 1;
  min-height: 230px;
  background-image: url(${`${ASSETS_PATH}/onboarding-connect.png`});
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  z-index: 10;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  width: 124px;
  height: 124px;
  left: 0px;
  bottom: 201px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: cover;
  background-position-x: -52px;
  background-repeat: no-repeat;
`;

export const DesireTypeIARIntroImage = styled.div`
  width: 90%;
  height: 100%;
  align-self: center;
  background-image: url(${`${ASSETS_PATH}/onboarding-desire-type-intro.png`});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  z-index: 10;
`;

export const FooterRightImage = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.PaleKelp,
  positionX: -240,
}))``;

export const DesireTypeResultImage = styled.div`
  align-self: center;
  width: 124px;
  height: 144px;
  top: 20px;
  background-image: url(${`${ASSETS_PATH}/concept_desire.png`});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  z-index: 10;
`;

export const DesireTypeTraumaBottomImage = styled.div`
  position: absolute;
  width: 688px;
  height: 319px;
  bottom: -130px;
  left: -80px;
  background-image: url(${`${ASSETS_PATH}/collage-feet.png`});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
`;

export const HeaderImageLayer1 = styled.div`
  position: absolute;
  width: 938px;
  height: 400px;
  left: 100px;
  top: 0px;
  background-image: url(${`${ASSETS_PATH}/suggested-journeys-header-layer-1.png`});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
`;

export const HeaderImageLayer2 = styled.div`
  position: absolute;
  width: 938px;
  height: 140px;
  left: 146px;
  top: 0px;
  background-image: url(${`${ASSETS_PATH}/suggested-journeys-header-layer-2.png`});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
`;

// bottom-jounreys-suggested.png
export const BottomBackground = styled.div`
  position: absolute;
  width: 856.72px;
  height: 444.88px;
  right: -100px;
  bottom: -300px;
  background-image: url(${`${ASSETS_PATH}/bottom-jounreys-suggested.png`});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
`;

// collage-feet.png
export const BottomFeet = styled.div`
  position: absolute;
  width: 688px;
  height: 319px;
  bottom: -130px;
  left: -400px;
  background-image: url(${`${ASSETS_PATH}/collage-feet.png`});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
`;

export const FooterSideImage = styled.div`
  position: absolute;
  width: 217px;
  height: 668px;
  left: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/legs-and-ball.png`});
  background-size: 217px 668px;
  background-position-x: -90px;
  background-position-y: 150px;
  background-repeat: no-repeat;
`;

export const BottomFeet3 = styled.div`
  position: absolute;
  width: 688px;
  height: 319px;
  bottom: -140px;
  right: -590px;
  background-image: url(${`${ASSETS_PATH}/collage-feet.png`});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const BottomCircle = styled.div`
  position: absolute;
  width: 230px;
  height: 230px;
  bottom: 0;
  right: 60px;
  background-image: url(${`${ASSETS_PATH}/circle_on_head.png`});
  background-size: contain;
  background-position-x: 0px;
  background-position-y: 60px;
  background-repeat: no-repeat;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const PillarIcon = styled.img.attrs((props: any) => ({
  src: props.src ?? ChatIcon,
}))`
  width: 55px;
  height: 55px;
  border-radius: ${55 / 2}px;
`;

export const PillarTitle = styled.text`
  font-size: 28px;
  color: ${Colors.Black};
  font-family: 'Andes';
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 10px;
`;

export const PillarView = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  /* margin-bottom: 50px; */
  margin-top: 70px;
`;

export const ChatContentImage = styled.img.attrs((props: any) => ({
  src: props.src ?? ChatContentIcon,
}))`
  width: 100%;
  height: 350px; /* Maintain aspect ratio */
  object-fit: cover;
`;

export const ChatContent = styled(CustomHeading).attrs(({ type }) => ({
  type: type ?? 6,
}))`
  ${tw`m-6 z-10 text-center`}
`;

export const TrialPaymentSelectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const TrialPaymentSelectionDescription = styled.text`
  font-family: 'Andes';
  line-height: 20px;
  padding: 0 43px;
  ${tw`z-10 text-base font-medium`}
`;

export const TrialPaymentSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 100px;
`;

export const TrialPaymentSelectionRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const TrialPaymentTooltipContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TrialPaymentTooltipContent = styled(CustomHeading).attrs(
  ({ type }) => ({
    type: type ?? 6,
  }),
)`
  font-size: 12px;
  width: 154px;
  font-family: 'Andes';
  font-weight: 500;
`;

export const TrialPaymentSelectionItem = styled.div<{
  isSelected?: boolean;
}>`
  height: 80px;
  width: 80px;
  align-content: center;
  text-align: center;
  z-index: 100;
  border-radius: 16px;
  font-family: 'GTSuper';
  font-size: 20px;
  background-color: ${({ isSelected }) =>
    isSelected ? Colors.Roe : '#ee8b683b'};
`;

export const TrialArrowCurved = styled.img.attrs({
  src: ArrowCurved,
  resizeMode: 'contain',
})`
  height: 44px;
  width: 53px;
`;

export const BottomTrialPaymentBackground = styled.img.attrs({
  src: BottomTrialPaymentSelection,
  resizeMode: 'contain',
})`
  height: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
`;

export const ChatBottomImage = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 1560,
  positionY: -200,
}))``;

export const PlayTopBackground = styled.img.attrs({
  src: PlayBackground,
  resizeMode: 'contain',
})`
  position: absolute;
  width: 100%;
  height: 300px;
  top: 0;
  left: 0;
  right: 0;
`;

export const TrialPaymentSelectionTopBackground = styled.img.attrs({
  src: TrialPaymentBackground,
  resizeMode: 'contain',
})`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
`;

export const FreeTrialTopBackground = styled.img.attrs({
  src: TopFreeTrialBackground,
  resizeMode: 'contain',
})`
  height: 152px;
  position: absolute;
  width: 100%;

  right: 0;
  left: 0;
  top: 0;
`;

export const FreeTrialCoralTextLogo = styled.img.attrs({
  src: CoralLogoBlack,
  resizeMode: 'contain',
})`
  height: 47px;
  width: 135px;
  margin-top: 30px;
  z-index: 10;
`;

export const AppOfTheDayLogo = styled.img.attrs(() => ({
  src: AppleLogoSvg,
}))`
  margin-top: 20px;
  z-index: 10;
  width: 143px;
  margin-bottom: 23.45px;
`;

export const FreeTrialContainer = styled.div`
  background-color: ${Colors.Coconut};
  align-items: center;
  ${tw`flex flex-1 flex-col h-full z-10 overflow-y-auto`}
`;

export const FreeTrialTopView = styled.div`
  background-color: ${Colors.Coconut};
  align-items: center;
  ${tw`flex flex-1 flex-col p-5 h-full z-10 overflow-y-auto`}
`;

export const PersonalizedTitle = styled(Heading)`
  font-size: 22px;
  margin-bottom: 19px;
`;

export const PersonalizedJourneysTitle = styled(Heading)`
  margin-top: 30px;
  font-size: 22px;
  margin-bottom: 19px;
  padding: 0 23px;
`;

export const PersonalizedCheck = styled.img.attrs(() => ({
  src: PersonalizedCheckIcon,
}))`
  height: 22px;
  width: 20px;
`;

export const PersonalizedJourneysContainer = styled.div`
  border-radius: 16px;
  padding: 30px 25px;
  background-color: ${Colors.White};
  margin-top: 8px;
  box-sizing: border-box;
  width: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;
`;

export const PersonalizedRow = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  padding-left: 28px;
  gap: 14px;
  margin-bottom: 17px;
`;

export const PersonalizedJourneyItem = styled.div`
  align-items: start;
  display: flex;
  width: 100%;
  gap: 11px;
`;

export const PersonalizedItemText = styled(Heading)`
  font-size: 14px;
  line-height: 17.12px;
`;

export const JoinOverTitle = styled(Heading)`
  font-size: 18px;
  line-height: 18.35px;
  font-weight: 500;
  margin-top: 23px;
`;

export const PersonalizedJourneyItemText = styled(Heading)`
  font-size: 15px;
  line-height: 20.83px;
  letter-spacing: 0.036px;
  font-family: 'Andes';
  text-transform: uppercase;
  text-align: start;
`;

export const YourJourneyItemDot = styled.span.attrs({
  children: `\u25CF`,
})`
  color: ${Colors.Red};
`;

export const ReviewCountText = styled(Heading)`
  font-size: 14px;
  line-height: 17.12px;
  font-weight: 500;
  font-family: 'Andes';
  margin-right: 6px;
`;

export const FreeTrialReviewContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 9px;
`;

export const Star = styled.img.attrs(() => ({
  src: StarSvg,
}))`
  width: 20px;
  height: 20px;
  margin: 3px;
`;

export const ReviewCountStarText = styled(Heading)`
  font-size: 14px;
  line-height: 12px;
  font-weight: 400;
  font-family: 'Andes';
  margin-left: 6px;
`;

export const FreeTrialChartContent = styled(Heading)`
  font-size: 14px;
  line-height: 17.12px;
  font-weight: 500;
  font-family: 'Andes';
  margin-bottom: 15px;
`;

export const FreeTrialChartContainer = styled.div`
  border-radius: 16px;
  padding: 30px 25px;
  background-color: ${Colors.White};
  margin-top: 8px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FreeTrialChartValueRow = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FreeTrialChartValue = styled(Heading)`
  font-size: 15px;
  line-height: 18.35px;
  font-weight: 500;
  font-family: 'Andes';
  letter-spacing: 2.1px;
`;

export const FreeTrialChart = styled.img.attrs(() => ({
  src: FreeTrialChartImage,
}))`
  height: 135px;
`;

export const PlayBottomImage = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 50,
  positionY: -100,
}))``;

export const LearnBottomImage = styled(OnBoardingDesireType).attrs(() => ({
  fill: Colors.Skin01,
  positionX: 1600,
  positionY: -50,
}))``;

export const GoalTitleText = styled(Heading).attrs({
  type: 6,
})`
  color: ${Colors.Ash};
  margin-bottom: 16px;
  z-index: 10;
`;

export const CloseButton = styled.a`
  position: absolute;
  display: flex;
  top: 12px;
  right: 16px;
  height: 47px;
  width: 47px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 12px 20px rgba(0, 0, 0, 0.11);
  z-index: 20;
`;

export const CloseIcon = styled(FiX)`
  color: ${Colors.Black};
  font-size: 26px;
`;
