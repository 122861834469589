import React, { useEffect } from 'react';
import { useHistory} from 'react-router';

import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import LearnIcon from 'assets/images/lesson_pillar.png';
import LearnContentIcon from 'assets/images/pillar_three.png';
import {useOnboardingContext} from 'context/onboarding';

const OnboardingLearn = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();
  const { setOnboardingDesireTypeCompleted } = useOnboardingContext();

  const onPressContinue = () => {
    setOnboardingDesireTypeCompleted();
    history.push(MyRoutes.ONBOARDING_FREE_TRIAL);
    // history.push(MyRoutes.ONBOARDING_TRIAL_PAYMENT_SELECTION);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.ONBOARDING_LEARN);
  }, []);

  return (
    <S.Container height={height}>
      <S.Content>
      <S.PillarView>
            <S.PillarIcon src={LearnIcon} />
            <S.PillarTitle>
              {'LEARN'}
            </S.PillarTitle>
          </S.PillarView>
   
      <S.ChatContent  type={4}>
          Tackle sexual issues head-on with personalized learning Journeys
    </S.ChatContent>
    <S.ChatContentImage  style={{width: '80%', alignSelf: 'center'}} src={LearnContentIcon}  />
      {/* <S.ChatContent  type={4}>
        Let's start by learning something new about Desire!
    </S.ChatContent> */}
        </S.Content>
      <S.Footer>
        <S.Button onClick={onPressContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.LearnBottomImage />
    </S.Container>
  );
};

export default OnboardingLearn;
