import {useHistory} from 'react-router';

import {TOTAL_STEPS} from 'constants/onboarding';
import {useOnboardingTraitsContext} from 'context/onboarding-traits';

import * as MyRoutes from 'constants/Routes';

import Colors from 'assets/styles/Colors';
import * as S from './styles';

type Props = {
  progress: number;
  showEditIcon?: boolean;
  partnerConnected?: boolean;
  title?: string;
  subTitle?: string;
  totalSteps?: number;
};

const OnboardingAnswersList = ({
  progress,
  showEditIcon,
  partnerConnected,
  title,
  subTitle,
  totalSteps = TOTAL_STEPS
}: Props) => {
  const history = useHistory();
  const {steps, hasPartner} = useOnboardingTraitsContext();

  return (
    <>
      <S.ProgressBar progress={progress} total={totalSteps} />
      {!!title &&  <S.Text >{title ?? 'Hello,'}</S.Text>}
      {!!subTitle && (
        <S.SubTitle >{subTitle}</S.SubTitle>
      )}
      {steps.Gender && progress > 1 && (
        <S.Answer
          showIcon={showEditIcon}
          text="I'm"
          selectedOption={steps.Gender.name}
          color={Colors.PaleCoral}
          onClick={() => (!hasPartner) ? history.push(MyRoutes.ONBOARDING_GENDER_TRAIT) : null}
        />
      )}
      {steps.Age && progress > 2 && (
        <S.Answer
          showIcon={showEditIcon}
          text="And am"
          selectedOption={steps.Age.name}
          color={Colors.PaleKelp}
          onClick={() => (!hasPartner) ? history.push(MyRoutes.ONBOARDING_AGE_TRAIT) : null}
        />
      )}
      {steps.InterestedIn && progress > 3 && (
        <S.Answer
          showIcon={showEditIcon}
          text="Interested in"
          selectedOption={steps.InterestedIn.name}
          color={Colors.Roe}
          onClick={() => (!hasPartner) ? history.push(MyRoutes.ONBOARDING_INTERESTED_IN_TRAIT) : null}
        />
      )}
      {steps.RelashionshipStatus && progress > 4 && (
        <S.Answer
          showIcon={showEditIcon}
          text="And"
          selectedOption={steps.RelashionshipStatus.name}
          color={Colors.Skin02}
          onClick={() => (!hasPartner) ? history.push(MyRoutes.ONBOARDING_RELATIONSHIP_STATUS_TRAIT) : null}
        />
      )}
      {steps.RelashionshipStatus && partnerConnected && progress > 5 && (
        <S.Answer
          showIcon
          text="and"
          selectedOption="we’re connected"
          color={Colors.Skin01}
          onClick={() => (!hasPartner) ? history.push(MyRoutes.ONBOARDING_RELATIONSHIP_STATUS_TRAIT) : null}
        />
      )}

    </>
  );
};

export default OnboardingAnswersList;