import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import { TRAIT_TYPES, TraitSteps } from 'constants/traits';
import OnBoardingOption from 'components/OnboardingOption';
import { Trait } from 'context/types';
import OnboardingAnswersList from 'components/OnboardingAswersList';

import * as MyRoutes from 'constants/Routes';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const TraitAge = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const {
    getTraitsForStep,
    loadingSetTrait,
    setUserTrait,
    selectedTrait,
  } = useOnboardingTraitsContext();
  const ages = getTraitsForStep(TRAIT_TYPES.AGE);

  const handleSelectAge = async (age: Trait) => {
    setUserTrait(TraitSteps.Age, age);
    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_INTERESTED_IN_TRAIT}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_INTERESTED_IN_TRAIT);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_AGE_TRAITS);
  }, []);

  return (
    <S.Container height={height}>
      <OnboardingAnswersList progress={15} />
      <S.LeftHeading>And am</S.LeftHeading>
      <S.OptionList>
        {ages?.map((age: Trait) => (
          <OnBoardingOption
            key={age.id}
            width={168}
            height={96}
            text={age.name}
            color={Colors.PaleKelp}
            onClick={() => handleSelectAge(age)}
            loading={loadingSetTrait && selectedTrait === age.id}
            />
        ))}
      </S.OptionList>
      <S.BottomImage3 />
    </S.Container>
  )
}

export default TraitAge;
