import {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Redirect} from 'react-router-dom';
import Banner from 'components/Banner';
import {useMutation} from '@apollo/client';
import {
  getAuth,
  getIdTokenResult,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import {CREATE_USER} from 'graphql/user/user.gql.mutations';
import {
  getExceptionMessage,
  getPublicIpAddress,
  showErrorToast,
  showSuccessToast,
} from 'helpers/utility-helper';
import {useFirebaseAuthContext} from 'context/auth';
import {useParamsContext} from 'context/params';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';

import * as MyRoutes from 'constants/Routes';
import * as S from './styles';
import {useDimensionsContext} from 'context/dimensions';

import useFlagsmith from 'common/hooks/useFlagsmith';
import {FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE} from 'constants/feature-flags';
import RatingAndMedia from 'components/RatingAndMedia';

type UserProps = {
  appsflyerId: string;
  createdOnPlatform: string;
  language: string;
  email: string;
  password: string;
  phone: string;
  ip: string;
  uid: string;
  isSSO: boolean;
};

type UserAuthProps = {
  email: string;
  password: string;
  phone?: string;
  uid?: string;
  isSSO?: boolean;
};

const loginValidationSchema = yup.object().shape<any>({
  email: yup
    .string()
    .email('Email address is not valid')
    .required('Enter your email address'),
  password: yup.string().required('Enter your password'),
});

const loginInitialValues = {
  email: '',
  password: '',
  phone: '',
};

const AuthForm = ({location}) => {
  // Not a great way to manage state of sign up vs login it encourages bugs
  // There should be a page /login & /signUp instead
  const [isUserSigningUp, setIsUserSigningUp] = useState(false);
  const [forceRedirect, setForceRedirect] = useState(false);
  const [isAppleSubmitting, setIsAppleSubmitting] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const {signinWithEmailAndPassword, isAuthenticated, signInSso} =
    useFirebaseAuthContext();
  const {params} = useParamsContext();
  const {height} = useDimensionsContext();
  const auth = getAuth();

  const [createUser] = useMutation(CREATE_USER);
  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  });

  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;

  const starsCount = [1, 2, 3, 4, 5];
  const checklistText = [
    'Daily activities to connect to each other',
    'Guided meditations for desire and arousal',
    'Private partner chat for sensual communication',
    'Expert-developed exercises for deeper intimacy',
  ];

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.LANDING_PITCH);
    analytics.track(ANALYTICS_EVENT_NAMES.LANDING_PITCH);
  }, []);

  useEffect(() => {
    setIsUserSigningUp(location?.state?.performSignUp ?? true);
  }, [location.state]);

  const loginUser = async ({email, password}: UserAuthProps) => {
    try {
      await signinWithEmailAndPassword(email, password);
      analytics.track(ANALYTICS_EVENT_NAMES.USER_LOGIN, {
        method: 'email',
      });
    } catch (error) {
      const errorMessage = getExceptionMessage(error as Error);
      showErrorToast('Login error', errorMessage);
      throw error;
    }
  };

  const {featureVariant: featureOBLVariant} = useFlagsmith({featureName: FEATURE_FLAG_NAME.WR_DISCOUNT_BANNER});
  const {featureVariant: featureOBNewVariant} = useFlagsmith({featureName: FEATURE_FLAG_NAME.WR_OB_ORDER});

  const isShowBanner = featureOBLVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_A;
  const isShowNewVariant = featureOBNewVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;

  // uid of the user is being passed via token
  const signUpUser = async ({email, password, isSSO, uid}: UserAuthProps) => {
    const deviceIpAddress = await getPublicIpAddress();

    let newUser = {
      //appsflyer is required
      appsflyerId: '',
      createdOnPlatform: 'web',
      language: 'en-US',
      ip: deviceIpAddress,
      email,
      password,
      isSSO,
      uid
    } as UserProps;

    try {
      const {data: createdUserData} = await createUser({
        variables: {newUser},
      });

      // Identify user to Segment
      analytics.identifyUser(createdUserData?.createUser?.id.toString(), {
        email,
      });

      analytics.track(ANALYTICS_EVENT_NAMES.USER_REGISTERED, {
        method: isSSO ? 'apple' : 'email',
        ...params,
      });

      window.fbq('track', 'CompleteRegistration');
    } catch (error) {
      // 409 means that the user is already registered
      // we should ignore this error and login instead registration 
      if (error.message !== '409: Conflict') {
        // TODO: sentry
        const errorMessage = getExceptionMessage(error as Error);
        showErrorToast('SignUp error', errorMessage);
        throw error;
      }
    }
    // sso users cannot log in again 
    if (!isSSO) {
      await loginUser({email, password});
    }
  };

  const onToggleLoginSignup = (e) => {
    e.preventDefault();
    setIsUserSigningUp(!isUserSigningUp);
  };

  const loginWithApple = async () => {

    const provider = new OAuthProvider('apple.com')

    provider.addScope('email');
    provider.addScope('name');

    try {

      window.location.hash = "redirecting";
      const result = await signInWithPopup(auth, provider);

      if (result) {
        setIsAppleSubmitting(true);
        const {email, uid} = result.user;

        // Apple credential - for debugging
        const credential = OAuthProvider.credentialFromResult(result);
        const idToken = credential.idToken;
        console.log("original ID token", idToken);

        if (isUserSigningUp) {
          await signUpUser({email, uid, isSSO: true, password: null, phone: null});
        }

        /* The backend update the customer claim, another word for
          the backend update the user meta data in order to be able to use it efficiently
          It adds two properties, isSSO and coralUserID
          However In order for a user in a client app to see changes to their custom claims, 
          the user has to either sign out and back in again, or force refresh their ID token. 
          In firebase that would be with user.getIdToken(true)
        */
        // await getIdToken(result.user, true);

        const updatedIdToken = await getIdTokenResult(result.user, true);

        if (updatedIdToken) {
          signInSso(updatedIdToken.token, result.user);
          // Redirect can be useful for debugging 
          // await signInWithRedirect(auth, provider)
          // const result = await getRedirectResult(auth);
          window.location.hash = '';
        }
      }
      // we cannot make req without making sure that the user is created in the DB
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The credential that was used.
      const credential = OAuthProvider.credentialFromError(error);
    } finally {
      setIsAppleSubmitting(false);
    }
  }

  console.log("#LOGIN page: isAuthenticated", isAuthenticated);
  console.log("#LOGIN page: forceRedirect", forceRedirect);


  return (isAuthenticated) ? (location.state?.performDeleteAccount ?
    <Redirect
      to={{
        pathname: MyRoutes.ACCOUNT_DELETTION,
      }}
    /> :
    <Redirect
      to={{
        pathname: MyRoutes.HOME,
      }}
    />
  ) : (
    <>
      <Formik
        initialValues={loginInitialValues}
        validationSchema={loginValidationSchema}
        onSubmit={async (values, {setSubmitting}) => {
         try {
          setIsFormSubmitting(true);
          if (isUserSigningUp) {
            await signUpUser(values);
          } else {
            await loginUser(values);
          }
          setTimeout(() => {
            setIsFormSubmitting(false);
          }, 1000);
         } catch (error) {
          setIsFormSubmitting(false);
         }
        }
        }>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          submitCount,
          values,
          errors,
        }) => (
          <S.Container height={height}>
            {isShowBanner && <Banner />}
            <S.InputsContainer>
              <S.Spaccing style={{display: 'flex', flexDirection: 'column'}}>
                <S.Heading>
                  {isUserSigningUp ? (isNewOBOrder ? 'Create Coral Account to learn your desire type' : 'Create Coral account for your personalized plan') : 'Log in to Coral'}
                </S.Heading>
                <S.AppleLogo />
                <S.SloganContainer>
                  <S.SloganText>
                    Your trusted guide to a happier, healthier intimate life.
                  </S.SloganText>
                  <S.StarsContainer>
                    {starsCount.map((count) => (
                      <S.StarIcon key={`star-${count}`} />
                    ))}
                  </S.StarsContainer>
                </S.SloganContainer>
                <S.AuthInput
                  label="E-mail"
                  placeholder={'Enter your email'}
                  value={values.email}
                  type="email"
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  error={!!submitCount && !!errors?.email}
                  errorMessage={errors?.email}
                />
                <S.AuthInput
                  label="Password"
                  placeholder={'Enter your password'}
                  value={values.password}
                  type="password"
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                />
                <S.AuthButton
                  onClick={handleSubmit}
                  disabled={!values.email || !values.password || isFormSubmitting || isAppleSubmitting}
                  loading={isFormSubmitting}>
                  {isUserSigningUp ? 'SIGN UP' : 'LOG IN'}
                </S.AuthButton>

                <S.AuthButton
                  disabled={isFormSubmitting || isAppleSubmitting}
                  onClick={() => loginWithApple()}
                  loading={isAppleSubmitting}
                >
                  <S.AppleIcon />
                  {isUserSigningUp ? 'SIGN UP WITH APPLE' : 'LOG IN WITH APPLE'}
                </S.AuthButton>

                {!isNewOBOrder || isShowNewVariant && (
                  <S.LinksContainer>
                    {isUserSigningUp ? `Have an account? ` : `Need an account? `}
                    {isUserSigningUp ? (
                      <S.AuthMethodLink onClick={onToggleLoginSignup}>
                        Log in
                      </S.AuthMethodLink>
                    ) : (
                      <S.AuthMethodLink onClick={onToggleLoginSignup}>
                        Sign up
                      </S.AuthMethodLink>
                    )}
                  </S.LinksContainer>
                )}
              </S.Spaccing>
            </S.InputsContainer>
            <S.HeaderBackgroundImage />
            <S.MiddleContainer>
              <S.Spaccing>
                <S.Heading>Join Over 1 MILLION Happy Customers</S.Heading>
                <RatingAndMedia />
              </S.Spaccing>
            </S.MiddleContainer>
            <S.LoginTextBackground />
            <S.Footer style={{display: 'flex', flexDirection: 'column'}}>
              <S.HeadingFooter>
                Your trusted guide to a happier, healthier intimate life.
              </S.HeadingFooter>
              <S.CurvedLineContainer>
                <S.CurvedLine />
              </S.CurvedLineContainer>
              <S.ChecklistContainer>
                {checklistText.map((item) => (
                  <S.CheckText key={item}>
                    <S.CheckIcon />
                    {item}
                  </S.CheckText>
                ))}
              </S.ChecklistContainer>
              <S.PrivacyText>
                Privacy matters to us.{' '}
                <S.LinkText to="#" onClick={() => { }}>
                  {'Learn how'}
                </S.LinkText>
                .
              </S.PrivacyText>
              <S.PrivacyText>
                By signing up you agree to receive promotional
                <br /> emails and to our{' '}
                <S.LinkText to="#" onClick={() => { }}>
                  terms and conditions
                </S.LinkText>
                .
              </S.PrivacyText>
              <S.Logo style={{alignSelf: 'center'}} />
            </S.Footer>
          </S.Container>
        )}
      </Formik>
    </>
  );
};

export default AuthForm;
