import { Fragment } from 'react'
import * as S from './styles';
import { Popover, Transition } from '@headlessui/react';
import analytics, { homePage } from 'helpers/analytics-helper';

type Props = {
  textColorBlack?: boolean;
};

const Header = ({textColorBlack}: Props) => {

  const menus = [
    {
      name: 'About',
      link: 'https://getcoral.app/about'
    },
    {
      name: 'FAQ',
      link: 'https://getcoral.app/faq'
    },
    {
      name: 'Values',
      link: 'https://getcoral.app/values'
    },
    {
      name: 'Gift',
      link: 'https://getcoral.app/gift'
    },
    {
      name: 'Journal',
      link: 'https://getcoral.app/journal',
      event: homePage
    },
    {
      name: 'Discussions',
      link: '/discussions',
      event: homePage
    },
  ];
  
  const eventButtonClick = (event) => {
    if(event) {
      analytics.googleAnalyticsEventTracker(event);
    }
  }

  return (
    <S.Container>
      <Popover className="relative bg-transparent h-full">
        <S.HeaderMainContainer>
          <S.HeaderMain textColor={textColorBlack}>
            <S.HeaderIconContainer>
              <S.HeaderIcon textColor={textColorBlack}/>
            </S.HeaderIconContainer>
            <S.RightMenuBars>
              <S.PopoverButton>
                <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <S.HeaderMenu aria-hidden="true" />
                </Popover.Button>
              </S.PopoverButton>
            </S.RightMenuBars>
            <Popover.Group as="nav" className="hidden md:flex xl:space-x-10 space-x-5 ml-5">
              {menus?.map((menu, index) => {
                return (
                  <S.ClickEvent onClick={() => eventButtonClick(menu.event)}>
                    <S.HeaderMenuButton target="_self" href={menu.link} key={index} textColor={textColorBlack}>
                      <S.HoverMenuButton>
                        {menu.name}
                      </S.HoverMenuButton>
                    </S.HeaderMenuButton>
                  </S.ClickEvent>
                )
              })}
            </Popover.Group>
          </S.HeaderMain>
        </S.HeaderMainContainer>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 inset-x-0  transition transform origin-top-right md:hidden h-full">
            <S.MenuPopupMobile>
              <S.MenuPopupContainer>
                <S.MenuHeader>
                  <S.MenuHeaderbutton>
                    <Popover.Button className="rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <S.CloseIconHeader />
                    </Popover.Button>
                  </S.MenuHeaderbutton>
                </S.MenuHeader>
                <S.PopupMenuListMobile>
                  {menus?.map((menu, index) => {
                    return (
                      <S.ClickEvent key={index} onClick={() => eventButtonClick(menu.event)}>
                        <S.HeaderMenuButtonMobile to={menu.link}>
                          <S.HoverMenuButton>
                            {menu.name}
                          </S.HoverMenuButton>
                        </S.HeaderMenuButtonMobile>
                      </S.ClickEvent>
                    )
                  })}
                </S.PopupMenuListMobile>
              </S.MenuPopupContainer>
            </S.MenuPopupMobile>
          </Popover.Panel>
        </Transition>
      </Popover>
    </S.Container>
  );
};

export default Header;
