import {FC, useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import {APP_STORES_URL_FOR_NON_DISCUSSION} from 'helpers/constants-helper';

import * as S from './styles';
import {useFirebaseAuthContext} from 'context/auth';
import {useParamsContext} from 'context/params';
import {useFlagsmithContext} from 'context/flagsmith';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import {getAuth} from 'firebase/auth';
import {useHistory} from 'react-router';
import * as MyRoutes from 'constants/Routes';
import {Dialog, } from '@headlessui/react';
import {setRandomGUID, showErrorToast, showSuccessToast} from 'helpers';
import {useMutation} from '@apollo/client';
import {DELETE_ACCOUNT} from 'graphql/user/user.gql.mutations';

type AccountDeletionProps = {
  isSubscribed?: boolean;
};

const AccountDeletion: FC = () => {
  const location = useLocation<AccountDeletionProps>();
  const isSubscribed = location.state?.isSubscribed;

  const {logout, isAuthenticated} = useFirebaseAuthContext();
  const {resetParams} = useParamsContext();
  const {getRandomID} = useFlagsmithContext();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const history = useHistory();
  const [deleteAccount, {loading: loadingDelete}] = useMutation(
    DELETE_ACCOUNT,
  );

  const auth = getAuth();

  const openLink = () => {
    window.fbq('track', 'Lead');
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.ACCOUNT_DELETTION);
  }, []);

  const onPressAccountDeletion = () => {
    if (isAuthenticated) {
      setIsOpenConfirmModal(true);
    } else {
      history.push(MyRoutes.LOGIN, {
        performSignUp: false,
        performDeleteAccount: true
      });
    }
  }

  const onPressConfirmDelete = async () => {
    setIsOpenConfirmModal(false);
    if (!isAuthenticated)
      return;
    try {
      const deletedAccount = await deleteAccount();
      if (deletedAccount.data.deleteAccount.isAccountRemoved) {
        showSuccessToast('Sucess', 'Your account will be deleted within the next 48 hours!');
        resetParams();
        if (isAuthenticated) {
          logout();
          setRandomGUID(getRandomID());
        }
      } else {
        showErrorToast('Oops!', 'Something went wrong. Please try again.');
      }
    } catch (error) {
      showErrorToast('Oops!', 'Something went wrong. Please try again.');
    }

  }

  return (
    <S.Container>
      <S.CoralLogo />
      <S.Heading>
        {isSubscribed ? 'Welcome to Coral premium!' : 'Welcome to Coral!'}
      </S.Heading>
      <S.SubHeading>
        Download Coral to start your journey. Login with the credentials you
        just created once inside the app.
      </S.SubHeading>
      <S.QRCode />
      <S.Button loading={loadingDelete} onClick={onPressAccountDeletion}>
        DELETE MY ACCOUNT
      </S.Button>
      <S.StoreLinks>
        <S.StoreLink target="_blank" href={APP_STORES_URL_FOR_NON_DISCUSSION} onClick={openLink}>
          <S.AppStoreLogo />
        </S.StoreLink>
        <S.StoreLink target="_blank" href={APP_STORES_URL_FOR_NON_DISCUSSION} onClick={openLink}>
          <S.PlayStoreLogo />
        </S.StoreLink>
      </S.StoreLinks>
      <S.HeaderBackgroundImage />
      <S.FooterBackgroundImage />
      <Dialog open={isOpenConfirmModal} onClose={() => setIsOpenConfirmModal(false)} className="relative z-50">
        <S.OverlayDiv aria-hidden="true" />
        <S.ModalContent>
          <Dialog.Panel>
            <S.ModalBody>
              <S.Heading>Delete account</S.Heading>

              <S.ModalTextContent>
                Deleting your account will permanently delete your Coral profile. Are you sure you want to delete your account without cancelling your subscription?
              </S.ModalTextContent>

              <S.WrapButton>
                <S.DeleteButton onClick={onPressConfirmDelete}>
                  Delete Account
                </S.DeleteButton>
                <S.CancelButton onClick={() => setIsOpenConfirmModal(false)}>
                  Cancel
                </S.CancelButton>
              </S.WrapButton>
            </S.ModalBody>
          </Dialog.Panel>
        </S.ModalContent>
      </Dialog>
    </S.Container>
  );
};

export default AccountDeletion;
