import gql from 'graphql-tag';

const ACCEPT_REFERRAL_LINK = gql`
  mutation AcceptReferralLink($referralLinkId: Int!) {
    acceptReferralLink(referralLinkId: $referralLinkId) {
      message
      id
      userId
      createdAt
      updatedAt
      originalReferralLinkId
      referralId
  }
}
`;

export default {
  ACCEPT_REFERRAL_LINK
};
