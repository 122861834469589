import styled from 'styled-components';
import Heading from 'components/base/Heading';

import Colors from 'assets/styles/Colors';
import tw from 'twin.macro';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  z-index: 1;
`;

export const Logo = styled.img.attrs(() => ({
  resizeMode: 'contain',
}))`
  width: 195px;
  height: 195px;
`;

export const Title = styled(Heading).attrs(() => ({
  type: 3,
}))`
  margin-top: -12px;
  text-align: center;
`;

export const Description = styled(Heading).attrs(() => ({
  type: 5,
}))`
  margin-top: 34px;
  text-align: center;
`;

export const SubDescription = styled.div`
  margin-top: 16px;
  text-align: center;
`;

export const SubDescriptionText = styled(Heading).attrs(() => ({
  type: 6,
}))`
  display: inline;
`;

export const SubDescriptionRed = styled(Heading).attrs(() => ({
  type: 6,
}))`
  display: inline;
  margin-top: 16px;
  text-align: center;
  color: ${Colors.Red};
`;

export const Footer = styled.div`
  ${tw`mb-5`}
  padding: 0 16px;
  z-index: 2;
`;

export const TestimonialContainer = styled.div`
  margin-top: 50px;
  height: 230px;
  align-items: center;
  justify-content: center;
`;
