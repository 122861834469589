import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Trait } from 'context/types';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import { TRAIT_TYPES, TraitSteps } from 'constants/traits';
import OnBoardingOption from 'components/OnboardingOption';
import OnboardingAnswersList from 'components/OnboardingAswersList';
import * as MyRoutes from 'constants/Routes';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const TraitInterestedIn = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const {
    getTraitsForStep,
    loadingSetTrait,
    setUserTrait,
    selectedTrait,
  } = useOnboardingTraitsContext();
  const interests = getTraitsForStep(TRAIT_TYPES.INTERESTED_IN);

  const handleSelectInterest = async (interestedIn: Trait) => {
    setUserTrait(TraitSteps.InterestedIn, interestedIn);
    utmMedium ? 
    history.push(`${MyRoutes.ONBOARDING_RELATIONSHIP_STATUS_TRAIT}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_RELATIONSHIP_STATUS_TRAIT);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_INTRESTED_TRAITS);
  }, []);

  return (
      <S.Container height={height}>
        <OnboardingAnswersList progress={16} />
        <S.LeftHeading>Interested in</S.LeftHeading>
        <S.OptionList>
          {interests?.map((interest: Trait) => (
            <OnBoardingOption
              key={interest.id}
              width={168}
              height={160}
              text={interest.name}
              color={Colors.Roe}
              onClick={() => handleSelectInterest(interest)}
              loading={loadingSetTrait && selectedTrait === interest.id}
            />
          ))}
        </S.OptionList>
        <S.BottomImage4 />
      </S.Container>
  );
};

export default TraitInterestedIn;
