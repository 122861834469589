enum Shadows {
  'Box_0' = '0px 0px 0px rgba(0, 0, 0, 0)',
  'Box_1' = '0px 2px 5px rgba(0, 0, 0, 0.1)',
  'Box_6' = '0px 3px 1px rgba(0, 0, 0, 0.06)',
  'Box_6_2' = '0px 2px 5px rgba(0, 0, 0, 0.06)',
  'Box_11' = '0px 12px 20px rgba(0, 0, 0, 0.11)',
  'Box_15' = '0px 3px 8px rgba(0, 0, 0, 0.15)',
  'Box_2' = '0px 2px 5px rgba(0, 0, 0, 0.2)',
  'Box_25' = '0px 12px 20px rgba(0, 0, 0, 0.25)',
  'Box_4' = '0px 4px 4px rgba(0, 0, 0, 0.4)',
  'Box_Accordion' = '0px 10px 20px rgba(174, 100, 70, 0.05)',
  'Box_Search' = '0px 5px 10px rgba(174, 100, 70, 0.15)',
}

export default Shadows;
