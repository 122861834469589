import styled from 'styled-components';
import coralIconCircle from 'assets/images/coral-icon-circle.png';

export const Container = styled.div`
  padding-left: 4px;
`;

export const CoralUserIcon = styled.img.attrs({
  src: coralIconCircle,
})`
  width: 22px;
  height: 22px;
`;
