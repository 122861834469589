import styled from 'styled-components';
import tw from 'twin.macro';

import Loader from 'components/base/Loader';
import Colors from "assets/styles/Colors";

export const Container = styled.div`
  ${tw`flex flex-1 flex-row justify-center items-center`}
`;

export const LoadingContainer = styled.div`
  ${tw`flex flex-row justify-center items-center`}
`;

export const Spinner = styled(Loader).attrs({
  color: Colors.Red
})`
  ${tw`ml-5`}
`;

export const LoadingContainerText = styled.span`
  ${tw`ml-5`}
`;