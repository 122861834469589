import * as S from './styles';
import CommunityCardContainer from '../../../../components/NewHome/Cards';
import * as MyRoutes from 'constants/Routes';

const Community = () => {
  return (
    <>
      <S.LeftCommunityBG />
      <S.CommunityContainer>
        <S.CommunityTitle>
          Our Community
          <S.Underline />
        </S.CommunityTitle>

        <CommunityCardContainer />

        <S.CommunityButton>
          <S.DiscutionButton to={MyRoutes.DISCUSSIONS}>
            See more discussions
          </S.DiscutionButton>
          <S.StartButton to={MyRoutes.DISCUSSIONS}>
            Join our community
          </S.StartButton>
        </S.CommunityButton>
      </S.CommunityContainer>
    </>
  );
};

export default Community;
