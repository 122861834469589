import * as S from './styles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import RatingReview from '../../../../components/NewHome/Sponsors';

const Testimonial = () => {

  const testimonials = [
    {
      description: 'Enjoy guided exercises for solo and partnered connection',
      name: 'Joshua, 38, M'
    },
    {
      description: 'Enjoy guided exercises for solo and partnered connection',
      name: 'Joshua, 38, M'
    },
    {
      description: 'Enjoy guided exercises for solo and partnered connection',
      name: 'Joshua, 38, M'
    },
    {
      description: 'Enjoy guided exercises for solo and partnered connection',
      name: 'Joshua, 38, M'
    }
  ];

  return (
    <>
      <S.TestimonialContainer>
        <Carousel infiniteLoop showArrows={false}>
          {testimonials.map((testimonial, index) => {
            return (
              <S.TestimonialTitle key={index}>
                {testimonial.description}
                <S.TestimonialName>{testimonial.name}</S.TestimonialName>
              </S.TestimonialTitle>
            )
          })}
        </Carousel>
        <RatingReview />
      </S.TestimonialContainer>
    </>
  );
};

export default Testimonial;
