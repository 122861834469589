import styled from 'styled-components';

import Colors from 'assets/styles/Colors';

type Props = {
    isExpertAnswer?: boolean;
};

export const Container = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const HerderRightButton = styled.div`
  flex-direction: row;
  display: flex;
  font-size: 24px;
`;

export const Title = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
`;

export const Spacer = styled.div`
flex: 1;
`;

export const Header = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const AuthorText = styled.div`
  font-family: 'Inter';
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: ${Colors.Black};
`;

export const Content = styled.div`
  background-color: ${Colors.White};
  border-radius: 32px;
  padding: 16px 20px;
  margin-top: 16px;
  background-color: ${({isExpertAnswer}: Props) => isExpertAnswer ? Colors.SeaGreen : Colors.White}
`;

export const Text = styled.div`
  font-family: 'Inter';
  font-size: 15px;
  line-height: 20px;
  color: ${Colors.Black};
`;

export const NewBadge = styled.div`
  width: 48px;
  height: 18px;
  background-color: ${Colors.Ash};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export const NewBadgeLabel = styled.div`
  letter-spacing: 2.4px;
  font-size: 10px;
  color: ${Colors.White};
  font-family: 'Andes';
  text-transform: uppercase;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
`;

export const LinkButton = styled.button`
margin-right: 20px;
`;

export const LinkButtonText = styled.div`
  font-family: 'Inter';
  font-size: 15px;
`;

export const DateText = styled.div`
  font-family: 'Inter';
  font-size: 12px;
  line-height: 16px;
`;