import React, { useEffect } from 'react';

import { FirebaseAuthProvider } from './auth';
import { UserProvider } from './user';
import { OnboardingProvider } from './onboarding';
import { OnboardingTraitsProvider } from './onboarding-traits';
import { OnboardingDesireTypeProvider } from './onboarding-desire-type';
import { CacheProvider } from './cache';
import { ParamsProvider } from './params';
import { DimensionsProvider } from './dimensions';
import { PitchProvider } from './onboarding-pitch';
import { FlagsmithProvider } from './flagsmith';
import { useIntercom } from 'react-use-intercom';
import { DiscussionProvider } from './discussion';
import { SubscriptionProvider } from './subscription';

type Props = {
  children: React.ReactNode;
};

const MainProvider = ({ children }: Props) => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot({});
  }, []);

  return (
    <FirebaseAuthProvider>
      <ParamsProvider>
        <UserProvider>
          <SubscriptionProvider>
            <OnboardingProvider>
              <OnboardingTraitsProvider>
                <OnboardingDesireTypeProvider>
                  <CacheProvider>
                    <DimensionsProvider>
                      <PitchProvider>
                        <FlagsmithProvider>
                          <DiscussionProvider>{children}</DiscussionProvider>
                        </FlagsmithProvider>
                      </PitchProvider>
                    </DimensionsProvider>
                  </CacheProvider>
                </OnboardingDesireTypeProvider>
              </OnboardingTraitsProvider>
            </OnboardingProvider>
          </SubscriptionProvider>
        </UserProvider>
      </ParamsProvider>
    </FirebaseAuthProvider>
  );
};

export default MainProvider;
