import {DESIRE_TYPES_IAR} from 'constants/onboarding';

export const GET_IN_MOOD_ANSWERS = {
  SUDDEN: 'sudden',
  GRADUAL: 'gradual',
};

export const MOOD_ANSWERS = {
  LESS_EFFORT: 'takes less effort',
  MORE_EFFORT: 'takes more effort',
  ABOUT_EFFORT: 'about the same',
};

export default [
  {
    id: 1,
    name: 'more often',
    type: DESIRE_TYPES_IAR.INITIATIVE,
  },
  {
    id: 2,
    name: 'less often',
    type: DESIRE_TYPES_IAR.INITIATIVE,
  },
  {
    id: 3,
    name: 'the same amount',
    type: DESIRE_TYPES_IAR.INITIATIVE,
  },
  {
    id: 4,
    name: MOOD_ANSWERS.LESS_EFFORT,
    type: DESIRE_TYPES_IAR.DESIRE_MOOD,
  },
  {
    id: 5,
    name: MOOD_ANSWERS.MORE_EFFORT,
    type: DESIRE_TYPES_IAR.DESIRE_MOOD,
  },
  {
    id: 6,
    name: MOOD_ANSWERS.ABOUT_EFFORT,
    type: DESIRE_TYPES_IAR.DESIRE_MOOD,
  },
  {
    id: 7,
    name: 'has dwindled',
    type: DESIRE_TYPES_IAR.DESIRE_PARTNER_MOOD,
  },
  {
    id: 8,
    name: 'has grown',
    type: DESIRE_TYPES_IAR.DESIRE_PARTNER_MOOD,
  },
  {
    id: 9,
    name: 'is about the same',
    type: DESIRE_TYPES_IAR.DESIRE_PARTNER_MOOD,
  },
  {
    id: 10,
    name: GET_IN_MOOD_ANSWERS.SUDDEN,
    type: DESIRE_TYPES_IAR.DESIRE_GET_IN_MOOD,
  },
  {
    id: 11,
    name: GET_IN_MOOD_ANSWERS.GRADUAL,
    type: DESIRE_TYPES_IAR.DESIRE_GET_IN_MOOD,
  },
  {
    id: 12,
    name: GET_IN_MOOD_ANSWERS.SUDDEN,
    type: DESIRE_TYPES_IAR.DESIRE_PARTNER_GET_IN_MOOD,
  },
  {
    id: 13,
    name: GET_IN_MOOD_ANSWERS.GRADUAL,
    type: DESIRE_TYPES_IAR.DESIRE_PARTNER_GET_IN_MOOD,
  },
];

export const descriptions = {
  [DESIRE_TYPES_IAR.INITIATIVE]:
    'How often do you initiate sex as compared to your partner?',
  [DESIRE_TYPES_IAR.DESIRE_MOOD]:
    'Does it take more effort to get in the mood now than at the start of your relationship?',
  [DESIRE_TYPES_IAR.DESIRE_PARTNER_MOOD]: 'What about your partner?',
  [DESIRE_TYPES_IAR.DESIRE_GET_IN_MOOD]:
    'Do you suddenly get in the mood, like a light switch, or gradually, like a dimmer, after you start touching or making out?',
  [DESIRE_TYPES_IAR.DESIRE_PARTNER_GET_IN_MOOD]: 'What about your partner? ',
};

export const desireTypeResult = {
  [`${GET_IN_MOOD_ANSWERS.GRADUAL}+${GET_IN_MOOD_ANSWERS.GRADUAL}`]: `You and your partner have the same desire type.

You both feel responsive desire, which means that you both need to get aroused before you get in the mood. 
  
Research shows that this is common in long term relationships, but when both partners feel responsive desire, you need to consciously create opportunities for intimacy to keep the spark alive.`,
  [`${GET_IN_MOOD_ANSWERS.GRADUAL}+${GET_IN_MOOD_ANSWERS.SUDDEN}`]: `You and your partner have different desire types. 

You feel responsive desire, which means that you need to get aroused before you get in the mood, while your partner’s desire might seem to strike from out of nowhere.
  
Research shows that responsive desire is common in long term relationships, but because you and your partner are coming from opposite ends of the spectrum, you need tools to stay compatible and connected.`,
  [`${GET_IN_MOOD_ANSWERS.SUDDEN}+${GET_IN_MOOD_ANSWERS.GRADUAL}`]: `You and your partner have different desire types. 

You feel spontaneous desire, which means that your desire can strike from out of nowhere, while your partner needs to get aroused before they get in the mood.
  
Research shows that responsive desire is common in long term relationships, but because you and your partner are coming from opposite ends of the spectrum, you need tools to stay compatible and connected.`,
  [`${GET_IN_MOOD_ANSWERS.SUDDEN}+${GET_IN_MOOD_ANSWERS.SUDDEN}`]: `You and your partner have the same desire type. 

You both feel spontaneous desire, which means that your desire can strike from out of nowhere. 
  
Research shows that spontaneous desire becomes less common within long term relationships, so you may need tools to stay connected and keep the spark alive.`,
};
