import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';

import { APP_STORES_URL_FOR_NON_DISCUSSION } from 'helpers/constants-helper';

import * as S from './styles';
import { useFirebaseAuthContext } from 'context/auth';
import { useParamsContext } from 'context/params';
import { useFlagsmithContext } from 'context/flagsmith';
import { setRandomGUID } from 'helpers/storage-helper';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

type ConfirmationProps = {
  isSubscribed?: boolean;
};

const Confirmation: FC = () => {
  const location = useLocation<ConfirmationProps>();
  const isSubscribed = location.state?.isSubscribed;

  const { logout, isAuthenticated } = useFirebaseAuthContext();
  const { resetParams } = useParamsContext(); 
  const { getRandomID } = useFlagsmithContext();

  const openLink = () => {
    window.fbq('track', 'Lead');
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.CONFIRMATION);
  }, []);

  useEffect(() => {
    resetParams();
    if (isAuthenticated) {
      logout();
      setRandomGUID(getRandomID());
    }
  }, [isAuthenticated]);

  return (
    <S.Container>
      <S.CoralLogo />
      <S.Heading>
        {isSubscribed ? 'Welcome to Coral premium!' : 'Welcome to Coral!'}
      </S.Heading>
      <S.SubHeading>
        Download Coral to start your journey. Login with the credentials you
        just created once inside the app.
      </S.SubHeading>
      <S.QRCode />
      <S.StoreLinks>
        <S.StoreLink target="_blank" href={APP_STORES_URL_FOR_NON_DISCUSSION} onClick={openLink}>
          <S.AppStoreLogo />
        </S.StoreLink>
        <S.StoreLink target="_blank" href={APP_STORES_URL_FOR_NON_DISCUSSION} onClick={openLink}>
          <S.PlayStoreLogo />
        </S.StoreLink>
      </S.StoreLinks>
      <S.HeaderBackgroundImage />
      <S.FooterBackgroundImage />
    </S.Container>
  );
};

export default Confirmation;
