import Heading from 'components/base/Heading';
import tw from 'twin.macro';
import styled from 'styled-components';

import imageBg2 from 'assets/images/discussion-img2.png';
import collageBottom from 'assets/images/discussion-bg-bottom.png';
import coralLogoRed from 'assets/images/coral-logo-red.png';
import coralLogoWhite from 'assets/images/coral-logo-white.png';
import AppleLogoSvg from 'assets/images/appleLogo.svg';
import Colors from 'assets/styles/Colors';
import Paragraph from 'components/base/Paragraph';
import Button from 'components/base/Button';
import {ASSETS_PATH} from 'constants/assets';
import Divider from 'assets/images/divider';
import Loader from 'components/base/Loader';
import HeartSvg from 'assets/images/Exercises/heart.svg';
import CommentSvg from 'assets/images/Exercises/comment.svg';
import ShareSvg from 'assets/images/Exercises/share.svg';

export const Container = styled.div.attrs(() => ({
    showsVerticalScrollIndicator: false,
    bounces: false,
    contentContainerStyle: {
        flexGrow: 1,
    },
}))`
  flex: 1;
  width: 100%;
  background-color: ${Colors.Coconut};
`;

export const SeeMoreLayer = styled.div`
  flex: 1;
  padding: 16px;
  margin-top: 30px;
  min-height: 100px;
  flex-shrink: 0;
`;

export const UnitLayer = styled.div`
  padding: 0 20px;
  margin-top: 40px;
`;

export const OverFlowComments = styled.div`
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  display: flex;
  height: 500px;
  border-color: #4cd964;
  position: absolute;
  background: linear-gradient(0deg, #F7F4F3 30.36%, rgba(217, 217, 217, 0) 87.98%);
  z-index: 99`

export const DownloadLayer = styled.div`
  flex: 1;
  width: 100%;
  padding: 40px 20px;
`;

export const BottomLayer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 40px 20px;
  margin-top: 190px;
  background-color: #3C536C
`;

export const RatingAndMediaContainer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 0 20px;
`;

export const BgBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  z-index: 0;
  top: -285px;
  left: 0;
  background-image: url(${collageBottom});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ImageBg2 = styled.img.attrs(() => ({
    src: imageBg2,
}))`
  width: 258px;
  height: 190px;
  margin: auto;
`;

export const Header = styled.div`
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 20px;
  z-index: 10;
`;

export const Separator = styled.div`
  width: 100%;
  opacity: 0.2;
  border: 0.5px solid #000000;
  margin-top: 40px;
  background-color: red;
`;

export const CoralLogo = styled.img.attrs({
    src: coralLogoRed
})`
  max-width: none;
  width: 99px;
  height: 30px;
  margin: auto;
  margin-bottom: 30px;
`;

export const CoralLogoWhite = styled.img.attrs({
    src: coralLogoWhite
})`
  max-width: none;
  width: 135px;
  height: 41px;
  margin: auto;
  margin-bottom: 30px;

`;

export const Title = styled(Heading).attrs({
    type: 2
})`
  margin: 20px 5px 0;
`;

export const TitleCenter = styled(Heading).attrs({
    type: 3
})`
  text-align: center;
  margin: 0 24px;
`;

export const ReadingTime = styled(Paragraph).attrs({
    type: 2
})`
  font-size: 12px;
  line-height: 26px;
  margin: 0 0;
`;

export const UnitTime = styled(Paragraph).attrs({
  type: 3
})`
  margin-bottom: 20px;
`
export const UnitTitle = styled(Heading).attrs({
    type: 3
})`
  font-weight: 400;
  text-align: left;
  margin-bottom: 16px;
`;

export const CommentsNumberText = styled.div`
  font-family: 'GTSuper';
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  margin: 16px 0px;
`;


export const UnitText = styled(Paragraph).attrs({
    type: 2
})`
  font-size: 19px;
  line-height: 30px;
`;

export const UnitSeparator = styled.div`
  background-color: ${Colors.Red};
  width: 100px;
  height: 8px;
  border-radius: 4px;
  margin: 40px 0;
`;

export const UnitSlide = styled.div`
  font-size: 19px;
  line-height: 36px;
`;

export const TextCenter = styled(Paragraph).attrs({
    type: 2
})`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin: 30px 24px;
`;

export const ExpertButtonContainer = styled.div`
  margin: 0 24px;
`;

export const ExpertButton = styled(Button).attrs({
    color: Colors.Red,
    textColor: Colors.White,
})`
  border-radius: 5px;
  padding: 21px;
`;

export const DailyPollSectionContainer = styled.div`
  padding: 0 16px;
  margin-top: 40px;
`;

export const AskAnExpertBtn = styled.div`
  background-color: ${Colors.Skin03};
  border-radius: 42px;
  padding-horizontal: 16px;
  padding-vertical: 12px;
  align-items: center;
  margin-bottom: 6px;
  flex-direction: row;
`;

export const AskAnExpertText = styled.div`
  color: ${Colors.Black};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  align-self: center;
  letter-spacing: 2.24px;
`;

export const AppOfTheDayLogo = styled.img.attrs(() => ({
    src: AppleLogoSvg,
}))`
  margin: auto;
  margin-bottom: 25px;

`;

export const StoreLinksContainer = styled.div`
  ${tw`z-10 flex justify-center mb-10`}
  margin-top: 20px;
`;

export const StoreLink = styled.a`
  ${tw`z-10 flex`}
`;

export const AppStoreLogo = styled.img.attrs(() => ({
    src: `${ASSETS_PATH}/app-store.png`,
}))`
  width: 151px;
  margin-right: 10px;
`;

export const PlayStoreLogo = styled.img.attrs(() => ({
    src: `${ASSETS_PATH}/play-store.png`,
}))`
  width: 151px;
`;

export const MenuContainer = styled.div`
  margin-bottom: 20px;
`;

export const MenuBig = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: white;
`;

export const MenuSmall = styled.div`
  font-family: 'Andes';
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  color: white;
`;

export const RelatedBox = styled.div`
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  margin: 40px 0;
`;

export const NumberOfUnitsContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 3px 8px;
  background: ${Colors.Black};
  border-radius: 10px;
  margin-left: 5px;
`;

export const NumberOfUnitsText = styled.div`
  font-family: 'Andes';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${Colors.White};
`;

export const RelatedTitle = styled.div`
  font-family: 'Andes';
  font-weight: 600;
  font-size: 12.1846px;
  line-height: 15px;
  letter-spacing: 0.425em;
  text-transform: uppercase;
`;

export const UnitAttachmentDividerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const UnitAttachmentDivider = styled(Divider)`
`;

export const SpinnerContainer = styled.div`
  ${tw`flex justify-center items-center h-48 mt-6`}
`;

  export const Spinner = styled(Loader).attrs({
  color: Colors.Red
})`
  ${tw`ml-5`}
`;

export const UnitShare = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 7%;
`;

export const ShareIcon = styled.img.attrs(() => ({
  src: ShareSvg,
}))`
`;

export const CommentSection = styled.div`
  align-items: center;
  display: flex;
  color: ${Colors.ColorIcon};
`;

export const ButtonSection = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const CommentIcon = styled.img.attrs(() => ({
  src: CommentSvg,
}))`
  margin-left: 8px;
`;

export const HeartIcon = styled.img.attrs(() => ({
  src: HeartSvg,
}))`
  margin-left: 8px;
`;

export const VideoPlayerCard = styled.div`
  align-items: center;
  width: 100%;
  margin: 20px auto;
`;

export const ClickEvent = styled.div``;