import Play from "./play";
import Pause from "./pause";
import Bar from "./bar";
import useAudioPlayer from "./useAudioPlayer";
import * as S from './styles';

interface AudioProps {
    url?: string
};

const AudioComponent = ({ url }: AudioProps) => {
    const {
        curTime,
        duration,
        playing,
        setPlaying,
        setClickedTime
    } = useAudioPlayer();

    return (
        <S.Player>
            <audio id="audio">
                <source src={url} />
            </audio>
            <S.Controls>
                <>
                    {playing ? (
                        <Pause handleClick={() => setPlaying(false)} />
                    ) : (
                        <Play handleClick={() => setPlaying(true)} />
                    )}
                    <Bar
                        curTime={curTime}
                        duration={duration}
                        onTimeUpdate={(time) => setClickedTime(time)}
                    />
                </>
            </S.Controls>
        </S.Player>
    )
}

export default AudioComponent;