import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import {IntercomProvider} from "react-use-intercom";

import BrowserRouter from 'routes';
import apolloClient from 'helpers/apollo-helper';
import MainProvider from 'context/MainProvider';
import {INTERCOM_APP_ID} from "helpers";

import 'react-toastify/dist/ReactToastify.css';

import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import * as amplitude from '@amplitude/analytics-browser';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_WRITE_KEY);
ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_WRITE_KEY);

amplitude.init(process.env.REACT_APP_SEGMENT_WRITE_KEY);

function App() {
  
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <ApolloProvider client={apolloClient}>
        <MainProvider>
          <BrowserRouter />
          <ToastContainer />
        </MainProvider>
      </ApolloProvider>
  </IntercomProvider>
  );
}

export default App;
