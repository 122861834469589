import { ReactElement } from 'react';

const getToastMessage = (title: string | ReactElement, message: string | ReactElement) => (
  <>
    <div className="title">{title}</div>
    <div className="message">{message}</div>
  </>
);

export {
  getToastMessage
}