import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useMutation } from '@apollo/client';

import onBoardingMutations from 'graphql/onBoarding/onBoarding.mutations';

import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';

import * as MyRoutes from 'constants/Routes';
import { DESIRE_TYPES_IAR, TOTAL_STEPS } from 'constants/onboarding';
import { useFirebaseAuthContext } from 'context/auth';
import {
  useOnboardingDesireTypeContext,
  DesireType,
  DesireTypeSteps,
} from 'context/onboarding-desire-type';

import desireTypeData, {
  descriptions as descriptionsIAR,
  GET_IN_MOOD_ANSWERS,
} from 'constants/desire-types-iar.mock';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';
/*
IDs based on this comment:
https://getcoral.atlassian.net/browse/PHOEN-588?focusedCommentId=13611

Trait Name
ResponsiveDesire -> GRADUAL
SpontaneousDesire -> SUDDEN
*/
const OPTION_VALUE_ID = {
  [GET_IN_MOOD_ANSWERS.GRADUAL]: 18,
  [GET_IN_MOOD_ANSWERS.SUDDEN]: 19,
};

const OPTION_CATEGORY = 'Desire Type';

const OnboardingDesireTypeGetInMood = () => {
  const history = useHistory();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const { setUserDesireType, steps } = useOnboardingDesireTypeContext();
  const { height } = useDimensionsContext();
  const { storeUserTraitsLocally } = useOnboardingTraitsContext();
  const [loadingTrait, setTrait] = useState<null | number>(null);
  const { isAuthenticated } = useFirebaseAuthContext();

  const desireMood = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES_IAR.DESIRE_GET_IN_MOOD,
  );

  const [setUserTraitsOnBoarding, { loading: settingTrait }] = useMutation(
    onBoardingMutations.SET_USER_TRAITS_ON_BOARDING,
  );

  const handleSelectValue = async (value: DesireType) => {
    setUserDesireType(DesireTypeSteps.DesireGetInMood, value);

    if (!isAuthenticated) {
      storeUserTraitsLocally({ id: OPTION_VALUE_ID[value.name], groupName: OPTION_CATEGORY, name: value.name })
    } else {
      await setUserTraitsOnBoarding({
        variables: {
          traitId: OPTION_VALUE_ID[value.name],
          traitCategory: OPTION_CATEGORY,
        },
      });
    }
    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_GET_MOOD}?${getAllParamsString()}`) :
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_GET_MOOD);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_IAR_GET_MOOD);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={13} total={TOTAL_STEPS} />
        <S.Answer
          text="I initiate"
          selectedOption={steps?.Initiative?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INITIATIVE,
            )
          }
        />
        <S.Answer
          text="Desire takes"
          selectedOption={steps?.DesireMood?.name}
          color={Colors.PaleCoral}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_MOOD)
          }
        />
        <S.Answer
          text="Their desire"
          selectedOption={steps?.DesirePartnerMood?.name}
          color={Colors.Skin02}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD,
            )
          }
        />
        <S.DescriptionLeft>
          {descriptionsIAR[DESIRE_TYPES_IAR.DESIRE_GET_IN_MOOD]}
        </S.DescriptionLeft>
        <S.LeftHeading>My desire is usually</S.LeftHeading>
        <OnBoardingOptions>
          {desireMood?.map((value) => (
            <OnBoardingOption
              key={value.id}
              text={value.name}
              color={Colors.Skin01}
              loading={settingTrait && loadingTrait === value.id}
              onClick={() => handleSelectValue(value)}
            />
          ))}
        </OnBoardingOptions>
      </S.TopContent>
      <S.BottomImage12 />
    </S.Container>
  );
};

export default OnboardingDesireTypeGetInMood;
