import React,{useEffect} from 'react';
import { useHistory } from 'react-router';


import {useOnboardingDesireTypeContext} from 'context/onboarding-desire-type';
import {useCacheContext} from 'context/cache';
import { TOTAL_STEPS } from 'constants/onboarding';
import * as MyRoutes from 'constants/Routes';

import {isInARelationship} from 'helpers/traits-helper';

import * as S from './styles';
import Colors from 'assets/styles/Colors';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';

const OnboardingDesireTypeConfirmation = () => {
  const history = useHistory();
  const {steps} = useOnboardingDesireTypeContext();
  const {userTraits} = useCacheContext();
  const { height } = useDimensionsContext();

  const isUserInARelationship = isInARelationship(userTraits);

  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  });

  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;
  const isShowNewVariant = featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;

  const onPressContinue = async () => {
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_RESULT);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_CONFIRMATION);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={11} total={TOTAL_STEPS} />
        <S.Answer
          text="My desire feels"
          selectedOption={steps?.DesireFeel?.name}
          color={Colors.PaleKelp}
          showIcon
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS)
          }
        />
        {isShowNewVariant || isUserInARelationship ? (
          <>
            <S.Answer
              text="Desire takes"
              selectedOption={steps?.DesireMood?.name}
              color={Colors.PaleCoral}
              showIcon
              onClick={() =>
                history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_MOOD)
              }
            />
            <S.Answer
              text="I need"
              selectedOption={steps?.DesirePartner?.name}
              color={Colors.Skin02}
              showIcon
              onClick={() =>
                history.push(
                  MyRoutes.ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER,
                )
              }
            />
          </>
        ) : (
          <S.Answer
            text="I spontaneously fantasize"
            selectedOption={steps?.DesireFantasy?.name}
            color={Colors.PaleCoral}
            showIcon
            onClick={() =>
              history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FANTASY)
            }
          />
        )}
        <S.Answer
          text="Connection is"
          selectedOption={steps?.Connection?.name}
          color={Colors.SeaGreen}
          showIcon
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_CONNECTION)
          }
        />
        <S.Answer
          text="Novelty"
          selectedOption={steps?.Novelty?.name}
          color={Colors.Skin01}
          showIcon
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_NOVELTY)
          }
        />
        <S.Answer
          text="Desire turns on"
          selectedOption={steps?.DesireTurns?.name}
          color={Colors.Roe}
          showIcon
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_TURNS_ON)
          }
        />
      </S.TopContent>
      <S.Content style={{justifyContent: 'flex-start'}}>
        <S.LeftHeading>How does that look?</S.LeftHeading>
      </S.Content>
      <S.Button
        icon={<S.CheckIcon />}
        onClick={onPressContinue}
      >
        LOOKS GOOD
      </S.Button>
      <S.BottomImage25 />
    </S.Container>
  );
};

export default OnboardingDesireTypeConfirmation;
