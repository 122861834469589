import { useEffect} from 'react';
import { useHistory} from 'react-router-dom';

import { usePitchContext } from 'context/onboarding-pitch';
import OnboardingPitch from 'components/OnboardingPitch';
import { ASSETS_PATH } from 'constants/assets';
import * as MyRoutes from 'constants/Routes';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import NewOnePitch from 'components/OnboardingNewPitch/one';
import * as S from './styles';
import analytics,{ ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const OnboardingPitchOne = () => {
  const history = useHistory();
  const { pitchCopy } = usePitchContext();
  const onboardingPitchOne = `${ASSETS_PATH}/onboarding-pitch-one.png`;

  const {utmMedium, getAllParamsString} = useUTMParams();

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_PITCH_ONE);
  }, []);

  const { featureVariant } = useFlagsmith({ featureName: FEATURE_FLAG_NAME.WR_OB_ORDER });
  const isShowDTQFirstVariant = featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;

  const goToNextPitch = () => {
    utmMedium ? 
    history.push(`${MyRoutes.ONBOARDING_PITCH_TWO}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_PITCH_TWO);
  };

  return (
    <>
      {isShowDTQFirstVariant ? (<NewOnePitch
       pitchCopy={pitchCopy}
       onboardingPitchOne={onboardingPitchOne}
       goToNextPitch={goToNextPitch}
       />) :
        <S.Container>
          <S.HeaderImageTwo />
          <S.HeaderImageOne />
          <S.HeaderImageMoon />
          <OnboardingPitch
            title={pitchCopy[0]?.title}
            description={pitchCopy[0]?.description}
            logoUrl={onboardingPitchOne}
            onPressContinue={goToNextPitch}
          />
          <S.BottomCenter />
          <S.OrangeCircle />
        </S.Container>
      }
    </>

  );
};

export default OnboardingPitchOne;
