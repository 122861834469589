import Colors from 'assets/styles/Colors';
import Loader from 'components/base/Loader';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  background-color: #f3f3f3;
  ${tw`flex flex-1 flex-col justify-center items-center overflow-y-auto`}
`;

export const LoadingSpinner = styled(Loader).attrs({
  color: Colors.Red
})``;
