import styled from 'styled-components';
import tw from 'twin.macro';
import Colors from 'assets/styles/Colors';
import { FaLessThan, FaGreaterThan } from 'react-icons/fa'

export const PrevNextButton = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Red};
  justify-content: end;

  @media (max-width: 500px) {
    margin-top: 5%;
    margin-bottom: 2%;
  }; 
`;

export const PrevButton = styled.div`
  width: 51px;
  height: 51px;
  display: flex;
  align-items: center;
  background: ${Colors.White};
  border-radius: 30px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const LeftSideArrow = styled(FaLessThan)`
  ${tw`mr-5 text-lg`}
  font-size: 16px;
  margin: auto;
`;

export const RightSideArrow = styled(FaGreaterThan)`
  ${tw`mr-5 text-lg`}
  font-size: 16px;
  margin: auto;
`;

export const CommunityCardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: 5%;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  };

  @media (max-width: 500px) {
    margin: 0;
  }; 
`;

