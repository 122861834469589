import styled from 'styled-components';

import Heading from 'components/base/Heading';
import BaseTitle from 'components/base/Title';
import Button from 'components/base/Button';
import OnBoardingOptions from 'components/OnboardingOptions';
import Colors from 'assets/styles/Colors';
import tw from 'twin.macro';

export const Container = styled.div`
  flex: 1;
  z-index: 10;
`;

export const Title = styled(Heading).attrs({
  type: 1,
})`
  padding-top: 5px;
  text-align: left;
`;

export const Text = styled(BaseTitle).attrs({
  type: 0,
})`
  text-align: center;
  color: ${Colors.Black};
`;

export const Paragraph = styled(Heading).attrs({
  type: 6,
})`
  color: ${Colors.Black};
  margin-top: 10px;
  margin-bottom: 24px;
`;

export const List = styled(OnBoardingOptions)`
  margin-top: 20px;
`;

export const ConnectButton = styled(Button)`
  margin-top: 14px;
`;

export const SkipButton = styled.a`
  font-family: 'Andes';
  letter-spacing: 0.2em;
  ${tw`flex justify-center text-sm font-medium`}
`;
