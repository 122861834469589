import gql from 'graphql-tag';
const GET_WEEKLY = gql`
  query GetWeeklyExercises {
    getWeeklyExercise {
      id
      name
      description
      players
      courseId
      topicId
      progress {
        started
        completed
      }
      data {
        hasAudio
        audioUrl
        time
      }
    }
  }
`;

const GET_STATIC_UNITS = gql`
  query GetUnits {
    units {
      id
      name
      description
      courseId
      players
      data {
        time
        hasAudio
      }
      progress {
        started
        completed
      }
    }
  }
`;

const GET_UNIT = gql`
  query getUnit($id: Int!) {
    unit(id: $id) {
      id
      name
      description
      bookmarked
      type
      hasAudio
      players
      versionName
      likes
      comments {
        text
      }
      unitMeta {
        hasImage
        imageUrl
        hasAudio
        audioUrl
      }
      data {
        time
        traitSets {
          name
        }
      }
      slides {
        id
        text
        type
        position
        data {
          references
        }
        attachments {
          id
          title
          position
          root
          type
          myAnswer {
            choiceInt
            choiceText
          }
          data {
            audioUrl
            videoUrl
            duration
            transcript
            imageUrl
            questionId
            questionType
            answerType
            graphType
            answerTraitGroup
            answerValueDelimiters
            customId
            answers {
              title
              total
              percentageTotal
              target
              actions {
                name
                target
                setType
                value
              }
            }
          }
        }
      }
    }
  }
`;

const GET_NEXT_UNIT = gql`
  query getNextUnit($courseId: Int!, $currentUnitId: Int!) {
    nextUnitInCourse(courseId: $courseId, currentUnitId: $currentUnitId) {
      id
      name
      type
      description
      hasAudio
      players
      bookmarked
      position
      data {
        time
      }
      progress {
        started
        completed
      }
    }
  }
`;

const GET_NEXT_INCOMPLETE_UNIT = gql`
  query getNextIncompleteUnit($courseId: Int!) {
    nextIncompleteUnit(courseId: $courseId) {
      id
      name
      type
      description
      hasAudio
      players
      bookmarked
      data {
        time
      }
      progress {
        started
        completed
      }
    }
  }
`;

const LIKE_UNIT = gql`
  mutation likeUnit($unitId: Int!) {
    likeUnit(unitId: $unitId)
  }
`;

const BOOKMARK_UNIT = gql`
  mutation bookmarkUnit($unitId: Int!, $slideId: Int!) {
    addUnitToBookmark(unit: {unitId: $unitId, slideId: $slideId}) {
      message
    }
  }
`;

const SAVE_UNIT_PROGRESS = gql`
  mutation saveProgress($progressInput: ProgressInput!) {
    saveProgress(progress: $progressInput) {
      isCurrentCourseComplete
      isCurrentTopicComplete
      totalTopicsCompleted
      totalUnitsCompleted
      coursesCompletedInCurrentTopic
      unitsCompletedInCurrentCourse
    }
  }
`;

const SAVE_QUESTION_RESULT = gql`
  mutation saveResult($resultInput: ResultInput!) {
    saveResult(result: $resultInput) {
      id
    }
  }
`;

const GET_UNIT_LIST_BY_COURSE = gql`
  query getUnitsByCourse($courseId: Int!) {
    getUnitsByCourse(courseId: $courseId) {
      visibleUnits {
        id
        name
        packId
        type
        description
        players
        bookmarked
        hasAudio
        position
        progress {
          started
          completed
        }
        data {
          time
        }
      }
    }
  }
`;

const SET_USER_TRAITS_FROM_UNIT_QUIZ = gql`
  mutation setUserTraitsFromUnitQuiz(
    $traitId: Int
    $setType: String
    $value: Int
    $previousValue: Int
  ) {
    setUserTraitsFromUnitQuiz(
      traitId: $traitId
      setType: $setType
      value: $value
      previousValue: $previousValue
    ) {
      traits
      valueTraits
    }
  }
`;

const GET_GENDER_BASED_EXERCISE_ITINERARY_LIST = gql`
  query GetGenderBasedExerciseItineraryList($traitGenderId: Int!) {
    getGenderBasedExerciseItineraryList(traitGenderId: $traitGenderId) {
      id
      name
      description
      type
      hasAudio
      players
      data {
        time
      }
    }
  }
`;

export default {
  GET_WEEKLY,
  GET_STATIC_UNITS,
  GET_UNIT,
  GET_NEXT_UNIT,
  GET_NEXT_INCOMPLETE_UNIT,
  LIKE_UNIT,
  BOOKMARK_UNIT,
  SAVE_UNIT_PROGRESS,
  GET_UNIT_LIST_BY_COURSE,
  SAVE_QUESTION_RESULT,
  SET_USER_TRAITS_FROM_UNIT_QUIZ,
  GET_GENDER_BASED_EXERCISE_ITINERARY_LIST,
};
