import * as React from 'react';

const Discussion = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z"
      fill="#ECA78C"
    />
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M16.946 17.885a3.256 3.256 0 00-2.32-.96h-5.57a3.276 3.276 0 00-3.28 3.297V21.6a.498.498 0 00.499.494h11.138a.498.498 0 00.497-.494v-1.378a3.258 3.258 0 00-.964-2.337z"
        fill="#fff"
      />
      <path
        d="M25.261 17.885a3.27 3.27 0 00-2.32-.96h-5.569a3.284 3.284 0 00-3.284 3.297V21.6a.498.498 0 00.498.493h11.138a.498.498 0 00.497-.493v-1.378a3.27 3.27 0 00-.96-2.337z"
        fill="#fff"
      />
      <path
        d="M21.105 17.885a3.27 3.27 0 00-2.32-.96h-5.573a3.278 3.278 0 00-3.28 3.297V21.6a.498.498 0 00.498.493h11.137a.498.498 0 00.498-.493v-1.378a3.27 3.27 0 00-.96-2.337z"
        fill="#1B211E"
      />
      <path
        d="M22.508 14.174a3.28 3.28 0 10-4.639-4.639 3.28 3.28 0 004.64 4.639zM14.072 14.174a3.28 3.28 0 10-4.639-4.638 3.28 3.28 0 004.64 4.638z"
        fill="#fff"
      />
      <path
        d="M15.969 15.133a3.28 3.28 0 100-6.56 3.28 3.28 0 000 6.56z"
        fill="#1B211E"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path
          fill="#fff"
          transform="translate(5.333 4.667)"
          d="M0 0h21.333v21.333H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Discussion;
