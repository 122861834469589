import React, { useEffect} from 'react';
import { useHistory} from 'react-router';

import {
  useOnboardingDesireTypeContext,
  DesireType,
  DesireTypeSteps,
} from 'context/onboarding-desire-type';

import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';

import * as MyRoutes from 'constants/Routes';
import { DESIRE_TYPES_IAR, TOTAL_STEPS } from 'constants/onboarding';
import desireTypeData, {
  descriptions as descriptionsIAR,
} from 'constants/desire-types-iar.mock';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const OnboardingDesireTypePartnerMood = () => {
  const history = useHistory();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const {setUserDesireType, steps} = useOnboardingDesireTypeContext();
  const { height } = useDimensionsContext();

  const desireMood = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES_IAR.DESIRE_PARTNER_MOOD,
  );

  const handleSelectValue = async (value: DesireType) => {
    setUserDesireType(DesireTypeSteps.DesirePartnerMood, value);
    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_GET_MOOD}?${getAllParamsString()}`) :
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_GET_MOOD);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={12} total={TOTAL_STEPS} />
        <S.Answer
          text="I initiate"
          selectedOption={steps?.Initiative?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INITIATIVE,
            )
          }
        />
        <S.Answer
          text="Desire takes"
          selectedOption={steps?.DesireMood?.name}
          color={Colors.PaleCoral}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_MOOD)
          }
        />
        <S.DescriptionLeft>
          {descriptionsIAR[DESIRE_TYPES_IAR.DESIRE_PARTNER_MOOD]}
        </S.DescriptionLeft>
        <S.LeftHeading>It feels their desire for me</S.LeftHeading>
        <OnBoardingOptions>
          {desireMood?.map((value) => (
            <OnBoardingOption
              key={value.id}
              text={value.name}
              color={Colors.Skin02}
              onClick={() => handleSelectValue(value)}
            />
          ))}
        </OnBoardingOptions>
      </S.TopContent>
      <S.BottomImage11 />
    </S.Container>
  );
};

export default OnboardingDesireTypePartnerMood;
