import styled from 'styled-components';
import tw from 'twin.macro';
import Colors from 'assets/styles/Colors';
import CustomButton from 'components/base/Button';
import AppStoreLogo from 'assets/images/NewHome/app-store.svg';
import PlayStoreLogo from 'assets/images/NewHome/play-store.svg';

export const LogoContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;

  @media (max-width: 575px) {
    justify-content: center;
  } 
`;

export const StoreLink = styled.a`
  ${tw`z-10 flex`}
`;

export const ClickEvent = styled.div``;

export const AppStoreImg = styled.img.attrs(() => ({
  src: AppStoreLogo,
}))`
  width: 160px;
`;

export const PlayStoreImg = styled.img.attrs(() => ({
  src: PlayStoreLogo,
}))`
  width: 160px; 
`;