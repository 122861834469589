import { ComponentType, useEffect } from "react";
import { Route, useLocation } from "react-router-dom";

import { EVENT_MAP_FOR_ROUTES } from "helpers/constants-helper";
import analytics from 'helpers/analytics-helper';
import { useParamsContext } from "context/params";
import { COACHING_CONFIRMATION, COACHING_PAYMENT } from "constants/Routes";

interface PublicRouteProps {
  exact?: boolean;
  path?: string;
  trackPage?: boolean;
  isHomePage?: boolean;
  component: ComponentType<any>;
} 
const PublicRoute = ({ component: Component, trackPage = true, path, isHomePage }: PublicRouteProps) => {
  const location = useLocation();
  const { addParams } = useParamsContext();
  
  useEffect(() => {
    document.documentElement.style.maxWidth = [COACHING_PAYMENT, COACHING_CONFIRMATION].includes(path)
      ? '100%'
      : !isHomePage ? '560px' : '';

    location.search && addParams(location.search);
    trackPage && analytics.page(EVENT_MAP_FOR_ROUTES[path]);
  }, [location]);

  return (
    <Route path={path} component={Component} />
  );
}

export default PublicRoute;