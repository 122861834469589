import Button from 'components/base/Button';
import TestimonialCarousel from 'components/TestimonialCarousel';
import * as S from './styles';
import { testimonialConnectItems } from 'constants/testimonialsItems';

type Props = {
  title?: string;
  description: string;
  subDescription?: string;
  subDescriptionRed?: string;
  logoUrl: string;
  onPressContinue: () => void;
  showTestimonial?: boolean;
};

const OnboardingPitch = ({
  title,
  description,
  logoUrl,
  onPressContinue,
  subDescription,
  subDescriptionRed,
  showTestimonial,
}: Props) => {
  return (
    <>
      <S.Content>
        {showTestimonial ? (
          <S.TestimonialContainer>
            <TestimonialCarousel testimonials={testimonialConnectItems} />
          </S.TestimonialContainer>
        ) : (
          <S.Logo src={logoUrl} />
        )}
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        <S.SubDescription>
          <S.SubDescriptionRed>
            {subDescriptionRed ? subDescriptionRed + ' ' : ''}
          </S.SubDescriptionRed>
          <S.SubDescriptionText>
            {subDescription}
          </S.SubDescriptionText>
        </S.SubDescription>
      </S.Content>
      <S.Footer>
        <Button onClick={onPressContinue}>CONTINUE</Button>
      </S.Footer>
    </>
  );
};

export default OnboardingPitch;
