import { useHistory} from 'react-router-dom';

import * as MyRoutes from 'constants/Routes';
import analytics from 'helpers/analytics-helper';

import { TestimonialType } from '../types';
import * as S from './styles';
import useUTMParams from 'common/hooks/useUTMParams';

type Props = {
  testimonial: TestimonialType
  onContinue?: () => void;
}

const Testimonial = ({testimonial, onContinue}: Props) => {
  const history = useHistory();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const handleContinue = () => {
    analytics.page(`Versioned OB testimonial ${testimonial.version}`);

    testimonial.isLastScreen
      ? utmMedium ? 
      history.push(`${MyRoutes.ONBOARDING_TRAITS}?${getAllParamsString()}`) : 
      history.push(MyRoutes.ONBOARDING_TRAITS)
      : onContinue();
  }

  const isVersion1 = testimonial.version === 1;

  return (
    <S.Container>
      <S.SmallGreyMoon />
      <S.HeaderImageTwo />
      <S.HeaderImageOne />
      <S.Content>
        <S.TestimonialContainer>
          <S.TestimonialLine>
            <S.TestimonalName>{testimonial.name}</S.TestimonalName>
            <S.TestimonalStarContainer>
              {testimonial.stars.map((_, idx) => (
                <S.TestimonialStar key={idx} />
              ))}
            </S.TestimonalStarContainer>
          </S.TestimonialLine>
          <S.TestimonialText>{testimonial.text}</S.TestimonialText>
        </S.TestimonialContainer>
      </S.Content>
      <S.Footer>
        <S.Button onClick={handleContinue}>
          CONTINUE
        </S.Button>
      </S.Footer>
      {isVersion1 ? <S.TestimonialBG /> : <S.TestimonialBG2 />}
    </S.Container>
  );
}

export default Testimonial;