import { createContext, useContext, useEffect, useState } from "react";

type DimensionsProps = {
  width: number;
  height: number;
}

type Props = {
  children: React.ReactNode;
};

const DimensionsContext = createContext({} as DimensionsProps);

export const DimensionsProvider = ({children}: Props) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    window.addEventListener('load', () => {
      const {innerWidth: windowWidth, innerHeight: windowHeight }= window;  
      setWidth(windowWidth);
      setHeight(windowHeight - 40);
    });
    
    window.addEventListener('resize', () => {
      const {innerWidth: windowWidth, innerHeight: windowHeight }= window;
      setWidth(windowWidth);
      setHeight(windowHeight);
    });
  }, []);

  return (
    <DimensionsContext.Provider
      value={{
        width,
        height
      }}>
      {children}
    </DimensionsContext.Provider>
  );
}

export const useDimensionsContext = () => useContext(DimensionsContext);
