import {createContext, useContext, useEffect, useState} from 'react';
import { useFirebaseAuthContext } from 'context/auth';
import { useMutation } from '@apollo/client';

import onBoardingMutations from 'graphql/onBoarding/onBoarding.mutations';
import { KEY_LOCAL_DESIRE_TYPES } from 'helpers/constants-helper';

export type DesireType = {
  id: number;
  name: string;
  type: string;
  groupName?: string;
};

export enum DesireTypeSteps {
  DesireFeel = 'DesireFeel',
  DesireMood = 'DesireMood',
  DesireFantasy = 'DesireFantasy',
  DesirePartner = 'DesirePartner',
  Connection = 'Connection',
  Novelty = 'Novelty',
  DesireTurns = 'DesireTurns',
  Initiative = 'Initiative',
  DesirePartnerMood = 'DesirePartnerMood',
  DesireGetInMood = 'DesireGetInMood',
  DesirePartnerGetInMood = 'DesirePartnerGetInMood',
}

type Props = {
  children: React.ReactNode;
};

type OnBoardingStep = Record<DesireTypeSteps, DesireType>;

type OnboardingDesireTypeContextProps = {
  steps: OnBoardingStep;
  handleUpdateStep: (key: DesireTypeSteps, value: DesireType) => void;
  setUserDesireType: (desireTypeStep: DesireTypeSteps, desireType: DesireType) => void;
};

const OnboardingDesireType = createContext(
  {} as OnboardingDesireTypeContextProps,
);

export const OnboardingDesireTypeProvider = ({children}: Props) => {
  const [steps, setSteps] = useState({} as OnBoardingStep);
  const [selectedDesireType, setDesireType] = useState<null | number>(null);
  const [localDesireTypes, setLocalDesireTypes] = useState<DesireType[]>([]);
  const [desireTypes, setDesireTypes] = useState<DesireType[]>([]);

  const { isAuthenticated } = useFirebaseAuthContext();

  useEffect(() => {
    const localDesireTypeFromStorage: DesireType[] = JSON.parse(localStorage.getItem(KEY_LOCAL_DESIRE_TYPES));

    if(localDesireTypeFromStorage) {
      setLocalDesireTypes(localDesireTypeFromStorage);
      setDesireTypes(localDesireTypeFromStorage);
      localDesireTypeFromStorage.map(desireType => handleUpdateStep(DesireTypeSteps[desireType.groupName], desireType));
    }
  }, []);

  const [setUserTraitsOnBoarding, { loading: settingDesireType }] = useMutation(
    onBoardingMutations.SET_USER_TRAITS_ON_BOARDING,
  );

  const storeUserDesireTypesLocally = (desireType: DesireType) => {
    const traitsIndex = localDesireTypes.findIndex(obj => obj?.groupName === desireType?.groupName);
    if (traitsIndex != -1) {
      localDesireTypes.splice(traitsIndex, 1);
    }

    const arr = [...localDesireTypes, desireType];
    setLocalDesireTypes(arr);
    localStorage.setItem(KEY_LOCAL_DESIRE_TYPES, JSON.stringify(arr));
  }

  const setUserDesireType = async (DesireTypeStep: DesireTypeSteps, desireType: DesireType) => {
    setDesireType(desireType.id);
    handleUpdateStep(DesireTypeStep, desireType);
    if (!isAuthenticated) {
      storeUserDesireTypesLocally({...desireType, groupName: DesireTypeStep});
    } else {
      await setUserTraitsOnBoarding({
        variables: {
          traitId: desireType.id,
          traitCategory: desireType.type,
        },
      });
      setDesireType(null);
    }

  };

  const handleUpdateStep = (key: DesireTypeSteps, value: DesireType) => {
    setSteps((prevStep) => ({
      ...prevStep,
      [key]: value,
    }));
  };

  return (
    <OnboardingDesireType.Provider value={{
      steps,
      handleUpdateStep,
      setUserDesireType
    }}>
      {children}
    </OnboardingDesireType.Provider>
  );
};

export const useOnboardingDesireTypeContext = () =>
  useContext(OnboardingDesireType);
