import * as S from './styles';
import { APP_STORES_URL_FOR_HOME } from 'helpers/constants-helper';
import analytics, { homePage } from 'helpers/analytics-helper';
import { useEffect } from 'react';

const Header = () => {
  
  const openLink = () => {
    window.fbq('track', 'Lead');
  };

  const buttonClick = (action: string) => {
    analytics.googleAnalyticsEventTracker(homePage);
    analytics.gaEvent(`${action} Clicked`);
  };

  useEffect(() => {
    analytics.initializeGoogleAnalytics();
  }, [])

  return (
      <S.LogoContainer>
        <S.ClickEvent onClick={() => buttonClick('Google Play')}>
          <S.StoreLink target="_blank" href={APP_STORES_URL_FOR_HOME} onClick={openLink}>
            <S.PlayStoreImg />
          </S.StoreLink>
        </S.ClickEvent>
        <S.ClickEvent onClick={() => buttonClick('Download on the app store')}>
        <S.StoreLink target="_blank" href={APP_STORES_URL_FOR_HOME} onClick={openLink}>
          <S.AppStoreImg />
        </S.StoreLink>
        </S.ClickEvent>
      </S.LogoContainer>
  );
};

export default Header;
