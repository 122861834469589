import * as S from './styles';

 const Play = (props) => {
  const { handleClick } = props;

  return (
      <S.PlayIconButton onClick={() => handleClick()}>
        <S.PlayIcon />
      </S.PlayIconButton>
  );
}

export default Play;