import { useEffect, useState } from 'react';

import {
  calculateValueWithDiscount,
  getMonthPriceCalculated,
} from 'helpers/purchase-helper';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

import * as S from './styles';
import { getStorageItem, KEY_PROMOTION_CODE } from 'helpers';
import { useParamsContext } from 'context/params';
import Button from 'components/base/Button';
export enum SubscriptionMode {
  Single = 'single',
  Connected = 'connected',
  SingleAndroidVariation = 'SingleAndroidVariation',
}

type Props = {
  mode: SubscriptionMode;
  onCompletePurchase: () => void;
  onSkip?: () => void;
  openedFrom?: 'onboarding' | 'upgrade';
};

type SubscriptionPackageType = {
  price: number;
  withDiscount?: number;
  currency: string;
};

const FreeTrialPrompt = ({
  mode,
  onCompletePurchase,
  onSkip,
  openedFrom,
}: Props) => {
  const [subscriptionPackage, setSubscriptionPackage] =
    useState<SubscriptionPackageType>({
      price: 0,
      currency: 'USD',
    });

  const { params } = useParamsContext();

  const propertiesToTrack = {
    context: openedFrom,
    price: 59.99,
    currency: 'USD'
  };

  const checklistTextBasic = [
    ' Limited access to content',
  ];

  const checklistTextPremium = [
    'Intimacy exercises',
    'Sensual meditations',
    'Conversation starters',
    'Private chat thread',
    'Sex tracker',
    'Community forums',
    'New content every week',
    'Cancel anytime',
  ];

  useEffect(() => {
    // TODO
    // Fetch subscriptiuon prices from Stripe

    const promotionCode = getStorageItem(KEY_PROMOTION_CODE);
    const withDiscount = calculateValueWithDiscount(
      59.99,
      promotionCode?.coupon?.percent_off ?? null,
      promotionCode?.coupon?.amount_off ?? null,
    );

    setSubscriptionPackage({
      price: 59.99,
      withDiscount,
      currency: '$',
    });

    analytics.page(ANALYTICS_EVENT_NAMES.TRIAL_START, propertiesToTrack);
  }, []);

  const handleSkip = () => {
    if (onSkip) {
      analytics.track(ANALYTICS_EVENT_NAMES.TRIAL_SKIPPED, propertiesToTrack);
      onSkip();
    }
  };

  const onPressSubscribe = () => {
    analytics.track(ANALYTICS_EVENT_NAMES.TRIAL_START_CLICKED, {
      ...propertiesToTrack,
      ...params,
    });
    onCompletePurchase();
  };

  return (
    <S.Container>
      <S.CoralLogo />
      <S.CloseButton onClick={handleSkip}>
        <S.CloseIcon />
      </S.CloseButton>
      <S.Heading>Try Coral Premium for 7 days</S.Heading>
      <S.SubHeading>
        <S.SubHeadingLeft />
        <S.SubHeadingCenter>
          Included in your basic membership
        </S.SubHeadingCenter>
        <S.SubHeadingRight />
      </S.SubHeading>

      <S.CheckBoXContainer>
        {checklistTextBasic.map((item) => (
          <S.TextContainer key={item}>
            <S.CheckIcon />
            {item}
          </S.TextContainer>
        ))}
      </S.CheckBoXContainer>

      <S.SubHeading>
        <S.SubHeadingLeft />
        <S.SubHeadingCenter>
          Your premium membership includes
        </S.SubHeadingCenter>
        <S.SubHeadingRight />
      </S.SubHeading>

      <S.CheckBoXContainer>
        {checklistTextPremium.map((item) => (
          <S.TextContainer key={item}>
            <S.CheckIconPremium />
            {item}
          </S.TextContainer>
        ))}
      </S.CheckBoXContainer>

      <S.SubHeadingFooter>
        <S.SubHeadingFooterCenter>
          7-DAY FREE TRIAL, THEN
        </S.SubHeadingFooterCenter>
        <S.SubHeadingFooterCenter>
          {
            getMonthPriceCalculated({
              productPrice:
                subscriptionPackage.withDiscount ?? subscriptionPackage.price,
            })
          }/MONTH, BILLED ANNUALLY
        </S.SubHeadingFooterCenter>
      </S.SubHeadingFooter>

      <S.ButtonContainer>
        <Button onClick={onPressSubscribe}>CONTINUE</Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default FreeTrialPrompt;
