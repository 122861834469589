
import React from 'react';
import './styles.css';

interface LoaderProps {
  color?: string
}

const Loader = ({color}: LoaderProps) => (
  <div className="line-spin-fade-loader">
    {Array.from(Array(8).keys()).map((index) => (
      <div key={index} style={{ backgroundColor: color ?? '#000' }}></div>
    ))}
  </div>
);

export default Loader;
