import * as S from './styles';
import PlayLogo from 'assets/images/NewHome/play.svg';
import LearnLogo from 'assets/images/NewHome/learn.svg';
import ConnectLogo from 'assets/images/NewHome/connect.svg';
import TalkLogo from 'assets/images/NewHome/talk.svg';
import Testimonial from './Testimonial';
import Community from './Community';
import Header from '../../../components/NewHome/Header';
import Footer from '../../../components/NewHome/Footer';
import Buttons from '../../../components/NewHome/Buttons';

const NewHome = () => {

  const Data = [
    {
      image: PlayLogo,
      title: 'Play',
      description: 'Enjoy guided exercises for solo and partnered connection'
    },
    {
      image: LearnLogo,
      title: 'Learn',
      description: 'Increase your confidence and reduce performance anxiety with science-based lessons'
    },
    {
      image: ConnectLogo,
      title: 'Connect',
      description: 'Daily conversation starters in a private chat with your partner'
    },
    {
      image: TalkLogo,
      title: 'Discuss',
      description: 'Ask experts and share stories with our vibrant community'
    }
  ];

  return (
    <>
      <Header />
      <S.Container>
        <S.TextHeaderContainer>
          <S.TextHeader>
            <S.CoralLogoContainer target="_self" href='#'>
            <S.CoralLogoHeader />
            </S.CoralLogoContainer>
            <S.Title>
              Your coach for connection
            </S.Title>
            <S.Description>
              We help couples & individuals deepen their connection with science backed methodology.
            </S.Description>
            <Buttons />
            <S.BottomDescription>
              “My wife and I have really learned and grown from the content, understanding each other’s needs better, as well as better communicating our needs and desires for each other.”
              <div>James, 38</div>
            </S.BottomDescription>
          </S.TextHeader>

          <S.ImageTopContainer>
            <S.ImageHeader />
          </S.ImageTopContainer>

        </S.TextHeaderContainer>
        <S.LeftBGImage />
        <S.FooterContainer>
          {Data?.map((data, index) => {
            return (
              <S.ListContainer key={index}>
                <S.FooterLogo src={data.image} />
                <S.FooterTitle>{data.title}</S.FooterTitle>
                <S.FooterDescription>{data.description}</S.FooterDescription>
              </S.ListContainer>
            )
          })}
        </S.FooterContainer>
        <S.RightLineBG />

        <Testimonial />
        <Community />

        <Footer />
      </S.Container>
    </>
  );
};

export default NewHome;
