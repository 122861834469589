import axios from 'axios';
import { PUBLIC_IP_FETCH_URL } from './constants-helper';
import { toast, ToastOptions } from 'react-toastify';
import { FirebaseError } from 'firebase/app';
import { getToastMessage } from './toast-message-helper';
import { ReactElement } from 'react';

const getPublicIpAddress = async () => {
  let ipAddress;
  try {
    const { data } = await axios.get(PUBLIC_IP_FETCH_URL);
    ipAddress = data?.ip;
  } catch (error) {
    ipAddress = 'NA';
  }
  return ipAddress;
};

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const convertObjectKeysToSnakeCase = (camelCaseObject: any) => {
  return (
    camelCaseObject &&
    Object.keys(camelCaseObject).reduce(
      (prev, curr) => ({
        ...prev,
        // @ts-ignore
        [camelToSnakeCase(curr)]: camelCaseObject[curr],
      }),
      {},
    )
  );
};

const showErrorToast = (
  title: string,
  message: string | ReactElement,
  config?: ToastOptions<{}> | undefined,
) => {
  const toastMessage = getToastMessage(title, message);

  toast.error(toastMessage, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    icon: false,
    closeButton: false,
    ...config,
  });
};

const showSuccessToast = (
  title: string | ReactElement,
  message: string | ReactElement,
  config?: ToastOptions<{}> | undefined,
) => {
  const toastMessage = getToastMessage(title, message);

  toast.success(toastMessage, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    icon: false,
    closeButton: false,
    ...config,
  });
};

const getExceptionMessage = (error: Error) => {
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case 'auth/wrong-password':
        return 'Please verify your password';
      case 'auth/user-not-found':
        return 'Could not find a user with that email';
      case 'auth/invalid-phone-number':
        return 'Your phone number was invalid. Please append country code (eg +1).';
      case 'auth/invalid-verification-code':
        return 'The code you entered was incorrect. Please try again.';
      case 'auth/missing-phone-number':
        return 'Looks like your phone number is missing!';
      case 'auth/quota-exceeded':
        return 'SMS quota exceeded';
      case 'auth/user-disabled':
        return 'Your account has been disabled';
      case 'auth/too-many-requests':
        return 'We have blocked all requests from this device due to unusual activity. Try again later.';
      default:
        return 'Something went wrong!';
    }
  }

  return 'Something went wrong!';
};

const parseStringToMultipleLines = (str: string) => {
  return str.split('\n');
};

const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const amountToPrice = (amount: number = 0) => {
  return Math.round(amount + Number.EPSILON) / 100;
};

const decodeHtmlCharCodes = (str: string) =>
  str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
    String.fromCharCode(charCode),
  );

export {
  getPublicIpAddress,
  convertObjectKeysToSnakeCase,
  showErrorToast,
  showSuccessToast,
  getExceptionMessage,
  parseStringToMultipleLines,
  isJson,
  amountToPrice,
  decodeHtmlCharCodes,
};
