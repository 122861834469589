import gql from 'graphql-tag';

const GET_STREAM_CHAT_TOKEN_QUERY = gql`
  query GetStreamChatToken($userId: String) {
    getStreamChatToken(userId: $userId)
  }
`;

const GET_USER_TRAITS = gql`
  query GetUserTraits {
    getUserTraitsForChat {
      traits
    }
  }
`;

const GET_USER_INFO = gql`
  query GetUserDataForChat {
    getUserDataForChat {
      id
      partnerId
      email
      username
      onboardingTraitsCompleted
      onboardingDesireTypeCompleted
    }
  }
`;

const GET_SENDBIRD_CHAT_CHANNEL_BY_USER_ID = gql`
  query GetSendbirdChatChannelByUserId {
    getSendbirdChatChannelByUserId {
      channelUrl
    }
  }
`;

const GET_CHAT_PLATFORM = gql`
  query GetChatPlatform {
    getChatPlatform {
      hasChatPlatform
      isPlatformSendBird
      isPlatformStreamChat
    }
  }
`;

export default {
  GET_STREAM_CHAT_TOKEN_QUERY,
  GET_USER_TRAITS,
  GET_USER_INFO,
  GET_SENDBIRD_CHAT_CHANNEL_BY_USER_ID,
  GET_CHAT_PLATFORM,
};
