import React from 'react';

import * as S from './styles';

type ParagraphProps = {
  type: 1 | 2 | 3 | 4 | 5 | 6;
  color?: string;
  children?: React.ReactNode;
  style?: object;
};

function Paragraph(props: ParagraphProps) {
  return <S.Paragraph {...props}>{props.children}</S.Paragraph>;
}

export default Paragraph;
