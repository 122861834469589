import Colors from 'assets/styles/Colors';
import styled from "styled-components";

const DEFAULT_NUMBER_OF_LINES = 4;
type Props = {
    showAllText?: boolean;
}

export const Container = styled.div``;

export const Text = styled.p`
  font-family: 'Inter';
  font-size: 15px;
  line-height: 20px;
  color: ${Colors.Black};
  ${({showAllText}: Props) => showAllText && `  
  display: -webkit-box;
  -webkit-line-clamp: ${DEFAULT_NUMBER_OF_LINES};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;`}
`;
