import { useEffect, useState } from 'react';
import * as S from './styles';
import CommunityCard from '../CommunityCard';
import { useQuery } from '@apollo/client';

import DiscussionsQuery from 'graphql/discussions/discussions.queries';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const CommunityCardContainer = () => {

  const {
    data: getNewDiscussionsPublic,
    loading: loadingNewDiscussionsPublic,
  } = useQuery(DiscussionsQuery.GET_NEW_DISCUSSIONS_PUBLIC);

  const newDiscussionsPublic = getNewDiscussionsPublic
    ? getNewDiscussionsPublic.getNewDiscussionsPublic
    : [];

  const createItems = () => {
    return newDiscussionsPublic.map((item, i) => (
      <div
        data-value={i + 1}
        className="item"
      >
        <CommunityCard data={item} />
      </div>
    ));
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [items, setAllItems] = useState();

  useEffect(() => {
    setAllItems(createItems())
  }, [newDiscussionsPublic])

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  return (
    <>
      {!loadingNewDiscussionsPublic &&
        (<>
          <S.PrevNextButton>
            <S.PrevButton onClick={slidePrev}>
              <S.LeftSideArrow />
            </S.PrevButton>
            <S.PrevButton onClick={slideNext}>
              <S.RightSideArrow />
            </S.PrevButton>
          </S.PrevNextButton>
          <S.CommunityCardContainer>

            <AliceCarousel
              mouseTracking
              disableDotsControls
              disableButtonsControls
              items={items}
              activeIndex={activeIndex}
              responsive={responsive}
              onSlideChanged={syncActiveIndex}
            />

          </S.CommunityCardContainer>
        </>)
      }
    </>
  );
};

export default CommunityCardContainer;
