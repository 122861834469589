import styled from 'styled-components';
import tw from 'twin.macro';
import Colors from 'assets/styles/Colors';
import CoralLogo from 'assets/images/NewHome/coral-logo.svg';
import waveBg1 from 'assets/images/DesireDiscrepency/top-green.svg';
import waveBg2 from 'assets/images/discussion-top-bg-cononut.svg';
import { FaSearch, FaWifi } from 'react-icons/fa';
import Input from 'components/base/NewInputSearch';

export const WaveBg1 = styled.img.attrs(() => ({
  src: waveBg1,
}))`
  position: absolute;
  width: 100%;
  top: -50px;

  @media (min-width: 501px) and (max-width: 1000px) {
    top: -10px;
  } 

  @media (max-width: 500px) {
    max-width: none;
    width: 650px;
    height: 199px;
    top: -21px;
    left: -26px;
  } 
`;

export const WaveBg2 = styled.img.attrs(() => ({
  src: waveBg2,
}))`
  position: absolute;
  max-width: none;
  width: 1667px;
  height: 674px;
  top: -265px;
  left: -339px;

  @media (min-width: 450px) {
    display: none;  
  } 
`;

export const TextHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.5px;
  color: ${Colors.Ash};
  flex-wrap: wrap;
  max-width: 83.4%;
  margin: auto;

  @media (max-width: 1150px) {
    max-width: 100%;
    margin: 5% 5% 0;
  } 
`;

export const TextHeader = styled.div`
  padding-bottom: 80px;
  padding-top: 4%;
  font-family: 'Andes';
  font-size: 66px;
  line-height: 1.5;
  width: 100%;

  @media (max-width: 1150px) {
    font-size: 44px;
    padding-bottom: 40px;
  } 

  @media (max-width: 767px) {
    font-size: 25px;
    padding-bottom: 20px;
  } 

  @media (max-width: 575px) {
    width: 100%;
  } 
`;

export const CoralLogoContainer = styled.a`
  position: relative;
`;

export const CoralLogoHeader = styled.img.attrs(() => ({
  src: CoralLogo,
}))`
  @media (max-width: 768px) {
    display: none;
  } 
`;

export const ScreenPathContainer = styled.div`
  display: flex;
  margin: 50px 0;
  letter-spacing: 1.53016px;

  @media (max-width: 560px) {
    margin: 75px 0 25px 0;
  } 
`;

export const ScreenPath = styled.a`
  font-family: 'Andes';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.BlackReal};
  text-decoration-line: underline;

  @media (max-width: 560px) {
    font-size: 10px;
  } 
`;

export const Slash = styled.div`
  margin: 0 10px;
  font-family: 'Andes';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.BlackReal};

  @media (max-width: 560px) {
    font-size: 10px;
  } 
`;

export const TextSearchBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 560px) {
    display: block;
  } 
`;

export const Title = styled.div`
  color: ${Colors.BlackTitle};
  margin: 0;
  font-family: "GTSuper";
  font-weight: 500;
  line-height: 1;
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  margin: auto 0;

  @media (max-width: 560px) {
    margin: 20px auto 15px auto;
  } 

  @media (min-width: 768px) and (max-width: 1150px) {
    margin-right: 4%;
  } 
`;

export const IconContainer = styled.div`
  width: 51px;
  height: 51px;
  border-radius: 51px;
  background: ${Colors.White};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
  display: flex;
  margin: auto;

  @media (max-width: 368px) {
    width: 45px;
    height: 45px;
  } 
`;

export const SearchIcon = styled(FaSearch)`
  width: 20px;
  height: 20px;
  color: ${Colors.Red};
  margin: auto;

  @media (max-width: 368px) {
    width: 15px;
    height: 15px;
  } 
`;

export const AuthInput = styled(Input)`
  ${tw`p-4`}
  height: 51px;
  font-family: 'Andes';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${Colors.Red};
  background: transparent;
`;

export const WifiIcon = styled(FaWifi)`
  width: 20px;
  height: 20px;
  color: ${Colors.Red};
  margin: auto;

  @media (max-width: 368px) {
    width: 15px;
    height: 15px;
  } 
`;