import styled from 'styled-components';
import tw from 'twin.macro';

import VektorSvg from 'assets/images/vector.svg';
import WellgoodSvg from 'assets/images/well-good.svg';
import TimeSvg from 'assets/images/angelTimes.svg';
import PeopleSvg from 'assets/images/people.svg';
import StarSvg from 'assets/images/star-rating.svg';

export const RatingLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ReviewsContainer = styled.div`
  font-family: 'Andes';
  letter-spacing: 0.2em;  
  margin-top: 2%;
  margin-bottom: 2%;
  ${tw`text-center text-xs uppercase font-medium z-10`}
`;

export const RatingContainer = styled.div`
  ${tw`flex flex-row ml-2 mr-2 justify-center`}
`;

export const Star = styled.img.attrs(() => ({
  src: StarSvg,
}))`
  width: 20px;
  height: 20px;
  margin: 3px;
`;

export const Images = styled.div`
  margin: 5% 0;
  text-align: center;
`;

export const Vector = styled.img.attrs(() => ({
  src: VektorSvg,
}))`
margin: 3% 10%;
display: unset;

@media screen and (max-width: 450px) {
  margin: 3% 2%;
}
`;

export const Wellgood = styled.img.attrs(() => ({
  src: WellgoodSvg,
}))`
margin: 3% 10%;
display: unset;

@media screen and (max-width: 450px) {
  margin: 3% 2%;
}
`;

export const Time = styled.img.attrs(() => ({
  src: TimeSvg,
}))`
margin: 3% 10%;
display: unset;

@media screen and (max-width: 450px) {
  margin: 3% 2%;
}
`;

export const People = styled.img.attrs(() => ({
  src: PeopleSvg,
}))`
margin: 3% 10%;
display: unset;

@media screen and (max-width: 450px) {
  margin: 3% 2%;
}
`;

