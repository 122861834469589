import {useEffect} from 'react';
import {Redirect} from 'react-router';
import {useMutation} from '@apollo/client';
import * as MyRoutes from 'constants/Routes';
import {FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE} from 'constants/feature-flags';
import useFlagsmith from 'common/hooks/useFlagsmith';

import referralMutations from 'graphql/referral/referral.mutations';
import {KEY_REFERRAL_ID} from 'helpers/constants-helper';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import {showErrorToast} from 'helpers/utility-helper'

const Home = () => {
  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  });

  const referralId = localStorage.getItem(KEY_REFERRAL_ID);

  const [setReferralId] = useMutation(
    referralMutations.ACCEPT_REFERRAL_LINK,
  );

  useEffect(() => {
    async function referralIdMutations() {
      try {
        const {data: referralIdData} = await setReferralId({
          variables: {
            referralLinkId: parseInt(referralId)
          }
        });

        analytics.track(ANALYTICS_EVENT_NAMES.REFERRAL_ACCEPTED, {
          Referred_User_ID: referralIdData.acceptReferralLink.userId
        });

        localStorage.removeItem(KEY_REFERRAL_ID);

      } catch (error) {
        showErrorToast('Referral link failed', 'Please try again.');
      }
    }

    if (referralId) {
      referralIdMutations();
    }
  }, []);

  return (
    <Redirect
      to={{
        pathname: MyRoutes.VIDEO
      }}
    />
  );

};

export default Home;
