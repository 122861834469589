import { v4 as uuidv4 } from 'uuid';
import { KEY_ACCESS_TOKEN, KEY_RANDOM_GUID } from './constants-helper';

// TODO: replace with a more performant option
const localStorage = window.localStorage;

export const getStorageItem = (key: string) => {
  return JSON.parse(localStorage.getItem(key));
};

export const removeStorageItem = (key: string) => {
  return localStorage.removeItem(key);
};

export const setStorageItem = (key: string, value: any) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getAccessToken = () => {
  return getStorageItem(KEY_ACCESS_TOKEN);
};

export const setAccessToken = (token: string) => {
  return setStorageItem(KEY_ACCESS_TOKEN, token);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const getRandomGUID = () => {
  let guid = getStorageItem(KEY_RANDOM_GUID);

  if(!guid) {
    guid = uuidv4();
    setStorageItem(KEY_RANDOM_GUID, guid);
  }

  return guid;
}

export const setRandomGUID = (guid: string) => {
  setStorageItem(KEY_RANDOM_GUID, guid);
}