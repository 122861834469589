import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import ProgressBar from 'components/ProgressBar';

import { useOnboardingDesireTypeContext } from 'context/onboarding-desire-type';

import { TOTAL_STEPS } from 'constants/onboarding';
import { desireTypeResult } from 'constants/desire-types-iar.mock';
import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import { parseStringToMultipleLines } from 'helpers/utility-helper';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnboardingDesireTypeResult = () => {
  const history = useHistory();
  const {steps} = useOnboardingDesireTypeContext();
  const { height } = useDimensionsContext();

  const description =
    desireTypeResult[
      `${steps.DesireGetInMood.name}+${steps.DesirePartnerGetInMood.name}`
    ];

  const onPressContinue = () => {
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_TRAUMA);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_IAR_GET_RESULT);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <ProgressBar progress={14} total={TOTAL_STEPS} />
        <S.DesireTypeResultImage />
        { parseStringToMultipleLines(description).map((descriptionLine, idx) => (
          <S.DescriptionLeft key={idx}>{descriptionLine}</S.DescriptionLeft>
        ))}
      </S.TopContent>
      <S.Footer>
        <S.Button onClick={onPressContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.BottomImage15 />
    </S.Container>
  );
};

export default OnboardingDesireTypeResult;
