import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useMutation } from '@apollo/client';
import onBoardingQueries from 'graphql/onBoarding/onBoarding.queries';
import onBoardingMutations from 'graphql/onBoarding/onBoarding.mutations';
import { TRAIT_TYPES, TraitSteps } from 'constants/traits';
import { Trait } from 'context/types';
import { KEY_LOCAL_DESIRE_TYPES, KEY_LOCAL_TRAITS } from 'helpers/constants-helper';

import { useFirebaseAuthContext } from 'context/auth';
import { useOnboardingContext } from 'context/onboarding';

type Props = {
  children: React.ReactNode;
};

type OnBoardingStep = Record<TraitSteps, Trait>;

type OnBoardingTraitsContextProps = {
  steps: OnBoardingStep;
  handleUpdateStep: (key: TraitSteps, value: Trait) => void;
  getTraitsForStep: (trait: TRAIT_TYPES) => Trait[];
  loadingTraits: boolean;
  setUserTrait: (traitStep: TraitSteps, trait: Trait) => void;
  LocalTraits: any;
  saveLocalTraits: () => Promise<void>;
  loadingSetTrait: boolean;
  selectedTrait: null | number;
  hasPartner: boolean;
  setOnboardingpartnerSelected: (value: boolean) => void;
  storeUserTraitsLocally: (trait: Trait) => void;
};

const OnBoardingTraits = createContext({} as OnBoardingTraitsContextProps);

export const OnboardingTraitsProvider = ({ children }: Props) => {
  const [steps, setSteps] = useState({} as OnBoardingStep);
  const [selectedTrait, setTrait] = useState<null | number>(null);
  const [traits, setTraits] = useState<Trait[]>([]);
  const [loadingTraits, setLoadingTraits] = useState(false);
  const [localTraits, setLocalTraits] = useState<Trait[]>([]);
  const [hasPartner, setHasPartner] = useState(false);

  const { setOnboardingTraitsCompleted, setOnboardingDesireTypeCompleted } = useOnboardingContext();
  const apolloClient = useApolloClient();
  const { isAuthenticated } = useFirebaseAuthContext();

  useEffect(() => {
    const localTraitsFromStorage: Trait[] = JSON.parse(localStorage.getItem(KEY_LOCAL_TRAITS));

    if(localTraitsFromStorage) {
      setLocalTraits(localTraitsFromStorage);
      setTraits(localTraitsFromStorage);
      localTraitsFromStorage.map(trait => handleUpdateStep(TraitSteps[trait.groupName], trait));
    }
  }, []);

  useEffect(() => {
    (async () => {
      const {
        data: { getTraitListForOnBoarding } = {},
        loading: loadingTraits,
      } = await apolloClient.query({
        query: onBoardingQueries.GET_TRAITS,
      });

      setLoadingTraits(loadingTraits);
      setTraits(getTraitListForOnBoarding ?? []);

      if (isAuthenticated) {
        await setOnboardingTraitsCompleted();
        await setOnboardingDesireTypeCompleted();
        await saveLocalTraits();
      }
    })();
  }, [isAuthenticated]);

  const setOnboardingpartnerSelected = async (value: boolean) => {
    setHasPartner(value)
  };

  const saveLocalTraits = async () => {
    try {
      const data = localStorage.getItem(KEY_LOCAL_TRAITS);
      const localtraits = JSON.parse(data);

      if(localtraits?.length > 0) {
        const traitIds = localtraits.map((item: Trait, index: number) => item.id);

        await setUserTraitsOnBoardingLocal({
          variables: { traitIds }
        });
        
        setLocalTraits(null);
      }
      localStorage.removeItem(KEY_LOCAL_TRAITS);
      localStorage.removeItem(KEY_LOCAL_DESIRE_TYPES);
    } catch(err) {
      console.log(err);
    }
  }

  const storeUserTraitsLocally = (trait: Trait) => {
    const traitsIndex = localTraits?.findIndex(obj => obj?.groupName === trait?.groupName);
    if (traitsIndex != -1) {
      localTraits.splice(traitsIndex, 1);
    }

    const arr = [...localTraits, trait];
    setLocalTraits(arr);
    localStorage.setItem(KEY_LOCAL_TRAITS, JSON.stringify(arr));
  }

  const setUserTrait = async (traitStep: TraitSteps, trait: Trait) => {

    setTrait(trait.id);
    handleUpdateStep(traitStep, trait);
    if (!isAuthenticated) {
      storeUserTraitsLocally(trait);
    } else {
      await setUserTraitsOnBoarding({
        variables: {
          traitId: trait.id,
          traitCategory: trait.groupName,
        },
      });
      setTrait(null);
    }
  };
  
  const getTraitsForStep = (type: TRAIT_TYPES) => {
    return traits?.filter((trait: Trait) => trait.groupName === type);
  }

  const [setUserTraitsOnBoarding, { loading: settingTrait }] = useMutation(
    onBoardingMutations.SET_USER_TRAITS_ON_BOARDING,
  );

  const [setUserTraitsOnBoardingLocal] = useMutation(
    onBoardingMutations.SET_USER_TRAITS_BULK
  );

  const handleUpdateStep = (key: TraitSteps, value: Trait) => {
    setSteps((prevStep) => ({
      ...prevStep,
      [key]: value,
    }));
  };

  return (
    <OnBoardingTraits.Provider
      value={{
        steps,
        handleUpdateStep,
        getTraitsForStep,
        loadingTraits,
        setUserTrait,
        selectedTrait,
        saveLocalTraits,
        loadingSetTrait: settingTrait,
        hasPartner,
        setOnboardingpartnerSelected,
        LocalTraits: localTraits,
        storeUserTraitsLocally
      }}>
      {children}
    </OnBoardingTraits.Provider>
  );
};

export const useOnboardingTraitsContext = () => useContext(OnBoardingTraits);
