import playSVG from 'assets/images/Audio/play.svg';
import pauseSVG from 'assets/images/Audio/pause.svg';
import styled from 'styled-components';
import Colors from 'assets/styles/Colors';

type Props = {
  curPercentage?: number;
}


export const PlayIcon = styled.img.attrs(() => ({
    src: playSVG,
  }))`
  font-size: 4em;
      color: white;`;

  export const pauseIcon = styled.img.attrs(() => ({
    src: pauseSVG,
  }))``;


export const Player = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 0;
margin: 40px 20px;
background-color: ${Colors.Roe};
border-radius: 40px;
`;

export const Controls = styled.div`
flex-grow: 1;
margin: 0 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const PlayIconButton = styled.div`
    width: fit-content;
    background-color: ${Colors.BlackReal};
    height:80px;
    width:80px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    border: none;
`;

export const BarProgress = styled.div`
flex: 1;
border-radius: 5px;
margin: 0 20px;
height: 5px;
display: flex;
align-items: center;
cursor: pointer;
`;

export const BarProgressKnob = styled.span`
position: relative;
height: 16px;
width: 16px;
border: 1.5px solid ${Colors.BlackReal};
border-radius: 50%;
background-color: ${Colors.BlackReal};
left: ${({curPercentage}: Props) => curPercentage - 2}%}
`;

export const Bar = styled.div`
user-select: none;
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
`;

export const BarTime = styled.span`
  font-size: 16px;
`;

export const BarTimeTotal = styled.span`
  font-size: 16px;
  float: right;
`;

export const BarTimeContainer = styled.div`
  width: 100%;
  padding: 1% 10%;
  color: ${Colors.BlackReal}
`;