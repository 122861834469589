import * as S from './styles';

type OnboardingProps = {
  data: any;
};

const CommunityCard = ({ data }: OnboardingProps) => {

  return (
    <>
        <S.ListContainer>
          <S.CardTitleContainer>
            <S.CardTitle>{data?.name}</S.CardTitle>
          </S.CardTitleContainer>
          <S.FooterLogo src={data.image} />
        </S.ListContainer>
    </>
  );
};

export default CommunityCard;
