import styled from 'styled-components';

import Heading from 'components/base/Heading';
import Bar from 'components/ProgressBar';
import OnBoardingAnswer from 'components/OnboardingAnswer';
import Colors from 'assets/styles/Colors';

export const Text = styled(Heading).attrs({
  type: 5,
})`
  color: ${Colors.Ash};
  margin-bottom: 16px;
  z-index: 10;
  font-weight: 500;
`;

export const SubTitle = styled(Heading).attrs({
  type: 5,
})`
  color: ${Colors.Ash};
  margin-bottom: 16px;
  z-index: 10;
  margin-top: -16px;
  font-weight: 500;
`;

export const Answer = styled(OnBoardingAnswer)``;

export const ProgressBar = styled(Bar)`
  margin-bottom: 0px;
`;

export const Title = styled(Heading).attrs({
  type: 1,
})``;
