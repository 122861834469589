import React, { useEffect } from 'react';
import { useHistory } from 'react-router';


import { useOnboardingDesireTypeContext } from 'context/onboarding-desire-type';
import { useCacheContext } from 'context/cache';

import { desireTypeResultSlideOne as desireTypeResultsPartnered } from 'constants/desire-types-partnered.mock';
import { desireTypeResultSlideOne as desireTypeResultsSingle } from 'constants/desire-types-single.mock';
import { desireTypeResultSlideNoUser as desireTypeResultswithoutUser } from 'constants/desire-types-partnered.mock';
import { TOTAL_STEPS } from 'constants/onboarding';

import { isInARelationship } from 'helpers/traits-helper';
import { parseStringToMultipleLines } from 'helpers/utility-helper';

import * as MyRoutes from 'constants/Routes';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnboardingDesireTypeResult = () => {
  const history = useHistory();
  const {steps} = useOnboardingDesireTypeContext();
  const {userTraits} = useCacheContext();
  const { height } = useDimensionsContext();

  const isUserInARelationship = isInARelationship(userTraits);
  const description = !steps.DesireTurns
    ? desireTypeResultswithoutUser
    : isUserInARelationship
      ? desireTypeResultsPartnered[steps.DesireTurns.name]
      : desireTypeResultsSingle[steps.DesireTurns.name];

  const onPressContinue = () => {
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_NEXT);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_RESULT);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={12} total={TOTAL_STEPS} />
        <S.DesireTypeResultImage style={{marginBottom: 35}} />
        { parseStringToMultipleLines(description).map((descriptionLine, idx) => (
          <S.DescriptionLeft key={idx}>{descriptionLine}</S.DescriptionLeft>
        ))}
      </S.TopContent>
      <S.Footer>
        <S.Button onClick={onPressContinue}>
        CONTINUE
        </S.Button>
      </S.Footer>
      <S.BottomImage26 />
    </S.Container>
  );
};

export default OnboardingDesireTypeResult;
