import {useEffect} from 'react';
import {useHistory} from 'react-router';
import OnBoardingOption from 'components/OnboardingOption';
import OnboardingAnswersList from 'components/OnboardingAswersList';
import * as MyRoutes from 'constants/Routes';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import {useDimensionsContext} from 'context/dimensions';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import {TOTAL_STEPS} from 'constants/onboarding';

const goalOptions = [
  'Enjoy sex again',
  'Feel more connected to my partner',
  'Improve my sex life',
  'Feel my body respond differently during sex',
  'Be less anxious about or during sex'
]

const OnboardingGoal = () => {
  const history = useHistory();
  const {height} = useDimensionsContext();

  const handleSelectGender = async (item: string) => {
    history.push(MyRoutes.ONBOARDING_PITCH_THREE);
    analytics.track(ANALYTICS_EVENT_NAMES.ONBOARDING_GOAL_SELECT_OPTION, {goal: item})
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_LANDING);
  }, []);

  return (
    <S.Container height={height}>
      <S.BottomImage1 />
      <S.BottomImage2 />
      <OnboardingAnswersList 
      totalSteps={TOTAL_STEPS}
      progress={1} title='Welcome!' 
      subTitle='What’s your intimacy goal?' />
      <S.LeftHeading>I want to...</S.LeftHeading>
      <S.OptionList>
        {goalOptions?.map((item) => (
          <OnBoardingOption
            key={item}
            width={178}
            height={160}
            text={item}
            color={Colors.PaleCoral}
            onClick={() => handleSelectGender(item)}
          />
        ))}
      </S.OptionList>
    </S.Container>
  );
}

export default OnboardingGoal;
