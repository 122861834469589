export const DESIRE_TYPES = {
  DESIRE_FEEL: 'desire-feel',
  DESIRE_MOOD: 'desire-mood',
  FANTASY: 'fantasy',
  DESIRE_PARTNER: 'desire-partner',
  CONNECTION: 'connection',
  NOVELTY: 'novelty',
  DESIRE_TURNS: 'desire-turns',
  INITIATIVE: 'initiative',
};

export const DESIRE_TYPES_IAR = {
  INITIATIVE: 'initiative',
  DESIRE_MOOD: 'desire-mood',
  DESIRE_PARTNER_MOOD: 'desire-partner-mood',
  DESIRE_GET_IN_MOOD: 'desire-get-in-mood',
  DESIRE_PARTNER_GET_IN_MOOD: 'desire-partner-get-in-mood',
};

export const TOTAL_STEPS = 20;

export const QUIZ = {
  INCREASE_MY_SEX_DRIVE: 'INCREASE_MY_SEX_DRIVE',
  TRY_NEW_THINGS_IN_BED: 'TRY_NEW_THINGS_IN_BED',
  IMPROVE_MY_PERFORMANCE: 'IMPROVE_MY_PERFORMANCE',
  RECONNECT_WITH_MY_PARTNER: 'RECONNECT_WITH_MY_PARTNER',
}