import React from "react";
import styled from "styled-components";
import tw from 'twin.macro';

type InputProps = React.HTMLAttributes<HTMLDivElement> & {
  error?: boolean
}

export const Container = styled.div<InputProps>`
  ${tw`w-full mt-2 mb-2 bg-white border rounded-lg outline-none`}
  ${({ error }) => error 
    ? tw`border-red-500 text-red-500`
    : tw`border-transparent`
  }
`;

export const InputContainer = styled.div`
  ${tw`flex flex-row`}
`;

export const Label = styled.label`
  ${tw`flex items-center pl-4 font-medium whitespace-nowrap text-gray-400`}
`;

export const Input = styled.input`
  ${tw`w-full mt-0.5 p-4 outline-none`}

  ::-webkit-input-placeholder {
    ${tw`text-center text-gray-400`}
  }

  :-moz-placeholder { /* Firefox 18- */
    ${tw`text-center text-gray-400`}
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    ${tw`text-center text-gray-400`}
  }

  :-ms-input-placeholder {  
    ${tw`text-center text-gray-400`}
  }
`;

export const ErrorMessage = styled.div`
  ${tw`font-bold text-xs text-gray-400 px-4 pb-2`}
  margin-top: -8px;
`;
