import {useHistory} from 'react-router';

import * as S from './styles';
import Subscription from 'screens/Subscription';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import * as MyRoutes from 'constants/Routes';
import {useMutation, useQuery} from '@apollo/client';
import onBoardingQueries from 'graphql/onBoarding/onBoarding.queries';
import TopicsMutations from 'graphql/topics/topics.qgl.mutations';

const personalizedItems = [
  'Enjoy Sex Again',
  'Feel More Connected',
  'Feel Good During Sex',
  'Improve Your Sex Life! ',
];

const CombinedFreeTrial = () => {
  const history = useHistory();
  const { data } = useQuery(
    onBoardingQueries.GET_ONBOARDING_JOURNEY_RECOMMENDATIONS,
  );

  const [addUserTopics] = useMutation(TopicsMutations.ADD_TOPICS);

  if (data) {
    const userGoalsToAdd = data.onBoardingJourneyRecommendations.map(
      (goal: { id: number }) => goal.id,
    );
    addUserTopics({ variables: { topics: userGoalsToAdd } });
  }

  const handleSkip = () => {
    const propertiesToTrack = {
      context: "onboarding",
      price: 59.99,
      currency: 'USD'
    };
    analytics.track(ANALYTICS_EVENT_NAMES.TRIAL_SKIPPED, propertiesToTrack);
    history.push(MyRoutes.CONFIRMATION);
  };

  return (
    <S.FreeTrialContainer>
      <S.CloseButton onClick={handleSkip}>
        <S.CloseIcon />
      </S.CloseButton>
      <S.FreeTrialTopView>
        <S.FreeTrialTopBackground />
        <S.FreeTrialCoralTextLogo />
        <S.AppOfTheDayLogo />
        <S.PersonalizedTitle>Your personalized plan</S.PersonalizedTitle>
        {personalizedItems.map((item) => (
          <S.PersonalizedRow>
            <S.PersonalizedCheck />
            <S.PersonalizedItemText>{item}</S.PersonalizedItemText>
          </S.PersonalizedRow>
        ))}
        <S.PersonalizedJourneysTitle>
          Your personalized recommended journeys
        </S.PersonalizedJourneysTitle>
        <S.PersonalizedJourneysContainer>
          {data?.onBoardingJourneyRecommendations.map((item) => (
            <S.PersonalizedJourneyItem key={item.id}>
              <S.YourJourneyItemDot />
              <S.PersonalizedJourneyItemText>
                {item.name}
              </S.PersonalizedJourneyItemText>
            </S.PersonalizedJourneyItem>
          ))}
        </S.PersonalizedJourneysContainer>
        <S.JoinOverTitle>Join Over 1 MILLION Happy Customers</S.JoinOverTitle>
        <S.FreeTrialReviewContainer>
          <S.ReviewCountText>3.5K Reviews</S.ReviewCountText>
          <S.Star />
          <S.Star />
          <S.Star />
          <S.Star />
          <S.Star />
          <S.ReviewCountStarText>(4.6)</S.ReviewCountStarText>
        </S.FreeTrialReviewContainer>
        <S.FreeTrialChartContainer>
          <S.FreeTrialChartContent>
            Based on your answers and goals, you can improve your intimacy in 4
            weeks!
          </S.FreeTrialChartContent>
          <S.FreeTrialChart />
          <S.FreeTrialChartValueRow>
            <S.FreeTrialChartValue>WK 1</S.FreeTrialChartValue>
            <S.FreeTrialChartValue>WK 2</S.FreeTrialChartValue>
            <S.FreeTrialChartValue>WK 3</S.FreeTrialChartValue>
            <S.FreeTrialChartValue>WK 4</S.FreeTrialChartValue>
          </S.FreeTrialChartValueRow>
        </S.FreeTrialChartContainer>
      </S.FreeTrialTopView>
      <Subscription />
    </S.FreeTrialContainer>
  );
};

export default CombinedFreeTrial;
