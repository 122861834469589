import React, { useEffect } from 'react';
import { useHistory} from 'react-router';

import * as MyRoutes from 'constants/Routes';
import { TOTAL_STEPS } from 'constants/onboarding';

import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const OnboardingDesireTypeIARIntro = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const onPressContinue = () => {
    utmMedium ? 
    history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS}?${getAllParamsString()}`) : 
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.DESIRE_TYPE);
  }, []);

  return (
    <S.Container height={height}>
      <S.ProgressBar progress={3} total={TOTAL_STEPS} />
      <S.TopContent>
        <S.Heading type={2}>This</S.Heading>
        <S.Heading type={2}>little-known insight is key to better sex. </S.Heading>
        <S.TextSpaced type={5}>
          Your desire type is affecting your sexual dynamic.
        </S.TextSpaced>
        <S.DesireTypeIARIntroImage style={{marginTop: 17, zIndex: 0, flex: 1}} />
      </S.TopContent>
    
      <S.Footer>
        <S.Button onClick={onPressContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.BottomImage8 />
      <S.FooterRightImage />
    </S.Container>
  );
};

export default OnboardingDesireTypeIARIntro;
