import React, { useEffect, useState } from 'react';

import * as S from './styles';

type AutoCapitalizeProps = 'none' | 'sentenses' | 'words' | 'characters';

interface InputProps {
  autoCapitalize?: AutoCapitalizeProps;
  label?: string;
  placeholder?: string;
  value: string;
  error?: boolean;
  errorMessage?: string;
  type?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  style?: object;
  textInputStyle?: object;
};

const Input = ({
  autoCapitalize = 'none',
  label,
  placeholder,
  value,
  error = false,
  errorMessage = '',
  type='text',
  onChange,
  onBlur,
  disabled,
  style,
  textInputStyle,
  ...props
}: InputProps) => {
  const [focus, setFocus] = useState(false);
  const [labelVisible, setLabelVisible] = useState(false);

  const handleFocus = () => setFocus(true);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocus(false);
    onBlur && onBlur(e);
  };

  useEffect(() => {
    setLabelVisible(!!label && (focus || !!value));
  }, [label, focus, value]);

  return (
    <>
      <S.Container
        style={style}
        error={error}
      >
        <S.InputContainer>
          {labelVisible && <S.Label>{label}</S.Label>}
          <S.Input
            autoCapitalize={autoCapitalize}
            value={value}
            placeholder={!labelVisible && placeholder}
            type={type}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            {...props}
            style={textInputStyle}
          />
        </S.InputContainer>
        {error && errorMessage && (
          <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        )}
      </S.Container>
    </>
  )
}

export default Input;