import * as React from 'react';

type LoginBottomBackgroundType = {
  fill?: string;
};

function LoginBottomBackground({
  fill = '#F2B3AD',
}: LoginBottomBackgroundType) {
  return (
    <div style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 405,
      overflow: 'hidden'
    }}>
      <svg
        width={1397}
        height={405}
        viewBox="0 0 1397 405"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
        }}
      >
        <path
          d="M132.133 88.797C38.697 88.797 5.783 139.92 0 252.264V405h1396.51c2.08-102.876 2.85-309.008-115.67-257.506-148.15 64.376-85.86-28.402-141.47-65.64-52.38-35.07-69.37 31.727-129.47 42.919C933.5 139 970.5 250.634 897.345 250.634c-117.751 0-131.243-83.311-171.728-164.097C685.132 5.75 614.839-48.528 542.322 63.816c-72.517 112.343-102.325 4.418-152.153 44.18C340.342 147.758 328 225 268.269 202.036 201.488 176.361 212.5 88.797 132.133 88.797z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default LoginBottomBackground;
