import Colors from 'assets/styles/Colors';
import styled from 'styled-components';
import tw from 'twin.macro';

type BarProps = {
  progress: number;
  total: number;
};

export const Container = styled.div`
  background-color: ${Colors.TransparentWhite};
  ${tw`rounded-full z-10 mb-3`}
`;

export const Bar = styled.div<BarProps>`
  width: ${({progress, total}) => `${(progress / total) * 100}%`};
  background-color: ${Colors.White};
  ${tw`h-4 rounded-full`}
`;
