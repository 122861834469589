import gql from 'graphql-tag';

const CREATE_USER = gql`
  mutation CreateUser($newUser: UserInput!) {
    createUser(newUser: $newUser) {
      id
      email
      emailVerified
      adminType
      cohort
      phone
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($message: String) {
    deleteAccount(message: $message) {
      message
      isAccountRemoved
    }
  }
`;

export { CREATE_USER , DELETE_ACCOUNT};
