import React, {
  useContext,
  useState,
  useEffect,
  createContext,
} from 'react';
import {useQuery} from '@apollo/client';

import DiscussionsQuery from 'graphql/discussions/discussions.queries';

interface Unit {
  id: number;
  name: string;
  description: string;
  comments: number;
  type: string;
  courseId?: number;
}

interface Course {
  id:number;
  name: string;
  type: string;
  discussions: Unit[];
}

interface DiscussionContextProps {
  fetchDiscussions: () => Promise<void>;
  courses: Course[];
  getCourse: (courseId: number) => Course;
  newDiscussions: Unit[];
  discussions: Unit[];
  isLoading: boolean;
  getUnit: (unitId: number) => Unit;
  getRelatedUnits: (courseId: number) => Unit[];
}

const DiscussionContext = createContext({} as DiscussionContextProps);

export const DiscussionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [discussions, setDiscussions] = useState<Unit[]>([]);
  const [newDiscussions, setNewDiscussions] = useState<any[]>([]);

  const {
    data: getNewDiscussionsPublic,
    loading: loadingNewDiscussionsPublic,
    refetch: refetchNewDiscussionsPublic,
  } = useQuery(DiscussionsQuery.GET_NEW_DISCUSSIONS_PUBLIC);
  
  useEffect(() => {
    // setNewDiscussionsPublic(getNewDiscussionsPublic?.getNewDiscussionsPublic || []);
  }, [getNewDiscussionsPublic])

  const {
    data: getDiscussionsPublic,
    loading: loadingDiscussionsPublic,
    refetch: refetchDiscussionsPublic,
  } = useQuery(DiscussionsQuery.GET_DISCUSSIONS_PUBLIC);

    useEffect(() => {
      // get all discussions
      const discussionsResult = getDiscussionsPublic?.getDiscussionsPublic.map((course) => {
        return course.courseDiscussions.traitsUnits.map((unit: Unit) => ({...unit, courseId: course.course.id})) as Unit[];
      }).flat()

      // get all courses
      const coursesResult = getDiscussionsPublic?.getDiscussionsPublic.map((course) => ({
          ...course.course,
          discussions: discussionsResult.filter(discussion => (
            discussion.courseId === course.course.id
          ))
        })) ?? []

      setDiscussions(discussionsResult);
      setCourses(coursesResult);
    }, [getDiscussionsPublic])
  
  const fetchDiscussions = async () => {
    await refetchDiscussionsPublic();
    await refetchNewDiscussionsPublic();
  }

  const getRelatedUnits = (courseId?: number) => (
    courseId ? discussions?.filter((discussion) => discussion.courseId === courseId) : []
  )

  const getUnit = (unitId: Number): Unit => (
    discussions?.find(discussion => discussion.id === unitId)
  );

  const getCourse = (courseId: number) => (
    courses?.find(course => course.id === courseId)
  )

  return (
    <DiscussionContext.Provider
      value={{
        fetchDiscussions,
        courses,
        getCourse,
        newDiscussions,
        discussions,
        isLoading: loadingNewDiscussionsPublic || loadingDiscussionsPublic,
        getUnit,
        getRelatedUnits
      }}
    >
      {children}
    </DiscussionContext.Provider>
  );
};

export const useDiscussionContext = () => useContext(DiscussionContext);
