import styled from 'styled-components';
import tw from 'twin.macro';

import Title from 'components/base/Heading';

import Colors from 'assets/styles/Colors';
import LoginBottomBackground from 'assets/images/login-bottom-background';
import Paragraph from 'components/base/Paragraph';

import { ASSETS_PATH } from 'constants/assets';

import hexRgb from 'hex-rgb';
import Button from 'components/base/Button';
import CoralWhite from 'assets/images/coral-logo-white.svg'
import CoralBlack from 'assets/images/coral-logo-black.png'

export const Container = styled.div`
  background-color: #f3f3f3;
  background-image: url(https://assets.website-files.com/5f35af7bc28f73089ed6239d/6203fa2df8a2e8196b31d75a_1.png), url(https://assets.website-files.com/5f35af7bc28f73089ed6239d/6203f428e3a2738b701ea7b2_Subtract%201.png);
  background-position: 75% 100%, 100% 100%;
  background-size: 50%, 90%;
  background-repeat: no-repeat, no-repeat;
  ${tw`flex flex-1 flex-col overflow-y-auto`}

`;

export const Head = styled.div`
  ${tw`bg-white`}
`;

export const HeadContent = styled.div`
  ${tw`flex justify-between max-w-7xl p-7 bg-white m-auto`}
`;

export const HeadLeftContent = styled.div``;
export const HeadRightContent = styled.div`
  a {
    font-family: 'Andes';
    ${tw`text-4xl font-semibold`}
  }
`;

export const Logo = styled.img.attrs(() => ({
  src: CoralBlack,
}))`
  width: 96px;
`;

export const LogoWhite = styled.img.attrs(() => ({
  src: CoralWhite,
}))`
  width: 96px;
`;

export const Content = styled.div`
  ${tw`flex flex-1 flex-col max-w-7xl m-auto my-10 px-8 w-full`}

  @media screen and (max-width: 768px) {
    ${tw`m-0 p-0`}
  }
`;

export const BigScreen = styled.div`
  @media screen and (max-width: 768px) {
    ${tw`hidden`}
  }
`;

export const SmallScreen = styled.div`
  @media screen and (min-width: 768px) {
    ${tw`hidden`}
  }
`;

export const Form = styled.div`
  ${tw`flex m-auto mt-10 max-w-7xl w-full`}

  @media screen and (max-width: 768px) {
    ${tw`flex-col-reverse m-0 p-5`}
  }
`;

export const LeftContent = styled.div`
  ${tw`flex flex-1 flex-col pr-10`}

  @media screen and (max-width: 768px) {
    ${tw`pr-0`}
  }
`;

export const RightContent = styled.div`
  width: 40%;

  @media screen and (max-width: 768px) {
    ${tw`w-full mb-5`}    
  }

`;

export const Heading = styled(Title).attrs(() => ({
  type: 2,
}))`
  ${tw`mb-4 text-left z-10`}
  max-width: 75%;
  font-family: 'GTSuper';
  letter-spacing: -2px;
  color: #355470
`;

export const SubHeading = styled(Paragraph).attrs(() => ({
  type: 2,
}))`
  ${tw`my-4 text-left z-10`}
  max-width: 90%;
  font-family: 'Andes';
  font-weight: 500;
`;

export const SmallText = styled.div`
  font-family: 'Andes';
  ${tw`text-xs py-6 leading-6`}

  a {
    color: ${Colors.Red}
  }
`;

export const SubscriptionMethods = styled.div`
  ${tw`z-10 p-8`}
  /* padding: 2rem 1rem; */
  background-color: #fff;
  /* border-radius: 2rem; */
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

export const HeaderBackgroundImage = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/moon-big-yellow.png`,
}))`
  position: absolute;
  width: 571px;
  height: 593px;
  top: -450px;
  right: -224px;
  transform: rotate(45deg);
  object-fit: cover;
`;

export const BottomBackgroundImage = styled(LoginBottomBackground)``;

export const BigGreyMoon = styled.div`
  position: absolute;
  width: 227.05px;
  height: 227.05px;
  left: 0;
  bottom: 50px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-big-grey.png`});
  background-size: cover;
  background-position-x: -140px;
  background-repeat: no-repeat;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  width: 124px;
  height: 124px;
  right: -60px;
  top: 55px;
  object-fit: cover;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Card = styled.div`
  font-family: 'Andes';
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
  ${tw`bg-white border rounded text-gray-700`}

  &+& {
    ${tw`mt-5`}
  }
`;

export const CardHead = styled.div`
  ${tw`px-5 py-1 border-b border-b-gray-200`}
`;

export const CardTitle = styled.div`
  ${tw`text-lg my-2.5 font-medium`}
`;

export const PlanTitle = styled.div`
  ${tw`text-2xl my-2.5`}
`;

export const PlanPrice = styled.div`
  ${tw`text-lg font-medium whitespace-nowrap`}
`;

export const PlanDescription = styled.div`
  ${tw`text-base py-5`}
`;

export const CardBody = styled.div`
  ${tw`p-5`}
`;

export const CardRow = styled.div`
  ${tw`flex flex-1 justify-between items-center`}

  &+& {
    ${tw`mt-2`}
  }
`;

export const CardRowGold = styled.div`
  background-color: ${hexRgb(Colors.Skin02, {format: 'css', alpha: 0.5})};
  ${tw`flex flex-1 justify-between items-center font-semibold px-3.5 py-1`}

  &+& {
    ${tw`mt-2`}
  }
`;

export const CardRowTop = styled.div`
  ${tw`flex flex-1 justify-between items-baseline`}

  &+& {
    ${tw`mt-2`}
  }
`;

export const Separator = styled.hr`
  ${tw`my-7 border-t border-t-black`}
`;

export const CardRowSmall = styled.div`
  ${tw`mt-10`}

  &+& {
    ${tw`mt-5`}
  }
`;

export const CardSmallText = styled.div`
  ${tw`text-sm`}
`;

export const CardRowInput = styled.div``;

export const CardInputLabel = styled.div`
  ${tw`text-sm mb-2 font-medium`}
`;

export const CardInputField = styled.input.attrs({
  readOnly: true
})`
  ${tw`border border-gray-300 rounded px-3 py-2 w-full outline-none text-sm bg-gray-50`}
`;

export const CardRowLabel = styled.div`
  ${tw`flex flex-1 mr-4`}
`;

export const CardRowValue = styled.div``;

export const CardRowValueBold = styled.div`
  ${tw`font-bold`}
`;

export const CardBox = styled.div`
  ${tw`p-5 w-full bg-white border border-gray-200 text-gray-700`}
`;

export const SubmitButton = styled(Button).attrs({
  color: Colors.DeepBlue,
  style: {
    borderRadius: '4px',
    fontSize: '14px',
    padding: '20px'
  }
})`
`;

export const Footer = styled.div`
  background-color: ${Colors.DeepBlue};
  ${tw``}
`;

export const FooterContent = styled.div`
  ${tw`flex justify-between max-w-7xl p-7 m-auto`}
`;

export const FooterLeftContent = styled.div``;
export const FooterRightContent = styled.div`
  a {
    font-family: 'Andes';
    ${tw`text-4xl font-semibold`}
  }
`;
