import React from 'react';
import { LinkProps } from 'react-router-dom';

import * as S from './styles';

const Link = ({children, ...props}: LinkProps) => {
  return (
    <S.Link {...props}>
      {children}
    </S.Link>
  );
}

export default Link;
