import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import { ASSETS_PATH } from 'constants/assets';
// import {
//   onboardingBottom,
//   pitchHeaderSecondBackground,
//   smallBlueMoon,
//   tanCurveOne,
// } from '@@assets';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${Colors.Coconut};
`;

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  width: 1584px;
  height: 207px;
  left: -535px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1584px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.div`
  position: absolute;
  top: 0;
  width: 1218px;
  height: 194px;
  left: 68px;
  background-image: url(${`${ASSETS_PATH}/pitch-header-second-background.png`});
  background-size: 1218px 194px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageMoon = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/moon-small-blue.png`,
}))`
  position: absolute;
  height: 96px;
  width: 96px;
  left: -57px;
  top: -32px;
`;

export const OrangeCircle = styled.div`
  position: absolute;
  height: 266px;
  width: 266px;
  right: -56px;
  bottom: -100px;
  border-radius: 266px;
  background-color: ${Colors.Skin03};
`;

export const BottomCenter = styled.div`
  position: absolute;
  width: 100%;
  height: 190px;
  z-index: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-bottom.png`});
  background-size: 780px 400px;
  background-position: top;
`;
