import styled from 'styled-components';
import tw from 'twin.macro';
import Colors from 'assets/styles/Colors';
import CustomButton from 'components/base/Button';
import CustomHeading from 'components/base/Heading';
import CoralLogo from 'assets/images/NewHome/coral-logo.svg';
import RightBGLine from 'assets/images/NewHome/rightBG-line.svg';
import { ASSETS_PATH } from 'constants/assets';
import RightBGImg from 'assets/images/NewHome/homeBG-right.png';
import LeftBGImg from 'assets/images/NewHome/homeBG-left.png';
import RightMobileImg from 'assets/images/NewHome/homeRight-mobile.png';

export const Container = styled.div`
  background-color: ${Colors.Coconut};
  width: 100%;
  padding-top: 4%;

  @media (min-width: 768px) {
    background-image: url(${`${RightBGImg}`});
    background-position: 100% 0, 0 46vw;
    background-repeat: no-repeat;
    background-size: 68%;
    transition: background 0.3s;
  }

  @media (min-width: 1300px) {
    background-image: url(${`${RightBGImg}`});
    background-position: 100% 0, 0 46vw;
    background-repeat: no-repeat;
    background-size: 60%;
    transition: background 0.3s;
  }

  @media (min-width: 2500px) {
    background-image: url(${`${RightBGImg}`});
    background-position: 100% 0, 0 46vw;
    background-repeat: no-repeat;
    background-size: 48%;
    transition: background 0.3s;
  }
`;

export const RightLineBG = styled.img.attrs(() => ({
  src: RightBGLine,
}))
`
  position: absolute;
  right: 0;
  top: 55vw;
  z-index: 1;

  @media (max-width: 1100px) {
    width: 2vw;
    top: 90vw;
  } 

  @media (max-width: 575px) {
    width: 3vw;
    top: 55vw;
  } 
`;

export const CoralLogoContainer = styled.a``;

export const CoralLogoHeader = styled.img.attrs(() => ({
  src: CoralLogo,
}))
`
  @media (max-width: 767px) {
    display: none;
  } 
`;

export const LeftBGImage = styled.img.attrs(() => ({
  src: LeftBGImg,
}))`
  position: absolute;
  left: 0;
  top: 19%;

  @media (min-width: 1200px) and (max-width: 1700px) {
    top:21%;
  } 

  @media (max-width: 1200px) {
    top:35%;
  } 

  @media (max-width: 768px) {
    display: none;
  }
`;


export const TextHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.5px;
  color: ${Colors.Ash};
  flex-wrap: wrap;
  max-width: 83.4%;
  margin: auto;

  @media (max-width: 1150px) {
    max-width: 100%;
    margin: 5%;
  } 
`;

export const TextHeader = styled.div`
width: 50%;
padding-bottom: 80px;
font-family: 'Andes';
font-size: 66px;
line-height: 1.5;

@media (max-width: 1150px) {
  font-size: 44px;
  padding-bottom: 40px;
} 

@media (max-width: 575px) {
  padding-bottom: 0px;
  width: 100%;
  padding-bottom: 30px;
} 
`;

export const ImageTopContainer = styled.div`
  width: 50%;

  @media (max-width: 575px) {
    width: 100%;
  } 
`;

export const Title = styled.div`
  color: ${Colors.BlackTitle};
  margin: 0;
  margin-top: 50px;
  font-family: "GTSuper";
  font-weight: 500;
  line-height: 1;
  width: 70%;

  @media (max-width: 1150px) {
    width: 50%;
    margin-top: 30px;
  } 
`;

export const Header = styled.div`
  width: 100%;
`;

export const ImageHeader = styled.img.attrs(() => ({
  src: RightMobileImg,
}))`
  margin: auto;
  width: 50%;

  @media (max-width: 1300px) {
    width: 70%;
  } 
`;

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  left: -150px;
  width: 1584px;
  height: 207px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1500px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/collage-butt.png`,
}))`
  z-index: 0;
  position: absolute;
`;

export const HeaderImageThree = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/moon-small-blue.png`,
}))`
  z-index: 0;
  position: absolute;
  right: -51px;
  height: 96px;
  width: 96px;
  top: -32px;
`;

export const OnboardingBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 190px;
  z-index: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-bottom.png`});
  background-size: 780px 400px;
  background-position: top;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/coral-logo.png`,
}))`
  height: 65px;
  z-index: 10;
`;

export const Description = styled(CustomHeading).attrs(() => ({
  type: 4,
}))`
  margin-top: 34px;
  margin-bottom: 34px;
  width: 50%;
  font-family: 'Andes';
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: ${Colors.Black};

  @media (max-width: 1150px) {
    width: 100%;
  } 
`;

export const Footer = styled.div`
  ${tw`mb-5`}
  padding: 0 16px;
  z-index: 10;
`;

export const LogInButton = styled(CustomButton).attrs(() => ({
  textColor: Colors.Black,
}))`
  margin-top: 8px;
  background-color: ${Colors.Transparent};
`;

export const BottomDescription = styled.div`
  font-family: 'Andes';
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: ${Colors.Black};
  max-width: 450px;
  margin-top: 5%;

  @media (max-width: 1150px) {
    max-width: 100%;
  } 
`;

export const FooterContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2% 10% 5%;

  @media (max-width: 575px) {
    margin: 0 2%;
    margin-bottom: 5%;
  } 
`;

export const ListContainer = styled.div`
    width: 25%;

    @media (max-width: 1200px) {
      flex: 0 0 45%;
      margin: 0 2%;
    } 

    @media (max-width: 768px) {
      flex: 0 0 90%;
      margin: 2%;
      width: 150px;
    } 
`;

export const FooterLogo = styled.img.attrs(() => ({
  resizeMode: 'contain',
}))`
  margin: auto;
`;

export const FooterTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  font-family: 'GTSuper';
  letter-spacing: -1px;
  color: ${Colors.BlackTitle_02};
`;


export const FooterDescription = styled.div`
  text-align: center;
  margin-top: 5px;
  font-family: 'Andes';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${Colors.Black};
`;