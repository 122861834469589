import * as React from 'react';

function ConnectOBLayer1() {
  return (
    <div style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    }}>
      <svg
        fill="none"
        viewBox="0 0 375 812"
        width="100vw"
      >
        <path
          d="M1329.61 621.473c198.26 2.892 268.12 117.139 280.39 374.556V1346h-4185.99c-1.39-73.96 257.7-495.034 459.76-590.034 132.23-62.174 227.33 90.526 388.95 37.439 166.97-54.843 126.91-188.76 275.67-218.37 188.48-37.515 205.81 141.724 343.64 180.931 323.266 91.953 182.205-65.078 300.214-150.401 111.141-80.358 181.376 61.958 274.723 98.339 74.704 29.114 175.75-18.115 238.849-238.617 120.841-422.28 278.5-190.894 364.41-376.002 85.91-185.11 235.073-309.479 388.956-52.062s217.136 10.123 322.871 101.232c105.736 91.108 27.774 347.745 258.677 215.477 224.69-128.709 90.63 264.649 288.88 267.541z"
          fill="#eee8e5"
        />
      </svg>
    </div>
  );
}

export default ConnectOBLayer1;
