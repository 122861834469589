import gql from 'graphql-tag';

const USER_COMMUNITY_EVENTS = gql`
  query UserCommunityEvents {
    userCommunityEvents {
      id
      name
      read
      fromUser {
        username
      }
      comment {
        id
        text
        unitId
        createdAt
        likesTotal
        user {
          username
        }
      }
    }
  }
`;

const GET_UNITS_COMPLETED = gql`
  query UnitsCompleted {
    unitsCompleted {
      id
      name
      type
    }
  }
`;

const GET_USER_PROPS_FOR_ANALYTICS = gql`
  query getUserAnalytics {
    UserAnalyticsData {
      id
      registrationDate
      # platform
      logInMethod
      email
      phone
      name
      emailVerified
      connectedToPartner
      admin
      unitsCompleted
      username
      weeklyCohort
      subscriptionType
      subscriptionEndDate
      subscriptionWillRenew
      lastSeen
      relationshipStatus
      ageRange
      gender
      interestedIn
      shyness
      subscriptionFromPartner
      partnerId
      # appVersion
      chatMessagesSent
      chatPlatform
      cancellationDate
      purchaseRenewalDate
      journeys
      trialStartDate
      country
      city
      state
      communication {
        email {
          pulse
          news
          activity
        }
        push {
          pulse
          news
          activity
        }
        sms {
          pulse
          news
          activity
        }
      }
    }
  }
`;

const USER_LOGGED_IN = gql`
  query userLoggedIn {
    userLoggedIn {
      isUserLoggedInSuccessfully
    }
  }
`;

const GET_USER_DATA_BY_USERNAME_QUERY = gql`
  query getUsersDataByUsername($username: String!) {
    getUsersDataByUsername(username: $username) {
      id
      username
      name
    }
  }
`;

const USER_CALL_TO_ACTION_QUERY = gql`
  query userCallToActionQuery {
    userCallToAction {
      isSubscribed
      hasPartner
      invitationPending
      isIAR
      hasChatMessages
      hasDiscussionActivity
      hasBookmarks
      hasReferralItinerary
      isReferralItinerarySent
    }
  }
`;

export default {
  USER_COMMUNITY_EVENTS,
  GET_UNITS_COMPLETED,
  GET_USER_PROPS_FOR_ANALYTICS,
  USER_LOGGED_IN,
  GET_USER_DATA_BY_USERNAME_QUERY,
  USER_CALL_TO_ACTION_QUERY,
};
