// import {FEATURE_FLAG_VARIANT_TYPE} from '@@constants/feature-flags';

export const shareCodeText = (
  invitationCode?: string,
  shareLink?: string,
) => {
  let shareText = 'Click here to connect with me on Coral:';
  let yourCode = 'Your invitation code is:' + ' ' + invitationCode;
  let linkText = shareLink;

  // switch (variant) {
  //   case FEATURE_FLAG_VARIANT_TYPE.VARIANT_A: {
  //     shareText =
  //       'We should try this! <3 Click here to join Coral, it’s a free, private space where we can make more time to connect. Click this link to join:';
  //     yourCode = 'Invitation code:' + ' ' + invitationCode;
  //     break;
  //   }
  //   case FEATURE_FLAG_VARIANT_TYPE.VARIANT_B: {
  //     shareText =
  //       'This app will help us get and stay connected. Click here to join Coral, it’s a private space that’s just for our intimate life:';
  //     yourCode = 'Invitation code: ' + ' ' + invitationCode;
  //     break;
  //   }
  //   case FEATURE_FLAG_VARIANT_TYPE.VARIANT_C: {
      shareText =
        'Do you want to try this with me? It’s free, private and can help us have a great intimate life.';
      linkText = 'Click here to join:' + ' ' + shareLink;
      yourCode = 'Invitation code: ' + ' ' + invitationCode;
  //     break;
  //   }
  //   default:
  //     break;
  // }
  return shareText + '\n' + linkText + '\n' + yourCode;
};
