import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background-color: ${Colors.Coconut};
  width: 100%;
`;

export const HeaderContainer = styled.div`
  @media (max-width: 767px) {
    z-index: 1;
  }
`;

export const CardContainer = styled.div`
  max-width: 90.4%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    max-width: 100%;
  } 
`;

export const RelatedCategoriesContainer = styled.div`
  margin: auto;
  margin: 8%;

  @media (max-width: 767px) {
    margin: 5%;
  } 
`;

export const RelatedTitle = styled.div`
  font-family: 'GTSuper';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`;

export const RelatedCardContainer = styled.div``;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const CommentsLayerOverFlow = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const OverFlowComments = styled.div`
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  display: flex;
  height: 600px;
  border-color: #4cd964;
  position: absolute;
  background: linear-gradient(0deg, #F7F4F3 30.36%, rgba(217, 217, 217, 0) 87.98%);
  z-index: 99`

export const SeeMoreLayer = styled.div`
  flex: 1;
  padding: 16px;
  margin-top: 30px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
`;

export const ExpertButton = styled(Link)`
  border-radius: 5px;
  padding: 21px;
  width: auto;
  background-color: ${Colors.Red};
  color: ${Colors.White};
  font-weight: bold;
  font-family: 'Andes';
  letter-spacing: 0.1em;
  
  @media (min-width: 768px) {
    padding: 15px 50px;
  }; 

  @media (max-width: 768px) {
    width: 100%;
  }; 
`;