import gql from 'graphql-tag';

const ADD_TOPICS = gql`
  mutation AddTopics($topics: [Int!]!) {
    addUserTopics(topicIds: $topics) {
      message
    }
  }
`;

const REMOVE_USER_TOPICS = gql`
  mutation RemoveUserTopic($topicId: Int!) {
    removeUserTopic(topicId: $topicId) {
      message
    }
  }
`;

const ADD_ONE_USER_TOPIC = gql`
  mutation AddOneUserTopic($topicId: Int!) {
    addOneUserTopic(topicId: $topicId) {
      message
    }
  }
`;

export default {ADD_TOPICS, REMOVE_USER_TOPICS, ADD_ONE_USER_TOPIC};
