import * as S from './styles';

const Pause = (props) => {
  const { handleClick } = props;

  return (
    <S.PlayIconButton onClick={() => handleClick()}>
      <S.pauseIcon />
    </S.PlayIconButton>
  );
}

export default Pause;
