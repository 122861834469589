import gql from 'graphql-tag';

export const GET_DAILY_POLL = gql`
  query getDailyPoll {
    getDailyPoll {
      id
      title
      total
      data {
        answers {
          title
          total
          percentageTotal
        }
      }
      myAnswer {
        choiceInt
      }
    }
  }
`;

export const GET_USER_DAY = gql`
  query GetUserDaysWithCoral {
    getUserDaysWithCoral {
      day
    }
  }
`;

const GET_DISCUSSIONS = gql`
  query GetDiscussions {
    getDiscussions {
      id
      name
      description
      published
      comments
      isNew
      type
    }
  }
`;

const GET_MY_DISCUSSIONS = gql`
  query GetMyDiscussions {
    getMyDiscussions {
      id
      name
      description
      comments
      isNew
      type
    }
  }
`;

const GET_NEW_DISCUSSIONS = gql`
  query GetNewDiscussions {
    getNewDiscussions {
      id
      name
      description
      comments
      isNew
      type
    }
  }
`;

const GET_NEW_DISCUSSIONS_PUBLIC = gql`
  query GetNewDiscussionsPublic {
    getNewDiscussionsPublic {
      courseId
      id
      name
      description
      comments
      isNew
      type
    }
  }
`;

const GET_FAQ_COURSES = gql`
  query GetFAQCourses {
    getFAQCourses {
      course {
        id
        name
        type
      }
      courseDiscussions {
        traitsUnits {
          id
          name
          type
          comments
          description
        }
        nonTraitsUnits {
          id
          name
          type
          comments
          description
        }
      }
    }
  }
`;

const GET_DISCUSSIONS_PUBLIC = gql`
  query GetDiscussionsPublic($unitsLimit: Int) {
    getDiscussionsPublic(unitsLimit: $unitsLimit) {
      course {
        id
        name
        type
      }
      courseDiscussions {
        traitsUnits {
          id
          name
          type
          comments
          description
        }
        nonTraitsUnits {
          id
          name
          type
          comments
          description
        }
      }
    }
  }
`;

const GET_PAGINATED_DISCUSSION_COMMENTS_BY_UNIT_ID = gql`
  query GetPaginatedCommentsByUnitId(
    $unitId: Int!
    $offset: Int!
    $limit: Int!
  ) {
    getPaginatedCommentsByUnitId(
      unitId: $unitId
      offset: $offset
      limit: $limit
    ) {
      totalCount
      comments {
        id
        createdAt
        flagsTotal
        liked
        likesTotal
        published
        repliesTotal
        text
        unitId
        user {
          id
          username
          forumTitle
        }
        replies {
          id
          liked
          createdAt
          text
          user {
            id
            username
            forumTitle
          }
        }
      }
    }
  }
`;

const GET_REPLIES_BY_COMMENT_ID = gql`
  query GetRepliesByCommentId($commentId: Int!) {
    getRepliesByCommentId(commentId: $commentId) {
      id
      parentId
      createdAt
      flagsTotal
      liked
      likesTotal
      published
      repliesTotal
      text
      unitId
      user {
        id
        username
      }
    }
  }
`;

const GET_BOOKMARKED_UNITS_IDS = gql`
  query GetBookmarkedUnitsIds {
    getBookmarksUnitsIds
  }
`;

const GET_UNIT_NAME_AND_DESCRIPTION = gql`
  query GetUnitNameAndDescription($unitId: Int!) {
    unit(id: $unitId) {
      name
      description
    }
  }
`;

const IS_COMMENT_FIRST = gql`
  query IsCommentFirst {
    isCommentFirst
  }
`;

const GET_PAGINATED_DISCUSSION_COMMENTS_BY_UNIT_ID_PUBLIC = gql`
  query getPaginatedCommentsByUnitIdPublic(
    $unitId: Int!
    $offset: Int!
    $limit: Int!
  ) {
    getPaginatedCommentsByUnitIdPublic(
      unitId: $unitId
      offset: $offset
      limit: $limit
    ) {
      totalCount
      unitMeta {
        name
        description
      }
      comments {
        id
        createdAt
        flagsTotal
        liked
        likesTotal
        published
        repliesTotal
        text
        unitId
        user {
          id
          username
          forumTitle
        }
      }
    }
  }
`;

export default {
  GET_DISCUSSIONS,
  GET_MY_DISCUSSIONS,
  GET_NEW_DISCUSSIONS,
  GET_NEW_DISCUSSIONS_PUBLIC,
  GET_FAQ_COURSES,
  GET_DISCUSSIONS_PUBLIC,
  GET_PAGINATED_DISCUSSION_COMMENTS_BY_UNIT_ID,
  GET_REPLIES_BY_COMMENT_ID,
  GET_BOOKMARKED_UNITS_IDS,
  GET_UNIT_NAME_AND_DESCRIPTION,
  IS_COMMENT_FIRST,
  GET_USER_DAY,
  GET_DAILY_POLL,
  GET_PAGINATED_DISCUSSION_COMMENTS_BY_UNIT_ID_PUBLIC,
};
