import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { useUserContext } from 'context/user';
import onBoardingMutations from 'graphql/onBoarding/onBoarding.mutations';

import { setStorageItem } from 'helpers/storage-helper';
import {
  KEY_ONBOARDING_TRAITS_COMPLETED,
  KEY_ONBOARDING_DESIRE_TYPE_COMPLETED,
} from 'helpers/constants-helper';


type OnboardingContextProps = {
  isOnboardingTraitsCompleted: boolean;
  isOnboardingDesireTypeTrauma: boolean;
  isOnboardingDesireTypeCompleted: boolean;
  isOnboardingTraitsCompletedAsyncStorage: boolean;
  isOnboardingDesireTypeCompletedAsyncStorage: boolean;
  setOnboardingTraitsCompleted: () => void;
  setOnboardingDesireTypeCompleted: () => void;
  setDesireTypeTrumaOn: (value: boolean) => void;
};

const OnboardingContext = React.createContext({} as OnboardingContextProps);

export const OnboardingProvider = ({ children }: { children: React.ReactNode }) => {
  const { userData } = useUserContext();

  const [isTraitsCompleted, setTraitsCompleted] = useState(false);
  const [isDesireTypeTrama, setDesireTypeTrama] = useState(false);
  const [isDesireTypeCompleted, setDesireTypeCompleted] = useState(false);

  const [
    isTraitsCompletedAsyncStorage,
    setTraitsCompletedAsyncStorage,
  ] = useState(false);
  const [
    isDesireTypeCompletedAsyncStorage,
    setDesireTypeCompletedAsyncStorage,
  ] = useState(false);

  const [setOnboardingTraitsCompletedMutation] = useMutation(
    onBoardingMutations.SET_TRAITS_ONBOARDING_COMPLETE,
  );

  const [setOnboardingDesireTypeCompletedMutation] = useMutation(
    onBoardingMutations.SET_DESIRE_TYPE_ONBOARDING_COMPLETE,
  );

  const setOnboardingTraitsCompleted = async () => {
    try {
    setTraitsCompleted(true);

    setTraitsCompletedAsyncStorage(true);
    setStorageItem(KEY_ONBOARDING_TRAITS_COMPLETED, JSON.stringify(true));
    setOnboardingTraitsCompletedMutation();
    } catch (error) {
    }
  };

  const setOnboardingDesireTypeCompleted = async () => {
    try {
      setDesireTypeCompleted(true);

      setDesireTypeCompletedAsyncStorage(true);
      setStorageItem(
        KEY_ONBOARDING_DESIRE_TYPE_COMPLETED,
        JSON.stringify(true)
      );
  
      setOnboardingDesireTypeCompletedMutation();
    } catch (error) {
    }

  };

  const setDesireTypeTrumaOn = (value: boolean) => {
    setDesireTypeTrama(value)
  }
  useEffect(() => {
    if (userData) {
      setTraitsCompleted(Boolean(userData.onboardingTraitsCompleted));
      setDesireTypeCompleted(Boolean(userData.onboardingDesireTypeCompleted));

    }
  }, [userData]);

  return (
    <OnboardingContext.Provider
      value={{
        isOnboardingTraitsCompleted: isTraitsCompleted,
        isOnboardingDesireTypeTrauma: isDesireTypeTrama,
        isOnboardingDesireTypeCompleted: isDesireTypeCompleted,
        isOnboardingTraitsCompletedAsyncStorage: isTraitsCompletedAsyncStorage,
        isOnboardingDesireTypeCompletedAsyncStorage: isDesireTypeCompletedAsyncStorage,
        setOnboardingTraitsCompleted,
        setOnboardingDesireTypeCompleted,
        setDesireTypeTrumaOn,
      }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
