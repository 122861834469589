type Subscription = {
  term: string;
  type: string;
  startedAt: string;
  expiresAt: string;
  renewsAt: string;
  subscriptionMethod: string;
  willRenew: boolean;
};

type Coupon = {
  id: string;
  amount_off?: number;
  created: number;
  livemode: boolean;
  name: string;
  percent_off?: number;
  valid: boolean;
}
type PromotionCode = {
  id: string;
  active?: boolean;
  code?: string;
  coupon?: Coupon; 
  expires_at?: number;
  livemode: boolean;
  times_redeemed: number;
}

enum SubscriptionMethod {
  STRIPE = 'stripe',
  BETA = 'beta',
  PROMOTIONAL = 'promotional',
  APP_STORE = 'app_store',
  PLAY_STORE = 'play_store',
}

enum SubscriptionType {
  STRIPE = 'stripe',
  BETA = 'beta',
  PARTNER = 'partner',
}

export class PaymentError extends Error {}

export type {Subscription, PromotionCode};
export {SubscriptionMethod, SubscriptionType};
