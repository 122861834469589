import React, { useEffect, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type ScrollToTopProps = RouteComponentProps & {
  children: React.ReactNode;
};

function ScrollToTop({ history, children }: ScrollToTopProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      console.log('scroll to top');
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
