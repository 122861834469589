import { useEffect} from 'react';

import { TOTAL_STEPS } from 'constants/onboarding';
import { useHistory, useLocation } from 'react-router';
import * as MyRoutes from 'constants/Routes'
import { useCacheContext } from 'context/cache';
import { isInARelationship } from 'helpers/traits-helper';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const ORGANIC_SOURCES = [
  'Friends/family',
  'Partner',
  'Social media',
  'Blog post / news article',
  'Healthcare professional',
  'Google search',
  "Can't remember",
  'App/Play store',
];

const IAR_ID = 13;

const AskOrganicSource = () => {
  const history = useHistory();
  const location = useLocation();
  const { userTraits } = useCacheContext();
  const { height } = useDimensionsContext();
  const { steps } = useOnboardingTraitsContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER,
  });
  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;

  const isUserInARelationship = isNewOBOrder
    ? steps?.RelashionshipStatus?.id === IAR_ID
    : isInARelationship(userTraits);

  function shuffle(array: string[]) {
    return array.sort(() => Math.random() - 0.5);
  }

  const sourcesArrayToRender = shuffle(ORGANIC_SOURCES);

  const handleContinue = (sourceIndex?: number) => {
    analytics.track(ANALYTICS_EVENT_NAMES.USER_ORGANIC_SOURCE_SUBMITTED, {
      answer: sourcesArrayToRender[sourceIndex],
    });

    history.push(
      isUserInARelationship
        ? utmMedium 
        ? `${MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INTRO}?${getAllParamsString()}`
        : MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INTRO
        : utmMedium
        ? `${MyRoutes.ONBOARDING_DESIRE_TYPE_INTRO}?${getAllParamsString()}`
        : MyRoutes.ONBOARDING_DESIRE_TYPE_INTRO,
    );
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_ASK_ORGANIC_SOURCE);
  }, []);

  return (
    <S.Container height={height}>
        <S.ProgressBar progress={9} total={TOTAL_STEPS} />
        <S.SkipButton onClick={() => handleContinue()}>
          SKIP
        </S.SkipButton>
        <S.Content>
          <S.Heading type={3}>How did you hear about Coral?</S.Heading>
          <S.CompactContent>
            {sourcesArrayToRender.map((item, idx) => {
              return (
                <S.SourceButton key={idx} onClick={() => handleContinue(idx)}>
                  {item}
                </S.SourceButton>
              );
            })}
          </S.CompactContent>
        </S.Content>
      <S.HeaderMoon />
      <S.BottomImage6 />
      <S.BottomImage7 />
      <S.SmallGreyMoon />
    </S.Container>
  );
};

export default AskOrganicSource;
