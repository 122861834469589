
import { useUnitReadingContext } from 'context/unit-reading';
import { useHistory } from 'react-router-dom';
import { Interweave } from 'interweave';

import * as MyRoutes from "constants/Routes";

import * as S from './styles';
import { showSuccessToast } from 'helpers/utility-helper';
import {getUnit_unit_slides_attachments} from 'graphql/units/types/getUnit';
import ReactPlayer from 'react-player';
import AudioComponent from "components/base/AudioPlayer/audioComponent";

const Slide = () => {
  const history = useHistory();
  const {
    unit,
    currentSlide,
    incrementSlideIndex,
    isFirstSlide,
    isLastSlide,
  } = useUnitReadingContext();
  const currentURL = window && window.location.href;

  let slideAttachments: getUnit_unit_slides_attachments[],
    initialText: string
  if (unit?.slides.length > 0) {
    slideAttachments = unit?.slides[currentSlide?.position]?.attachments;
    initialText = unit?.slides[currentSlide?.position]?.text;
  }

  const getSlideContent = () => {
    if(!currentSlide) {
      return <></>;
    }
    const attachamentsTexts = 
      currentSlide.attachments.filter(attachment => {
        return !attachment.root
      }).map((attachment:any, id:number) => {
        if (attachment.type === 'video') {
          return (
              <S.VideoPlayerCard key={id}>
                <ReactPlayer
                  playing
                  volume={1}
                  url={attachment.data.videoUrl}
                  width="100%"
                  height="250px"
                  controls={true}
                  playsinline
                  config={{
                    file: {
                      attributes: {
                        autoPlay: true,
                        muted: true
                      }
                    }
                  }}
                />
              </S.VideoPlayerCard>
          )
        } else if(attachment.type === 'audio') {
          return (
            <AudioComponent url={attachment.data.audioUrl} />
          )
        } else {
          return (
            <>
              <S.UnitAttachmentDividerContainer>
                <S.UnitAttachmentDivider />
              </S.UnitAttachmentDividerContainer>
              <Interweave content={"attachment.title"} />
            </>
            )
        }
  });

    return (
      <S.UnitSlide>
        <Interweave content={currentSlide?.text || ''} /> 
        {attachamentsTexts.map(attachment => attachment)}
      </S.UnitSlide>
    );
  }

  const handleComplete = () => {
    history.push(MyRoutes.ONBOARDING_LANDING)
  }

  const handleContinue = () => {
    incrementSlideIndex();
  }

  if(!unit) {
    return (
      <S.SpinnerContainer>
        <S.Spinner />
      </S.SpinnerContainer>
    );
  }

  const shareButtonClick = (data) => {
    if (window && window.innerWidth <= 760) {
      navigator.share(data);
    } else {
      navigator.clipboard.writeText(currentURL);
      showSuccessToast('Success', 'Copied to clipboard');
    }
  }

  return (
    <S.UnitLayer>
      {isFirstSlide() && (
        <>
          <S.UnitTime>{unit?.data?.time ?? 1} min read</S.UnitTime>
          <S.UnitTitle>{unit.name}</S.UnitTitle>
          <S.UnitText>{unit.description}</S.UnitText>
          <S.UnitAttachmentDividerContainer>
            <S.UnitAttachmentDivider />
          </S.UnitAttachmentDividerContainer>
        </>
      )}
      {getSlideContent()}
      {
        isLastSlide()
          ? (
            <>
              <S.ExpertButton onClick={handleComplete}>COMPLETE</S.ExpertButton>
              <S.UnitShare>
                <S.ButtonSection onClick={() => shareButtonClick(unit)}>
                  <S.ShareIcon />
                </S.ButtonSection>
                <S.CommentSection>
                  {unit.comments.length}
                  <S.ButtonSection onClick={() => handleComplete()}>
                    <S.CommentIcon />
                  </S.ButtonSection>
                </S.CommentSection>
                <S.CommentSection>
                  {unit.likes}
                  <S.ButtonSection onClick={() => handleComplete()}>
                    <S.HeartIcon />
                  </S.ButtonSection>
                </S.CommentSection>
              </S.UnitShare>
            </>
            )
          : <S.ExpertButton onClick={handleContinue}>CONTINUE</S.ExpertButton>
      }
      
      <S.Separator/>
    </S.UnitLayer>
  );
};

export default Slide;