import styled from 'styled-components';
import Colors from 'assets/styles/Colors';

export const BannerText = styled.div` 
  background: ${Colors.Red};;
  z-index: 11;
  position: absolute;
  width: 100%;
  padding: 1rem 2rem;
  text-align: center;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
`;

export const BannerTitle = styled.div`
  color: white;
  font-weight: 600;
`;

export const bannerText = styled.span`
  font-weight: 400;
  margin-left: 3px;
`;

export const GoalsText = styled.span`
  color: #179E35;
  text-decoration: underline;
  margin-left: 3px;
`;