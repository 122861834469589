import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import {useApolloClient, useQuery} from '@apollo/client';

import PartnersQueries from 'graphql/partner/partner.gql.queries';

type PartnerStateProps = {
  partnerId: string | null;
  isSubscribed?: boolean | null;
  invitationId?: string | null;
  accepted?: boolean | null;
  name?: string | null;
  username: string | null;
  freeViaPartner: boolean | null;
};

type PartnerContextProps = {
  partnerData?: PartnerStateProps;
  loadingPartnerContext: boolean;
  hasPartnerConnected: boolean;
  verifyIfHasPartnerConnected: () => void;
  setHasPartnerConnected: (value: boolean) => void;
  setPartnerData: (data: PartnerStateProps) => void;
  updatePartnerData: (data: PartnerStateProps) => void;
  refetchPartnerData: () => Promise<void>;
  updateAndGetPartnerId: () => Promise<number>;
  updateAndGetPartnerData: () => Promise<PartnerStateProps>;
  resetPartnerData: () => void;
};

const partnerInitialState: PartnerStateProps = {
  partnerId: null,
  name: null,
  invitationId: null,
  username: null,
  accepted: null,
  freeViaPartner: null,
  isSubscribed: false,
};

const PartnerContext = createContext({} as PartnerContextProps);

export const PartnerProvider = ({children}: {children: React.ReactNode}) => {
  const partnerProviderValues = useRef({
    partnerData: partnerInitialState,
    hasPartnerConnected: false,
  });
  const [partnerData, setPartnerData] = useState(
    partnerInitialState as PartnerStateProps,
  );
  const [hasPartnerConnected, setHasPartnerConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const apolloClient = useApolloClient();

  const updatePartnerData = (data: PartnerStateProps) => {
    const hasConnected =
      Boolean(data) && Boolean(data.accepted) && Boolean(data.partnerId);

    const partnerInfo = {
      ...data,
      isSubscribed: Boolean(data) && Boolean(data.freeViaPartner),
    };

    if (
      JSON.stringify(partnerInfo) !==
      JSON.stringify(partnerProviderValues.current.partnerData)
    ) {
      partnerProviderValues.current.partnerData = partnerInfo;
      setPartnerData(partnerInfo);
    }

    if (hasConnected !== partnerProviderValues.current.hasPartnerConnected) {
      partnerProviderValues.current.hasPartnerConnected = hasConnected;
      setHasPartnerConnected(hasConnected);
    }
  };

  const resetPartnerData = () => {
    setHasPartnerConnected(false);
    setPartnerData(partnerInitialState);
  };

  const queryPartnerConnected = async () => {
    try {
      const {
        data: {getPartner},
      } = await apolloClient.query({
        query: PartnersQueries.GET_PARTNER,
        fetchPolicy: 'no-cache',
      });

      updatePartnerData(getPartner);
    } catch (error) {
      // TODO capture error
    }
  };

  const updateAndGetPartnerId = async () => {
    const {
      data: {getPartner},
    } = await apolloClient.query({
      query: PartnersQueries.GET_PARTNER,
      fetchPolicy: 'no-cache',
    });

    updatePartnerData(getPartner);
    return getPartner.partnerId;
  };

  const updateAndGetPartnerData = async () => {
    const {
      data: {getPartner},
    } = await apolloClient.query({
      query: PartnersQueries.GET_PARTNER,
      fetchPolicy: 'no-cache',
    });

    updatePartnerData(getPartner);
    return getPartner;
  };

  const verifyIfHasPartnerConnected = async () => {
    setLoading(true);
    await queryPartnerConnected();
    setLoading(false);
  };

  const {data: getPartner} = useQuery(PartnersQueries.GET_PARTNER, {
    fetchPolicy: 'no-cache',
    pollInterval: 3000,
  });

  useEffect(() => {
    updatePartnerData(getPartner?.getPartner);
    setLoading(false);
  }, [getPartner?.getPartner]);

  return (
    <PartnerContext.Provider
      value={{
        partnerData,
        loadingPartnerContext: loading,
        hasPartnerConnected,
        verifyIfHasPartnerConnected,
        setHasPartnerConnected,
        setPartnerData,
        refetchPartnerData: verifyIfHasPartnerConnected,
        updateAndGetPartnerId,
        updatePartnerData,
        resetPartnerData,
        updateAndGetPartnerData,
      }}>
      {children}
    </PartnerContext.Provider>
  );
};

export const usePartnerContext = () => useContext(PartnerContext);
