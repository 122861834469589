import { useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { usePitchContext } from 'context/onboarding-pitch';
import { ASSETS_PATH } from 'constants/assets';
import * as MyRoutes from 'constants/Routes';
import NewThreePitch from 'components/OnboardingNewPitch/three';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnboardingPitchThree = () => {
  const history = useHistory();
  const { pitchCopy } = usePitchContext();
  const onboardingPitchThree = `${ASSETS_PATH}/onboarding-pitch-three.png`;

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_PITCH_THREE);
  }, []);


  const onPressContinue = () => {
    history.push(MyRoutes.DESIRE_TYPE);
  };

  return (
    <>
      <NewThreePitch
        pitchCopy={pitchCopy}
        onboardingPitchThree={onboardingPitchThree}
        goToLogin={onPressContinue}
      />
    </>
  );
};

export default OnboardingPitchThree;
