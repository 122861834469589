import { useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { usePitchContext } from 'context/onboarding-pitch';
import OnboardingPitch from 'components/OnboardingPitch';
import { ASSETS_PATH } from 'constants/assets';
import * as MyRoutes from 'constants/Routes';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import NewTwoPitch from 'components/OnboardingNewPitch/two';
import * as S from './styles';
import analytics,{ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const OnboardingPitchTwo = () => {
  const history = useHistory();
  const { pitchCopy } = usePitchContext();
  const onboardingPitchTwo = `${ASSETS_PATH}/onboarding-pitch-three.png`;
  const location = useLocation();
  
  const {utmMedium, getAllParamsString} = useUTMParams();

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_PITCH_TWO);
  }, []);

  const { featureVariant } = useFlagsmith({ featureName: FEATURE_FLAG_NAME.WR_OB_ORDER });

  const isShowDTQFirstVariant = featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;
  const isVersionedOB = featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_B;

  const goToNextPitch = () => {
    if (isShowDTQFirstVariant) {
      history.push(MyRoutes.ONBOARDING_TRAITS);
    } else if (isVersionedOB) {
      utmMedium ? 
      history.push(`${MyRoutes.ONBOARDING_QUIZ}/1?${getAllParamsString()}`) : 
      history.push(MyRoutes.ONBOARDING_QUIZ);
    } else {
      history.push(MyRoutes.LOGIN, {
        performSignUp: true
      });
    }
  };

  return (
    <>
      {isShowDTQFirstVariant ? (<NewTwoPitch 
     pitchCopy={pitchCopy}
     goToNextPitch={goToNextPitch}
     onboardingPitchTwo={onboardingPitchTwo}
      />) :
        <S.Container>
          <S.CollageMan />
          <S.HeaderImageTwo />
          <S.HeaderImageOne />
          <OnboardingPitch
            title={pitchCopy[1]?.title}
            description={pitchCopy[1]?.description}
            logoUrl={onboardingPitchTwo}
            onPressContinue={goToNextPitch}
            showTestimonial={true}
          />
          <S.BottomCenter />
          <S.OrangeCircle />
          <S.BigYellowMoon />
        </S.Container>
      }
    </>
  );
};

export default OnboardingPitchTwo;
