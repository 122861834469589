export const USER_TRAITS_GROUP_NAME = {
  RELATIONSHIP_STATUS: 'Relationship Status',
  SHYNESS: 'Shyness'
};

export const MAPPED_USER_TRAIT_GROUP_NAME = {
  SHYNESS: 'Survivor of sexual trauma'
}

export const USER_TRAIT_SHYNESS_DISPLAY_NAME = {
  NOT_AT_ALL: 'Not at all',
  VERY_SHY: 'Very shy'
}

export const MAPPED_USER_TRAIT_SHYNESS_DISPLAY_NAME = {
  NOT_AT_ALL: 'No',
  VERY_SHY: 'Yes'
}

export const USER_TRAIT_RELATIONSHIP_STATUS = {
  COMPLICATED: 'Complicated',
  NOT_IN_A_RELATIONSHIP: 'Not in a relationship',
  IN_A_RELATIONSHIP: 'In a relationship',
};
