import Colors from 'assets/styles/Colors';
import styled from 'styled-components';

// const FONT_FAMILY = {
//   1: Fonts.GTMedium,
//   2: Fonts.Inter,
//   3: Fonts.GTRegular,
//   4: Fonts.Inter,
//   5: Fonts.Inter,
//   6: Fonts.GTRegular,
// };

enum FONT_SIZE {
  '18px',
  '16px',
  '20px',
  '15px',
  '9px',
  '24px',
};

enum LINE_HEIGHT {
  '36px',
  '32px',
  '24px',
  '20px',
  '12px',
  '29px',
};

const LETTER_SPACING = 0;

const DEFAULT_COLOR = Colors.Black;

interface ParagraphProps {
  type: number
}

export const Paragraph = styled.div<ParagraphProps>`
  /* font-family: ({type}) => FONT_FAMILY[type]; */
  font-size: ${({type}) => FONT_SIZE[type]};
  line-height: ${({type}) => LINE_HEIGHT[type]};
  letter-spacing: ${LETTER_SPACING};
  color: ${({color}) => color || DEFAULT_COLOR};
`;
