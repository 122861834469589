import OnboardingPitch from 'components/OnboardingPitchNew';

import * as S from './styles';
import OnboardingAnswersList from 'components/OnboardingAswersList';

type OnboardingPitchThreeProps = {
  pitchCopy: any;
  goToLogin: () => void;
  onboardingPitchThree: string;
};

const OnboardingPitchThree = ({pitchCopy, goToLogin, onboardingPitchThree}: OnboardingPitchThreeProps) => {

  return (
    <S.Container>
      <S.SmallGreyMoon />
      <S.HeaderImageTwo />
      <S.HeaderImageOne />
      <S.ProgressContainer>
        <OnboardingAnswersList progress={2} title='' />
      </S.ProgressContainer>
      <S.Title>You’re in good hands!</S.Title>
      <OnboardingPitch
        title={pitchCopy[2]?.title}
        description={pitchCopy[2]?.description}
        subDescription={pitchCopy[2]?.subDescription}
        subDescriptionRed={pitchCopy[2]?.subDescriptionRed}
        logoUrl={onboardingPitchThree}
        onPressContinue={goToLogin}
      />

      <S.BottomCenter />
      <S.Footer>
        <S.BigYellowMoon />
      </S.Footer>
    </S.Container>
  );
};

export default OnboardingPitchThree;
