import React, { useEffect } from 'react';
import { useHistory} from 'react-router';

import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnboardingChat = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const onPressContinue = () => {
    history.push(MyRoutes.ONBOARDING_PLAY);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.ONBOARDING_CHAT);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent style={{paddingTop: 30}}>
        <S.Heading type={2}>Welcome to Coral</S.Heading>
      </S.TopContent>
      <S.Content>
      <S.PillarView>
            <S.PillarIcon />
            <S.PillarTitle>
              {'CHAT'}
            </S.PillarTitle>
          </S.PillarView>
      <S.ChatContentImage />
      <S.ChatContent type={4} >
          With your partner, you'll answer fun questions and do sexy exercises to feel more connected and spice thing up
    </S.ChatContent>
        </S.Content>
      <S.Footer>
        <S.Button onClick={onPressContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.ChatBottomImage />
    </S.Container>
  );
};

export default OnboardingChat;
