import * as React from 'react';

type OnBoardingTraitBottomType = {
  fill?: string;
  width?: number;
  height?: number;
  positionX?: number;
  positionY?: number;
};

function OnBoardingTraitBottom({
  fill = '#C0C7BC',
  width = 1571,
  height = 504,
  positionX = 0,
  positionY = 0
}: OnBoardingTraitBottomType) {
  return (
    <div style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 504,
      overflow: 'hidden'
    }}>
      <svg
        width="1571"
        height="504"
        viewBox={`${positionX} ${positionY} ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity={0.3}
          d="M148.5 252.556c-105 1-142 40.5-148.5 129.5v121h1569.5c2.33-81.5 3.2-244.8-130-204-166.5 51-96.5-22.5-159-52-58.86-27.784-96.06 21.421-145.5 34-39.56 10.066-93.08-6.264-126.5-82.5-64-146-147.5-66.001-193-130-45.5-64-124.5-107-206-18s-115 3.5-171 35-14.71 120.23-137 74.499c-119-44.5-48 91.501-153 92.501z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default OnBoardingTraitBottom;
