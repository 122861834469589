import React, {useEffect} from 'react';
import {useHistory} from 'react-router';

import OnboardingOptions from 'components/OnboardingOptions';
import OnboardingOption from 'components/OnboardingOption';
import {DESIRE_TYPES, TOTAL_STEPS} from 'constants/onboarding';
import {
  useOnboardingDesireTypeContext,
  DesireType,
  DesireTypeSteps,
} from 'context/onboarding-desire-type';

import {useCacheContext} from 'context/cache';

import desireTypeData, {
  descriptions as descriptionsPartner,
} from 'constants/desire-types-partnered.mock';
import {descriptions as descriptionsSingle} from 'constants/desire-types-single.mock';

import {isInARelationship} from 'helpers/traits-helper';

import * as MyRoutes from 'constants/Routes';
import Colors from 'assets/styles/Colors';
import * as S from './styles';
import {useDimensionsContext} from 'context/dimensions';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const DesireTypeConnection = () => {
  const history = useHistory();
  const {setUserDesireType, steps} = useOnboardingDesireTypeContext();
  const {userTraits} = useCacheContext();
  const {height} = useDimensionsContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const isUserInARelationship = isInARelationship(userTraits) as boolean;
  const description = isUserInARelationship
    ? descriptionsPartner[DESIRE_TYPES.CONNECTION]
    : descriptionsSingle[DESIRE_TYPES.CONNECTION];

  const desireConnection = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES.CONNECTION,
  );

  const handleSelectValue = async (value: DesireType) => {
    setUserDesireType(DesireTypeSteps.Connection, value);

    utmMedium ?
      history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_NOVELTY}?${getAllParamsString()}`) :
      history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_NOVELTY);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_CONNECTION);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={8} total={TOTAL_STEPS} />
        <S.Answer
          text="My desire feels"
          selectedOption={steps?.DesireFeel?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS)
          }
        />
        <S.Answer
          text="Desire takes"
          selectedOption={steps?.DesireMood?.name}
          color={Colors.PaleCoral}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_MOOD)
          }
        />
        <S.Answer
          text="I need"
          selectedOption={steps?.DesirePartner?.name}
          color={Colors.Skin02}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER,
            )
          }
        />
        <S.DescriptionLeft>{description}</S.DescriptionLeft>
        <S.LeftHeading>Connection is</S.LeftHeading>
        <OnboardingOptions>
          {desireConnection?.map((value) => (
            <OnboardingOption
              key={value.id}
              text={value.name}
              color={Colors.SeaGreen}
              onClick={() => handleSelectValue(value)}
            />
          ))}
        </OnboardingOptions>
      </S.TopContent>
      <S.BottomImage22 />
    </S.Container>
  );
};

export default DesireTypeConnection;
