import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import styled from 'styled-components';


import waveBg1 from 'assets/images/discussion-top-bg-green.svg';
import waveBg2 from 'assets/images/discussion-top-bg-cononut.svg';
import imageBg1 from 'assets/images/discussion-img1.png';
import imageBg2 from 'assets/images/discussion-img2.png';
import waveSeparator from 'assets/images/discussion-wave-separator.svg';
import collageBottom from 'assets/images/discussion-bg-bottom.png';
import coralLogoWhite from 'assets/images/coral-logo-white.png';
import AppleLogoSvg from 'assets/images/appleLogo.svg';
import LeftBGImg from 'assets/images/NewHome/homeBG-left.png';
import Colors from 'assets/styles/Colors';
import Heading from 'components/base/Heading';
import Paragraph from 'components/base/Paragraph';
import Button from 'components/base/Button';
import { ASSETS_PATH } from 'constants/assets';

export const Container = styled.div.attrs(() => ({
  showsVerticalScrollIndicator: false,
  bounces: false,
  contentContainerStyle: {
    flexGrow: 1,
  },
}))`
  flex: 1;
  width: 100%;
  background-color: ${Colors.Coconut};
`;

export const FirstLayer = styled.div`
  position: relative;
  flex: 1;
  margin-top: 30px;
  width: 100%;
  min-height: 100px;
  flex-shrink: 0;
  max-width: 83.4%;
  margin: auto;
`;

export const SecondLayer = styled.div`
  flex: 1;
  width: 100%;
  margin: auto;

  @media (min-width: 1700px) {
    margin-right: 30%;
  }; 
`;

export const QuestionContainer = styled.div`
  max-width: 83.4%;
  margin: auto;

  @media (min-width: 768px) {
    display: flex;
    padding: 5% 0;
  }; 

  @media (max-width: 768px) {
    margin-bottom: 5%;
  }; 
`;

export const ThirdLayer = styled.div`
  flex: 1;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 40px;
  margin-top: -1px;
  background-color: ${Colors.Skin02};
`;

export const CommentsLayerOverFlow = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
`;


export const ForthLayer = styled.div`
  flex: 1;
  width: 100%;
  padding: 40px 20px;
`;

export const SimpleLayer = styled.div`
  max-width: 83.4%;
  margin: auto;
`;

export const OverFlowComments = styled.div`
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  display: flex;
  height: 500px;
  border-color: #4cd964;
  position: absolute;
  background: linear-gradient(0deg, #F7F4F3 30.36%, rgba(217, 217, 217, 0) 87.98%);
  z-index: 99`

export const SeeMoreLayer = styled.div`
  padding: 16px;
  margin-top: 30px;
  min-height: 100px;
`;

export const BottomLayer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 40px 20px;
  margin-top: 190px;
  background-color: #3C536C
`;

export const RatingAndMediaContainer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 0 20px;
`;

export const BgBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  z-index: 0;
  top: -285px;
  left: 0;
  background-image: url(${collageBottom});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const WaveBg1 = styled.img.attrs(() => ({
  src: waveBg1,
}))`
  position: absolute;
  max-width: none;
  width: 964px;
  height: 306px;
  top: -197px;
  left: -285px;
`;

export const WaveBg2 = styled.img.attrs(() => ({
  src: waveBg2,
}))`
  position: absolute;
  max-width: none;
  width: 1667px;
  height: 674px;
  top: -271px;
  left: -339px;
`;

export const ImageBg1 = styled.img.attrs(() => ({
  src: imageBg1,
}))`
  width: 255px;
  height: 188px;
  margin: auto;

  @media (min-width: 1500px) {
    height: 300px;
    width: auto;
  }; 

`;


export const ImageBg2 = styled.img.attrs(() => ({
  src: imageBg2,
}))`
  width: 258px;
  height: 190px;
  margin: auto;
`;

export const WaveSeparator = styled.img.attrs(() => ({
  src: waveSeparator,
}))`
  width: 100%;
  object-fit: cover;
  margin: auto;
`;

export const CoralLogoWhite = styled.img.attrs({
  src: coralLogoWhite
})`
  max-width: none;
  width: 135px;
  height: 41px;
  margin: auto;
  margin-bottom: 30px;
`;

export const DiscussionTitle = styled(Heading).attrs({
  type: 5
})``;

export const DiscussionTitleButton = styled(Link)`
  width: fit-content;
`;

export const TitleCenter = styled(Heading).attrs({
  type: 3
})`
  text-align: center;
  margin: 0 24px;
  font-weight: 500;

  @media (min-width: 1000px) {
    font-size: 66px;
    text-align: left;
  }; 
`;

export const TitleCenterFooter = styled(Heading).attrs({
  type: 3
})`
  text-align: center;
  margin: 0 24px;
  font-weight: 500;
`;

export const TextCenter = styled(Paragraph).attrs({
  type: 2
})`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin: 30px 24px;

  @media (min-width: 768px) {
    text-align: left;
  }; 
`;

export const TextCenterFooter = styled(Paragraph).attrs({
  type: 2
})`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin: 30px 24px;
`;

export const ExpertButtonContainer = styled.div`
  margin: 0 24px;
`;

export const LearnMoreContainer = styled.div`
  text-align: center;
`;

export const ExpertButton = styled(Link)`
  display: inline-block;
  border-radius: 5px;
  padding: 21px;
  width: auto;
  background-color: ${Colors.Red};
  color: ${Colors.White};
  font-weight: bold;
  font-family: 'Andes';
  letter-spacing: 0.1em;

  @media (min-width: 768px) {
    padding: 15px 50px;
  };
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  };
`;

export const DailyPollSectionContainer = styled.div`
  padding: 0 16px;
  margin-top: 40px;
`;

export const AskAnExpertBtn = styled.div`
  background-color: ${Colors.Skin03};
  border-radius: 42px;
  padding-horizontal: 16px;
  padding-vertical: 12px;
  align-items: center;
  margin-bottom: 6px;
  flex-direction: row;
`;

export const AskAnExpertText = styled.div`
  color: ${Colors.Black};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  align-self: center;
  letter-spacing: 2.24px;
`;

export const AppOfTheDayLogo = styled.img.attrs(() => ({
  src: AppleLogoSvg,
}))`
margin: auto;
margin-bottom: 25px;

`;

export const StoreLinksContainer = styled.div`
  ${tw`z-10 flex justify-center mb-10`}
`;

export const StoreLink = styled.a`
  ${tw`z-10 flex`}
`;

export const ClickEvent = styled.div``;

export const AppStoreLogo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/app-store.png`,
}))`
  width: 151px;
  margin-right: 10px;
`;

export const PlayStoreLogo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/play-store.png`,
}))`
  width: 151px;
`;

export const MenuContainer = styled.div`
  margin-bottom: 20px;
`;

export const MenuBig = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: white;
`;

export const MenuSmall = styled.div`
  font-family: 'Andes';
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  color: white;
`;

export const FooterContainer = styled.div`
  position: relative;
  z-index: 1;
`;

export const LeftBGImage = styled.img.attrs(() => ({
  src: LeftBGImg,
}))`
  position: absolute;
  bottom: 0;

  @media (max-width: 1030px) {
    display: none;
  }; 
`;