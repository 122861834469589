import getSymbolFromCurrency from 'currency-symbol-map';

type Props = {
  productPrice?: number;
  currencyCode?: string;
  showCurrencyCode?: boolean;
};

export const getMonthPriceCalculated = ({
  productPrice = 0,
  currencyCode = 'USD',
  showCurrencyCode = true,
}: Props) => {

  const priceCalculated = Math.ceil(productPrice / 12);

  if(!showCurrencyCode) {
    return priceCalculated;
  }
  
  let currencyPrefix = getSymbolFromCurrency(currencyCode);
  return `${currencyPrefix}${priceCalculated}`;
};

export const calculateValueWithDiscount = (
  originalPrice: number,
  percentOff?: number,
  amountOff?: number,
): number => {
  return percentOff 
  ? parseFloat((originalPrice - ((originalPrice * percentOff) / 100)).toFixed(2))
  : amountOff
  ? parseFloat((originalPrice - (amountOff / 100)).toFixed(2))
  : originalPrice;
};

export const calculateDiscountAmount = (
  originalPrice: number,
  percentOff?: number,
  amountOff?: number,
): number => {
  return percentOff 
  ? parseFloat((((originalPrice * percentOff) / 100)).toFixed(2))
  : amountOff
  ? parseFloat(((amountOff / 100)).toFixed(2))
  : 0;
};

