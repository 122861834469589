import { useEffect} from 'react';
import { useHistory, useLocation } from 'react-router';

import OnboardingAnswersList from 'components/OnboardingAswersList';
import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import { useOnboardingContext } from 'context/onboarding';
import useUTMParams from 'common/hooks/useUTMParams';

interface LocationProps {
  partnerConnected?: boolean
}

const TraitsConfirmation = () => {
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const { height } = useDimensionsContext();
  const { setOnboardingTraitsCompleted } = useOnboardingContext();

  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  })

  const {utmMedium, getAllParamsString} = useUTMParams();

  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;
  const isShowNewVariant = featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_TRAITS_CONFIRMATION);
  }, []);

  const partnerConnected = location.state?.partnerConnected ;

  console.log(`${MyRoutes.ONBOARDING_ASK_ORGANIC_SOURCE}?${getAllParamsString}`);

  const handleFinishTraits = async () => {
    if(!isNewOBOrder || isShowNewVariant) {
      await setOnboardingTraitsCompleted();
    }
    if (isShowNewVariant) {
      history.push(MyRoutes.ONBOARDING_SUGGESTED_JOURNEYS);
    } else {
      utmMedium ?
      history.push(`${MyRoutes.ONBOARDING_ASK_ORGANIC_SOURCE}?${getAllParamsString}`) : 
      history.push(MyRoutes.ONBOARDING_ASK_ORGANIC_SOURCE); 
    }
  };

  return (
    <S.Container height={height}>
      <S.FormContent>
        <OnboardingAnswersList
          progress={8}
          showEditIcon={true}
          partnerConnected={partnerConnected}
        />
        <S.Content>
          <S.LeftHeading>How does that look?</S.LeftHeading>
        </S.Content>
      </S.FormContent>
      <S.FinishButton
        icon={<S.CheckIcon />}
        onClick={handleFinishTraits}
      >
        LOOKS GOOD
      </S.FinishButton>
      <S.BottomImageDesireType />
      <S.BottomLegsImage />
    </S.Container>
  );
};

export default TraitsConfirmation;
