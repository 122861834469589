import * as S from './styles';

const RatingAndMedia = () => {
  const starsCount = [1, 2, 3, 4, 5];

  return (
    <>
      <S.RatingLine>
        <S.ReviewsContainer>3.5K Reviews</S.ReviewsContainer>
        <S.RatingContainer>
          {starsCount.map((count) => (
            <S.Star key={`star-${count}`} />
          ))}
        </S.RatingContainer>
        <S.ReviewsContainer>(4.6)</S.ReviewsContainer>
      </S.RatingLine>

      <S.Images>
        <S.Vector />
        <S.Wellgood />
        <S.Time />
        <S.People />
      </S.Images>
    </>
  );
};

export default RatingAndMedia;