import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import { ASSETS_PATH } from 'constants/assets';
import BodyBackGround from 'assets/images/background-black-preview.png';
import PinkBGCurve from 'assets/images/pink-bg-curve.svg';
// import {
//   onboardingBottom,
//   pitchHeaderSecondBackground,
//   smallBlueMoon,
//   tanCurveOne,
// } from '@@assets';

const width = window.screen.width;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${Colors.Coconut};
`;

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  width: 1584px;
  height: 207px;
  left: -1325px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1584px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.div`
  position: absolute;
  top: 0;
  width: 1218.5px;
  height: 194px;
  left: -702px;
  background-image: url(${`${ASSETS_PATH}/pitch-header-second-background.png`});
  background-size: 1218px 194px;
  background-position: left;
  z-index: 0;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  height: 113px;
  width: 113px;
  left: 50px;
  top: 1px;
  border-radius: 0px;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: 113px 113px;
  background-position: top;
`;

export const BackGroungImage = styled.img.attrs(() => ({
  src: BodyBackGround
}))`
position: absolute;
bottom: 10%;
width: 100%;
height: auto;

@media (min-width: 1000px) and (max-width: 2500px) {
  bottom: -2%;
}
`;

export const BodyPinkBGCurve = styled.img.attrs(() => ({
  src: PinkBGCurve
}))`
position: absolute;
bottom: 5%;
`;


export const OrangeCircle = styled.div`
  position: absolute;
  height: 266px;
  width: 266px;
  right: -56px;
  bottom: -100px;
  border-radius: 266px;
  background-color: ${Colors.Skin03};
`;

export const BottomCenter = styled.div`
  position: absolute;
  width: 100%;
  height: 190px;
  z-index: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-bottom.png`});
  background-size: 780px 400px;
  background-position: top;
`;

export const Footer = styled.div`
  align-items: center;
`;

export const BigYellowMoon = styled.div`
  position: absolute;
  width: 571px;
  height: 593px;
  bottom: -400px;
  left: -${() => (571 - width) / 2}px;
  background-image: url(${`${ASSETS_PATH}/moon-big-yellow.png`});
  background-size: 571px 593px;
`;
