import { useHistory } from 'react-router-dom';
import { usePitchContext } from 'context/onboarding-pitch';
import OnboardingPitch from 'components/OnboardingPitchNew';
import { ASSETS_PATH } from 'constants/assets';
import * as MyRoutes from 'constants/Routes';

import * as S from './styles';

type OnboardingPitchTwoProps = {
  pitchCopy: any;
  goToNextPitch: () => void;
  onboardingPitchTwo:string;
};

const OnboardingPitchTwo = ({pitchCopy, goToNextPitch, onboardingPitchTwo} : OnboardingPitchTwoProps) => {
  
  return (
    <S.Container>
      {/* <S.CollageMan /> */}
      <S.HeaderImageTwo />
      <S.HeaderImageOne />
      <OnboardingPitch
        title={pitchCopy[1]?.title}
        description={pitchCopy[1]?.description}
        logoUrl={onboardingPitchTwo}
        onPressContinue={goToNextPitch}
        showTestimonial={true}
      />
      <S.OrangeCircle />
      <S.BigYellowMoon />
    </S.Container>
  );
};

export default OnboardingPitchTwo;
