enum Colors {
  'Ash' = '#858585',
  'Coconut' = '#F7F4F3',
  'Roe' = '#EE8B68',
  'SeaGreen' = '#80A49A',
  'DeepBlue' = '#35546F',
  'Skin' = 'rgba(236,167,140,1)',
  'Black' = '#1B211E',
  'BlackReal' = '#000000',
  'BlackGreen' = 'rgba(28,33,30,1)',
  'BlackGreenTransparent' = 'rgba(27, 33, 30, 0.4)',
  'White' = '#FFFFFF',
  'Steam' = '#DEDAD9',
  'Steam_01' = '#d2cdcb', // use this color for instead of Steam if Steam looks incorrect
  'Red' = '#FF6F61',
  'Kelp' = '#122F21',
  'PaleCoral' = '#F2B3AD',
  'PaleKelp' = '#C0C7BC',
  'Skin01' = '#D9CCC4',
  'Skin02' = '#E9C7AF',
  'Skin03' = '#ECA78C',
  'Skin04' = '#AE6446',
  'Skin05' = '#573120',
  'Skin06' = '#31231C',
  'BorderGrey' = 'rgba(133, 133, 133, 0.2)',
  'Gray90' = '#E5E5E5',
  'Transparent' = 'transparent',
  'ErrorRed' = '#FF1700',
  'LoaderBackgroundColor' = '#f3f3f3',
  'LoaderForegroundColor' = '#ecebeb',
  'OrangeOld' = '#fc6f65',
  'TransparentBlack' = 'rgba(0,0,0,0.5)',
  'TransparentWhite' = 'rgba(255, 255, 255, 0.3)',
  'BlackInactive' = 'rgba(0, 0, 0, 0.54)',
  'BackgroundRoe' = 'rgba(242, 111, 99, 1)',
  'BackgroundFooterWave' = '#f1b3ad',
  'ColorIcon' = '#fe6262',

  //New Home page
  'BlackTitle' = '#1a211e',
  'BlackTitle_02' = '#181618',

  'BlueText' = '#5E00BD',
}

export default Colors;
