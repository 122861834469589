import { useEffect, useState } from 'react';
import * as MyRoutes from 'constants/Routes';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import analytics from 'helpers/analytics-helper';
import {QUIZ} from 'constants/onboarding';

import Welcome from './Welcome';
import Testimonial from './Testimonial';
import Question from './Question';
import {QUIZZES_SCREENS_NEW, QUIZZES_SCREENS} from './data'
import {MainQuestionType, QuestionType, TestimonialType} from './types';
import * as S from './styles';
import useUTMParams from 'common/hooks/useUTMParams';

type Params = {screen?: string};
type LocationState = {version?: string};

const OnboardingQuiz = () => {

  const history = useHistory();
  const location = useLocation<LocationState>();
  const params = useParams<Params>();

  const [screens, setScreens] = useState<string>();
  const {utmMedium, getAllParamsString} = useUTMParams();
  
  useEffect(() => {
    if(location.state?.version) {
      setScreens(location?.state?.version);
    }
  }, [location.state]);

  const options: MainQuestionType[] = [
    {
      label: 'INCREASE MY SEX DRIVE',
      short: 'Sex drive',
      value: QUIZ.INCREASE_MY_SEX_DRIVE,
    },
    {
      label: 'TRY NEW THINGS IN BED',
      short: 'Try new things',
      value: QUIZ.TRY_NEW_THINGS_IN_BED,
    },
    {
      label: 'IMPROVE MY PERFORMANCE',
      short: 'Performance',
      value: QUIZ.IMPROVE_MY_PERFORMANCE,
    },
    {
      label: 'RECONNECT WITH MY PARTNER',
      short: 'Reconnect',
      value: QUIZ.RECONNECT_WITH_MY_PARTNER,
    },
  ];

  const handleStartQuiz = (option: MainQuestionType) => {
    analytics.page('Versioned OB main question', {
      answer: option.short
    });

    utmMedium ? 
    history.push(`${MyRoutes.ONBOARDING_QUIZ}/1?${getAllParamsString()}`, {
      version: option.value
    }) : 
    history.push(`${MyRoutes.ONBOARDING_QUIZ}/1`, {
      version: option.value
    });
  }

  const goToNext = () => {
    utmMedium ? 
    history.push(`${MyRoutes.ONBOARDING_QUIZ}/${parseInt(params.screen) + 1}?${getAllParamsString()}`) :
    history.push(`${MyRoutes.ONBOARDING_QUIZ}/${parseInt(params.screen) + 1}`)
  }

  const getQuizScreen = () => {
    const currentQuiz = utmMedium ? QUIZZES_SCREENS_NEW[utmMedium] : QUIZZES_SCREENS[screens];

    const currentScreen = params?.screen && currentQuiz[parseInt(params.screen) - 1];

    switch(currentScreen.type) {
      case 'Welcome': 
        return <Welcome onContinue={goToNext} title={utmMedium ? currentScreen.subtitle : null}/>
      case 'Testimonial': 
        return <Testimonial testimonial={currentScreen as TestimonialType} onContinue={goToNext} />
      case 'Question': 
        return <Question question={currentScreen as QuestionType} onContinue={goToNext} />
    }

    return <div>None</div>;
  }

  return screens || utmMedium
    ? (
      getQuizScreen()
    ) : (
      <S.Container>
        <S.Header>
          <S.HeaderImageOne />
          <S.HeaderImageThree />
        </S.Header>
        <S.Content>
          <S.Logo />
          <S.DescriptionContainer>
            <S.Description>
            Our goal is to help you improve your sexual wellness.
            </S.Description>
            <S.Description>
            What can we help you with today?
            </S.Description>
          </S.DescriptionContainer>
          <S.ButtonsContainer>
            {options.map((option, idx) => (
              <S.CtaButton key={idx} onClick={() => handleStartQuiz(option)}>{option.label}</S.CtaButton>
            ))}
          </S.ButtonsContainer>
        </S.Content>
      </S.Container>
    );
};

export default OnboardingQuiz;
