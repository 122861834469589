import styled from 'styled-components';
import tw from 'twin.macro';

import CustomButton from 'components/base/Button';
import { ASSETS_PATH } from 'constants/assets';
import Colors from 'assets/styles/Colors';

const width = window.screen.width;

export const Container = styled.div`
  ${tw`flex flex-1 flex-col`}
  background-color: ${Colors.Coconut};
`;

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  width: 1584px;
  height: 207px;
  left: -1325px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1584px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.div`
  position: absolute;
  top: 0;
  width: 1218.5px;
  height: 194px;
  left: -702px;
  background-image: url(${`${ASSETS_PATH}/pitch-header-second-background.png`});
  background-size: 1218px 194px;
  background-position: left;
  z-index: 0;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  height: 113px;
  width: 113px;
  left: 50px;
  top: 1px;
  border-radius: 0px;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: 113px 113px;
  background-position: top;
`;

export const Content = styled.div`
  ${tw`flex flex-col flex-1 justify-center items-center p-5 z-10`}
`;

export const QuestionTitle = styled.div`
  ${tw`text-2xl text-center mb-7`}
  font-family: 'Andes';
  color: ${Colors.DeepBlue};
`;

export const QuestionDescription = styled.div`
  ${tw`text-lg text-center`}
  font-family: 'Andes';
  color: ${Colors.DeepBlue};
  white-space: pre-line;
`;

export const QuestionOptionsContainer = styled.div`
  ${tw`w-full mt-7`}
`;

export const QuestionOption = styled(CustomButton).attrs({
  color: Colors.White,
  textColor: Colors.DeepBlue,
})`
  text-transform: uppercase;
`;

export const QuestionOptionSelected = styled(CustomButton).attrs({
  color: Colors.Red,
  textColor: Colors.DeepBlue,
})`
  text-transform: uppercase;
  ${tw`mb-4`}
`;

export const QuestionComment = styled.div`
  ${tw`text-lg text-center mt-7`}
  font-family: 'Andes';
  color: ${Colors.DeepBlue};
  white-space: pre-line;
`;

export const Footer = styled.div`
  ${tw`flex px-5 py-0 z-10`}
`;

export const Button = styled(CustomButton)`
  ${tw`z-10 mb-4`}
`;

export const BigYellowMoon = styled.div`
  position: absolute;
  width: 571px;
  height: 593px;
  bottom: -350px;
  right: -${width/1.5}px;
  background-image: url(${`${ASSETS_PATH}/moon-big-yellow.png`});
  background-size: 571px 593px;
`;
