import { useHistory } from 'react-router';
import * as S from './styles';
import Footer from '../../../components/NewHome/Footer';
import CommunityCard from '../../../components/DesireDiscrepency/CommunityCard';
import RelatedCategoryCard from '../../../components/DesireDiscrepency/Cards';
import Header from '../../../components/NewHome/Header';
import SearchBox from '../../../components/NewHome/SearchBox';
import * as MyRoutes from 'constants/Routes';
import { useParams } from 'react-router-dom';
import { useDiscussionContext } from 'context/discussion';

const DiscussionList = () => {
  const history = useHistory();
  const { id: courseId } = useParams<{ id: string }>();

  const { getCourse } = useDiscussionContext();

  const course = getCourse(Number(courseId));

  const createItems = () => {
    return (
      course?.discussions.map((discussion, i) => {
        return (
          <CommunityCard
            discussion={discussion}
            courseId={course.id}
            index={i}
          />
        );
      }) ?? <></>
    );
  };

  return (
    <>
      <S.HeaderContainer>
        <Header textColorBlack={true} />
      </S.HeaderContainer>
      <S.Container>
        <SearchBox
          title={course?.name || 'New Discussions'}
          breadcrumbs={['Home', 'Discuss', course?.name || 'New Discussions']}
        />
        <S.CommentsLayerOverFlow>
          <S.CardContainer>{createItems()}</S.CardContainer>

          <S.OverFlowComments>
            <S.SeeMoreLayer>
              <S.ExpertButton to={MyRoutes.CONFIRMATION}>
                SEE MORE
              </S.ExpertButton>
            </S.SeeMoreLayer>
          </S.OverFlowComments>
        </S.CommentsLayerOverFlow>

        <S.RelatedCategoriesContainer>
          <S.RelatedTitle>Related Categories</S.RelatedTitle>

          <S.RelatedCardContainer>
            <RelatedCategoryCard title={course?.name || 'New Discussions'} />
          </S.RelatedCardContainer>
        </S.RelatedCategoriesContainer>

        <Footer />
      </S.Container>
    </>
  );
};

export default DiscussionList;
