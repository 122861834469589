import React, { useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useFirebaseAuthContext } from 'context/auth';
import { useUserContext } from 'context/user';

import { EVENT_MAP_FOR_ROUTES } from 'helpers/constants-helper';
import analytics from 'helpers/analytics-helper';

import Loader from 'components/base/Loader';
import * as MyRoutes from 'constants/Routes';
import Colors from 'assets/styles/Colors';
import { useParamsContext } from 'context/params';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { useFlagsmithContext } from 'context/flagsmith';
import useUTMParams from 'common/hooks/useUTMParams';

interface AuthenticatedRouteProps {
  exact?: boolean;
  path?: string;
  trackPage?: boolean;
  component: React.ComponentType<any>;
} 

const AuthenticatedRoute = ({ component: Component, path, trackPage = true, ...props }: AuthenticatedRouteProps) => {
  const [loadingFF, setLoadingFF] = useState(true);
  const [showLandingScreens, setShowLandingScreens] = useState<boolean>();
  const [showDesireScreens, setShowDesireScreens] = useState<boolean>();
  const { isAuthenticated } = useFirebaseAuthContext();
  const { loadingUserData } = useUserContext();
  const location = useLocation();
  const { addParams } = useParamsContext();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const {loadingFeatureFlags} = useFlagsmithContext();
  const {featureVariant} = useFlagsmith({ featureName: FEATURE_FLAG_NAME.FE_SHOW_LANDING_SCREENS_OB});
  const {featureVariant : OBOrderVariant} = useFlagsmith({ featureName: FEATURE_FLAG_NAME.WR_OB_ORDER});
  
  useEffect(() => {
    if(!loadingFeatureFlags) {
      setShowLandingScreens(featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_A);
      setShowDesireScreens(OBOrderVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C);
      setLoadingFF(false);
    }

    if (utmMedium) {
      window.location.href = `${MyRoutes.ONBOARDING_LANDING}?${getAllParamsString()}`
    }
  }, [loadingFeatureFlags]);

  useEffect(() => {
    document.documentElement.style.maxWidth = path === MyRoutes.COACHING_PAYMENT
      ? '100%'
      : '560px';

    location.search && addParams(location.search);
    trackPage && analytics.page(EVENT_MAP_FOR_ROUTES[path]);
  }, [location]);

  return (
    <Route
      {...props}
      path={path}
      render={ componentProps =>
        loadingUserData || loadingFF
        ? (
          <div style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}><Loader color={Colors.Red} /></div>
        )
        : !isAuthenticated
        ? (
          <Redirect
            to={{
              pathname: MyRoutes.ONBOARDING_LANDING,
              state: { from: componentProps.location }
            }}
          />
        )
        : (
            <Component {...componentProps} />
        )
      }
    />
  )
};

export default AuthenticatedRoute;