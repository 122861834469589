import Colors from 'assets/styles/Colors';
import styled from 'styled-components';
import tw from 'twin.macro';
import { FaStar } from 'react-icons/fa'

export const CommentContainer = styled.div`
  ${tw`h-52 flex flex-col justify-center px-5 bg-white rounded-xl`}
`;

export const CommentAvaliation = styled.div`
  ${tw`flex flex-row`}
`;

export const StarIcon = styled(FaStar)`
  ${tw`mr-2 text-lg text-yellow-400`}
`;

export const CommentContent = styled.div`
  font-family: 'Inter';
  ${tw`text-lg leading-5 tracking-tight mt-4`}
`;

export const CommentAuthor = styled.div`
  font-family: 'Andes';
  color: ${Colors.Black};
  ${tw`font-normal text-xs leading-4 uppercase opacity-40 mt-5`}
`;
