import gql from 'graphql-tag';

const GET_TRAITS = gql`
  query GetTraitListForOnBoarding {
    getTraitListForOnBoarding {
      id
      name
      groupName
    }
  }
`;

const CHECK_INVITATION_CODE = gql`
  query CheckInvitationCodeOnBoarding($invitationCode: String) {
    checkInvitationCodeOnBoarding(invitationCode: $invitationCode) {
      inviterEmail
      inviterPhone
    }
  }
`;

const GET_ONBOARDING_JOURNEY_RECOMMENDATIONS = gql`
  query GetOnboardingJourneyRecommendations {
    onBoardingJourneyRecommendations {
      id
      name
      color
      data {
        hasImage
        imageUrl
      }
    }
  }
`;

export default {
  GET_TRAITS,
  CHECK_INVITATION_CODE,
  GET_ONBOARDING_JOURNEY_RECOMMENDATIONS,
};
