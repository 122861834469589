import * as React from 'react';

function Snake() {
  return (
    <svg width="138" height="10" viewBox="0 0 138 10" fill="none">
      <path
        d="M138 1C130.334 1 130.334 9.00001 122.666 9C114.999 9 114.999 0.999999 107.333 0.999999C99.6665 0.999998 99.6665 9 92 9C84.3324 9 84.3324 0.999998 76.6665 0.999997C68.9994 0.999997 68.9995 9 61.333 9C53.6665 9 53.6665 0.999996 45.9995 0.999996C38.333 0.999996 38.3329 9 30.6659 9C22.9989 9 22.9989 0.999995 15.333 0.999995C7.66648 0.999994 7.66649 9 -1.1712e-06 9"
        stroke="#FF6F61"
        strokeWidth="2"
      />
    </svg>
  );
}

export default Snake;
