import { getRandomGUID } from "helpers";
import { createContext, useContext, useState } from "react";

type FlagsmithContextProps = {
  loadingFeatureFlags: boolean;
  setLoadingFeatureFlags: (value: boolean) => void;
  getRandomID: () => string
}

type Props = {
  children: React.ReactNode;
};

const FlagsmithContext = createContext({} as FlagsmithContextProps);

export const FlagsmithProvider = ({children}: Props) => {
  const [loadingFeatureFlags, setLoadingFeatureFlags] = useState<boolean>(true);
  const [unknownID, setUnknownID] = useState<string>();

  const getRandomID = () => {
    if(unknownID) return unknownID;

    const randomGUID = getRandomGUID();
    setUnknownID(randomGUID);

    return randomGUID;
  }

  return (
    <FlagsmithContext.Provider
      value={{
        loadingFeatureFlags,
        setLoadingFeatureFlags,
        getRandomID,
      }}>
      {children}
    </FlagsmithContext.Provider>
  );
}

export const useFlagsmithContext = () => useContext(FlagsmithContext);
