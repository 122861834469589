import DiscussionsSection from 'components/DiscussionsSection';
import { APP_STORES_URL_FOR_DISCUSSION } from 'helpers/constants-helper';

import * as S from './styles';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { ONBOARDING_LANDING } from '../../constants/Routes';
import Header from 'components/NewHome/Header';
import Footer from 'components/NewHome/Footer';
import SearchBox from 'components/NewHome/SearchBox';
import RatingReview from 'components/NewHome/Sponsors';
import * as MyRoutes from 'constants/Routes';
import analytics, { discussionsPage } from 'helpers/analytics-helper';
import { useDiscussionContext } from 'context/discussion';
import { useEffect } from 'react';

const Discussions = () => {
  const history = useHistory();
  const { newDiscussions, courses, getRelatedUnits, isLoading } =
    useDiscussionContext();

  const openLink = () => {
    window.fbq('track', 'Lead');
  };

  const openTrial = () => {
    if (isMobile) {
      window.open(APP_STORES_URL_FOR_DISCUSSION);
    } else {
      history.push(ONBOARDING_LANDING);
    }
  };

  const buttonClick = () => {
    analytics.googleAnalyticsEventTracker(discussionsPage);
  };

  const handleStoresCTAClick = (action: string) => {
    analytics.googleAnalyticsEventTracker(discussionsPage);
    analytics.gaEvent(`${action} Clicked`);
  }

  useEffect(() => {
    analytics.initializeGoogleAnalytics();
  }, [])

  return (
    <>
      <Header textColorBlack={true} />
      <S.Container>
        <SearchBox title={'Discuss'} breadcrumbs={['Home', 'Discuss']} />
        {!!newDiscussions.length && (
          <S.FirstLayer>
            <S.DiscussionTitleButton
              to={`${MyRoutes.ALL_DISCUSSIONS}/${newDiscussions[0].courseId}`}>
              <S.DiscussionTitle>New Discussions</S.DiscussionTitle>
            </S.DiscussionTitleButton>
            <DiscussionsSection
              shownUnits={newDiscussions}
              courseId={newDiscussions[0].courseId}
            />
          </S.FirstLayer>
        )}
        <S.QuestionContainer>
          <S.ImageBg1 />
          <S.SecondLayer>
            <S.TitleCenter>Have a question?</S.TitleCenter>
            <S.TextCenter>
              Everything you always wanted to know about sex but were afraid to
              ask is at your fingetips. Questions are posted anonymously and
              answered by our team of experts and the Coral community!
            </S.TextCenter>
            <S.ExpertButtonContainer>
              <S.ExpertButton
                to={{pathname: APP_STORES_URL_FOR_DISCUSSION}}
                target="_blank"
                onClick={() => handleStoresCTAClick('Ask an Expert')}
              >
                ASK AN EXPERT
              </S.ExpertButton>
            </S.ExpertButtonContainer>
          </S.SecondLayer>
        </S.QuestionContainer>
        <S.CommentsLayerOverFlow>
          <S.WaveSeparator />
          <S.ThirdLayer>
            {!isLoading &&
              courses.slice(0, 5).map((course) => (
                <S.SimpleLayer key={`title${course.id}`}>
                  <S.DiscussionTitleButton
                    to={`${MyRoutes.ALL_DISCUSSIONS}/${course.id}`}>
                    <S.DiscussionTitle>{course.name}</S.DiscussionTitle>
                  </S.DiscussionTitleButton>
                  <DiscussionsSection
                    courseId={course.id}
                    title={course.name}
                    shownUnits={getRelatedUnits(course.id)}
                  />
                </S.SimpleLayer>
              ))}
          </S.ThirdLayer>
          <S.OverFlowComments>
            <S.SeeMoreLayer>
              <S.ExpertButton
                to={{ pathname: MyRoutes.STORES_URL}}
                target="_blank"
                onClick={() => handleStoresCTAClick('See more answers')}
              >
                SEE MORE ANSWERS
              </S.ExpertButton>
            </S.SeeMoreLayer>
          </S.OverFlowComments>
        </S.CommentsLayerOverFlow>
        <S.ForthLayer>
          <S.AppOfTheDayLogo />
          <S.TitleCenterFooter>Start your journey today</S.TitleCenterFooter>
          <S.TextCenterFooter>
            We help over 500k people deepen their connection with science backed
            methodology
          </S.TextCenterFooter>
          <S.StoreLinksContainer>
            <S.ClickEvent onClick={() => handleStoresCTAClick('Download on the app store')}>
              <S.StoreLink
                target="_blank"
                href={APP_STORES_URL_FOR_DISCUSSION}
                onClick={openLink}>
                <S.AppStoreLogo />
              </S.StoreLink>
            </S.ClickEvent>
            <S.ClickEvent onClick={() => handleStoresCTAClick('Get it on Google Play')}>
              <S.StoreLink
                target="_blank"
                href={APP_STORES_URL_FOR_DISCUSSION}
                onClick={openLink}>
                <S.PlayStoreLogo />
              </S.StoreLink>
            </S.ClickEvent>
          </S.StoreLinksContainer>
          <S.LearnMoreContainer>

            <S.ExpertButton
              to={{ pathname: APP_STORES_URL_FOR_DISCUSSION}}
              target="_blank"
              onClick={() => handleStoresCTAClick('Learn more')}
            >
              LEARN MORE
            </S.ExpertButton>
          </S.LearnMoreContainer>
        </S.ForthLayer>

        <S.RatingAndMediaContainer>
          <RatingReview />
        </S.RatingAndMediaContainer>
        <S.FooterContainer>
          <Footer />
        </S.FooterContainer>

        <S.LeftBGImage />
      </S.Container>
    </>
  );
};

export default Discussions;
