import { UTM_PARAMETERS } from "constants/utm-params";
import { useState } from "react";
import { useLocation } from "react-router-dom";

type ReturnProps = {
    utmMedium: string;
    utmSource:string;
    utmCampaign:string;
    utmContent:string;
    getAllParamsString: () => void;
  };

const useUTMParams = (): ReturnProps => {
    const location = useLocation();

    const [utmMedium] = useState<string>(new URLSearchParams(location.search).get(UTM_PARAMETERS.UTM_MEDIUM));
    const [utmSource] = useState<string>(new URLSearchParams(location.search).get(UTM_PARAMETERS.UTM_SOURCE));
    const [utmCampaign] = useState<string>(new URLSearchParams(location.search).get(UTM_PARAMETERS.UTM_CAMPAIGN));
    const [utmContent] = useState<string>(new URLSearchParams(location.search).get(UTM_PARAMETERS.UTM_CONTENT));
  
    const getAllParamsString = () => {
        return `${UTM_PARAMETERS.UTM_SOURCE}=${utmSource}&${UTM_PARAMETERS.UTM_MEDIUM}=${utmMedium}&${UTM_PARAMETERS.UTM_CAMPAIGN}=${utmCampaign}&${UTM_PARAMETERS.UTM_CONTENT}=${utmContent}`
    }
  
    return {
      utmMedium,
      utmSource,
      utmCampaign,
      utmContent,
      getAllParamsString
    };
  };
  
  export default useUTMParams;