import React from 'react';

import * as S from './styles';

type TitleProps = {
  type: 0 | 1 | 2 | 3;
  color?: string;
  children?: React.ReactNode;
  style?: object;
};

function Title({children, ...props}: TitleProps) {
  return <S.Title {...props}>{children}</S.Title>;
}

export default Title;
