import { FC, createContext, useContext, useState, useEffect } from 'react';
import {
  User,
  UserCredential,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';

import {
  setAccessToken,
  clearLocalStorage,
  getAccessToken,
} from 'helpers/storage-helper';
import analytics from 'helpers/analytics-helper';
import React from 'react';

type AuthUser = User | null;
type ContextState = {
  authUser: AuthUser;
  isAuthenticated: boolean;
  signInWithToken: () => Promise<UserCredential>;
  signinWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<UserCredential>;
  signInSso: (token: string, user: AuthUser) => void;
  logout: () => Promise<void>;
};

const FirebaseAuthContext = createContext<ContextState | undefined>(undefined);
const useReactHash = () => {
  const [hash, setHash] = useState(window.location.hash);
  const listenToHash = () => {
    const myHash = window.location.hash;
    setHash(myHash);
  }

  React.useEffect(() => {
    window.addEventListener('hashchange', listenToHash);
    return () => window.removeEventListener('hashchange', listenToHash);
  }, []);
  return hash;
};

const FirebaseAuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authUser, setAuthUser] = useState<AuthUser>(null);
  const hash = useReactHash();
  const auth = getAuth();

  const identifyUser = (authUser: AuthUser) => {
    analytics.identifyUser(authUser.uid, {
      email: authUser.email,
    });
  };

  const SSOInProcess = () => (window.location.hash === '#redirecting');

  const setUserAndStoreToken = async (authUser: AuthUser) => {
    if (SSOInProcess() || !authUser) { return };
    identifyUser(authUser);

    const idToken = await authUser.getIdToken(true);
    //set token
    setAccessToken(idToken);
    setAuthUser(authUser);
    setIsAuthenticated(true);
    return;
  };

  useEffect(() => {
    setIsAuthenticated(!!authUser);
  }, [authUser, hash]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUserAndStoreToken);
    return unsubscribe;
  }, []);

  const signinWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithToken = () => {
    const token = getAccessToken();
    return signInWithCustomToken(auth, token);
  };

  const signInSso = (token: string, user: AuthUser) => {
    setAccessToken(token);
    setAuthUser(user);
    setIsAuthenticated(true);
  };

  const logout = async () => {
    clearLocalStorage();
    setAuthUser(null);
    await signOut(auth);
  };

  return (
    <FirebaseAuthContext.Provider
      value={{
        authUser,
        isAuthenticated,
        signInWithToken,
        signinWithEmailAndPassword,
        signInSso,
        logout,
      }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

const useFirebaseAuthContext = () => {
  const context = useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseAuthProvider',
    );
  }
  return context;
};

export { FirebaseAuthProvider, useFirebaseAuthContext };
