import * as S from './styles';
import People from 'assets/images/NewHome/Testimonials/people.svg';
import Cusmopolitan from 'assets/images/NewHome/Testimonials/cusmopolitan.svg';
import Insider from 'assets/images/NewHome/Testimonials/insider.svg';
import Blustle from 'assets/images/NewHome/Testimonials/blustle.svg';
import Wellgood from 'assets/images/NewHome/Testimonials/wellgood.svg';
import Angels from 'assets/images/NewHome/Testimonials/angels.svg';

const SponsorRatingReivews = () => {

  const starsCount = [1, 2, 3, 4, 5];

  const testimonialImages = [People, Insider, Angels, Wellgood, Blustle, Cusmopolitan];

  return (
    <>
      <S.TestimonialTextContainer>
        <S.ReviewText>
          2.1K Reviews
        </S.ReviewText>
        <S.StarContainer>
          {starsCount.map((count) => (
            <S.StarIcon key={`star-${count}`} />
          ))}
        </S.StarContainer>

        <S.TestimonialCount>
          (4.6)
        </S.TestimonialCount>
      </S.TestimonialTextContainer>

      <S.TestimonialImageContainer>
        {testimonialImages.map((testimonialImage, index) => {
          return (
            <S.TestimonilaLogo src={testimonialImage} key={index} />
          )
        })}
      </S.TestimonialImageContainer>
    </>
  );
};

export default SponsorRatingReivews;
