import * as S from './styles';

type Props = {
  onContinue?: () => void;
  title: string;
}

const Welcome = ({ onContinue, title }: Props) => {
  return (
    <S.Container>
      <S.SmallGreyMoon />
      <S.HeaderImageTwo />
      <S.HeaderImageOne />
      <S.Content>
        <S.Title>Welcome to</S.Title>
        <S.LogoContainer>
          <S.CoralLogo />
        </S.LogoContainer>
        {title ?
          <S.Description>
            {title}
          </S.Description> :
          <>
            <S.Description>Congratulations on taking the first step towards a more satisfying intimate life and relationship.</S.Description>
            <S.Description>Let’s get started with a few questions to personalize your experience.</S.Description>
          </>
        }
      </S.Content>
      <S.Footer>
        <S.Button onClick={onContinue}>
          CONTINUE
        </S.Button>
      </S.Footer>
      <S.BigYellowMoon />
    </S.Container>
  );
}

export default Welcome;