import {gql} from '@apollo/client';

const GET_PARTNER = gql`
  query GetPartner {
    getPartner {
      partnerId
      name
      invitationId
      username
      accepted
      freeViaPartner
    }
  }
`;

const GET_INVITATION_CODE = gql`
  query GetInvitationCode {
    getInvitationCode
  }
`;

const GET_INVITATION_PRE_WRITTEN_MESSAGE = gql`
  query GetPartnerInvitationPreWrittenMessages {
    getPartnerInvitationPreWrittenMessages {
      name
      text
    }
  }
`;

const CHECK_INVITATION_STATE_QUERY = gql`
  query CheckInvitationState {
    checkInvitationState {
      invitationId
      isAccepted
    }
  }
`;

const GET_EXERCISE_ITINERARY_LIST = gql`
  query GetExerciseItineraryList {
    getExerciseItineraryList {
      id
      name
      description
      type
      hasAudio
      players
      data {
        time
      }
    }
  }
`;

const GET_EXERCISE_ITINERARY = gql`
  query GetExerciseItineraryUnits {
    getExerciseItineraryUnits {
      id
      name
      description
      type
      hasAudio
      players
      data {
        time
      }
    }
  }
`;

export default {
  GET_PARTNER,
  GET_INVITATION_CODE,
  GET_INVITATION_PRE_WRITTEN_MESSAGE,
  CHECK_INVITATION_STATE_QUERY,
  GET_EXERCISE_ITINERARY_LIST,
  GET_EXERCISE_ITINERARY,
};
