import styled from 'styled-components';

import Colors from 'assets/styles/Colors';

interface HeadingProps {
  type?: number
}


const FONT_SIZE = {
  1: '48px',
  2: '42px',
  3: '32px',
  4: '28px',
  5: '24px',
  6: '20px',
  7: '15px',
  8: '17px',
};

const LINE_HEIGHT = {
  1: '46px',
  2: '53px',
  3: '38px',
  4: '34px',
  5: '29px',
  6: '24px',
  7: '20px',
  8: '24px',
};

const LETTER_SPACING = '-0.04px';

const DEFAULT_COLOR = Colors.Black;

export const Heading = styled.div<HeadingProps>`
  font-family: 'GTSuper';
  font-size: ${({type}) => FONT_SIZE[type]};
  line-height: ${({type}) => LINE_HEIGHT[type]};
  letter-spacing: ${LETTER_SPACING};
  color: ${({color}) => color || DEFAULT_COLOR};
`;
