import React, { useEffect } from 'react';

import OnBoardingShareCodeForm from 'components/OnboardingShareCodeForm';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const PartnerSendCode = () => {
  const { height } = useDimensionsContext();

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.PARTNER_SEND_CODE);
  }, []);

  return (
    <S.Container2 height={height}>
      <S.Content>
        <S.ConnectImage />
        <OnBoardingShareCodeForm />
        <S.ConnectBgImageLayer1 />
        <S.ConnectBgImageLayer2 />
      </S.Content>
    </S.Container2>
  );
};

export default PartnerSendCode;
