import styled from 'styled-components';
import tw from 'twin.macro';

import Colors from 'assets/styles/Colors';

import CustomButton from 'components/base/Button';
import CustomHeading from 'components/base/Heading';
import CoralBlack from 'assets/images/coral-logo-black.png';
import WomanHandIPhone from 'assets/images/woman-hand-holding-i_phone.png';
import { ASSETS_PATH } from 'constants/assets';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${Colors.Coconut};
`;

export const Header = styled.div`
  width: 100%;
`;

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  left: -150px;
  width: 1584px;
  height: 207px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1500px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/collage-butt.png`,
}))`
  z-index: 0;
  position: absolute;
`;

export const HeaderImageThree = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/moon-small-blue.png`,
}))`
  z-index: 0;
  position: absolute;
  right: -51px;
  height: 96px;
  width: 96px;
  top: -32px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  top: 5rem;
`;

export const Logo = styled.img.attrs(() => ({
  src: CoralBlack,
}))`
  height: 65px;
  width: 60%;
  z-index: 10;
  margin: 0 auto 65px;
  object-fit: contain;
`;

export const WomanHandHoldIPhone = styled.img.attrs(() => ({
  // src: `${ASSETS_PATH}/coral-logo.png`,
  src: WomanHandIPhone,
}))`
  height: 62%;
  width: 88%;
  position: absolute;
  right: -15px;
  bottom: 0;
  object-fit: contain;
  object-position: right bottom;
`;

export const DescriptionContainer = styled.div`
  ${tw`mb-5`}
`;

export const Description = styled(CustomHeading).attrs(() => ({
  type: 4,
}))`
  font-family: 'Andes';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 156%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  text-align: center;
  z-index: 10;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
`;

export const ButtonsContainer = styled.div`
  padding: 0 16px;
`

export const Footer = styled.div`
padding: 0 16px;
position: absolute;
bottom: 2%;
z-index: 10;
width: 100%;
`;

export const CtaButton = styled(CustomButton).attrs({
  color: Colors.White,
  textColor: Colors.DeepBlue,
})`
  ${tw`z-10 mb-5`}
`;

export const Button = styled(CustomButton)`
  ${tw`z-10 mb-4`}
`;

export const LogInButton = styled(CustomButton).attrs(() => ({
  textColor: Colors.Black,
}))`
  margin-top: 8px;
  background-color: ${Colors.Transparent};
`;
