import styled from 'styled-components';
import tw from 'twin.macro';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  textColor: string;
}
export const Button = styled.button<ButtonProps>`
  font-family: 'Andes';
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  ${tw`flex flex-row justify-center items-center tracking-widest font-semibold mt-4 p-3.5 w-full rounded-full z-10`}
`;

export const LoaderContainer = styled.div`
  margin-right: 20px;
`;

export const IconContainer = styled.span`
  ${tw`mr-2.5 text-lg font-bold`}
`;