import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import { ASSETS_PATH } from 'constants/assets';
import Heading from 'components/base/Heading';
import tw from 'twin.macro';

const width = window.screen.width;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${Colors.Coconut};
`;

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  width: 1584px;
  height: 207px;
  left: -1325px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1584px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.div`
  position: absolute;
  top: 0;
  width: 1218.5px;
  height: 194px;
  left: -702px;
  background-image: url(${`${ASSETS_PATH}/pitch-header-second-background.png`});
  background-size: 1218px 194px;
  background-position: left;
  z-index: 0;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  height: 113px;
  width: 113px;
  left: 110px;
  top: 1px;
  border-radius: 0px;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: 113px 113px;
  background-position: top;
`;

export const Footer = styled.div`
  align-items: center;
`;

export const BigYellowMoon = styled.div`
  position: absolute;
  width: 571px;
  height: 593px;
  bottom: -400px;
  left: -${() => (571 - width) / 2}px;
  background-image: url(${`${ASSETS_PATH}/moon-big-yellow.png`});
  background-size: 571px 593px;
`;

export const BottomCenter = styled.div`
  position: absolute;
  width: 665px;
  height: 190px;
  z-index: 0;
  bottom: 0;
  background-image: url(${`${ASSETS_PATH}/onboarding-bottom.png`});
  background-size: 780px 400px;
  background-position: top;
  top: 50%;
  height: 544.11px;
  left: -200px;
  transform: rotate(6.56deg);

  @media screen and (min-width: 500px) {
    width: 100vh;
    left: -170px;
  }

  @media (min-width: 1000px) and (max-width: 2500px) {
    top: 54%;
    background-size: 780px 450px;
  }
`;

export const Title = styled(Heading).attrs({
  type: 1,
})`
  text-align: center;
  margin-top: 74px;
  padding: 30px 45px;
  font-weight: 500;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Black};
  margin: 20px;
`;