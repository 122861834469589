import styled from 'styled-components';
import tw from 'twin.macro';
import Colors from 'assets/styles/Colors';
import leftCommunityImg from 'assets/images/NewHome/left-communityImg.png';
import { Link } from 'react-router-dom';

export const LeftCommunityBG = styled.img.attrs(() => ({
  src: leftCommunityImg,
}))`
  position: absolute;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CommunityContainer = styled.div`
    margin-bottom: 2%;
    position: relative;
`;

export const CommunityTitle = styled.div`
  font-family: 'GTSuper';
  font-style: normal;
  font-weight: 500;
  font-size: 66px;
  line-height: 76px;
  text-align: center;
  letter-spacing: -1px;
  color: ${Colors.BlackTitle_02};
  margin: 4% 0;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
`;

export const Underline = styled.span`
  background: ${Colors.DeepBlue};
  display: block;
  height: 1px;
  margin: 15px auto;
  width: 300px;
  opacity: 0.5;

  @media screen and (max-width: 768px) {
    width: 200px;
  }
`;

export const StartButton = styled(Link)`
  ${tw`mb-4`}
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-left: 10px;
  background-color: ${Colors.Red};
  color: ${Colors.White};
  text-align: center;
  padding: 12px 20px;
  font-weight: bold;
  font-family: 'Andes';
  letter-spacing: 0.1em;

  @media (max-width: 500px) {
    margin-left: 0px;
    width: 100%
  } 
`;

export const DiscutionButton = styled(Link)`
  ${tw`mb-4`}
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: ${Colors.Black};
  color: ${Colors.White};
  text-align: center;
  padding: 12px 20px;
  font-weight: bold;
  font-family: 'Andes';
  letter-spacing: 0.1em;

  @media (max-width: 500px) {
    width: 100%
  } 
`;

export const CommunityButton = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 500px) {
    display: block;
    margin: 0 5%;
  } 
`;