import styled from 'styled-components';
import tw from 'twin.macro';
import FooterBGImg from 'assets/images/NewHome/FooterBG.png';
import Colors from 'assets/styles/Colors';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import CoralLogoWhite from 'assets/images/NewHome/white-logo.svg';
import { Link } from 'react-router-dom';

export const HomeFooterContainer = styled.div``;

export const FooterBGImage = styled.img.attrs(() => ({
  src: FooterBGImg,
}))`
  width: 100%;
  position: relative;
  bottom: -1px;
`;

export const FooterTextContainer = styled.div`
  padding: 2% 5%;
  background: ${Colors.DeepBlue};
`;

export const FooterImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SocialLink = styled.a.attrs({
  target: '_blank',
})``;

export const Twitter = styled(FaTwitter)`
  ${tw`mr-5 text-lg text-white`}
  font-size: 25px;

  &:hover {
    color: ${Colors.Red};
    cursor: pointer;
  }
`;

export const Facebook = styled(FaFacebook)`
  ${tw`mr-5 text-lg text-white`}
  font-size: 25px;

  &:hover {
    color: ${Colors.Red};
    cursor: pointer;
  }
`;

export const Instagram = styled(FaInstagram)`
  ${tw`mr-3 text-lg text-white`}
  font-size: 25px;

  &:hover {
    color: ${Colors.Red};
    cursor: pointer;
  }
`;

export const CoralLogoFooter = styled.img.attrs(() => ({
  src: CoralLogoWhite,
}))`
  margin: 3% auto;

  @media (max-width: 575px) {
    margin: 6% auto;
  }
`;

export const MenuName = styled.a`
  margin: 10px 20px;
  color: ${Colors.White};
  font-family: 'Andes';
  font-weight: 400;
  font-size: 20px;
  transition: 0.2s;

  @media (max-width: 575px) {
    margin: 0px 20px;
  }

  &:hover {
    color: ${Colors.Red};
    cursor: pointer;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 575px) {
    display: block;
    text-align: center;
    margin-bottom: 5%;
  }
`;

export const LastMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;

  @media (max-width: 575px) {
    display: block;
    text-align: center;
    margin-bottom: 5%;
  }
`;

export const LastMenuName = styled.a`
  margin: 10px 20px;
  color: ${Colors.White};
  font-family: 'Andes';
  font-weight: 400;
  font-size: 14px;
  transition: 0.2s;

  &:hover {
    color: ${Colors.Red};
    cursor: pointer;
  }
`;
