import { useHistory } from 'react-router';

import { useMutation, useQuery } from '@apollo/client';
import PartnersMutations from 'graphql/partner/partners.mutations';
import PartnersQueries from 'graphql/partner/partner.gql.queries';
import { GetInvitationCode } from 'graphql/partner/types/GetInvitationCode';
import { DEEPLINK_URL, shareCodeText, showErrorToast } from 'helpers';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import * as MyRoutes from 'constants/Routes';

import * as S from './styles';

const OnboardingShareCodeForm = () => {
  const history = useHistory();

  const handleGoToNext = async () => {
     history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_TRAUMA);
  };

  const [sendInvitation, { loading: sendLoading }] = useMutation(
    PartnersMutations.INVITE_PARTNER,
  );

  const { data, loading } = useQuery<GetInvitationCode>(
    PartnersQueries.GET_INVITATION_CODE,
  );

  const code = data?.getInvitationCode;

  const sendInvite = async () => {
    if (code !== '') {
      const dataForSend = {
        invitationAddress: code,
        invitationMode: 'code',
      };

      await sendInvitation({
        variables: {
          partnerInvitation: dataForSend,
        },
        refetchQueries: [
          {
            query: PartnersQueries.CHECK_INVITATION_STATE_QUERY,
          },
        ],
        awaitRefetchQueries: true,
      });
    }
  };

  const sendText = async () => {
    try {
      const shareLink = `${DEEPLINK_URL}partner/${code}`;
      const messageToShare = shareCodeText(code, shareLink);

      navigator.share({
        title: '',
        text: messageToShare,
      });

      await sendInvite();
      analytics.track(ANALYTICS_EVENT_NAMES.PARTNER_INVITED, {
        from: 'web-reg',
      });
      
      handleGoToNext();
    } catch (error) {
      showErrorToast('Oooos', 'Something went wrong');
      console.log(error);
    }
  };

  const textToDisplay = {
    subTitle: 'Enjoy deeper intimacy with your\npartner in just 7 days',
    redBoxText: 'Your partner joins Coral',
    firstText: 'Invite your partner\nto connect accounts',
    secondText:
      'Receive exercises and\nconversation starters to\ndo with your partner',
    thirdText: 'Learn more about\nyour lover everyday',
  };
  textToDisplay.subTitle =
    'Fun exercises and conversation starters\nto bring you closer in just 7 days.';
  textToDisplay.redBoxText = 'Invite your partner to connect accounts';
  textToDisplay.firstText = 'Learn what your partner\nloves about your body';
  textToDisplay.secondText = 'Explore what makes\nyou feel sexy';
  textToDisplay.thirdText =
    'Create the perfect\nlove nest for\nplaytime together IRL';

  return (
    <>
      <S.Container>
        <S.CloseButton onClick={handleGoToNext}>
          <S.CloseIcon />
        </S.CloseButton>
        <S.Title>Connect</S.Title>
        <S.Paragraph>{textToDisplay.subTitle}</S.Paragraph>
        <S.RedBackground onClick={sendText}>
          <S.TextInRedContainer>
            {textToDisplay.redBoxText}
            <S.TextInRedContainerBold>{' for free!'}</S.TextInRedContainerBold>
          </S.TextInRedContainer>
        </S.RedBackground>
        <S.Spacer />
        <S.TextInList>{textToDisplay.firstText}</S.TextInList>
        <S.PaleCircle />
        <S.TextInList>{textToDisplay.secondText}</S.TextInList>
        <S.PaleCircle />
        <S.TextInList>{textToDisplay.thirdText}</S.TextInList>
        <S.Spacer />
      </S.Container>
      <S.ConnectButton loading={loading || sendLoading} onClick={sendText}>
        SHARE CODE
      </S.ConnectButton>
    </>
  );
};

export default OnboardingShareCodeForm;
