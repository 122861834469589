import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';

const costSelections = [1, 9, 14];

const TrialPaymentSelection = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const [selectedCost, setSelectedCost] = useState<number | undefined>();

  const onPressContinue = () => {
    history.push(MyRoutes.ONBOARDING_SUGGESTED_JOURNEYS);
  };

  const onSelectItem = (cost: number) => {
    setSelectedCost(cost);
  };

  return (
    <S.Container height={height}>
      <S.TrialPaymentSelectionTopBackground />

      <S.Content style={{ marginTop: 100 }}>
        <S.Heading type={5}>Try Coral for 7 days</S.Heading>
        <S.TrialPaymentSelectionContent>
          <S.TrialPaymentSelectionDescription>
            Money shouldn’t get in the way of improving your intimacy!
          </S.TrialPaymentSelectionDescription>
          <S.TrialPaymentSelectionDescription>
            It costs us approximately $9 to offer a 7 day trial. Please pick an
            amount that’s reasonable for you.
          </S.TrialPaymentSelectionDescription>
        </S.TrialPaymentSelectionContent>
      </S.Content>
      <S.TrialPaymentSelectionContainer>
        <S.TrialPaymentSelectionRow>
          {costSelections.map((cost) => {
            const isSelected = selectedCost == cost;
            return (
              <S.TrialPaymentSelectionItem
                isSelected={isSelected}
                onClick={() => onSelectItem(cost)}>
                ${cost}
              </S.TrialPaymentSelectionItem>
            );
          })}
        </S.TrialPaymentSelectionRow>
        <S.TrialPaymentTooltipContainer>
          <S.TrialPaymentTooltipContent>
            This option helps us support those who pick $1!
          </S.TrialPaymentTooltipContent>
          <S.TrialArrowCurved />
        </S.TrialPaymentTooltipContainer>
      </S.TrialPaymentSelectionContainer>
      <S.Footer>
        <S.Button onClick={onPressContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.BottomTrialPaymentBackground />
    </S.Container>
  );
};

export default TrialPaymentSelection;
