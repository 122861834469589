import { useEffect, useState } from 'react';

import { useParamsContext } from 'context/params';
import {
  calculateValueWithDiscount,
  getMonthPriceCalculated,
} from 'helpers/purchase-helper';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import {
  amountToPrice,
  getStorageItem,
  getStripePrice,
  KEY_PROMOTION_CODE,
  STRIPE_SUBSCRIPTION_PRICE_ID
} from 'helpers';
import { PriceType, SubscriptionPackageType } from 'types/stripe-types';

import * as S from './styles';

export enum SubscriptionMode {
  Single = 'single',
  Connected = 'connected',
  SingleAndroidVariation = 'SingleAndroidVariation',
}

type Props = {
  mode: SubscriptionMode;
  onCompletePurchase: () => void;
  onSkip?: () => void;
  openedFrom?: 'onboarding' | 'upgrade';
};

const SubscriptionPrompt = ({
  mode,
  onCompletePurchase,
  onSkip,
  openedFrom,
}: Props) => {
  const [subscriptionPackage, setSubscriptionPackage] =
    useState<SubscriptionPackageType>({
      price: 0,
      currency: 'USD',
    });

  const { params } = useParamsContext();

  let propertiesToTrack = {
    context: openedFrom,
    price: 59.99,
    currency: 'USD'
  };

  useEffect(() => {
    const initStripePrice = async () => {
      const promotionCode = getStorageItem(KEY_PROMOTION_CODE);
      const priceId = STRIPE_SUBSCRIPTION_PRICE_ID;
      
      const stripePrice: PriceType = await getStripePrice(priceId);

      // price.unit_amount from Stripe doesn't have decimal. Ex. $19.99 is 1999
      // amountToPrice() fixes this.
      const priceAmount = amountToPrice(stripePrice.unit_amount);
  
      const withDiscount = calculateValueWithDiscount(
        priceAmount,
        promotionCode?.coupon?.percent_off ?? null,
        promotionCode?.coupon?.amount_off ?? null,
      );
  
      setSubscriptionPackage({
        price: priceAmount,
        withDiscount,
        currency: '$',
      });

      propertiesToTrack.price = priceAmount;
  
      analytics.page(ANALYTICS_EVENT_NAMES.TRIAL_START, propertiesToTrack);
    };

    initStripePrice();
  }, []);

  const handleSkip = () => {
    if (onSkip) {
      analytics.track(ANALYTICS_EVENT_NAMES.TRIAL_SKIPPED, propertiesToTrack);
      onSkip();
    }
  };

  const onPressSubscribe = () => {
    analytics.track(ANALYTICS_EVENT_NAMES.TRIAL_START_CLICKED, {
      ...propertiesToTrack,
      ...params,
    });
    onCompletePurchase();
  };

  return (
    <S.Container>
      <S.Content>
        {onSkip && (
          <S.CloseButton onClick={handleSkip}>
            <S.CloseIcon />
          </S.CloseButton>
        )}
        <S.TrialTitle>How your free trial works</S.TrialTitle>
        <S.HowWorksWrapper>
          <S.TimelineImage />
          <S.HowWorksItems>
            <S.HowWorksItem>
              <S.HowWorksItemTitle>Today</S.HowWorksItemTitle>
              <S.HowWorksItemText>
                Access unlocked Coral to enhance your sexual wellbeing.
              </S.HowWorksItemText>
            </S.HowWorksItem>
            <S.HowWorksItem>
              <S.HowWorksItemTitle>On Day 5</S.HowWorksItemTitle>
              <S.HowWorksItemText>
                Get a reminder about when your free trial will end.
              </S.HowWorksItemText>
            </S.HowWorksItem>
            <S.HowWorksItem>
              <S.HowWorksItemTitle>On Day 7</S.HowWorksItemTitle>
              <S.HowWorksItemText>
                You'll be charged today, cancel anytime before.
              </S.HowWorksItemText>
            </S.HowWorksItem>
          </S.HowWorksItems>
        </S.HowWorksWrapper>
      </S.Content>
      <S.Footer>
        <S.TrialPeriodText>7-day free trial, then</S.TrialPeriodText>
        <S.PriceText>
          <strong>
            {getMonthPriceCalculated({
              productPrice:
                subscriptionPackage.withDiscount ?? subscriptionPackage.price,
            })}
            /mo
          </strong>
          {` Billed Annually`}
        </S.PriceText>
        <S.PremiumForPartnerText>
          Free premium for your partner
        </S.PremiumForPartnerText>
        <S.StartButton onClick={onPressSubscribe}>
          Start your 7-day free trial
        </S.StartButton>
      </S.Footer>
      <S.FooterImageBackground />
    </S.Container>
  );
};

export default SubscriptionPrompt;
