import {
  formatDistanceStrict,
  differenceInMonths,
  differenceInDays,
  format,
  parseISO,
  set,
  add,
  getHours,
  getTime,
  isBefore,
} from 'date-fns';

const MILESTONE_FOR_CHAT_FIRST = '2022-04-28T05:00:00.000Z';

export const getDateDistanceFrom = (dateStart: Date) =>
  formatDistanceStrict(new Date(Number(dateStart)), new Date());

export const getDifferenceInMonths = (dateStart: Date) =>
  differenceInMonths(new Date(), new Date(dateStart));

export const getDifferenceInDays = (dateStart: Date) =>
  differenceInDays(new Date(), new Date(dateStart));

export const getISODateFormated = (ISODate: string, dateFormat: string) =>
  format(parseISO(ISODate), dateFormat);

export const getFormatedDate = (date: string, dateFormat: string) =>
  format(new Date(date), dateFormat);

export const getScheduledEventTimeSlot = (eventDuration: number) => {
  const desiredEventTime = set(new Date(), {
    hours: 21,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  // We want to schedule the event at 9pm on the same day if current time is before 7pm
  // If it's after 7pm then schedule it at 9pm for the next day.
  const eventStartTime =
    getHours(new Date()) >= 19
      ? add(desiredEventTime, { days: 1 })
      : desiredEventTime;
  const eventEndTime = add(eventStartTime, { minutes: eventDuration || 30 });
  return {
    eventStartTime: eventStartTime.toISOString(),
    eventEndTime: eventEndTime.toISOString(),
  };
};

/**
 * Compares two dates
 * @param registrationDate The expected older date
 * @param milestoneDate The expected newer date
 * @returns true if first timestamp is older than the second one
 */
export const isAfterChatFirstMilestone = (registrationDate?: string) => {
  return (
    getTime(registrationDate ? new Date(registrationDate) : new Date()) >
    getTime(new Date(MILESTONE_FOR_CHAT_FIRST))
  );
};

export const isDateBefore = (date: string) => {
  return isBefore(new Date(), new Date(date));
};
