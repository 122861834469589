import StarCircle from 'assets/icons/StarCircle';
import * as S from './styles';

type Props = {
    forumTitle: string;
};

const ExpertLabel = ({ forumTitle }: Props) => {
    if (forumTitle !== 'expert') {
        return <></>;
    }

    return <S.Container><StarCircle /></S.Container>
};

export default ExpertLabel;
