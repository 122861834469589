import { useState } from 'react';

import analytics from 'helpers/analytics-helper';
import {QuestionType, OptionType} from '../types';

import * as S from './styles';

type Props = {
  question: QuestionType;
  onContinue?: () => void;
}

const Question = ({question, onContinue}: Props) => {
  const [selectedOption, setSelectedOption] = useState<OptionType>();

  const handleOptionClicked = (option: OptionType) => {
    analytics.page(`Versioned OB ${question.name}`, {
      answer: option.value
    });

    question.showFeedback
        ? setSelectedOption(option)
        : handleContinue();

  };

  const handleContinue = () => {
    setSelectedOption(null);
    onContinue();
  }

  return (
    <S.Container>
      <S.SmallGreyMoon />
      <S.HeaderImageTwo />
      <S.HeaderImageOne />
      {selectedOption ? (
        <>
          <S.Content>
            <S.QuestionTitle>{question.title}</S.QuestionTitle>
              <S.QuestionOptionSelected>
                {selectedOption.text}
              </S.QuestionOptionSelected>
              <S.QuestionComment>
                {selectedOption.comment}
              </S.QuestionComment>
          </S.Content>
          {question.showFeedback && (
            <S.Footer>
              <S.Button onClick={handleContinue}>
                CONTINUE
              </S.Button>
            </S.Footer>
          )}
        </>
      ) : (
        <S.Content>
          <S.QuestionTitle>{question.title}</S.QuestionTitle>
          {question.description && <S.QuestionDescription>{question.description}</S.QuestionDescription>}
          <S.QuestionOptionsContainer>
            {question.options.map((option, idx) => (
              <S.QuestionOption key={idx} onClick={() => handleOptionClicked(option)}>
                {option.text}
              </S.QuestionOption>
            ))}
          </S.QuestionOptionsContainer>
        </S.Content>
      )}
      <S.BigYellowMoon />
    </S.Container>
  );
}

export default Question;