import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import { ASSETS_PATH } from 'constants/assets';
import BodyBackGround from 'assets/images/mockups-iphone.png';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${Colors.Coconut};
`;

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  width: 1584px;
  height: 207px;
  left: -900px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1500px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.div`
  position: absolute;
  top: 0;
  width: 1218px;
  height: 194px;
  left: -352px;
  background-image: url(${`${ASSETS_PATH}/pitch-header-second-background.png`});
  background-size: 1218px 194px;
  background-position: left;
  z-index: 0;
`;

export const CollageMan = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/collage-man.png`,
}))`
  position: absolute;
  height: 468px;
  width: 498px;
  top: -308px;
`;

export const BigYellowMoon = styled.div`
  position: absolute;
  width: 571px;
  height: 593px;
  bottom: -530px;
  right: -330px;
  background-image: url(${`${ASSETS_PATH}/moon-big-yellow.png`});
  background-size: 571px 593px;
  background-position: top;
  z-index: 1;
`;

export const OrangeCircle = styled.div`
  position: absolute;
  height: 266px;
  width: 300px;
  left: -200px;
  bottom: -160px;
  border-radius: 266px;
  background-color: ${Colors.Skin03};
  z-index: 1;
`;
