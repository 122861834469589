import {useState, useEffect} from 'react';
import { useHistory} from 'react-router';

import { useMutation } from '@apollo/client';

import onBoardingMutations from 'graphql/onBoarding/onBoarding.mutations';
import { useFirebaseAuthContext } from 'context/auth';
import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';

import {
  useOnboardingDesireTypeContext,
  DesireType,
  DesireTypeSteps,
} from 'context/onboarding-desire-type';

import * as MyRoutes from 'constants/Routes';
import { DESIRE_TYPES_IAR, TOTAL_STEPS } from 'constants/onboarding';
import desireTypeData, {
  descriptions as descriptionsIAR,
  MOOD_ANSWERS,
} from 'constants/desire-types-iar.mock';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';
/*
IDs based on this comment:
https://getcoral.atlassian.net/browse/PHOEN-588?focusedCommentId=13611

Trait Name
LowDesire -> MORE_EFFORT
AvgDesire -> ABOUT_EFFORT
HighDesire -> LESS_EFFORT
*/
const OPTION_VALUE_ID = {
  [MOOD_ANSWERS.LESS_EFFORT]: 17,
  [MOOD_ANSWERS.MORE_EFFORT]: 15,
  [MOOD_ANSWERS.ABOUT_EFFORT]: 16,
};

const OPTION_CATEGORY = 'Desire Level';

const OnboardingDesireTypeMood = () => {
  const history = useHistory();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const { setUserDesireType, steps } = useOnboardingDesireTypeContext();
  const { height } = useDimensionsContext();
  const [loadingTrait, setTrait] = useState<null | number>(null);
  const { storeUserTraitsLocally } = useOnboardingTraitsContext();
  const { isAuthenticated } = useFirebaseAuthContext();
  const desireMood = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES_IAR.DESIRE_MOOD,
  );

  const { featureVariant } = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  });

  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;

  const [setUserTraitsOnBoarding, { loading: settingTrait }] = useMutation(
    onBoardingMutations.SET_USER_TRAITS_ON_BOARDING,
  );

  const handleSelectValue = async (value: DesireType) => {
    setUserDesireType(DesireTypeSteps.DesireMood, value);

    if (!isAuthenticated) {
      storeUserTraitsLocally({ id: OPTION_VALUE_ID[value.name], groupName: OPTION_CATEGORY, name: value.name });

    }else {
      await setUserTraitsOnBoarding({
        variables: {
          traitId: OPTION_VALUE_ID[value.name],
          traitCategory: OPTION_CATEGORY,
        },
      });
    }

    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD}?${getAllParamsString()}`) :
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_IAR_MOOD);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={11} total={TOTAL_STEPS} />
        <S.Answer
          text="I initiate"
          selectedOption={steps?.Initiative?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(
              MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_INITIATIVE,
            )
          }
        />
        <S.DescriptionLeft>
          {descriptionsIAR[DESIRE_TYPES_IAR.DESIRE_MOOD]}
        </S.DescriptionLeft>
        <S.LeftHeading>Getting in the mood</S.LeftHeading>
        <OnBoardingOptions>
          {desireMood?.map((value) => (
            <OnBoardingOption
              key={value.id}
              text={value.name}
              color={Colors.PaleCoral}
              onClick={() => handleSelectValue(value)}
              loading={settingTrait && loadingTrait === value.id}
            />
          ))}
        </OnBoardingOptions>
      </S.TopContent>
      <S.BottomImage10 />
    </S.Container>
  );
};

export default OnboardingDesireTypeMood;
