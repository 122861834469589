import styled from 'styled-components';
import tw from 'twin.macro';
import { FaStar } from 'react-icons/fa';
import Colors from 'assets/styles/Colors';

export const TestimonialContainer = styled.div`
  background-color: ${Colors.Skin01};
  position: relative; 
`;

export const TestimonialTitle = styled.div`
  font-family: 'GTSuper';
  font-weight: 500;
  font-size: 38px;
  margin: 5% 32% 3% 32%;

  @media (max-width: 1200px) {
    margin: 5% 20%;
  }; 

  @media (max-width: 768px) {
    font-size: 24px;
    margin: 10% 5%;
  }; 

  @media (max-width: 400px) {
    margin-bottom: 20%
  }; 
`;

export const TestimonialName = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 15px;

  @media (max-width: 768px) {
    font-size: 14px;
  }; 
`;

export const TestimonialTextContainer = styled.div`
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center; 
`;

export const TestimonialImageContainer = styled.div`
  padding-top: 1%;
  padding-bottom: 3%;
  text-align: center;

  @media (max-width: 768px) {
    margin: 5px 0;
  }; 
`;


export const TestimonilaLogo = styled.img.attrs(() => ({
  resizeMode: 'contain',
}))`
  margin: 15px 30px;
  width: 160px;
  display: inline-block;
`;

export const ReviewText = styled.div`
  font-family: 'Andes';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-left: auto;
  margin-right: 10px;
`;

export const StarContainer = styled.div`
  display: flex;

  @media (max-width: 500px) {
    justify-content: center;
    bottom: 0px;
    margin: 10px 0;
  }; 
`;

export const StarIcon = styled(FaStar)`
  ${tw`mr-2 text-lg text-red-400`}
  font-size: 25px;
`;

export const TestimonialCount = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: auto
`;