import {FC, useState, useEffect} from 'react';
import {useMutation} from '@apollo/client';
import {useHistory} from 'react-router';

import StripeForm from 'components/StripeForm';
import {StripeProvider} from 'context/Stripe';
import * as MyRoutes from 'constants/Routes';
import {amountToPrice, showErrorToast} from 'helpers/utility-helper';

import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import {
  CREATE_STRIPE_SUBSCRIPTION,
  CREATE_STRIPE_SETUP_INTENT,
  CREATE_STRIPE_SETUP_INTENT_DEV,
  CREATE_STRIPE_SUBSCRIPTION_DEV
} from 'graphql/subscription/subscription.mutations';
import {KEY_PROMOTION_CODE, STRIPE_SUBSCRIPTION_PRICE_ID} from 'helpers/constants-helper';
import {
  calculateValueWithDiscount,
  getMonthPriceCalculated,
} from 'helpers/purchase-helper';

import {getStorageItem, getStripePrice} from 'helpers';
import {PromotionCode} from 'types/subscription-types';

import useFlagsmith from 'common/hooks/useFlagsmith';
import {FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE} from 'constants/feature-flags';
import {PriceType, SubscriptionPackageType} from 'types/stripe-types';

import * as S from './styles';

const Subscription: FC = () => {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [promotionCode, setPromotionCode] = useState<string>(null);
  const [subscriptionPackage, setSubscriptionPackage] =
    useState<SubscriptionPackageType>({
      price: 0,
      currency: 'USD',
    });
  const history = useHistory();
  const [planPriceInfo, setPlanPriceInfo] = useState<PriceType>();

  const [createStripeSubscription] = useMutation(
    CREATE_STRIPE_SUBSCRIPTION,
  );

  const [createStripeSetupIntent, {data: createdSetupIntent}] = useMutation(
    CREATE_STRIPE_SETUP_INTENT,
  );

  const initStripePrice = async () => {
    const promotionCode = getStorageItem(KEY_PROMOTION_CODE);
    const priceId = STRIPE_SUBSCRIPTION_PRICE_ID;

    const stripePrice: PriceType = await getStripePrice(priceId);

    setPlanPriceInfo(stripePrice);

    // price.unit_amount from Stripe doesn't have decimal. Ex. $19.99 is 1999
    // amountToPrice() fixes this.
    const priceAmount = amountToPrice(stripePrice.unit_amount);

    const withDiscount = calculateValueWithDiscount(
      priceAmount,
      promotionCode?.coupon?.percent_off ?? null,
      promotionCode?.coupon?.amount_off ?? null,
    );

    setSubscriptionPackage({
      price: priceAmount,
      withDiscount,
      currency: '$',
      hasDiscount: priceAmount > withDiscount,
    });
  };

  useEffect(() => {
    initStripePrice();
  }, []);

  useEffect(() => {
    getPromotionCode();
    createStripeSetupIntent();
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.SUBSCRIBE);
  }, []);

  const getPromotionCode = () => {
    const promotionCodeData: PromotionCode = getStorageItem(KEY_PROMOTION_CODE);

    if (promotionCodeData?.coupon?.valid) {
      setPromotionCode(promotionCodeData?.id);
    }
  };

  const createSubscription = async (priceId: string, paymentMethod: string) => {
    try {
      await createStripeSubscription({
        variables: {
          priceId,
          paymentMethod,
          promotionCode,
        },
      });
    } catch (error) {
      setIsSubscribing(false)
      showErrorToast(
        'Subscription failure',
        'Your subscription failed. Please try again.',
      );
    }
  };

  const onSubscriptionSuccess = async (paymentMethod: string, paymentMethodType: string) => {
    try {
      analytics.track(ANALYTICS_EVENT_NAMES.TRIAL_STARTED, {
        paymentMethod: paymentMethodType
      });

      setIsSubscribing(true);
      await createSubscription(STRIPE_SUBSCRIPTION_PRICE_ID, paymentMethod);
      window.fbq('track', 'StartTrial');
      history.push(MyRoutes.CONFIRMATION, {
        isSubscribed: true,
      });
    } catch (error) {
    }
  };

  const onSubscriptionFailure = () => {
    showErrorToast('Payment failure', 'Your payment failed. Please try again.');
  };


  const {featureVariant: featureOBLVariant} = useFlagsmith({featureName: FEATURE_FLAG_NAME.WR_DISCOUNT_BANNER});

  const isShowDiscount = featureOBLVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_A;
  const isShowNewDiscount = featureOBLVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_B;

  return (
    <S.Container >
      <S.CurvelHeader />
      <S.HeaderContainer>
        <S.Heading>FREE PREMIUM FOR YOUR PARTNER! 2 for 1 pricing</S.Heading>
        <S.RedLineImage />
        <S.TextLogo />
      </S.HeaderContainer>
      <S.Content>
        <S.PaymentBox>
          <S.PriceInfo>
            <S.PriceInfoRow>
              <S.PriceInfoLabelBold>Total due today*</S.PriceInfoLabelBold>
              <S.PriceInfoValueBold>USD 0.00</S.PriceInfoValueBold>
            </S.PriceInfoRow>
            {!subscriptionPackage.hasDiscount && (isShowDiscount || isShowNewDiscount) ?
              <S.DiscountInfoRow>
                <S.DiscountInfoLabel>Code CORAL25 applied!</S.DiscountInfoLabel>
                <S.DiscountInfoValue>USD 75.00</S.DiscountInfoValue>
              </S.DiscountInfoRow> : ''
            }
            {!subscriptionPackage.hasDiscount ?
              (
                <S.PriceInfoRow>
                  <S.PriceInfoLabel>Cost per year after trial</S.PriceInfoLabel>
                  <S.PriceInfoValueNewText>
                    {
                      getMonthPriceCalculated({
                        productPrice:
                          subscriptionPackage.withDiscount ?? subscriptionPackage.price,
                      })
                    }/Month billed annually
                  </S.PriceInfoValueNewText>
                </S.PriceInfoRow>
              ) : (
                <>
                  <S.PriceInfoRow>
                    <S.PriceInfoLabel>Cost per month</S.PriceInfoLabel>
                    <S.PriceInfoValue>USD {
                      getMonthPriceCalculated({
                        productPrice:
                          subscriptionPackage.withDiscount ?? subscriptionPackage.price,
                        showCurrencyCode: false
                      })
                    }.00</S.PriceInfoValue>
                  </S.PriceInfoRow>
                  <S.PriceInfoText>
                    <S.PriceInfoLabelSmall>*USD {
                      subscriptionPackage.withDiscount ?? subscriptionPackage.price
                    } annually after trial</S.PriceInfoLabelSmall>
                  </S.PriceInfoText>
                </>
              )
            }
          </S.PriceInfo>
          {Boolean(createdSetupIntent) && (
            <S.SubscriptionMethods>
              <StripeProvider
                clientSecret={createdSetupIntent?.setupStripeIntent.clientSecret}>
                <StripeForm
                  onSuccess={onSubscriptionSuccess}
                  onFailure={onSubscriptionFailure}
                  isSubscribing={isSubscribing}
                />
              </StripeProvider>
            </S.SubscriptionMethods>
          )}
        </S.PaymentBox>
        <S.BigGreyMoon />
        <S.SmallGreyMoon />
      </S.Content>
    </S.Container>
  );
};

export default Subscription;
