import { QUIZ } from "constants/onboarding";

export const QUIZZES_SCREENS = {
  [QUIZ.INCREASE_MY_SEX_DRIVE]: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: 'Congratulations on taking the first step towards a more satisfying intimate life and relationship.',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'Jenny',
      text: `“When I want to get in the mood, I open Coral. I’m actually initating sex with my husband again.”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'What is currently impacting your sex drive?',
      showFeedback: true,
      options: [
        {
          text: 'ANXIOUS THOUGHTS',
          value: 1,
          comment: `We understand. 
  
          Coral helps you create the space to meet your goals.
          `,  
        },
        {
          text: 'BUSY SCHEDULE',
          value: 2,
          comment: `We understand. 
  
          Coral helps you create the space to meet your goals.
          `,  
        },
        {
          text: 'RELATIONSHIP ISSUES',
          value: 3,
          comment: `We understand. 
  
          Coral helps you create the space to meet your goals.
          `,  
        },
        {
          text: 'FAMILY RESPONSIBILITIES',
          value: 4,
          comment: `We understand. 
  
          Coral helps you create the space to meet your goals.
          `,  
        },
        {
          text: 'WORK STRESS',
          value: 5,
          comment: `We understand. 
  
          Coral helps you create the space to meet your goals.
          `,  
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'How do you feel about your sexual frequency?',
      description: `Desire levels will naturally change over time and things like stress and anxiety will affect how much you want to get busy in the bedroom on any given day.`,
      showFeedback: true,
      options: [
        {
          text: 'IT’S A LOT LESS THAN I’D PREFER',
          value: 1,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'IT’S ABOUT AS MUCH AS I’D PREFER',
          value: 2,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'IT’S MUCH MORE THAN I’D PREFER',
          value: 2,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What type of content are you interested in?',
      showFeedback: false,
      options: [
        {
          text: 'TIPS FOR INCREASING SEX DRIVE',
          value: 1,
        },
        {
          text: 'SOLO EXERCISES TO GET IN THE MOOD',
          value: 2,
        },
        {
          text: 'HELP GETTING OUT OF YOUR HEAD',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'Jade',
      text: `“Coral’s meditations help me get out of my head so I can actually get into the headspace to get in the mood”`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
  [QUIZ.TRY_NEW_THINGS_IN_BED]: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: 'Congratulations on taking the first step towards a more satisfying intimate life and relationship.',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'Janet',
      text: `“Our sex life used to be predictable. Now we’re trying something new every week!”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'How often do you like to try new things in bed?',
      showFeedback: true,
      options: [
        {
          text: 'ALL THE TIME',
          value: 1,
          comment: `Love it!
  
          We like trying new things, too. Coral has hundreds of expert-written, guided exercises...and we’re adding more every week. You’ll have plenty of options to explore solo or with your partner!
          `,
        },
        {
          text: 'WHEN THE MOOD STRIKES',
          value: 2,
          comment: `Love it!
  
          We like trying new things, too. Coral has hundreds of expert-written, guided exercises...and we’re adding more every week. You’ll have plenty of options to explore solo or with your partner!
          `,
        },
        {
          text: 'ON A SPECIAL OCCASION',
          value: 3,
          comment: `Love it!
  
          We like trying new things, too. Coral has hundreds of expert-written, guided exercises...and we’re adding more every week. You’ll have plenty of options to explore solo or with your partner!
          `,
        },
      ],
      
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'How do you feel about your sexual frequency?',
      description: `Desire levels will naturally change over time and things like stress and anxiety will affect how much you want to get busy in the bedroom on any given day.`,
      showFeedback: true,
      options: [
        {
          text: 'IT’S A LOT LESS THAN I’D PREFER',
          value: 1,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'IT’S ABOUT AS MUCH AS I’D PREFER',
          value: 2,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'IT’S MUCH MORE THAN I’D PREFER',
          value: 2,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What type of content are you most interested in?',
      showFeedback: false,
      options: [
        {
          text: 'TIPS FOR INITIATING',
          value: 1,
        },
        {
          text: 'SHARING FANTASIES',
          value: 2,
        },
        {
          text: 'IDEAS FOR WHAT TO TRY NEXT',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'June',
      text: `“After 4 years together I thought we knew what worked. Coral helped us explore new frontiers of pleasure.”`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
  [QUIZ.IMPROVE_MY_PERFORMANCE]: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: 'Congratulations on taking the first step towards a more satisfying intimate life and relationship.',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'Jeff',
      text: `“The confidence this app has given me is a gamechanger. My wife noticed the difference immediately.”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'What do you think is currently impacting your performance?',
      showFeedback: true,
      options: [
        {
          text: 'ANXIOUS THOUGHTS',
          value: 1,
          comment: `We understand. 

          Anxious Thoughts are a common obstacle to sexual performance, which is why Coral offers science-based content and guided exercises to help improve your performance.
          `,  
        },
        {
          text: 'RELATIONSHIP ISSUES',
          value: 2,
          comment: `We understand. 

          Relationship Issues are a common obstacle to sexual performance, which is why Coral offers science-based content and guided exercises to help improve your performance.
          `,  
        },
        {
          text: 'FAMILY RESPONSIBILITIES',
          value: 3,
          comment: `We understand. 

          Family Responsibilities are a common obstacle to sexual performance, which is why Coral offers science-based content and guided exercises to help improve your performance.
          `,
        },
        {
          text: 'WORK STRESS',
          value: 4,
          comment: `We understand. 

          Work Stress is a common obstacle to sexual performance, which is why Coral offers science-based content and guided exercises to help improve your performance.
          `,
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'How do you feel about your sexual frequency?',
      description: `Desire levels will naturally change over time and things like stress and anxiety will affect how much you want to get busy in the bedroom on any given day.`,
      showFeedback: true,
      options: [
        {
          text: 'IT’S A LOT LESS THAN I’D PREFER',
          value: 1,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'IT’S ABOUT AS MUCH AS I’D PREFER',
          value: 2,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'IT’S MUCH MORE THAN I’D PREFER',
          value: 2,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What’s your goal for improving performance?',
      showFeedback: true,
      options: [
        {
          text: 'FEEL MORE PLEASURE',
          value: 1,
          comment: 'More pleasure is always better! We can definitely help you with that.'
        },
        {
          text: 'GIVE PLEASURE BETTER',
          value: 2,
          comment: 'More pleasure is always better! We can definitely help you with that.'
        },
        {
          text: 'BOTH',
          value: 3,
          comment: 'More pleasure is always better! We can definitely help you with that.'
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q4',
      title: 'What type of content are you interested in?',
      showFeedback: false,
      options: [
        {
          text: 'ERECTILE FUNCTION',
          value: 1,
        },
        {
          text: 'SEXUAL SKILLS',
          value: 2,
        },
        {
          text: 'INTIMATE COMMUNICATION',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'Kelly',
      text: `“I used to fake it. Now that we use Coral I don’t have to anymore.”`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
  [QUIZ.RECONNECT_WITH_MY_PARTNER]: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: 'Congratulations on taking the first step towards a more satisfying intimate life and relationship.',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'Jeff',
      text: `“This app has helped facilitate connection between my partner and I. We’re communicating and practicing intimacy in a way that we haven’t done for a long time”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'What do you think is currently impacting your connection with your partner?',
      showFeedback: true,
      options: [
        {
          text: 'ANXIOUS THOUGHTS',
          value: 1,
          comment: `We understand. 

          Anxious Thoughts are a common obstacle to sexual wellness, which is why Coral offers a private partner chat to remove obstacles to connection and help you feel close again.
          `,  
        },
        {
          text: 'BUSY SCHEDULE',
          value: 2,
          comment: `We understand. 

          Busy Schedule is a common obstacle to sexual wellness, which is why Coral offers a private partner chat to remove obstacles to connection and help you feel close again.
          `,  
        },
        {
          text: 'FAMILY RESPONSIBILITIES',
          value: 3,
          comment: `We understand. 

          Family Responsibilities are a common obstacle to sexual wellness, which is why Coral offers a private partner chat to remove obstacles to connection and help you feel close again.
          `,
        },
        {
          text: 'WORK STRESS',
          value: 4,
          comment: `We understand. 

          Work Stress is a common obstacle to sexual wellness, which is why Coral offers a private partner chat to remove obstacles to connection and help you feel close again.
          `,
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'Do your partner experience responsive or spontaneous desire?',
      description: `Spontaneous desire is like a light switch and can appear out of nowhere.

      Responsive desire appears gradually through arousal. Sometimes it takes more effort to get in the mood. 
      `,
      showFeedback: true,
      options: [
        {
          text: 'SPONTANEOUS',
          value: 1,
          comment: 'Got it! We’ll make recommendations with that in mind.',
        },
        {
          text: 'RESPONSIVE',
          value: 2,
          comment: 'Got it! We’ll make recommendations with that in mind.',
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What type of content are you interested in?',
      showFeedback: false,
      options: [
        {
          text: 'EYE GAZING FOR CONNECTION',
          value: 1,
        },
        {
          text: 'STOKE MY PARTNER DESIRE',
          value: 2,
        },
        {
          text: 'SENSUAL MASSAGE',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'Josh',
      text: `“After 12 years of marriage we’re having conversation about sex that we’ve never had. It’s totally relit the spark for us.”`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
};


export const QUIZZES_SCREENS_NEW = {
  ['bedrooms']: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: 'We help you and your partner have more frequent sex.',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'George',
      text: `“No more dead bedroom. My wife and I are having sex again!”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'Was the sex ever frequent?',
      showFeedback: true,
      options: [
        {
          text: 'Yes',
          value: 1,
          comment: `We’ll help you get back to what it was like at the start.`,  
        },
        {
          text: 'No',
          value: 2,
          comment: `We’ll help you cultivate the chemistry that’s been missing.`,  
        }
      ],
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'How often do you like to have sex?',
      description: `Remember that desire levels can fluctuate over time. Stress and anxiety can affect how much you or your partner want to get busy in the bedroom on any given day.`,
      showFeedback: true,
      options: [
        {
          text: 'Daily',
          value: 1,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'Weekly',
          value: 2,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
        {
          text: 'Monthly',
          value: 3,
          comment: 'You have a great sense of how much sex you want to be having. We’ll make recommendations with that in mind.',
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What type of content are you interested in?',
      showFeedback: false,
      options: [
        {
          text: 'Discussion forums',
          value: 1,
        },
        {
          text: 'ways to increase desire',
          value: 2,
        },
        {
          text: 'Understanding the desire gap',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'Noa',
      text: `"This completely changed our dynamic. We're more connected in the bedroom than we have been in years."`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
  ['SSRIs']: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: 'We help you overcome the sexual side effects of SSRIs to feel more desire, pleasure and connection.',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'Jenna',
      text: `“Coral’s guided audio exercises helped me tune into my body and have orgasms again despite how hard that has been on SSRIs.”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'Are you or your partner taking SSRIs?',
      showFeedback: true,
      options: [
        {
          text: 'Me',
          value: 1,
          comment: `Thank you for sharing. We will make recommendations with that in mind.`,
        },
        {
          text: 'My partner',
          value: 2,
          comment: `Thank you for sharing. We will make recommendations with that in mind.`,
        },
        {
          text: 'Both of us',
          value: 3,
          comment: `Thank you for sharing. We will make recommendations with that in mind.
          `,
        },
      ],
      
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'What are the main side effects?',
      showFeedback: true,
      options: [
        {
          text: 'Low desire',
          value: 1,
          comment: 'We can help. Today you’ll feel more in touch with desire and sensation.',
        },
        {
          text: 'Trouble reaching orgasm',
          value: 2,
          comment: 'We can help. Today you’ll feel more in touch with desire and sensation.',
        },
        {
          text: 'Lack of sensation and numbness',
          value: 3,
          comment: 'We can help. Today you’ll feel more in touch with desire and sensation.',
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What type of content are you most interested in?',
      showFeedback: false,
      options: [
        {
          text: 'DISCUSSION FORUMS',
          value: 1,
        },
        {
          text: 'FEEL PLEASURE BETTER',
          value: 2,
        },
        {
          text: 'MIND BODY CONNECTION',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'Steve',
      text: `"Side effects suck, but you can have good sex on meds. Coral has techniques that help.”`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
  ['purity_culture']: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: ' We help you feel sexually free and empowered. We know that you deserve love, pleasure and connection. ',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'John',
      text: `“I was ashamed of my desire for years. Coral taught me that sexual desire is safe and helped me form my first healthy relationship.”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'What would you like to achieve?',
      showFeedback: true,
      options: [
        {
          text: 'Change my mindset',
          value: 1,
          comment: `We know unlearning a lifetime of shame doesn’t sound easy, but by using Coral 30 minutes/week you can shift your whole mindset.`,  
        },
        {
          text: 'Overcome guilt and shame',
          value: 2,
          comment: `We know unlearning a lifetime of shame doesn’t sound easy, but by using Coral 30 minutes/week you can overcome guilt and shame.`,  
        },
        {
          text: 'Learn about my body',
          value: 3,
          comment: `We know unlearning a lifetime of shame doesn’t sound easy, but by using Coral 30 minutes/week you can learn more about your body.`,
        },
        {
          text: 'WORK STRESS',
          value: 4,
          comment: `We understand. 

          Work Stress is a common obstacle to sexual performance, which is why Coral offers science-based content and guided exercises to help improve your performance.
          `,
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'How would you describe your intimate life now?',
      description: `Remember that intimacy can fluctuate over time. Stress and anxiety can affect how much you or your partner feel in bedroom on any given day.`,
      showFeedback: true,
      options: [
        {
          text: 'nonexistent',
          value: 1,
          comment: 'We’ll make recommendations with that in mind.',
        },
        {
          text: 'struggling',
          value: 2,
          comment: 'We’ll make recommendations with that in mind.',
        },
        {
          text: 'improving',
          value: 3,
          comment: 'We’ll make recommendations with that in mind.',
        },
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What type of content are you interested in?',
      showFeedback: false,
      options: [
        {
          text: 'Understand your sexual influences',
          value: 1,
        },
        {
          text: 'Mind body connection',
          value: 2,
        },
        {
          text: 'discussion forums',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'Jane',
      text: `"I stopped letting my upbringing hold back my sex life and started enjoying intimacy.”`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
  ['body_confidence']: [
    {
      type: 'Welcome',
      title: 'Welcome to Coral',
      subtitle: 'We help you feel confident and powerful in bed.',
    },
    {
      type: 'Testimonial',
      version: 1,
      name: 'Samantha',
      text: `“I used to worry about what I look like all the time, especially during sex. Now I feel comfortable and confident in my own skin.”`,
      stars: [1,2,3,4,5],
    },
    {
      type: 'Question',
      name: 'Q1',
      title: 'Which of these false narratives about your body do you want to start challenging first?',
      showFeedback: true,
      options: [
        {
          text: 'Weight is tied to desirability',
          value: 1,
          comment: `We know understanding body shame isn’t easy. By using Coral 30 minutes/week you can learn to love your body just the way it is.`,  
        },
        {
          text: 'my parts aren’t normal',
          value: 2,
          comment: `By using Coral you can learn about the diversity and beauty of the human body and love yourself just the way you are. `,  
        },
        {
          text: 'i’m not conventionally sexy',
          value: 3,
          comment: `By using Coral you can learn about the diversity and beauty of the human sexuality and love yourself just the way you are. `,
        }
      ],
    },
    {
      type: 'Question',
      name: 'Q2',
      title: 'How would you describe your relationship to your body now?',
      description: `Our relationships to our bodies can change over time. Things like stress and anxiety can influence how comfortable you feel in the bedroom on any given day.`,
      showFeedback: true,
      options: [
        {
          text: 'i’m always critical of my body',
          value: 1,
          comment: 'We’ll make recommendations with that in mind.',
        },
        {
          text: 'i’m sometimes critical of my body',
          value: 2,
          comment: 'We’ll make recommendations with that in mind.',
        },
        {
          text: 'i’m learning self love',
          value: 3,
          comment: 'We’ll make recommendations with that in mind.',
        }
      ],
    },
    {
      type: 'Question',
      name: 'Q3',
      title: 'What type of content are you interested in?',
      showFeedback: false,
      options: [
        {
          text: 'body positivity exercises',
          value: 1,
        },
        {
          text: 'Discussion forums',
          value: 2,
        },
        {
          text: 'meditations for self-acceptance',
          value: 3,
        },
      ],
    },
    {
      type: 'Testimonial',
      version: 2,
      name: 'Phil',
      text: `"I needed to love my body to love my sex life. Coral made a huge difference.”`,
      stars: [1,2,3,4,5],
      isLastScreen: true,
    },
  ],
};
