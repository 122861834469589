import * as React from 'react';

type OnBoardingDesireType = {
  fill?: string;
  width?: number;
  height?: number;
  positionX?: number;
  positionY?: number;
};

function OnBoardingDesireType({
  fill = '#C0C7BC',
  width = 2000,
  height = 312,
  positionX = 0,
  positionY = 0
}: OnBoardingDesireType) {
  return (
    <div style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 312,
      overflow: 'hidden'
    }}>
      <svg
        width={2000}
        height={312}
        viewBox={`${positionX} ${positionY} ${width} ${height}`}
        fill="none"
      >
        <path
          opacity={0.3}
          d="M986.979 61.512C885.521 61.512 864 151.657 812 161.158c-52 9.5-92.5-37.17-188.5-37.17s-95-57.503-202-39.002c-65.303 11.291-104.987 120.007-178 120.007-70 0-106-75.193-188-24.001C16.447 205.372 0 312 0 312h3465.5c-5.11-19.627-24.05-47.265-85.5-57.984-35.27-6.152-82.63-91.384-128-95.506-44.28-4.023-105.9 59.694-154 56.984-50.76-2.86-91.56-72.887-140-74.985-61.13-2.648-109.72 38.216-155 35.483-50.63-3.057-77.5 39.502-121.5 39.502-79.01 0-99.08-95.042-139.5-107.484-68.91-21.211-94.58 77.937-157 67.982-64.5-10.287-70.5-93.387-150.64-67.982-160.88 50.997-138.65-98.529-205.86-98.529-66.5 0-68 55.004-139.5 64.504-71.5 9.5-86.59 42.718-160.5 50.003-91.5 9.02-95.78-16.34-131.5-68.337-43.96-63.997-124.8-76.672-177.04-17.638-46.01 51.998-107.46-59.534-170.46 0-45.51 43.002-88 53.64-130.5 46.973-42.5-6.668-90-23.474-132.021-23.474z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default OnBoardingDesireType;
