import { FC, useEffect } from 'react';

import * as S from './styles';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const CoachingConfirmation: FC = () => {
  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.COACHING_CONFIRMATION)
    window.location.href="https://www.trycoral.app/order-confirmation";
  }, []);

  return (
    <S.Container>
      <S.LoadingSpinner />
    </S.Container>
  );
};

export default CoachingConfirmation;
