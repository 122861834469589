import gql from 'graphql-tag';

const GET_STRIPE_SUBSCRIPTION_PRICES = gql`
  query getStripeSubscriptionPrices {
    getStripeSubscriptionPrices {
      id
      active
      currency
      unit_amount
    }
  }
`;

const GET_STRIPE_SUBSCRIPTIONS = gql`
  query getStripeSubscriptions {
    getStripeSubscriptions {
      id
    }
  }
`;

const GET_STRIPE_COUPONS = gql`
  query getStripeCoupons {
    getStripeCoupons {
      id
      object
      amount_off
      created
      currency
      duration
      duration_in_months
      livemode
      max_redemptions
      name
      percent_off
      times_redeemed
      valid
    }
  }
`;

const GET_STRIPE_PROMOTION_CODE = gql`
  query getStripePromotionCode($code: String!) {
    getStripePromotionCode(code: $code) {
      id
      active
      code
      coupon {
        id
        amount_off
        created
        livemode
        name
        percent_off
        valid
      }
      expires_at
      livemode
      times_redeemed
    }
  }
`;

export {
  GET_STRIPE_SUBSCRIPTION_PRICES,
  GET_STRIPE_SUBSCRIPTIONS,
  GET_STRIPE_COUPONS,
  GET_STRIPE_PROMOTION_CODE
};
