import styled from "styled-components";
import tw from "twin.macro";

import Colors from "assets/styles/Colors";

import ZoeCoaching from 'assets/images/zoe-coaching.png';

export const About = styled.div`
  background-color: ${Colors.DeepBlue};
  ${tw`relative flex p-5 text-white max-w-7xl w-full`}

  @media screen and (max-width: 768px) {
    ${tw`flex-col px-6 py-10`}
  }
`;

export const AboutContentRow = styled.div``;

export const PicureContainer = styled.div`
  ${tw`m-10`}

  @media screen and (max-width: 768px) {
    ${tw`absolute m-0 right-6`}
  }
`;

export const PictureContent = styled.img.attrs({
  src: ZoeCoaching
})`
  border-color: ${Colors.Skin02};
  width: 200px;
  height: 200px;
  ${tw`border-8 rounded-full`}

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    ${tw`border-4`}
  }
`;

export const AboutCenter = styled.div`
  ${tw`flex flex-1 flex-col pt-14 pr-5`}

  @media screen and (max-width: 768px) {
    width: calc(100% - 120px);
    ${tw`p-0`}
  }
`;

export const AboutTitle = styled.div`
  font-family: 'Andes';
  color: ${Colors.Skin02};
  ${tw`font-semibold`}
`;

export const BigScreen = styled.div`
  @media screen and (max-width: 768px) {
    ${tw`hidden`}
  }
`;

export const SmallScreen = styled.div`
  @media screen and (min-width: 768px) {
    ${tw`hidden`}
  }
`;

export const AboutName = styled.div`
  font-family: 'Canela';
  font-size: 80px;

  @media screen and (max-width: 768px) {
    font-size: 54px;
  }
`;

export const AboutRight = styled.div`
  width: 30%;
  ${tw`pt-14 mr-5`}

  @media screen and (max-width: 768px) {
    ${tw`p-0 mr-0 mt-5 w-full`}
  }

`;

export const AboutContentText = styled.div`
${tw`mt-4`}`;

export const AboutContact = styled.div`
  ${tw`hidden`}

  @media screen and (max-width: 768px) {
    ${tw`flex mt-4`}
  }
`;

