import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useApolloClient} from '@apollo/client';

import ChatQueries from 'graphql/chat/chat.queries';
import UserQueries from 'graphql/user/user.gql.queries';
import {UserAnalytics} from 'graphql/user/user.types';
import { useFirebaseAuthContext } from 'context/auth';
import {useIntercom} from "react-use-intercom";

export type UserProps = {
  id: number;
  partnerId: string;
  joinedCoral: boolean;
  email: string;
  username: string;
  isSubscribed: boolean;
  gender: string;
  onboardingTraitsCompleted: boolean;
  onboardingDesireTypeCompleted: boolean;
};

type UserContextProps = {
  userData?: UserProps;
  loadingUserData: boolean;
  userAnalyticsData?: UserAnalytics;
};

type Props = {
  children: React.ReactNode;
};

const UserContext = React.createContext({
  loadingUserData: true,
} as UserContextProps);

export const UserProvider = ({children}: Props) => {
  const [userData, setUserData] = useState<UserProps>();
  const [userAnalyticsData, setUserAnalyticsData] = useState<UserAnalytics>();
  const [loading, setLoading] = useState(false);

  const { isAuthenticated } = useFirebaseAuthContext();

  const apolloClient = useApolloClient();
  
  const {update} = useIntercom();

  const fetchUserInfo = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: {getUserDataForChat},
      } = await apolloClient.query({
        query: ChatQueries.GET_USER_INFO,
        fetchPolicy: 'no-cache',
      });

      setUserData(getUserDataForChat);
      setLoading(false);

      const {
        data: {UserAnalyticsData: analyticsData},
      } = await apolloClient.query({
        query: UserQueries.GET_USER_PROPS_FOR_ANALYTICS,
        fetchPolicy: 'no-cache',
      });

      setUserAnalyticsData(analyticsData);
    } catch (error) {
      setLoading(false);
    }
  }, [apolloClient]);

  useEffect(() => {
    isAuthenticated && fetchUserInfo();
  }, [isAuthenticated]);

  useEffect(() => {
    if(userData){
      update({
        email: userData.email ? userData.email : '',
        userId: String(userData.id),
      })
    }
  }, [userData]);

  return (
    <UserContext.Provider
      value={{
        userData,
        loadingUserData: loading,
        userAnalyticsData,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
