import styled from 'styled-components';
import tw from 'twin.macro';
import { FaBars, FaTimes } from 'react-icons/fa';
import Colors from 'assets/styles/Colors';
import CoralLogo from 'assets/images/NewHome/coral-logo.svg';
import { Link } from 'react-router-dom';

type Props = {
  textColor?: boolean;
}

export const HeaderMenu = styled(FaBars)`
  color: ${Colors.BlackTitle};
  font-size: 20px;
`;

export const CloseIconHeader = styled(FaTimes)`
  color: ${Colors.BlackTitle};
  font-size: 24px;
`;

export const Container = styled.div`
    width: fit-content;
    position: absolute; 
    left: calc(100% - 35px);
    transform-origin: left top;
    transform: rotate(90deg);
    z-index: 1;
    ${tw`flex flex-1 flex-col`}

  @media (max-width: 767px) {
    position: absolute;
    left: 0;
    transform: rotate(360deg);
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1095.98px) {
    left: calc(100% - 10px);
  }
`;

export const HeaderIconContainer = styled.div`
  ${tw`flex justify-start lg:w-0 lg:flex-1`}
`;

export const HeaderIcon = styled.img.attrs(() => ({
  src: CoralLogo,
}))
`
  display: none;

  @media (max-width: 767px) {
    width: 75px;
    display: unset;
  } 

  @media (max-width: 560px) {
    margin-top: ${({textColor}: Props) => textColor ? '45px' : '0'};
  }
`;

export const HeaderMainContainer = styled.div`
  ${tw`sm:px-6 px-4 py-2`}
`;

export const HeaderMain = styled.div`
  ${tw`flex justify-between items-center md:justify-start md:space-x-10 md:ml-10 ml-0`}
  margin-top: ${({textColor}: Props) => textColor ? '16px' : '0'};
`;

export const LeftHeaderLogo = styled.div`
  ${tw`flex justify-start`}
`;

export const RightMenuBars = styled.div`
  ${tw`-mr-2 -my-2 md:hidden`}
`;

export const PopoverButton = styled.button`
  ${tw`rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
`;

export const HeaderMenuButtonTrial = styled.a`
  ${tw`text-base font-medium text-white hover:opacity-80 `}
  border: 1px solid white;
  padding: 4px 16px;
  border-radius: 5px;
  width: 190px;
`;

export const HeaderMenuButton = styled.a`
  ${tw`text-base font-medium hover:opacity-80`}
  color: ${({textColor}: Props) => textColor ? 'black' : 'white'};
`;

export const ClickEvent = styled.div``;

export const HoverMenuButton = styled.div`
    position: relative;
    font-family: 'GTSuper';
    
    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0%;
      height: 2px;
      background: ${Colors.Red};
      transition: width 0.3s;
      cursor: pointer;
    };

    &:hover:before {
      width: 100%;
    }
`;

export const HeaderMenuButtonMobile = styled(Link)`
  ${tw`text-base font-medium text-black hover:opacity-80`}
  display: block;
  margin: 20px 0px;
  font-size: 25px;
`;

export const MenuPopupMobile = styled.div`
  ${tw`rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50 h-full`}
  background: ${Colors.Coconut};
`;

export const MenuPopupContainer = styled.div`
  ${tw`pt-5 pb-6 px-5`}
  background: ${Colors.Coconut};
`;

export const MenuHeader = styled.div`
  ${tw`flex items-center justify-between`}
`;

export const MenuHeaderbutton = styled.div`
  margin-left: auto;
`;

export const PopupMenuListMobile = styled.div`
  ${tw``}
`;
