import React from 'react';
import * as S from './styles';

type Props = {
  text: string;
  selectedOption: string;
  color: string;
  style?: object;
  showIcon?: boolean;
  onClick: () => void;
};

const OnBoardingAnswer = ({
  text,
  selectedOption,
  color,
  showIcon,
  style,
  onClick,
}: Props) => (
  <S.Container style={style} onClick={onClick}>
    <S.Text>{text}</S.Text>
    <S.SelectedOption color={color}>
      <S.SelectedOptionText>{selectedOption}</S.SelectedOptionText>
      {showIcon && <S.Edit />}
    </S.SelectedOption>
  </S.Container>
);

export default OnBoardingAnswer;