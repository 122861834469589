import styled from 'styled-components';
import Colors from 'assets/styles/Colors';

const FONT_SIZE = {
  0: '15px',
  1: '12px',
  2: '10px',
  3: '8px',
};

const LINE_HEIGHT = {
  0: '15px',
  1: '15px',
  2: '12px',
  3: '10px',
};

const LETTER_SPACING = {
  0: '4px',
  1: '4px',
  2: '4px',
  3: '1px',
};

const DEFAULT_COLOR = Colors.Black;

export const Title = styled.span`
  font-family: 'Andes';
  font-size: ${({type}) => FONT_SIZE[type]};
  line-height: ${({type}) => LINE_HEIGHT[type]};
  letter-spacing: ${({type}) => LETTER_SPACING[type]};
  color: ${({color}) => color || DEFAULT_COLOR};
  text-align: center;
`;
