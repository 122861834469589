import { useState } from 'react';
import flagsmith from 'flagsmith';

import {useUserContext} from 'context/user';
import analytics from 'helpers/analytics-helper';
import {isJson} from 'helpers/utility-helper';

type Props = {
  featureName: string;
};

type ReturnProps = {
  featureVariant?: string | undefined;
  variantConfig?: any;
  loadingFeatureFlags: boolean;
  setLoadingFeatureFlags: (value: boolean) => void;
};

type FeatureValue = string | number | boolean;

const featureFlagsTracked = new Set();

const useFlagsmith = ({featureName}: Props): ReturnProps => {
  const [loadingFeatureFlags, setLoadingFeatureFlags] = useState<boolean>(true);
  const {userData} = useUserContext();

  const trackFeatureAccess = (featureFlagName: string, variant: string) => {
    if (!variant) {
      return;
    }

    const featureFlagToTrack = JSON.stringify({
      userId: userData?.id || 0,
      featureFlagName,
      variant,
    });

    if (!featureFlagsTracked.has(featureFlagToTrack)) {
      analytics.track(`FS FeatureFlag ${featureFlagName}`, {
        variant,
      });
      featureFlagsTracked.add(featureFlagToTrack);
    }
  };

  const parseFeature = (featureValue: FeatureValue) => {
    let featureVariant = '';
    let variantConfig = {};

    if (typeof featureValue === 'string' && isJson(featureValue)) {
      const parsedValue = JSON.parse(featureValue);
      featureVariant = parsedValue.label;
      variantConfig = parsedValue.config;
    } else {
      featureVariant = featureValue?.toString();
    }

    return {
      featureVariant,
      variantConfig,
    };
  };

  const {featureVariant, variantConfig} = flagsmith.hasFeature(featureName)
    ? parseFeature(flagsmith.getValue(featureName))
    : {featureVariant: '', variantConfig: {}};

  trackFeatureAccess(featureName, featureVariant);

  return {
    featureVariant,
    variantConfig,
    loadingFeatureFlags,
    setLoadingFeatureFlags,
  };
};

export default useFlagsmith;