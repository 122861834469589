import React, { FormEvent, useEffect, useRef, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

import * as S from './styles';

type CardFromProps = {
  onSuccess: (paymentMethod: string, paymentMethodTypes: string) => void;
  onFailure?: () => void;
  isSubscribing: boolean;
  showPaymentButton?: boolean;
  externalSubmit?: boolean;
};

const StripeForm = ({
  onSuccess,
  onFailure,
  isSubscribing,
  showPaymentButton = true,
  externalSubmit = false,
}: CardFromProps) => {
  const [isIntentLoading, setIsIntentLoading] = useState(false);
  const [isLoadingPaymentForm, setIsLoadingPaymentForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();
  const buttonRef = useRef<HTMLButtonElement>();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsIntentLoading(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/confirmation`,
      },
      redirect: 'if_required',
    });
    
    setIsIntentLoading(false);

    if (error) {
      // show error and collect new card details.
      onFailure();
      return;
    }

    if (setupIntent.payment_method) {
      onSuccess(setupIntent.payment_method.toString(), paymentMethodSelected);
    }
  };

  const handleLoadPaymentForm = () => {
    setIsLoadingPaymentForm(false);
  }

  useEffect(() => {
    externalSubmit && buttonRef.current.click();
  }, [externalSubmit]);

  useEffect(() => {
    setIsLoading(isIntentLoading || isSubscribing)
  }, [isIntentLoading, isSubscribing]);

  const handlePaymentChange = (e: StripePaymentElementChangeEvent) => {
    setPaymentMethodSelected(e.value.type);
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onReady={handleLoadPaymentForm} onChange={handlePaymentChange} />
      {isLoadingPaymentForm ? (
        <S.LoadingContainer>
          <S.Spinner />
          <S.LoadingContainerText>
            Loading Payment Form
          </S.LoadingContainerText>
        </S.LoadingContainer>
      ) : (
        <>
          {showPaymentButton && (
            <S.PaymentButton disabled={!stripe || isLoading} loading={isLoading}>
              {
                isIntentLoading 
                  ? 'PAYING...' 
                  : isSubscribing 
                  ? 'SUBSCRIBING...'
                  : 'PAY NOW'
              }
            </S.PaymentButton>
          )}
          <button style={{
            visibility: 'hidden'
          }} ref={buttonRef}>submit</button>
        </>
      )}
    </form>
  );
};

export default StripeForm;
