import gql from 'graphql-tag';

const GET_STATIC_COURSES = gql`
  query GetCourses {
    staticCourses {
      id
      name
      topicId
    }
  }
`;

const GET_COURSES_BY_UNIT = gql`
  query GetCoursesByUnit($id: Int!) {
    getUnitsByCourse(courseId: $id) {
      courseName
      visibleUnits {
        id
        name
        description
        courseId
        players
        type
        bookmarked
        hasAudio
        progress {
          started
          completed
        }
        data {
          time
          hasAudio
          traitSets {
            name
          }
        }
      }
      hiddenUnits {
        id
        name
        description
        courseId
        players
        type
        bookmarked
        hasAudio
        progress {
          started
          completed
        }
        data {
          time
          hasAudio
          traitSets {
            name
          }
        }
      }
    }
  }
`;

const GET_COURSES_BY_TOPIC = gql`
  query GetCoursesByTopic($topicId: Int!) {
    getCoursesByTopic(topicId: $topicId) {
      id
      name
      type
      started
      completed
      progress
      unitsTotal
      unitsCompletedAmount
      position
      currentUnit {
        id
        name
      }
      data {
        imageUrl
      }
    }
  }
`;

export default {
  GET_STATIC_COURSES,
  GET_COURSES_BY_UNIT,
  GET_COURSES_BY_TOPIC,
};
