import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';

type Pitch = {
  title?: string;
  description: string;
  subDescription?: string;
  subDescriptionRed?: string;
};

interface IPitchContextProps {
  isPitchHidden: boolean;
  pitchCopy: Pitch[];
  pitchVariant?: string;
}

const PitchContext = createContext<IPitchContextProps>({
  isPitchHidden: false,
  pitchCopy: [],
});

export function PitchProvider({ children }: { children: ReactNode }) {

  const [pitchCopy, setPitchCopy] = useState<Pitch[]>([]);
  const [isPitchHidden, _] = useState(false);

  const getLandingPitchCopy = () => {
    return [
      {
        description: 'Society sets us up to fail when it comes to intimacy',
      },
      {
        description:
          'But we all have the potential to create a fulfilling sex life',
      },
      {
        description:
          'Coral has helped 1,233,998 people deepen pleasure and intimacy',
        subDescriptionRed: '66%',
        subDescription: 'of Coralers report a better sex life within two weeks',
      },
    ];
  };

  useEffect(() => {
    const onboardingLandingPitchCopy = getLandingPitchCopy();
    setPitchCopy(onboardingLandingPitchCopy);
  }, []);

  return (
    <PitchContext.Provider
      value={{
        pitchCopy,
        isPitchHidden,
      }}>
      {children}
    </PitchContext.Provider>
  );
}

export const usePitchContext = () => useContext(PitchContext);
