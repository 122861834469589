import { useHistory } from 'react-router-dom';

import { usePitchContext } from 'context/onboarding-pitch';
import OnboardingPitch from 'components/OnboardingPitchNew';
import { ASSETS_PATH } from 'constants/assets';
import * as MyRoutes from 'constants/Routes';

import * as S from './styles';

type OnboardingPitchOneProps = {
  pitchCopy: any;
  goToNextPitch: () => void;
  onboardingPitchOne:string;
};

const OnboardingPitchOne = ({pitchCopy,goToNextPitch,onboardingPitchOne} : OnboardingPitchOneProps) => {
 
  return (
    <S.Container>
      <S.SmallGreyMoon />
      <S.HeaderImageTwo />
      <S.HeaderImageOne />
      <S.BackGroungImage />
      <OnboardingPitch
        title={pitchCopy[0]?.title}
        description={pitchCopy[0]?.description}
        logoUrl={onboardingPitchOne}
        onPressContinue={goToNextPitch}
      />
      <S.Footer>
        <S.BodyPinkBGCurve />
        <S.BigYellowMoon />
      </S.Footer>
    </S.Container>
  );
};

export default OnboardingPitchOne;
