import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as MyRoutes from 'constants/Routes';
import { Redirect } from 'react-router-dom';
import { setStorageItem } from 'helpers';
import { useFlagsmithContext } from 'context/flagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { useFirebaseAuthContext } from 'context/auth';
import { useUserContext } from 'context/user';
import Colors from 'assets/styles/Colors';
import Loader from 'components/base/Loader';
import { KEY_REFERRAL_ID } from 'helpers/constants-helper';

type Params = { id?: any };

const ReferralId = () => {
  const params = useParams<Params>();

  const [loadingFF, setLoadingFF] = useState(true);
  const { featureVariant } = useFlagsmith({ featureName: FEATURE_FLAG_NAME.FE_SHOW_LANDING_SCREENS_OB });
  const { featureVariant: OBOrderVariant } = useFlagsmith({ featureName: FEATURE_FLAG_NAME.WR_OB_ORDER });

  const [showLandingScreens, setShowLandingScreens] = useState<boolean>();
  const [showDesireScreens, setShowDesireScreens] = useState<boolean>();

  setStorageItem(KEY_REFERRAL_ID, parseInt(params?.id));

  const { loadingFeatureFlags } = useFlagsmithContext();

  useEffect(() => {
    if (!loadingFeatureFlags) {
      setShowLandingScreens(featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_A);
      setShowDesireScreens(OBOrderVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C);
      setLoadingFF(false);
    }
  }, [loadingFeatureFlags]);

  const { isAuthenticated } = useFirebaseAuthContext();
  const { loadingUserData } = useUserContext();

  return (
    <>
      {loadingUserData || loadingFF ? (
        <div style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}><Loader color={Colors.Red} /></div>
      )
        : !isAuthenticated
          ? (
            <Redirect
              to={{
                pathname: showDesireScreens ? MyRoutes.DESIRE_TYPE : showLandingScreens ? MyRoutes.ONBOARDING_LANDING : MyRoutes.LOGIN
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: MyRoutes.HOME,
              }}
            />
          )
      }
    </>
  )
};

export default ReferralId;
