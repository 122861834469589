import styled from 'styled-components';
import { FiX } from 'react-icons/fi';

import Button from 'components/base/Button';
import Title from 'components/base/Title';

import Colors from 'assets/styles/Colors';
import Heading from 'components/base/Heading';
import { ASSETS_PATH } from 'constants/assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${Colors.Coconut};
  overflow-y: auto;
`;

export const CloseButton = styled.a`
  position: absolute;
  display: flex;
  top: 24px;
  right: 16px;
  height: 47px;
  width: 47px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 12px 20px rgba(0, 0, 0, 0.11);
  z-index: 20;
`;

export const CloseIcon = styled(FiX)`
  color: ${Colors.Black};
  font-size: 26px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  padding: 0 30px;
  align-items: center;
`;

export const HowWorksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 36px;
  /* margin-top: 36px; */
  background-color: ${Colors.White};
  padding: 40px 40px 16px;
  border-radius: 32px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 12px 20px rgba(0, 0, 0, 0.11);
  z-index: 10;
`;

export const HowWorksItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-left: 12px;
  margin-top: 2px;
`;

export const HowWorksItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
`;

export const HowWorksItemTitle = styled.span`
  font-family: 'GTSuper';
  font-size: 20px;
  letter-spacing: -0.32px;
  color: ${Colors.Black};
`;

export const HowWorksItemText = styled.span`
  /* font-family: {Fonts.Inter}; */
  font-size: 15px;
  letter-spacing: -0.32px;
  color: ${Colors.Black};
  margin-left: 1px;
  line-height: 20px;
`;

export const TimelineImage = styled.div`
  width: 18px;
  height: 263px;
  background-image: url(${`${ASSETS_PATH}/trial-start-line.png`});
  background-size: 18px 263px;
  background-repeat: no-repeat;
`;

export const TrialTitle = styled(Heading).attrs({
  type: 3,
})`
  margin-top: 50px;
  text-align: center;
  max-width: 260px;
  color: ${Colors.Black};
  z-index: 10;
`;

export const FooterImageBackground = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 460px;
  background-image: url(${`${ASSETS_PATH}/trial-start-bg.png`});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 0 16px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
`;

const FooterText = styled.span`
  font-family: 'Andes';
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.96px;
`;

export const TrialPeriodText = styled(FooterText)``;

export const PriceText = styled(FooterText)`
  font-family: 'Andes';
  font-weight: regular;
  font-size: 16px;
  text-align: center;
`;

export const PriceTextMonthly = styled(FooterText)`
  margin-top: 8px;
`;

export const PremiumForPartnerText = styled(FooterText)`
  font-size: 12px;
  font-weight: bold;
  margin: 20px 0 10px;
  background-color: ${Colors.White};
  padding: 2px 4px;
  border-radius: 4px;
  letter-spacing: ;
`;

export const StartButton = styled(Button)`
  text-transform: uppercase;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.09), 0px 12px 20px rgba(0, 0, 0, 0.21);
`;

export const DiscountLabel = styled.div`
  background-color: ${Colors.Red};
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  border: 4px solid ${Colors.Red};
  width: 88px;
  height: 88px;
  z-index: 99;
  position: absolute;
  top: 40px;
  left: 15px;
`;

export const DiscountLabelText = styled.span`
  /* font-family: {Fonts.InterSemiBold}; */
  color: ${Colors.White};
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
`;

export const PlanDescriptionDiscount = styled.div`
  margin-top: 8px;
`;

export const DiscountedPlanDescriptionText = styled.span`
  font-family: 'GTSuper';
  font-weight: 500;
  font-size: 13px;
  color: ${Colors.Black};
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 22px;
`;

export const DiscountedPlanDescriptionPrice = styled(
  DiscountedPlanDescriptionText,
)`
  font-size: 22px;
`;

export const TextCrossLine1 = styled.div`
  position: absolute;
  top: -20px;
  align-self: center;
  z-index: 99;
`;

export const RestorePurchase = styled.a``;

export const RestorePurchaseText = styled(Title).attrs(() => ({
  type: 0,
}))`
  font-size: 13px;
  font-weight: 500;
`;
