import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useOnboardingContext } from 'context/onboarding';

import SubscriptionPrompt, {
  SubscriptionMode,
} from 'components/SubscriptionPrompt';

import { usePartnerContext } from 'context/partner';
import * as MyRoutes from 'constants/Routes';
import useFlagsmith from 'common/hooks/useFlagsmith';
import {
  FEATURE_FLAG_NAME,
  FEATURE_FLAG_VARIANT_TYPE,
} from 'constants/feature-flags';
import FreeTrialPrompt from 'components/FreeTrial';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import { getStorageItem, KEY_PROMOTION_CODE, PURSHASES_API_KEY } from 'helpers';
import axios from 'axios';
import { useUserContext } from 'context/user';
import { isDateBefore } from 'helpers/date-helper';
import { useSubscriptionContext } from 'context/subscription';

import { LoaderContainer } from './styles';
import Loader from 'components/base/Loader';
import Colors from 'assets/styles/Colors';
import CombinedFreeTrial from './CombinedFreeTrial';

const OnboardingTrialStartSingle = () => {
  const history = useHistory();
  const { setOnboardingDesireTypeCompleted } = useOnboardingContext();
  const { isSubscribed } = useSubscriptionContext();
  const { featureVariant } = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER,
  });

  const promotionCode = getStorageItem(KEY_PROMOTION_CODE);

  const isShowDTQFirstVariant =
    featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;

  const skipSubscription = () => {
    setOnboardingDesireTypeCompleted();
    history.push(MyRoutes.CONFIRMATION);
  };

  const attemptSubscription = () => {
    setOnboardingDesireTypeCompleted();
    history.push(MyRoutes.SUBSCRIBE);
  };

  useEffect(() => {
    isSubscribed && skipSubscription();
  }, [isSubscribed]);

  return (
    <>
      {isShowDTQFirstVariant || !!promotionCode ? (
        <SubscriptionPrompt
          mode={SubscriptionMode.Single}
          onCompletePurchase={attemptSubscription}
          onSkip={skipSubscription}
          openedFrom="onboarding"
        />
      ) : (
        <FreeTrialPrompt
          mode={SubscriptionMode.Single}
          onCompletePurchase={attemptSubscription}
          onSkip={skipSubscription}
          openedFrom="onboarding"
        />
      )}
    </>
  );
};

const OnboardingTrialStartConnected = () => {
  const history = useHistory();
  const { setOnboardingDesireTypeCompleted } = useOnboardingContext();
  const { isSubscribed } = useSubscriptionContext();

  const promotionCode = getStorageItem(KEY_PROMOTION_CODE);

  const { featureVariant } = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER,
  });
  const isShowDTQFirstVariant =
    featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;

  const skipSubscription = () => {
    setOnboardingDesireTypeCompleted();
    history.push(MyRoutes.CONFIRMATION);
  };

  const attemptSubscription = () => {
    setOnboardingDesireTypeCompleted();
    history.push(MyRoutes.SUBSCRIBE);
  };

  useEffect(() => {
    isSubscribed && skipSubscription();
  }, [isSubscribed]);

  return (
    <>
      {isShowDTQFirstVariant || !!promotionCode ? (
        <SubscriptionPrompt
          mode={SubscriptionMode.Single}
          onCompletePurchase={attemptSubscription}
          onSkip={skipSubscription}
          openedFrom="onboarding"
        />
      ) : (
        <FreeTrialPrompt
          mode={SubscriptionMode.Connected}
          onCompletePurchase={attemptSubscription}
          onSkip={skipSubscription}
          openedFrom="onboarding"
        />
      )}
    </>
  );
};

const FreeTrial = () => {
  const [loadingIsSubscribed, setLoadingIsSubscribed] = useState(true);
  const { hasPartnerConnected, loadingPartnerContext, partnerData } =
    usePartnerContext();
  const {
    userData: { id: userId },
  } = useUserContext();
  const { setIsSubscribed } = useSubscriptionContext();

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(
      ANALYTICS_EVENT_NAMES.BOARDING_FREE_TRIAL,
    );
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingIsSubscribed(true);
      const revCatSubscriber = await axios.get(
        `https://api.revenuecat.com/v1/subscribers/${userId.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${PURSHASES_API_KEY}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!revCatSubscriber) return;

      const activeSubscription = Object.values(
        revCatSubscriber.data.subscriber.subscriptions,
      ).find((subscription: any) => isDateBefore(subscription.expires_date));

      setIsSubscribed(!!activeSubscription);
      setLoadingIsSubscribed(false);
    })();
  }, []);

  if (loadingPartnerContext || loadingIsSubscribed) {
    return (
      <LoaderContainer>
        <Loader color={Colors.Red} />
      </LoaderContainer>
    );
  }

  return <CombinedFreeTrial />;
};

export default FreeTrial;
