import styled from 'styled-components';
import tw from 'twin.macro';
import CustomButton from 'components/base/Button';
import Colors from 'assets/styles/Colors';
import testimonialBG from 'assets/images/testimonial-bg.svg';
import testimonialBG2 from 'assets/images/testimonial-bg2.svg';
import { ASSETS_PATH } from 'constants/assets';
import { FaStar } from 'react-icons/fa'

const width = window.screen.width;

export const Container = styled.div`
  ${tw`flex flex-1 flex-col`}
  background-color: ${Colors.Coconut};
`

export const HeaderImageOne = styled.div`
  position: absolute;
  top: 0;
  width: 1584px;
  height: 207px;
  left: -1325px;
  background-image: url(${`${ASSETS_PATH}/tan-curve-one.png`});
  background-size: 1584px 207px;
  background-position: left;
  z-index: 0;
`;

export const HeaderImageTwo = styled.div`
  position: absolute;
  top: 0;
  width: 1218.5px;
  height: 194px;
  left: -702px;
  background-image: url(${`${ASSETS_PATH}/pitch-header-second-background.png`});
  background-size: 1218px 194px;
  background-position: left;
  z-index: 0;
`;

export const SmallGreyMoon = styled.div`
  position: absolute;
  height: 113px;
  width: 113px;
  left: 50px;
  top: 1px;
  border-radius: 0px;
  background-image: url(${`${ASSETS_PATH}/moon-small-grey.png`});
  background-size: 113px 113px;
  background-position: top;
`;

export const Content = styled.div`
  ${tw`flex flex-1 justify-center flex-col z-10`}
`;

export const TestimonialContainer = styled.div`
  ${tw`flex flex-col mx-8 p-5 bg-white rounded-lg`}
  box-shadow: 0px 136px 54px rgba(49, 37, 33, 0.01), 0px 76px 46px rgba(49, 37, 33, 0.05), 0px 34px 34px rgba(49, 37, 33, 0.09), 0px 8px 19px rgba(49, 37, 33, 0.1), 0px 0px 0px rgba(49, 37, 33, 0.1);
`;

export const TestimonialLine = styled.div`
  ${tw`flex justify-between align-middle mb-5`}
`;

export const TestimonalName = styled.div`
  ${tw`text-xl font-bold`}
`;
export const TestimonalStarContainer = styled.div`
  ${tw`flex items-center`}
`;

export const TestimonialStar = styled(FaStar)`
  ${tw`mr-1.5 text-lg text-yellow-400`}
`;

export const TestimonialText = styled.div`
  ${tw`text-base leading-7`}
`;

export const Footer = styled.div`
  ${tw`flex px-5 py-0 z-10`}
`;

export const Button = styled(CustomButton)`
  ${tw`z-10 mb-4`}
`;

export const TestimonialBG = styled.div`
  position: absolute;
  width: 571px;
  height: 593px;
  bottom: 0px;
  left: -${() => (571 - width) / 2}px;
  background-image: url(${testimonialBG});
  background-size: 571px 593px;
`;

export const TestimonialBG2 = styled.div`
  position: absolute;
  width: 434px;
  height: 504px;
  bottom: -10px;
  left: 50%;
  margin-left: -${434/2}px;
  background-image: url(${testimonialBG2});
  background-size: 434px 504px;
  background-position: center;
`;
