import styled from 'styled-components';

import Heading from 'components/base/Heading';
import CustomTitle from 'components/base/Title';
import Button from 'components/base/Button';
import Colors from 'assets/styles/Colors';
import tw from 'twin.macro';
import { FiX } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const Title = styled(Heading).attrs({
  type: 1,
})`
  margin-top: 10px;
  text-align: center;
`;

export const Paragraph = styled.div`
  /* font-family: Fonts.Inter; */
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.19px;
  color: ${Colors.Black};
  margin-top: 10px;
  text-align: center;
`;

export const TextInRedContainer = styled.div`
  /* font-family: Fonts.Inter; */
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.18px;
  color: ${Colors.Black};
  text-align: center;
`;

export const TextInRedContainerBold = styled.div`
  /* font-family: Fonts.InterSemiBold; */
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.18px;
  color: ${Colors.Black};
  font-weight: bold;
`;

export const RedBackground = styled.a`
  width: 100%;
  display: flex;
  background-color: ${Colors.Red};
  border-radius: 50px;
  padding: 10px 10px;
  flex-direction: row;
  margin-top: 27px;
`;

export const TextInList = styled.div`
  /* font-family: Fonts.Inter; */
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.96px;
  color: ${Colors.Black};
  text-transform: uppercase;
  text-align: center;
`;

export const PaleCircle = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 100px;
  background-color: ${Colors.PaleKelp};
  margin: 13px auto;
`;

export const ConnectButton = styled(Button)`
  justify-content: space-evenly;
  margin-top: 14px;
`;

export const SkipButton = styled.a`
  font-family: 'Andes';
  letter-spacing: 0.2em;
  ${tw`flex justify-center text-sm font-medium pt-2 z-10`}
`;

export const SkipButtonText = styled(CustomTitle).attrs({
  type: 0,
})`
  text-align: center;
  color: ${Colors.Black};
`;

export const Spacer = styled.div`
  min-height: 20px;
`;

export const TestimonialContainer = styled.div`
  height: 230px;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled.a`
  position: absolute;
  display: flex;
  top: 24px;
  right: 16px;
  height: 47px;
  width: 47px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 12px 20px rgba(0, 0, 0, 0.11);
  z-index: 20;
`;

export const CloseIcon = styled(FiX)`
  color: ${Colors.Black};
  font-size: 26px;
`;