import { initializeApp } from "firebase/app";

// TODO: Fetch the config from AWS SSM
const firebaseConfig = {
  apiKey: "AIzaSyCnWMHLUbjZew3U9jHx7BisUEJNZa5D8nE",
  authDomain: "coral-11.firebaseapp.com",
  databaseURL: "https://coral-11.firebaseio.com",
  projectId: "coral-11",
  storageBucket: "coral-11.appspot.com",
  messagingSenderId: "916077449389",
  appId: "1:916077449389:web:5c42ec1627cfb632",
  measurementId: "G-M30RMSJFWG"
};

const initializeFirebaseApp = () => initializeApp(firebaseConfig);

export default initializeFirebaseApp;
