import axios from 'axios';
import { CONVERSIONS_API_KEY, CONVERSIONS_API_URL } from './constants-helper';

const conversationAPI = axios.create({
  baseURL: CONVERSIONS_API_URL,
});

export const sendEvents = async (eventData: {}) => {
 try {
   conversationAPI.post(`/events?access_token=${CONVERSIONS_API_KEY}`, eventData);
 } catch (error) {
 }
}