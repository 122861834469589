import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import CardImageSVG from 'assets/images/NewHome/discussion.svg';
import { FaArrowRight } from 'react-icons/fa';
import tw from 'twin.macro';
import smallMobileImg from 'assets/images/DesireDiscrepency/small-mobileImg.png';
import { Link } from 'react-router-dom';

export const ListContainer = styled(Link)`
  margin: 0 0.5%;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 6%);
  background: ${Colors.White};
  border-radius: 24px;
  padding: 20px;
  margin: 10px;
  flex: 0 0 30%;

  @media (max-width: 1200px) {
    flex: 0 0 45%;
  } 

  @media (max-width: 768px) {
    flex: 0 0 90%;
  } 
`;

export const ListDiffrentContainer = styled.div`
  margin: 0 0.5%;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 6%);
  background: ${Colors.Skin02};
  border-radius: 24px;
  padding: 20px;
  margin: 10px;
  flex: 0 0 30%;
  display: flex;
  max-height: 255px;

  @media (max-width: 1200px) {
    flex: 0 0 45%;
  } 

  @media (max-width: 768px) {
    flex: 0 0 90%;
  } 
`;

export const TextContainer = styled.div`
  width: 50%;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  font-family: 'Canela';
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  justify-content: space-between;
`;


export const CardTitle = styled.div`
  font-family: 'Canela';
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 73px;
`;

export const CardTitleJourney = styled.div`
  font-family: 'GTSuper';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.005em;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 390px) {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  } 
`;

export const CardImage = styled.img.attrs(() => ({
  src: CardImageSVG,
}))``;

export const Image = styled.img.attrs(() => ({
  src: smallMobileImg,
}))
`
  height:90%;
  margin: auto;
`;

export const FooterDescriptionContainer = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 15px;
  background: ${Colors.Coconut};
  padding: 15px;
  margin: 15px 0;
  line-height: 20px;
`;

export const FooterDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 20px;
  min-height: 60px;
`;

export const FooterDescriptionJourny = styled.div`
  font-family: 'Inter';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 20px;
  min-height: 60px;
  font-weight: 400;
  font-size: 15px;
  padding-top: 2%;
`;

export const DownloadNowText = styled.div`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-top: 15%;
`;  

export const LeftSideArrow = styled(FaArrowRight)`
  ${tw`mr-5 text-lg`}
  margin-left: 10px;
`;

export const CardFooter = styled.div`
  display: flex;
`;

export const NewTooltips = styled.div`
  font-family: 'Andes';
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${Colors.White};
  justify-content: center;
  display: flex;
  background: ${Colors.BlackReal};
  border-radius: 10px;
  padding: 0 6px;
`;

export const CommentCount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 135%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  color: ${Colors.Ash};
`;