/* eslint-disable import/no-anonymous-default-export */
import { DESIRE_TYPES } from 'constants/onboarding';

export const DESIRE_FEEL_ANSWER = {
  LOWER_THAN_ID_LIKE: 'lower than I’d like',
  JUST_RIGHT: 'just right',
  HIGHER_THAN_ID_LIKE: 'higher than I’d like',
};

export const DESIRE_TURNS_ANSWER = {
  SUDDENLY: 'suddenly',
  SLOWLY: 'slowly',
  BOTH_WAYS: 'both ways',
};

export default [
  {
    id: 1,
    name: DESIRE_FEEL_ANSWER.LOWER_THAN_ID_LIKE,
    type: 'desire-feel',
  },
  {
    id: 2,
    name: DESIRE_FEEL_ANSWER.JUST_RIGHT,
    type: 'desire-feel',
  },
  {
    id: 3,
    name: DESIRE_FEEL_ANSWER.HIGHER_THAN_ID_LIKE,
    type: 'desire-feel',
  },
  {
    id: 4,
    name: 'less effort',
    type: 'desire-mood',
  },
  {
    id: 5,
    name: 'more effort',
    type: 'desire-mood',
  },
  {
    id: 6,
    name: 'about the same',
    type: 'desire-mood',
  },
  {
    id: 7,
    name: 'more time',
    type: 'desire-partner',
  },
  {
    id: 8,
    name: 'less time',
    type: 'desire-partner',
  },
  {
    id: 9,
    name: 'about the same',
    type: 'desire-partner',
  },
  {
    id: 10,
    name: 'necessary',
    type: 'connection',
  },
  {
    id: 11,
    name: 'not necessary',
    type: 'connection',
  },
  {
    id: 12,
    name: 'a bonus',
    type: 'connection',
  },
  {
    id: 13,
    name: 'increases my desire',
    type: 'novelty',
  },
  {
    id: 14,
    name: 'decreases my desire',
    type: 'novelty',
  },
  {
    id: 15,
    name: 'isn’t a factor',
    type: 'novelty',
  },
  {
    id: 16,
    name: DESIRE_TURNS_ANSWER.SUDDENLY,
    type: 'desire-turns',
  },
  {
    id: 17,
    name: DESIRE_TURNS_ANSWER.SLOWLY,
    type: 'desire-turns',
  },
  {
    id: 18,
    name: DESIRE_TURNS_ANSWER.BOTH_WAYS,
    type: 'desire-turns',
  },
  {
    id: 16,
    name: 'more often',
    type: DESIRE_TYPES.INITIATIVE,
  },
  {
    id: 17,
    name: 'less often',
    type: DESIRE_TYPES.INITIATIVE,
  },
  {
    id: 18,
    name: 'the same amount',
    type: DESIRE_TYPES.INITIATIVE,
  },
];

export const descriptions = {
  [DESIRE_TYPES.DESIRE_FEEL]:
    'Understanding that desire changes over time and discovering how you primarily experience desire for your partner are the first steps to creating your best intimate life.',
  [DESIRE_TYPES.DESIRE_MOOD]:
    'Does it feel like in the beginning of your relationship you randomly and easily felt desire for your partner, but over time it’s begun to take more effort to get in the mood?',
  [DESIRE_TYPES.DESIRE_PARTNER]:
    'Do you feel that your desire flares up instantaneously when seeing an attractive stranger across the room, but that you need more time for desire for your partner to grow?',
  [DESIRE_TYPES.CONNECTION]:
    'Do you need to feel connected to your partner in order to feel desire?',
  [DESIRE_TYPES.NOVELTY]:
    'Does novelty, or trying new things in bed, increase your desire for your partner?',
  [DESIRE_TYPES.DESIRE_TURNS]:
    'Does your desire for your partner turn on suddenly or grow slowly?',
  [DESIRE_TYPES.INITIATIVE]:
    'How often do you initiate sex as compared to your partner?',
};

export const desireFeelResult = {
  [DESIRE_FEEL_ANSWER.LOWER_THAN_ID_LIKE]: `There are many causes of low desire. 

It could be that you primarily experience responsive desire for your partner, and you’re not creating the circumstances you need for your desire to grow.

Let's figure out if you’re feeling responsive desire in your relationship.
  `,
  [DESIRE_FEEL_ANSWER.JUST_RIGHT]: `Great! How we feel about our desire is the most important test of whether or not it's at a healthy level.

Now, let's understand desire in your relationship.`,
  [DESIRE_FEEL_ANSWER.HIGHER_THAN_ID_LIKE]: `There are many causes of "high" desire.

It could be a physiological reason, or you could think you have problematically high desire because your desire levels are high relative to your partner or peers.

Let's start by understanding desire in your relationship.`,
};

export const desireTypeResultSlideNoUser = `This means you most likely tend towards spontaneous desire in your relationship. You’re in good company! This is the dominant experience for 34% of male, 12% of female and 15% of trans and gender diverse Coralers.

Spontaneous desire is like a light switch and can seem to appear out of nowhere.`
export const desireTypeNextNoUser = `The other type of desire is responsive. Responsive desire appears gradually through arousal, or when you deliberately engage in sexy behavior.

Clinicians observe that most people rely on responsive desire in the context of long term relationships.`;
export const desireTypeResultSlideOne = {
  [DESIRE_TURNS_ANSWER.SUDDENLY]: `This means you most likely tend towards spontaneous desire in your relationship. You’re in good company! This is the dominant experience for 34% of male, 12% of female and 15% of trans and gender diverse Coralers.

Spontaneous desire is like a light switch and can seem to appear out of nowhere.`,
  [DESIRE_TURNS_ANSWER.SLOWLY]: `This means you currently tend towards responsive desire for your partner. You’re in good company! This is the dominant experience for 35% of female, 14% of male and 27% of trans and gender diverse Coralers.

Responsive desire appears gradually through arousal, or when you deliberately engage in sexy behavior. Sometimes it takes more effort to get in the mood.`,
  [DESIRE_TURNS_ANSWER.BOTH_WAYS]: `This means you’re experiencing a mix of spontaneous and responsive desire for your partner. That makes sense!

This is the dominant experience for 53% of female, 52% of male and 58% of trans and gender diverse Coralers.

Spontaneous desire is like a light switch and can seem to appear out of nowhere.`,
};

export const desireTypeResultSlideTwo = {
  [DESIRE_TURNS_ANSWER.SUDDENLY]: `The other type of desire is responsive. Responsive desire appears gradually through arousal, or when you deliberately engage in sexy behavior.

Clinicians observe that most people rely on responsive desire in the context of long term relationships.`,
  [DESIRE_TURNS_ANSWER.SLOWLY]: `The other type of desire is spontaneous. Spontaneous desire is like a light switch and can seem to appear out of nowhere.

You may have felt more spontaneous desire for your partner in the past, and you may currently feel it under different circumstances, like when you see a sexy stranger.

Clinicians observe that most people rely on responsive desire in the context of long term relationships.`,
  [DESIRE_TURNS_ANSWER.BOTH_WAYS]: `Responsive desire appears gradually through arousal, or when you deliberately engage in sexy behavior. Sometimes it takes more effort to get in the mood.

Clinicians observe that while most people rely more on responsive desire as relationships progress, they may still feel spontaneous desire from time to time.`,
};
