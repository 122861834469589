import React, { useEffect } from 'react';

import OnboardingPartnerCodeForm from 'components/OnboardingPartnerCodeForm';
import OnboardingAnswersList from 'components/OnboardingAswersList';

import { useDimensionsContext } from 'context/dimensions';
import useFlagsmith from 'common/hooks/useFlagsmith';
import { FEATURE_FLAG_NAME, FEATURE_FLAG_VARIANT_TYPE } from 'constants/feature-flags';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

import * as S from './styles';

const PartnerCode = () => {
  const { height } = useDimensionsContext();
  const {featureVariant} = useFlagsmith({
    featureName: FEATURE_FLAG_NAME.WR_OB_ORDER
  });

  const isNewOBOrder = featureVariant !== FEATURE_FLAG_VARIANT_TYPE.DEFAULT;
  const isShowNewVariant = featureVariant === FEATURE_FLAG_VARIANT_TYPE.VARIANT_C;

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_PARTNER_CODE);
  }, []);

  return (
    <S.Container height={height}>
      {!isNewOBOrder || isShowNewVariant && <OnboardingAnswersList progress={7} />}
      <OnboardingPartnerCodeForm />
      <S.TraitBottomImage />
    </S.Container>
  );
};

export default PartnerCode;
