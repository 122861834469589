import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';

import { DESIRE_TYPES, TOTAL_STEPS } from 'constants/onboarding';

import {
  useOnboardingDesireTypeContext,
  DesireType,
  DesireTypeSteps,
} from 'context/onboarding-desire-type';

import desireTypeData, {
  descriptions as descriptionsPartner,
} from 'constants/desire-types-partnered.mock';

import * as MyRoutes from 'constants/Routes';
import * as S from './styles';
import Colors from 'assets/styles/Colors';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnboardingDesireTypeMood = () => {
  const history = useHistory();
  const {setUserDesireType, steps} = useOnboardingDesireTypeContext();
  const { height } = useDimensionsContext();

  const desireMood = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES.DESIRE_MOOD,
  );

  const handleSelectValue = async (value: DesireType) => {
    setUserDesireType(DesireTypeSteps.DesireMood, value);
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_MOOD);
  }, []);

  return (
    <S.Container height={height}>
      <S.Content style={{justifyContent: 'flex-start'}}>
        <S.ProgressBar progress={6} total={TOTAL_STEPS} />
        <S.Answer
          text="My desire feels"
          selectedOption={steps?.DesireFeel?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS)
          }
        />
        <S.DescriptionLeft>
          {descriptionsPartner[DESIRE_TYPES.DESIRE_MOOD]}
        </S.DescriptionLeft>
        <S.LeftHeading >Getting in the mood takes</S.LeftHeading>
        <OnBoardingOptions>
          {desireMood?.map((value) => (
            <OnBoardingOption
              key={value.id}
              text={value.name}
              color={Colors.PaleCoral}
              onClick={() => handleSelectValue(value)}
            />
          ))}
        </OnBoardingOptions>
      </S.Content>
    </S.Container>
  );
};

export default OnboardingDesireTypeMood;
