import * as S from './styles';
import * as MyRoutes from 'constants/Routes';
import { useHistory } from 'react-router';
import analytics, { discussionListPage } from 'helpers/analytics-helper';
import { DiscussionProps } from 'types/api';

type OnboardingProps = {
  discussion: DiscussionProps;
  courseId: number;
  index?: number;
};

const CommunityCard = ({ discussion, courseId, index }: OnboardingProps) => {
  const history = useHistory();

  const startYourJourney = () => {
    history.push(MyRoutes.CONFIRMATION);
  };

  const handleClick = () => {
    const newDiscussionDetailEvent = {
      ...discussionListPage,
      CategoryId: courseId,
      UnitId: discussion.id,
    };
    analytics.googleAnalyticsEventTracker(newDiscussionDetailEvent);
  };

  const commentsLabel =
    discussion?.comments === 0
      ? 'No comments yet'
      : discussion?.comments === 1
      ? '1 comment'
      : `${discussion?.comments} comments`;

  return (
    <>
      {index === 5 && (
        <S.ListDiffrentContainer onClick={() => startYourJourney()}>
          <S.TextContainer>
            <S.CardTitleContainer>
              <S.CardTitleJourney>Start your journey today</S.CardTitleJourney>
            </S.CardTitleContainer>
            <S.FooterDescriptionJourny>
              We help over 500k people deepen their connection with science
              backed methodology
            </S.FooterDescriptionJourny>
            <S.DownloadNowText>
              Download Now
              <S.LeftSideArrow />
            </S.DownloadNowText>
          </S.TextContainer>
          <S.Image />
        </S.ListDiffrentContainer>
      )}
      <S.ListContainer
        to={`${MyRoutes.DISCUSSIONS}/${discussion.id}`}
        onClick={handleClick}>
        <S.CardTitleContainer>
          <S.CardTitle>{discussion?.name}</S.CardTitle>
          <S.CardImage />
        </S.CardTitleContainer>
        <S.FooterDescriptionContainer>
          <S.FooterDescription>{discussion?.description}</S.FooterDescription>
        </S.FooterDescriptionContainer>
        <S.CardFooter>
          {discussion?.isNew && <S.NewTooltips>NEW</S.NewTooltips>}
          <S.CommentCount>{commentsLabel}</S.CommentCount>
        </S.CardFooter>
      </S.ListContainer>
    </>
  );
};

export default CommunityCard;
