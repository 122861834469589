import React, { FC } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { STRIPE_KEY } from 'helpers/constants-helper';

type StripeProviderProps = {
  children: React.ReactNode;
  clientSecret: string;
};

const stripePromise = loadStripe(STRIPE_KEY);

const StripeProvider: FC<StripeProviderProps> = ({
  children,
  clientSecret,
}) => {
  const options = {
    clientSecret,
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export { StripeProvider };
