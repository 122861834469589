import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router';
import {useMutation} from '@apollo/client';

import onBoardingMutations from 'graphql/onBoarding/onBoarding.mutations';

import ProgressBar from 'components/ProgressBar';
import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';

import {TOTAL_STEPS} from 'constants/onboarding';
import {TRAIT_TYPES} from 'constants/traits';
import * as MyRoutes from 'constants/Routes';

import * as S from './styles';
import Colors from 'assets/styles/Colors';
import {useDimensionsContext} from 'context/dimensions';
import analytics, {ANALYTICS_EVENT_NAMES} from 'helpers/analytics-helper';
import {useFirebaseAuthContext} from 'context/auth';
import {useOnboardingTraitsContext} from 'context/onboarding-traits';

const OPTION_VALUE_ID = {
  HAS_TRAUMA: 8,
  NO_TRAUMA: 10,
};

const OnboardingDesireTypeTrauma = () => {
  const history = useHistory();
  const {height} = useDimensionsContext();
  const [loadingTrait, setLoadingTrait] = useState<boolean>(false);
  const [traitId, setTraitId] = useState<number | null>(null);
  const { isAuthenticated } = useFirebaseAuthContext();
  const [setUserTraitsOnBoarding] = useMutation(
    onBoardingMutations.SET_USER_TRAITS_ON_BOARDING,
  );
  const { storeUserTraitsLocally } = useOnboardingTraitsContext();

  const handleTraumaSelection = async (traumaId: number) => {
    setTraitId(traumaId);
    setLoadingTrait(true);
    if (!isAuthenticated) {
      storeUserTraitsLocally({id: traumaId, groupName: TRAIT_TYPES.TRAUMA, name: traitId === OPTION_VALUE_ID.HAS_TRAUMA ? "Yes": "No"});
   } else {
      await setUserTraitsOnBoarding({
        variables: {
          traitId: traumaId,
          traitCategory: TRAIT_TYPES.TRAUMA,
        },
      });
    }

    setLoadingTrait(false);
    handleProceed();
  };

  const handleProceed = () => {
    history.push(MyRoutes.ONBOARDING_CHAT);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_TRAUMA);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <ProgressBar progress={20} total={TOTAL_STEPS} />
        <S.LeftHeading>One last thing</S.LeftHeading>
        <S.DescriptionTopSpacedLeft>
          In the U.S., 25% of men, 44% of women and 47% of trans and nonbinary
          people experience sexual violence or assault in their lifetimes.
        </S.DescriptionTopSpacedLeft>
        <S.DescriptionTopSpacedLeft>
          Would you like us to personalize your journey with trauma sensitivity
          in mind?
        </S.DescriptionTopSpacedLeft>
        <OnBoardingOptions>
          <OnBoardingOption
            text="Yes"
            color={Colors.PaleCoral}
            onClick={() => handleTraumaSelection(OPTION_VALUE_ID.HAS_TRAUMA)}
            loading={loadingTrait && traitId === OPTION_VALUE_ID.HAS_TRAUMA}
          />
          <OnBoardingOption
            text="No"
            color={Colors.PaleCoral}
            onClick={() => handleTraumaSelection(OPTION_VALUE_ID.NO_TRAUMA)}
            loading={loadingTrait && traitId === OPTION_VALUE_ID.NO_TRAUMA}
          />
        </OnBoardingOptions>
      </S.TopContent>
      <S.BottomImage16 />
      <S.DesireTypeTraumaBottomImage />
    </S.Container>
  );
};

export default OnboardingDesireTypeTrauma;
