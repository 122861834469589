import { useEffect} from 'react';
import { useHistory } from 'react-router';
import { Trait } from 'context/types';
import { useOnboardingTraitsContext } from 'context/onboarding-traits';
import { TRAIT_TYPES, TraitSteps } from 'constants/traits';
import OnBoardingOption from 'components/OnboardingOption';
import OnboardingAnswersList from 'components/OnboardingAswersList';
import * as MyRoutes from 'constants/Routes';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const NOT_IN_RELATIONSHIP_ID = 14;

const TraitRelationshipStatus = () => {
  const history = useHistory();
  const { height } = useDimensionsContext();

  const {
    getTraitsForStep,
    loadingSetTrait,
    setUserTrait,
    selectedTrait,
    setOnboardingpartnerSelected,
  } = useOnboardingTraitsContext();

  const statusList = getTraitsForStep(TRAIT_TYPES.RELATIONSHIP_STATUS);


  const handleSelectStatus = async (status: Trait) => {
    setUserTrait(TraitSteps.RelashionshipStatus, status);
    if (status.id === NOT_IN_RELATIONSHIP_ID) {
      history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_TRAUMA);
    } else {
      history.push(MyRoutes.ONBOARDING_PARTNER_CODE);
    }
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_RELATIONSHIP_STATUS_TRAITS);
  }, []);

  return (
    <S.Container height={height}>
      <OnboardingAnswersList progress={17} />
      <S.LeftHeading>Currently</S.LeftHeading>
      <S.OptionList>
        {statusList?.map((status: Trait) => (
          <OnBoardingOption
            key={status.id}
            width={168}
            height={160}
            text={status.name}
            color={Colors.Skin02}
            onClick={() => handleSelectStatus(status)}
            loading={loadingSetTrait && selectedTrait === status.id}
          />
        ))}
      </S.OptionList>
      <S.BottomImage5 />
    </S.Container>
  );
};

export default TraitRelationshipStatus;
