import { useEffect } from 'react';
import { useFirebaseAuthContext } from 'context/auth';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const Logout = () => {
  const { logout, isAuthenticated } = useFirebaseAuthContext();

  useEffect(() => {
    (async () => {
      if(isAuthenticated) {
        await logout();
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.LOGOUT);
  }, []);

  return (
    <div>Logout</div>
  );
}

export default Logout;
