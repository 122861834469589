import React from 'react';

import * as S from './styles';

type HeadingProps = {
  type: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  color?: string;
  text?: string;
  children?: string | Array<React.ReactElement>;
};

function Heading(props: HeadingProps) {
  return <S.Heading {...props}>{props.text || props.children}</S.Heading>;
}

export default Heading;
