import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  fromPromise,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getAuth } from 'firebase/auth';

import { GRAPHQL_BASE_URL } from './constants-helper';
import { setAccessToken, getAccessToken } from './storage-helper';

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // @ts-ignore
        switch (err.extensions?.exception?.status) {
          case 401: {
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                // @ts-ignore
                getAuth()
                  .currentUser?.getIdToken(true)
                  .then((accessToken) => {
                    setAccessToken(
                      accessToken === undefined ? '' : accessToken,
                    );

                    resolvePendingRequests();
                    return accessToken;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  }),
              ).filter((value) => Boolean(value));
            } else {
              forward$ = fromPromise(
                new Promise((resolve) => {
                  // @ts-ignore
                  pendingRequests.push(() => resolve());
                }),
              );
            }

            return forward$.flatMap(() => forward(operation));
          }
        }
      }
    }

    if (networkError) {
      // TODO: report to sentry
    }
  },
);

const httpLink = createHttpLink({
  uri: GRAPHQL_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token || null,
    },
  };
});

const TOO_MANY_REQUESTS = 429;
const CLIENT_ERROR = /4[0-9]{2}/m;
const SERVER_ERROR = /5[0-9]{2}/m;
const SERVER_ERROR_MAXIMUM_ATTEMPTS = 3;
const MIN_DELAY = 1000;

const retryLink = new RetryLink({
  delay: (count, _, error) => {
    const errorCode = error.statusCode;
    const baseDelay = Math.min(Infinity, MIN_DELAY * Math.pow(2, count));
    const delay = Math.random() * baseDelay;

    if (SERVER_ERROR.test(`${errorCode}`)) {
      return count * 5000;
    }

    return delay;
  },
  attempts: (count, operation, error) => {
    const { operationName } = operation;

    if (operationName === 'GetPartner') {
      return false;
    }

    const errorCode = error.statusCode;
    const isTooManyRequestError = errorCode === TOO_MANY_REQUESTS;
    const isServerError = SERVER_ERROR.test(`${errorCode}`);
    const isClientError = CLIENT_ERROR.test(`${errorCode}`);

    if (isServerError) {
      return count <= SERVER_ERROR_MAXIMUM_ATTEMPTS;
    }

    return isTooManyRequestError || !isClientError;
  },
});

const concatLink = authLink
  .concat(retryLink)
  .concat(errorLink)
  .concat(httpLink);

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const client = new ApolloClient({
  link: ApolloLink.from([cleanTypeName, concatLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

export default client;
