import * as S from './styles';

type Props = {
    forumTitle: string;
};

const CoralUserLogo = ({ forumTitle }: Props) => {
    if (forumTitle !== 'team') {
        return <></>;
    } 

    return <S.Container><S.CoralUserIcon /></S.Container>
};

export default CoralUserLogo;
