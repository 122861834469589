import styled from 'styled-components';
import Heading from 'components/base/Heading';
import BodyBackGround from 'assets/images/mockups-iphone.png';

// import {getBottomSpace} from '@@helpers/platform-helper';
import Colors from 'assets/styles/Colors';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  z-index: 1;
  position: relative;
  bottom: 3%;
`;

export const TestimonialContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  z-index: 1;
  position: relative;
  top: 15%;
`;

export const Logo = styled.img.attrs(() => ({
  resizeMode: 'contain',
}))`
  margin: auto;
  width: 100px;
  height: 100px;
`;

export const Cart = styled.div`
  padding: 2rem;
  background: white;
  box-shadow: 0px 391.326px 156.714px rgb(49 37 33 / 1%),
    0px 219.949px 131.969px rgb(49 37 33 / 5%),
    0px 98.0606px 98.0606px rgb(49 37 33 / 9%),
    0px 24.7443px 54.0708px rgb(49 37 33 / 10%), 0px 0px 0px rgb(49 37 33 / 10%);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
`;

export const TestimonialCart = styled.div`
  padding: 1rem;
  background: #f7f4f3;
  box-shadow: -73px 259px 107px rgba(171, 171, 171, 0.01),
    -41px 145px 91px rgba(171, 171, 171, 0.04),
    -18px 65px 67px rgba(171, 171, 171, 0.07),
    -5px 16px 37px rgba(24, 24, 24, 0.09), 0px 0px 0px rgba(171, 171, 171, 0.09);
`;

export const BackGroungImage = styled.img.attrs(() => ({
  src: BodyBackGround,
}))`
  position: absolute;
  top: 35%;
  width: 100%;
  height: auto;
`;

export const Title = styled(Heading).attrs(() => ({
  type: 3,
}))`
  margin-top: -12px;
  text-align: center;
`;

export const Description = styled(Heading).attrs(() => ({
  type: 5,
}))`
  margin-top: 1rem;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  color: #35546f;
  font-family: 'Andes';
`;

export const TestimonialTitle = styled(Heading).attrs(() => ({
  type: 5,
}))`
  padding: 1rem;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #35546f;
`;

export const SubDescription = styled.div`
  margin-top: 16px;
  text-align: center;
`;

export const SubDescriptionText = styled(Heading).attrs(() => ({
  type: 6,
}))`
  display: inline;
`;

export const SubDescriptionRed = styled(Heading).attrs(() => ({
  type: 6,
}))`
  display: inline;
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
  color: ${Colors.Red};
  font-family: 'Andes';
  font-weight: 500;
`;

export const Footer = styled.div`
  padding: 0 16px;
  padding-bottom: 24px;
  z-index: 2;
`;

export const TestimonialFooter = styled.div`
  padding: 0 16px;
  z-index: 2;
  position: absolute;
  bottom: 2rem;
  width: 100%;
`;

export const TestimonialContainer = styled.div`
  align-items: center;
  justify-content: center;
`;
