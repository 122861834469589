export const HOME = '/';
export const LOGIN = '/login';
export const SIGNIN = '/signin';
export const LOGOUT = '/logout';
export const ACCOUNT_DELETTION = '/account-deletion';
export const SUBSCRIBE = '/subscribe';
export const CONFIRMATION = '/confirmation';
export const CODE = '/code/:code';
export const COACHING_PAYMENT = '/coaching-payment';
export const COACHING_CONFIRMATION = '/coaching-confirmation';

export const ONBOARDING_LANDING = '/onboarding-landing';
export const ONBOARDING_PITCH_ONE = '/onboarding-pitch-one';
export const VIDEO = '/video';
export const ONBOARDING_PITCH_TWO = '/onboarding-pitch-two';
export const ONBOARDING_PITCH_THREE = '/onboarding-pitch-three';
export const ONBOARDING_QUIZ = '/onboarding-quiz';
export const ONBOARDING_TRAITS = '/onboarding-traits';
export const ONBOARDING_GENDER_TRAIT = '/onboarding-gender-trait';
export const ONBOARDING_AGE_TRAIT = '/onboarding-age-trait';
export const ONBOARDING_INTERESTED_IN_TRAIT = '/onboarding-interested-in-trait';
export const ONBOARDING_RELATIONSHIP_STATUS_TRAIT =
  '/onboarding-relationship-status-trait';
export const ONBOARDING_TRAITS_CONFIRMATION = '/traits-confirmation';
export const ONBOARDING_PARTNER_CODE = '/onboarding-partner-code';
export const ONBOARDING_PARTNER_SEND_CODE = '/partner-send-code';

export const ONBOARDING_ASK_ORGANIC_SOURCE = '/onboarding-ask-organic-source';
export const ONBOARDING_DESIRE_TYPE_INTRO = '/onboarding-desire-type-intro';
export const ONBOARDING_DESIRE_TYPE_FEELS = '/onboarding-desire-type-feels';
export const ONBOARDING_DESIRE_TYPE_FEELS_RESULT =
  '/onboarding-desire-type-feels-result';
export const ONBOARDING_DESIRE_TYPE_MOOD = '/onboarding-desire-type-mood';
export const ONBOARDING_DESIRE_TYPE_FANTASY = '/onboarding-desire-type-fantasy';
export const ONBOARDING_DESIRE_TYPE_CONNECTION =
  '/onboarding-desire-type-connection';
export const ONBOARDING_DESIRE_TYPE_NOVELTY = '/onboarding-desire-type-novelty';
export const ONBOARDING_DESIRE_TYPE_DESIRE_PARTNER =
  '/onboarding-desire-type-desire-partner';
export const ONBOARDING_DESIRE_TYPE_TURNS_ON =
  '/onboarding-desire-type-turns-on';
export const ONBOARDING_DESIRE_TYPE_CONFIRMATION =
  '/onboarding-desire-type-confirmation';
export const ONBOARDING_DESIRE_TYPE_RESULT = '/onboarding-desire-type-result';
export const ONBOARDING_DESIRE_TYPE_NEXT = '/onboarding-desire-type-next';
export const ONBOARDING_DESIRE_TYPE_TRAUMA = '/onboarding-desire-type-trauma';
export const ONBOARDING_CHAT = '/onboarding-chat';
export const ONBOARDING_PLAY= '/onboarding-play';
export const ONBOARDING_LEARN = '/onboarding-learn';
export const ONBOARDING_TRIAL_PAYMENT_SELECTION = '/trial-payment-selection';
export const ONBOARDING_GOAL = '/onboarding-goal';

export const ONBOARDING_DESIRE_TYPE_IAR_INTRO =
  '/onboarding-desire-type-iar-intro';
export const ONBOARDING_DESIRE_TYPE_IAR_INITIATIVE =
  '/onboarding-desire-type-iar-initiative';
export const ONBOARDING_DESIRE_TYPE_IAR_MOOD =
  '/onboarding-desire-type-iar-mood';
export const ONBOARDING_DESIRE_TYPE_IAR_GET_MOOD =
  '/onboarding-desire-type-iar-get-mood';
export const ONBOARDING_DESIRE_TYPE_IAR_PARTNER_MOOD =
  '/onboarding-desire-type-iar-partner-mood';
export const ONBOARDING_DESIRE_TYPE_IAR_PARTNER_GET_MOOD =
  '/onboarding-desire-type-iar-partner-get-mood';
export const ONBOARDING_DESIRE_TYPE_IAR_CONFIRMATION =
  '/onboarding-desire-type-iar-get-confirmation';
export const ONBOARDING_DESIRE_TYPE_IAR_RESULT =
  '/onboarding-desire-type-iar-get-result';

export const ONBOARDING_SUGGESTED_JOURNEYS = '/onboarding-suggested-journeys';
export const ONBOARDING_FREE_TRIAL = '/onboarding-free-trial';
export const ONBOARDING_DESIRE_TYPE = '/onboarding-desire-type';
export const DESIRE_TYPE = '/desire-type';
export const EXERCISES = '/exercises/:unitId';
export const DISCUSSIONS = '/discussions';
export const ALL_DISCUSSIONS = '/discussions-category';
export const INVITE = '/invite';

export const NEW_HOME = '/new-home';
export const DESIRE_DISCREPENCY = '/desire-discrepency';

export const STORES_URL = 'https://links.getcoral.app/MVvh/eilnr460';
