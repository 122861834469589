import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  DesireType,
  DesireTypeSteps,
  useOnboardingDesireTypeContext,
} from 'context/onboarding-desire-type';

import OnBoardingOptions from 'components/OnboardingOptions';
import OnBoardingOption from 'components/OnboardingOption';

import * as MyRoutes from 'constants/Routes';
import { DESIRE_TYPES_IAR, TOTAL_STEPS } from 'constants/onboarding';

import desireTypeData, {
  descriptions as descriptionsIAR,
} from 'constants/desire-types-partnered.mock';

import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';
import useUTMParams from 'common/hooks/useUTMParams';

const OnboardingDesireTypeFeels = () => {
  const history = useHistory();

  const {utmMedium, getAllParamsString} = useUTMParams();

  const {setUserDesireType} = useOnboardingDesireTypeContext();
  const { height } = useDimensionsContext();
  
  const description = descriptionsIAR[DESIRE_TYPES_IAR.INITIATIVE];

  const desireFeel = desireTypeData?.filter(
    (value) => value.type === DESIRE_TYPES_IAR.INITIATIVE,
  );

  const handleSelectValue = async (value: DesireType) => {
    setUserDesireType(DesireTypeSteps.Initiative, value);
    utmMedium ?
    history.push(`${MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_MOOD}?${getAllParamsString()}`) :
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_IAR_MOOD);
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOARDING_DESIRE_TYPE_IAR_INITIATIVE);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={10} total={TOTAL_STEPS} />
        <S.DescriptionLeft>{description}</S.DescriptionLeft>
        <S.LeftHeading>I initiate</S.LeftHeading>
        <OnBoardingOptions>
          {desireFeel?.map((value) => (
            <OnBoardingOption
              key={value.id}
              text={value.name}
              color={Colors.PaleKelp}
              onClick={() => handleSelectValue(value)}
              loading={false}
            />
          ))}
        </OnBoardingOptions>
      </S.TopContent>
      <S.BottomImage9 />
    </S.Container>
  );
};

export default OnboardingDesireTypeFeels;
