import styled from 'styled-components';
import tw from 'twin.macro';
import { FiX } from 'react-icons/fi';
import Title from 'components/base/Heading';
import { FaCheck } from 'react-icons/fa';
import Colors from 'assets/styles/Colors';
import Paragraph from 'components/base/Paragraph';

import { ASSETS_PATH } from 'constants/assets';

export const Container = styled.div`
  background-color: ${Colors.Coconut};
  ${tw`flex flex-1 flex-col overflow-y-auto`}
`;

export const Heading = styled(Title).attrs(() => ({
  type: 2,
}))`
  font-weight: 500;
  font-size: 20px;
  line-height: 200%;
  text-align: center;
  margin: 1rem;
`;

export const SubHeading = styled(Paragraph).attrs(() => ({
  type: 3,
}))`
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  display: flex;
  align-items: center;
  font-family: 'Andes';
  justify-content: space-between;
`;

export const SubHeadingFooter = styled(Paragraph).attrs(() => ({
  type: 3,
}))`
  padding-top: 2rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  align-items: center;
  font-family: 'Andes';
  justify-content: space-between;
  width: 50%;
  align-self: center;
`;

export const SubHeadingLeft = styled.div` 
  width: 3.2rem;
  text-align: left;
  height: 1px;
  background: #000000;
`;

export const SubHeadingCenter = styled.div`
  padding: 0 0.5rem;
`;

export const SubHeadingFooterCenter = styled.div`
  padding: 0 0.5rem;
  display: block;
  text-align: center;
  width: 100%;
`;

export const SubHeadingRight = styled.div` 
  width: 3.2rem;
  text-align: right;
  height: 1px;
  background: #000000;
`;

export const SubscriptionMethods = styled.div`
  ${tw`z-10`}
  padding: 2rem 1rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

export const TextContainer = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  display: flex;
  min-width: 80%;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem auto;
`;

export const CheckIcon = styled(FaCheck)`
  color: white;
  font-size: 20px;
  margin-right: 20px;
  background: #ADADAD;
  border-radius: 50%;
  padding: 4px;
`;

export const CheckIconPremium = styled(FaCheck)`
  color: white;
  font-size: 20px;
  margin-right: 20px;
  background: #FC6E65;
  border-radius: 50%;
  padding: 4px;
`;

export const CheckBoXContainer = styled.div`
margin: 1rem auto;
`;

export const ButtonContainer = styled.div`
padding: 1.25rem;
`;

export const CoralLogo = styled.img.attrs(() => ({
  src: `${ASSETS_PATH}/coral-logo.svg`,
}))`
  ${tw`m-5 text-left z-10`}
  width: 100px;
`;

export const CloseButton = styled.a`
  position: absolute;
  display: flex;
  top: 12px;
  right: 16px;
  height: 47px;
  width: 47px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 12px 20px rgba(0, 0, 0, 0.11);
  z-index: 20;
`;

export const CloseIcon = styled(FiX)`
  color: ${Colors.Black};
  font-size: 26px;
`;