import { useEffect, useState } from 'react';
import * as S from './styles';
import RelatedCategoriesCard from '../RelatedCategoriesCard';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Related1 from 'assets/images/DesireDiscrepency/related-1.png';
import Related2 from 'assets/images/DesireDiscrepency/related-2.png';
import Related3 from 'assets/images/DesireDiscrepency/related-3.png';

const responsive = {
  0: { items: 1 },
  700: { items: 2 },
  1024: { items: 3 },
};

type categoryProps = {
  title: string;
};

const RelatedCategoriesCardContainer = ({title}: categoryProps) => {

  const data = [
    {
      name: 'Tell us more',
      image: Related1
    },
    {
      name: 'Sex after infidelity',
      image: Related2
    },
    {
      name: 'Coral confessions',
      image: Related3
    },
    {
      name: 'Desire discrepancy',
      image: Related3
    },
    {
      name: 'LTR sex',
      image: Related1
    }
  ];

  const createItems = () => {
    const allData = data?.filter((item) => item.name !== title);
    return allData.map((item, i) => (
      <RelatedCategoriesCard data={item} />
    ));
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState(createItems());

  useEffect(() => {
    setItems(createItems());
  }, []);

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  return (
    <>
      <S.PrevNextButton>
        <S.PrevButton onClick={slidePrev}>
          <S.LeftSideArrow />
        </S.PrevButton>
        <S.PrevButton onClick={slideNext}>
          <S.RightSideArrow />
        </S.PrevButton>
      </S.PrevNextButton>

      <S.CommunityCardContainer>

        <AliceCarousel
          mouseTracking
          disableDotsControls
          disableButtonsControls
          items={items}
          activeIndex={activeIndex}
          responsive={responsive}
          onSlideChanged={syncActiveIndex}
        />

      </S.CommunityCardContainer>
    </>
  );
};

export default RelatedCategoriesCardContainer;
