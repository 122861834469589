import * as MyRoutes from 'constants/Routes';
import { DiscussionProps } from 'types/api';
import analytics, { discussionsPage } from 'helpers/analytics-helper';

import * as S from './styles';

type Props = {
  discussion: DiscussionProps;
  big?: boolean;
};

const DiscussionCard = ({ discussion, big = false }: Props) => {
  const handleClick = () => {
    const newEvent = { ...discussionsPage, unitId: discussion.id };
    analytics.googleAnalyticsEventTracker(newEvent);
  };

  const commentsLabel =
    discussion.comments === 0
      ? 'No comments yet'
      : discussion.comments === 1
      ? '1 comment'
      : `${discussion.comments} Comment`;

  return (
    <S.Container
      to={`${MyRoutes.DISCUSSIONS}/${discussion.id}`}
      onClick={handleClick}
      big={big}>
      <S.Header>
        <S.Title>
          {discussion.name
            ? discussion.name
            : discussion.title
            ? discussion.title
            : ''}
        </S.Title>
        <div>
          <S.Icon />
        </div>
      </S.Header>
      {!!discussion.description && (
        <S.DescriptionOverlay>
          <S.Description>{discussion.description}</S.Description>
        </S.DescriptionOverlay>
      )}
      <S.Footer>
        {discussion.isNew && (
          <S.Badge>
            <S.BadgeLabel>NEW</S.BadgeLabel>
          </S.Badge>
        )}
        <S.Comments>{commentsLabel}</S.Comments>
      </S.Footer>
    </S.Container>
  );
};

export default DiscussionCard;
