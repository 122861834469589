import React, { useEffect} from 'react';
import { useHistory } from 'react-router';

import { useOnboardingDesireTypeContext } from 'context/onboarding-desire-type';
import { useCacheContext } from 'context/cache';

import {desireFeelResult as desireFeelResultPartner} from 'constants/desire-types-partnered.mock';
import {desireFeelResult as desireFeelResultSingle} from 'constants/desire-types-single.mock';
import { TOTAL_STEPS } from 'constants/onboarding';

import {isInARelationship} from 'helpers/traits-helper';

import * as MyRoutes from 'constants/Routes';
import Colors from 'assets/styles/Colors';
import * as S from './styles';
import { parseStringToMultipleLines } from 'helpers/utility-helper';
import { useDimensionsContext } from 'context/dimensions';
import analytics, { ANALYTICS_EVENT_NAMES } from 'helpers/analytics-helper';

const OnboardingDesireTypeFeelsResult = () => {
  const history = useHistory();
  const {steps} = useOnboardingDesireTypeContext();
  const {userTraits} = useCacheContext();
  const { height } = useDimensionsContext();
  const isUserInARelationship = isInARelationship(userTraits);
  const description = isUserInARelationship
    ? desireFeelResultPartner[steps?.DesireFeel?.name]
    : desireFeelResultSingle[steps?.DesireFeel?.name];

  const handleContinue = () => {
    history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_MOOD)
  };

  useEffect(() => {
    analytics.googleAnalyticsEventTracker(ANALYTICS_EVENT_NAMES.BOAEDING_DESIRE_TYPE_FEELS_RESULTS);
  }, []);

  return (
    <S.Container height={height}>
      <S.TopContent>
        <S.ProgressBar progress={5} total={TOTAL_STEPS} />
        <S.Answer
          text="My desire feels"
          selectedOption={steps?.DesireFeel?.name}
          color={Colors.PaleKelp}
          onClick={() =>
            history.push(MyRoutes.ONBOARDING_DESIRE_TYPE_FEELS)
          }
        />
        { parseStringToMultipleLines(description).map((descriptionLine, idx) => (
          <S.DescriptionLeft key={idx}>{descriptionLine}</S.DescriptionLeft>
        ))}
      </S.TopContent>
      <S.Footer>
        <S.Button onClick={handleContinue}>CONTINUE</S.Button>
      </S.Footer>
      <S.BottomImage20 />
    </S.Container>
  );
};

export default OnboardingDesireTypeFeelsResult;
