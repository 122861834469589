import React from 'react';
import hexRgb from 'hex-rgb';

import Colors from 'assets/styles/Colors';

import * as S from './styles';
import Loader from '../Loader';

interface ButtonProps {
  color?: string,
  children: React.ReactNode;
  textColor?: string;
  icon?: React.ReactElement | null;
  onClick?: () => void;
  style?: {}
  disabled?: boolean;
  loading?: boolean;

}
const Button  = ({
  color = Colors.Black,
  children,
  textColor = Colors.White,
  icon,
  onClick = () => {},
  style = {},
  disabled = false,
  loading = false,
  ...props
}: ButtonProps) => {
  const getDisabledBgColor = (color: string) => {
    return hexRgb(color, {format: 'css', alpha: 0.4})
  }

  return (
      <S.Button
        color={disabled ? getDisabledBgColor(color) : color}
        textColor={textColor}
        onClick={onClick}
        disabled={disabled}
        style={style}
        {...props}
      >
        {icon && <S.IconContainer>{icon}</S.IconContainer>}
        {loading ? (
          <S.LoaderContainer>
            <Loader color={textColor} />
          </S.LoaderContainer>
        ) : <></>}
        { children }
      </S.Button>
  )
}

export default Button;