import React, { useContext, useState } from 'react';

type SubscriptionContextProps = {
  isSubscribed: boolean;
  setIsSubscribed: (isSubscribed: boolean) => void;
};

type Props = {
  children: React.ReactNode;
};

const SubscriptionContext = React.createContext({} as SubscriptionContextProps);

export const SubscriptionProvider = ({ children }: Props) => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  return (
    <SubscriptionContext.Provider
      value={{
        isSubscribed,
        setIsSubscribed,
      }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => useContext(SubscriptionContext);
