import gql from 'graphql-tag';

const INVITE_PARTNER = gql`
  mutation InvitePartner($partnerInvitation: PartnerInvitation!) {
    invitePartner(partnerInvitation: $partnerInvitation) {
      id
    }
  }
`;

const ACCEPT_PARTNER = gql`
  mutation AcceptPartner($acceptPartner: AcceptPartner) {
    acceptPartner(acceptPartner: $acceptPartner) {
      isSubscriptionFreeFromPartner
      accepted
      inviterEmail
      inviterId
      inviterPhone
      invitationId
      name
      username
      freeViaPartner
    }
  }
`;

const DELETE_PARTNER = gql`
  mutation DeletePartner($invitationId: Int!) {
    deletePartner(invitationId: $invitationId) {
      message
      user {
        subscriptionId
        subscriptionStarted
        subscriptionExpiry
        subscriptionRenewalTerm
        subscriptionRenews
        subscriptionMethod
        isSubscribed
      }
    }
  }
`;

const CREATE_PARTNER_CHAT_USER = gql`
  mutation CreatePartnerChatUser($userId: String!) {
    createPartnerChatUser(userId: $userId)
  }
`;

const SET_EXERCISE_ITINERARY = gql`
  mutation SetExerciseItinerary($unitIds: [Int!]!) {
    setExerciseItineraryUnits(unitIds: $unitIds) {
      id
    }
  }
`;

export default {
  ACCEPT_PARTNER,
  INVITE_PARTNER,
  DELETE_PARTNER,
  CREATE_PARTNER_CHAT_USER,
  SET_EXERCISE_ITINERARY,
};
