import gql from 'graphql-tag';

const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation createStripeSubscription(
    $priceId: String!
    $paymentMethod: String!
    $coupon: String
    $promotionCode: String
  ) {
    createStripeSubscription(
      priceId: $priceId,
      paymentMethod: $paymentMethod,
      coupon: $coupon,
      promotionCode: $promotionCode
    ) {
      subscriptionId
    }
  }
`;

const CREATE_STRIPE_SUBSCRIPTION_DEV = gql`
  mutation createStripeSubscriptionDev(
    $priceId: String!
    $paymentMethod: String!
    $coupon: String
    $promotionCode: String
  ) {
    createStripeSubscription_dev(
      priceId: $priceId,
      paymentMethod: $paymentMethod,
      coupon: $coupon,
      promotionCode: $promotionCode
    ) {
      subscriptionId
    }
  }
`;

const CREATE_STRIPE_SETUP_INTENT = gql`
  mutation setupStripeIntent {
    setupStripeIntent {
      clientSecret
    }
  }
`;

const CREATE_STRIPE_SETUP_INTENT_DEV = gql`
  mutation setupStripeIntentDev {
    setupStripeIntent_dev {
      clientSecret
    }
  }
`;

const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation stripePaymentIntentsCreate(
    $currency: String!
    $amount: Int!
  ) {
    stripePaymentIntentsCreate(
      currency: $currency,
      amount: $amount,
    ){
    	clientSecret
    }
  }
`;

const CREATE_STRIPE_PAYMENT_INTENT_DEV = gql`
  mutation stripePaymentIntentsCreateDev(
    $currency: String!
    $amount: Int!
  ) {
    stripePaymentIntentsCreate_dev(
      currency: $currency,
      amount: $amount,
    ){
    	clientSecret
    }
  }
`;

export { 
  CREATE_STRIPE_SUBSCRIPTION, 
  CREATE_STRIPE_SETUP_INTENT,
  CREATE_STRIPE_SETUP_INTENT_DEV,
  CREATE_STRIPE_SUBSCRIPTION_DEV,
  CREATE_STRIPE_PAYMENT_INTENT_DEV,
  CREATE_STRIPE_PAYMENT_INTENT 
};
