import React from 'react';
import * as S from './styles';

const Banner = () => (
  <S.BannerText>
    <S.BannerTitle>
      Special offer:
      <S.bannerText>
        Get 25% off +7 days free with code
      </S.bannerText>
      <S.GoalsText>
        GOALS
      </S.GoalsText>
    </S.BannerTitle>
  </S.BannerText>
);

export default Banner;
