import { useState } from 'react'
import * as S from './styles';

type Props = {
  title?: string;
  breadcrumbs?: any;
};

const SearchBox = ({ title, breadcrumbs }: Props) => {
  const [search, setSearch] = useState();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <S.WaveBg2 />
      <S.WaveBg1 />
      <S.TextHeaderContainer>
        <S.TextHeader>
          <S.CoralLogoContainer target="_self" href='/'>
            <S.CoralLogoHeader />
          </S.CoralLogoContainer>
          <S.ScreenPathContainer>
            {breadcrumbs.map((path, index, { length }) => {
              return (
                <>
                  <S.ScreenPath target="_self" href='#' key={index}>
                    {path}
                  </S.ScreenPath>
                  {length - 1 !== index && <S.Slash>/</S.Slash>}
                </>
              )
            })}
          </S.ScreenPathContainer>
          <S.TextSearchBoxContainer>
            <S.Title>
              {title}
            </S.Title>

            {/* SearchBox */}
            {/* <S.SearchBoxContainer>
              <S.IconContainer>
                <S.SearchIcon />
              </S.IconContainer>
              <S.AuthInput
                placeholder={'What are you looking for?'}
                value={search}
                type="email"
                onChange={(e) => handleChange(e)}
              />

              <S.IconContainer>
                <S.WifiIcon />
              </S.IconContainer>
            </S.SearchBoxContainer> */}

          </S.TextSearchBoxContainer>

        </S.TextHeader>
      </S.TextHeaderContainer>
    </>
  );
};

export default SearchBox;
