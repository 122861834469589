import {
    GetPaginatedCommentsByUnitId_getPaginatedCommentsByUnitId_comments
} from 'graphql/discussions/types/GetPaginatedCommentsByUnitId';

import * as S from './styles';
import ExpertLabel from 'components/base/ExpertLabel';
import {formatDistance} from "date-fns";
import EllipsedText from "../EllipsedText";
import { Author } from 'components/DiscussionComments';
//For Coral Logo
import CoralUserLogo from 'components/base/CoralUserLogo';
import { ONBOARDING_LANDING } from "../../constants/Routes";
import { useHistory } from "react-router";

type Props = GetPaginatedCommentsByUnitId_getPaginatedCommentsByUnitId_comments & {
    author: string;
    forumTitle: string;
    isReply: boolean;
    isNew?: boolean;
    onLike?: (commentId: number) => void;
    onUnlikeComment?: (commentId: number) => void;
    onAddReply?: (isReply: boolean, comment: Author) => void;
    onFlagAsInappropriate?: (commentId: number) => void;
};

const Comment = ({
    author,
    forumTitle,
    createdAt,
    text,
    liked,
    likesTotal,
    isNew,
}: Props) => {
    const history = useHistory();
    const upvoteText = `Upvote${liked ? 'd' : ''}(${likesTotal})`;
    const dateText = formatDistance(new Date(createdAt), new Date(), {addSuffix: true})
    
    const onLinkPress = () => {
        history.push(ONBOARDING_LANDING);
    }

    return (
        <S.Container>
            <S.Content isExpertAnswer={forumTitle === 'expert'}>
                <S.Header>
                    <S.Title>
                        <S.AuthorText>{`${author}`}</S.AuthorText>
                        <ExpertLabel forumTitle={forumTitle}/>
                        <CoralUserLogo forumTitle={forumTitle} />
                        {isNew && (
                            <S.NewBadge>
                                <S.NewBadgeLabel>new</S.NewBadgeLabel>
                            </S.NewBadge>
                        )}
                    </S.Title>
                    <S.Spacer/>
                    <S.HerderRightButton>···</S.HerderRightButton>
                </S.Header>
                <EllipsedText>{text}</EllipsedText>
                <S.Footer>
                    <S.LinkButton onClick={onLinkPress}>
                        <S.LinkButtonText>Reply</S.LinkButtonText>
                    </S.LinkButton>
                    <S.LinkButton onClick={onLinkPress}>
                        <S.LinkButtonText>{upvoteText}</S.LinkButtonText>
                    </S.LinkButton>
                    <S.Spacer/>
                    <S.DateText>{dateText}</S.DateText>
                </S.Footer>
            </S.Content>
        </S.Container>
    );
};

export default Comment;
