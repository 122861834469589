import styled from 'styled-components';
import Colors from 'assets/styles/Colors';

export const ListContainer = styled.div`
  margin: 0 0.5%;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 6%);
  background: ${Colors.White};
  border-radius: 24px;
  margin: 10px;
  flex: 0 0 30%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex: 0 0 45%;
  } 

  @media (max-width: 768px) {
    flex: 0 0 90%;
  } 
`;

export const CardTitleContainer = styled.div`
  display: flex;
  font-family: 'Canela';
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  width: 50%;
  margin-top: 6%;
  margin-left: 10%;
`;

export const CardTitle = styled.div`
  font-family: 'Canela';
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0
  min-height: 73px;

  @media (max-width: 450px) {
    font-size: 24px;
  } 
`;

export const FooterLogo = styled.img.attrs(() => ({
  resizeMode: 'contain',
}))`
  border-radius: 24px;
  max-height: 140px;

  @media (max-width: 450px) {
    width: 50%;
    max-height: 150px;
  } 
`;